import SisenseDashboard from '../types/SisenseDashboard';
import { authedFetch, Endpoints } from '../utils/API';
import ReportType from '../types/ReportType';

export default class ReportsActions {

  /**
   * Function called to fetch the gpc dashboard
   */
  static fetchGpcReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.gpc.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the largest users dashboard
   */
  static fetchLargestUsersReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.largestUsers.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the dynamic carbon v1 dashboard
   */
  static fetchGetDynamicCarbonV1Report(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.dynamicCarbonV1.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the community ghgi dashboard
   */
  static fetchCommunityGHGIReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.communityGHGI.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the nestle report
   */
  static fetchNhscConsolidatedReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.nhscConsolidated.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the nestle report
   */
  static fetchSolarTogetherReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.solarTogether.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the list of reports
   */
  static fetchReportsList(): Promise<ReportType[]> {
    return authedFetch(Endpoints.auth.reports.fetchAll, {
      method: 'GET',
    })
      .then((res) => {
        return res.reportTypes;
      });
  }

  /**
   * Function called to fetch the sasb dashboard
   */
  static fetchSasbReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.sasb.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the gri dashboard
   */
  static fetchGriReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.gri.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the fy dynamic carbon dashboard
   */
  static fetchFYDynamicCarbonReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.fyDynamicCarbon.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the gps dashboard
   */
  static fetchGpsReport(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.gps.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

    /**
   * Function called to fetch the gps2 dashboard
   */
   static fetchGps2Report(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.gps2.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the gps dashboard
   */
  static fetchEndCustomerAllocation(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.endCustomerAllocation.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the ryder custom 1 dashboard
   */
  static fetchRyderCustom1(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.ryderCustom1.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

  /**
   * Function called to fetch the vitaflo custom 1 dashboard
   */
  static fetchVitafloCustom1(): Promise<SisenseDashboard> {
    return authedFetch(Endpoints.auth.reports.vitafloCustom1.fetch, {
      method: 'GET',
    })
      .then((res) => {
        return res.dashboard;
      });
  }

}

export async function getEmissionsReport(organizationId: string, reportYear: number) {
  const res = await authedFetch(Endpoints.auth.reports.emissions.fetch, {
    method: 'GET',
    queryParameters: {
      organizationId,
      year: reportYear.toString(),
    },
  })

  return res.report
}

export async function getActivityReport(organizationId: string, reportYear: number) {
  const res = await authedFetch(Endpoints.auth.reports.activity.fetch, {
    method: 'GET',
    queryParameters: {
      organizationId,
      year: reportYear.toString(),
    },
  })

  return res.report
}

export async function getEmissionsReportYears(organizationId: string) {
  const res = await authedFetch(Endpoints.auth.reports.emissions.years.fetch, {
    method: 'GET',
    queryParameters: {
      organizationId,
    },
  })

  return res.years
}
