import Strategy from '../types/Strategy';
import SharedActions, { ActionType } from '../utils/ActionTypes';

export type StrategyState = {
  strategies: { [key: string]: Strategy }
}

const initialState = {
  strategies: {},
};

export default function strategyReducer(
  state: StrategyState = initialState,
  action: ActionType
): StrategyState {
  switch (action.type) {

    case SharedActions.goal.update: {
      const { strategies } = action.payload;

      return {
        ...state,
        strategies: {
          ...state.strategies,
          ...strategies
        }
      };
    }

    case SharedActions.treeView.fetchFullTree: {
      const { strategies } = action.payload;
      const strategiesObject = strategies.reduce(
        (obj: any, item: Strategy) => ((obj[item.id] = item), obj),
        {}
      );

      return {
        ...state,
        strategies: {
          ...state.strategies,
          ...strategiesObject,
        },
      };
    }

    case SharedActions.strategy.create:
    case SharedActions.strategy.update:
    case SharedActions.initiative.create:
    case SharedActions.initiative.delete: {
      const { strategy } = action.payload;

      return {
        ...state,
        strategies: {
          ...state.strategies,
          [strategy.id]: strategy
        }
      };
    }

    case SharedActions.strategy.delete: {
      const { strategyId } = action.payload;

      const { [strategyId]: removedItem, ...rest } = state.strategies;

      return {
        ...state,
        strategies: {
          ...rest,
        }
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}