import { useState, useEffect } from "react"
import { JsxElement } from "typescript"
import classNames from "../utils/classNames"

export interface Props {
  tabs: TabItem[]
  onChange: (tab: TabItem) => void
  containerStyle?: string
  tabStyle?: string
}

export interface TabItem {
  name: string
  icon?: JsxElement
  disabled?: boolean,
  id: string
}

export default function Tabs({ tabs, onChange, tabStyle, containerStyle }: Props) {
  const [selected, setSelected] = useState<TabItem>(tabs[0])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  return (
    <div className={classNames("flex gap-x-2", containerStyle)}>
      {tabs.map((tab) => (
        <button
          disabled={tab.disabled}
          className={classNames("focus:outline-none rounded-md border flex items-center px-3 py-2 cursor-pointer", selected.id === tab.id ? "bg-accent shadow-sm text-white text-bold" : "bg-white ", tabStyle, "disabled:cursor-not-allowed disabled:opacity-50")} key={tab.name}
          onClick={() => !tab.disabled && setSelected(tab)}
        >
          {tab.icon}
          <h4>{tab.name}</h4>
        </button>
      ))}
    </div>
  )
}