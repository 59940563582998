import { useMemo, useState } from 'react'
import { SelectOption } from '~/components/Select'
// import { FuelAccount, PurchasedElectricityAccount, WasteAccount, WaterAccount, RefrigerantsAccount } from '~/types/Account'
import { PartialActivity } from '~/types/Activity'
import { GHGIActivityData } from '~/views/DataImport/useDataImport'
import { GeneralAccountEditForm } from '~/views/EmissionsPortfolio/GeneralAccountEditForm'
// import FuelAccountEditForm from '~/views/EmissionsPortfolio/FuelAccountEditForm'
// import PurchasedElectricityAccountEditForm from '~/views/EmissionsPortfolio/PurchasedElectricityAccountEditForm'
// import WasteAccountEditForm from '~/views/EmissionsPortfolio/WasteAccontEditForm'
// import WaterAccountEditForm from '~/views/EmissionsPortfolio/WaterAccountEditForm'
// import RefrigerantsAccountEditForm from '~/views/EmissionsPortfolio/RefrigerantsAccountEditForm'
import FacilityAssetEditForm from '~/views/EmissionsPortfolio/FacilityAssetEditForm'
import classNames from '~/utils/classNames'
import DashboardChangeConfirmation, { ConfirmChange } from '~/components/DashboardChangeConfirmation'
import type { CustomerAssetType, CustomerLocationAccountType } from '~/types/entities'
import { AccountSelector } from '~/views/EmissionsPortfolio/AccountSelector'

interface Props {
  selectedAsset?: CustomerAssetType
  selectedActivity?: GHGIActivityData
  setSelectedActivity: (activity: GHGIActivityData) => void
  activityTypes: GHGIActivityData[]
  accounts: CustomerLocationAccountType[]
  activity?: any // Not ideal but typescript is not happy with the union type
  loadingAccounts: boolean
  selectedAccount?: CustomerLocationAccountType
  setSelectedAccount: (account: CustomerLocationAccountType) => void
  updateAsset: (assetId: string, update: Partial<CustomerAssetType>) => Promise<void>
  updateAccount: (update: any) => Promise<void>
  updateActivity: (update: PartialActivity) => Promise<void>
  onLoadMore: () => void
  editing: boolean
  setEditing: (editing: boolean) => void
}

const accountMapping = (account: CustomerLocationAccountType) => ({
  name: account.accountNumber,
  id: String(account.id),
  category: account.resource.category,
})

export function AccountsList({
  selectedAsset,
  selectedActivity,
  // setSelectedActivity,
  // activity,
  accounts,
  loadingAccounts,
  selectedAccount,
  setSelectedAccount,
  // updateAccount,
  updateAsset,
  // updateActivity,
  onLoadMore,
  editing,
  setEditing,
  activityTypes,
}: Props) {
  const [confirmChange, showConfirmation] = useState<ConfirmChange | undefined>()
  const [loadingConfirmation, setLoadingConfirmation] = useState(false)

  const accountOptions: Record<string, SelectOption[]> = useMemo(() => {

    const options: Record<string, SelectOption[]> = {}

    for (const activityType of activityTypes) {
      options[activityType.id] = accounts
        .filter(account => account.resource?.category === activityType.label)
        .map(accountMapping) as SelectOption[]
    }

    options.all = accounts.map(accountMapping) as SelectOption[]

    return options
  }, [accounts, activityTypes])

  const onConfirmChanges = async () => {
    try {
      setLoadingConfirmation(true)
      await confirmChange?.onConfirm()
      setLoadingConfirmation(false)
      showConfirmation(undefined)
    }
    catch (error) {
      console.error('Error confirming change', error)
    }
  }

  const onCloseChanges = () => {
    confirmChange?.onCancel()
    showConfirmation(undefined)
  }

  const onChangeAccount = (option: SelectOption) => {
    const selected = accounts.find((account: CustomerLocationAccountType) => String(account.id) === option.id)
    if (selected) setSelectedAccount(selected)
  }

  const isConfirmingChanges = !!confirmChange
  const hasSelectedActivity = !!selectedActivity
  const hasSelectableActivityTypes = activityTypes.length > 0
  const selectedAccountOption = accountOptions[selectedActivity?.id ?? 'all']?.find(option => option.id === String(selectedAccount?.id))

  return (
    <div className={classNames("flex-grow flex-1 flex flex-col mb-12 bg-white rounded-lg shadow-sm border")}>
      <DashboardChangeConfirmation
        visible={isConfirmingChanges}
        label={confirmChange?.label ?? ''}
        changes={confirmChange?.changes ?? []}
        onConfirm={onConfirmChanges}
        onClose={onCloseChanges}
        loading={loadingConfirmation}
      />

      {hasSelectableActivityTypes && selectedActivity && !accountOptions[selectedActivity.id]?.length && (
        <div className="py-4 px-6 flex flex-col items-center">
          <h3 className="font-bold text-lg mb-1">No accounts for this category</h3>
          <p className="text-gray-500">Upload a workbook to see the accounts</p>
        </div>
      )}

      {/*Loading activity types*/}
      {!hasSelectedActivity && !activityTypes && (
        <div className="w-full bg-gray-100 h-32 rounded-lg animate-pulse" />
      )}

      {hasSelectableActivityTypes ? (
        activityTypes
          .filter(activityType => !selectedActivity || activityType.id === selectedActivity.id)
          .map(activityType => (
            <AccountSelector
              key={activityType.id}
              activityType={activityType}
              loading={loadingAccounts}
              disabled={!accountOptions[activityType.id]?.length || editing}
              selectedAccountOption={selectedAccountOption}
              accountOptions={accountOptions[activityType.id] ?? []}
              onChangeAccount={onChangeAccount}
              onLoadMore={onLoadMore}
            />
          ))
      ) : (
        <AccountSelector
          loading={loadingAccounts}
          disabled={!accountOptions.all?.length || editing}
          selectedAccountOption={selectedAccountOption}
          accountOptions={accountOptions.all ?? []}
          onChangeAccount={onChangeAccount}
          onLoadMore={onLoadMore}
        />
      )}

      {selectedAsset && (
        <FacilityAssetEditForm
          asset={selectedAsset}
          updateAsset={updateAsset}
          onEditing={setEditing}
          showConfirmation={showConfirmation}
        />
      )}

      {selectedAccount && <>
        <div className="h-[1px] w-full bg-slate-100 my-4x" />
        <GeneralAccountEditForm account={selectedAccount} />
        {/* TODO: Having copy pasted code like this is not ideal. Make a generalized component.*/}
        {/*selectedAccount.resource.category === 'Mobile Combustion' && (
          <MobileCombustionAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
        {/*selectedAccount.resource.category === 'Stationary Combustion' && (
          <FuelAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
        {/*selectedAccount.resource.category === 'Purchased Electricity' && (
          <PurchasedElectricityAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
        {/*selectedAccount.resource.category === 'Waste' && (
          <WasteAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
        {/*selectedAccount.resource.category === 'Water' && (
          <WaterAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
        {/*selectedAccount.resource.category === 'Refrigerants' && (
          <RefrigerantsAccountEditForm
            account={selectedAccount}
            updateAccount={updateAccount}
            onEditing={setEditing}
            showConfirmation={showConfirmation}
          />
        )*/}
      </>}
    </div>
  )
}
