/** Removes one element from an array and doesn't mutate the original array. */
export function removeOne<T>(items: T[], match: (item: T) => boolean) {
  const i = items.findIndex(match)
  if (i === -1) return items
  items = [...items]
  items.splice(i, 1)
  return items
}

/** Modifies one element in an array and doesn't mutate the original array. */
export function findAndUpdateOne<T>(items: T[], match: (item: T) => boolean, newItem: T) {
  const i = items.findIndex(match)
  if (i === -1) return items
  items = [...items]
  items[i] = newItem
  return items
}

export function uniq<T>(arr: T[]) : T[] {
  return Array.from(new Set(arr))
}
