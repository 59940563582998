import {
  useState,
  useEffect,
  useMemo,
} from 'react'
import { useForm } from 'react-hook-form'
import { AiFillEdit, AiOutlineSave } from 'react-icons/ai'
import Button from '~/components/Button'
import Input from '~/components/Input'
import Data from '~/components/Data'
import FormSelect/*, { SelectOption }*/ from '~/components/FormSelect'
import { FormUtils } from '~/utils/FormUtils'
// import { UsStates } from '~/utils/UsStates'
import { ConfirmChange } from '~/components/DashboardChangeConfirmation'
import type { CustomerAssetType } from '~/types/entities'

interface Props {
  asset: CustomerAssetType,
  updateAsset: (assetId: string, asset: Partial<CustomerAssetType>) => Promise<void>
  onEditing: (editing: boolean) => void
  showConfirmation: (change: ConfirmChange) => void
}

// Andres: Using the dot notation for the nested values will return an object in the submit handler
const createDefaultForm: any = (asset: CustomerAssetType) => ({
  nickName: asset.nickName,
  street: asset.street ?? '',
  city: asset.city ?? '',
  state: asset.state ?? '',
  zipCode: asset.zipCode ?? '',
  poc1: asset.poc1 ?? '',
  poc1Email: asset.poc1Email ?? '',
  departmentName: asset.departmentName ?? '',
  type: asset.type,
  leased: asset.leased,
})

export default function FacilityAssetEditForm({
  asset,
  updateAsset,
  onEditing,
  showConfirmation,
}: Props) {
  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false) // eslint-disable-line @typescript-eslint/no-unused-vars

  const defaultForm = createDefaultForm(asset)

  const assetForm = useForm({ defaultValues: defaultForm })

  /*const stateOptions: SelectOption[] = useMemo(() => UsStates.map((state) => ({
    name: state.name,
    value: state.shorthand,
    id: state.shorthand
  })), [UsStates])*/

  const addressDeps = [asset.street, asset.city, asset.state, asset.zipCode]
  const assetAddress: string = useMemo(() => addressDeps.filter(item => !!item).join(', '), addressDeps)

  useEffect(() => {
    assetForm.reset(defaultForm)
  }, [asset])

  useEffect(() => {
    console.log('Errors: ', assetForm.formState.errors)
  }, [assetForm.formState.errors])

  const handleSubmit = assetForm.handleSubmit(async (data) => {
    console.log('New asset data: ', data)
    // TODO: v2 asset edit
    try {
      setSaving(true)
      // Revert the select options to the original values
      data.leased = data.leased.value
      data.id = asset.id
      // There is a limitation in this util where it will not detect changes in nested objects
      const changes = FormUtils.getObjectChanges(defaultForm, data)
      const assetChanges = FormUtils.getObjectDifference(defaultForm, data)

      console.log('Changes: ', changes, assetChanges)
      showConfirmation({
        label: asset.nickName!,
        changes: assetChanges,
        onConfirm: async () => {
          await updateAsset(data.id, changes)
          setEditing(false)
          onEditing(false)
        },
        onCancel: () => { }
      })
    }
    catch (error: any) {
      console.error(error)
    }
    finally {
      setSaving(false)
    }
  })

  return (
    <div className="w-full flex flex-col p-4">
      {editing ? (
        <>
          <div className="flex items-center justify-between">
            <h5 className="text-gray-400 uppercase font-semibold text-xs tracking-widest mb-3">ASSET DETAIL</h5>
            <span className="flex items-center justify-end">
              <Button
                label="Cancel"
                variant='text'
                onClick={() => {
                  setEditing(false)
                  onEditing(false)
                }}
              />
              <span className="w-2" />
              <Button
                label="Save"
                iconLeft={<AiOutlineSave />}
                type="submit"
                form="asset-form"
                loading={saving}
              />
            </span>
          </div>
          <form
            className="grid grid-cols-6 w-full mt-2 gap-x-4 gap-y-3"
            onSubmit={handleSubmit}
            id="asset-form"
          >
            <span className="col-span-3">
              <Input
                label="Name"
                name="nickName"
                options={{ required: true }}
                register={assetForm.register}
                value={assetForm.watch("nickName")}
                disabled={saving}
              />
            </span>
            <span className="col-span-3">
              <Input
                label="Type"
                name="type"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("type")}
                disabled={saving}
              />
            </span>
            <span className="col-span-4">
              <Input
                label="Address"
                name="street"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("street")}
                disabled={saving}
              />
            </span>
            <span className="col-span-2">
              <Input
                label="City"
                name="city"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("city")}
                disabled={saving}
              />
            </span>
            <span className="col-span-2">
              <Input
                label="State"
                name="state"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("state")}
                disabled={saving}
              />
            </span>
            <span className="col-span-2">
              <Input
                label="Zip Code"
                name="zipCode"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("zipCode")}
                disabled={saving}
              />
            </span>
            <span className="col-span-3">
              <Input
                label="Point of Contact Name"
                name="poc1"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("poc1")}
                disabled={saving}
              />
            </span>
            <span className="col-span-3">
              <Input
                label="Point of Contact Email"
                name="poc1Email"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("poc1Email")}
                disabled={saving}
              />
            </span>
            <span className="col-span-3">
              <Input
                label="Department"
                name="departmentName"
                options={{ required: false }}
                register={assetForm.register}
                value={assetForm.watch("departmentName")}
                disabled={saving}
              />
            </span>
            <span className="col-span-3">
              <FormSelect
                label="Leased?"
                name="leased"
                options={{ required: false }}
                register={assetForm.register}
                control={assetForm.control}
                defaultValue={asset.leased ? { name: 'Yes', value: true, id: 'yes' } : { name: 'No', value: false, id: 'no' }}
                value={assetForm.watch("leased")}
                selectOptions={[
                  { name: 'Yes', value: true, id: 'yes' },
                  { name: 'No', value: false, id: 'no' },
                ]}
                disabled={saving}
              />
            </span>
          </form>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <h5 className="text-gray-400 uppercase font-semibold text-xs tracking-widest mb-3">ASSET DETAIL</h5>
            <button
              className="text-slate-400 hover:text-slate-600 focus:outline-none focus:text-slate-600 flex items-center justify-end"
              onClick={() => {
                setEditing(true)
                onEditing(true)
              }}
            >
              <AiFillEdit className="text-lg mr-2" />
              <p className="font-semibold">Edit</p>
            </button>
          </div>
          <div className="grid grid-cols-[2fr_1fr] gap-4">
            <div className="flex flex-col">
              <h4 className="text-xl font-bold mb-2">{asset.nickName}</h4>
              <p className="text-slate-600">{assetAddress}</p>
              <p className="text-slate-600">{asset.country}</p>
              <span className="h-6" />
              <h5 className="font-semibold text-slate-400 text-xs">Point of Contact</h5>
              <div className="flex items-center mt-2">
                <span className="h-10 w-10 bg-gray-200 flex items-center justify-center rounded-full mr-3">
                  <h5 className="font-bold uppercase text-slate-500">{asset.poc1 && asset.poc1[0]}</h5>
                </span>
                <span className="flex flex-col">
                  <h5 className="font-semibold text-slate-600">{asset.poc1}</h5>
                  <p className="text-slate-500">{asset.poc1Email}</p>
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <Data
                label="Department"
                value={asset.departmentName ?? ''}
                valueStyles="capitalize"
              />
              <span className="h-4" />
              <Data
                label="Type"
                value={asset.type ?? ''}
                valueStyles="capitalize"
              />
              <span className="h-4" />
              <Data
                label="Leased"
                value={asset.leased ? "Yes" : "No"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
