
let FileCache: {[fileId: string]: {fileUrl: string, expires?: number}} = {};

/**
 * Function called to clear the file cache
 */
export function clearFileCache() {
  FileCache = {};
}

export default FileCache;