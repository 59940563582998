import SharedActions, { ActionType } from '../utils/ActionTypes';
import User from '../types/User';
import Organization from '../types/Organization';

export type UserState = {
  currentUser: string | null
  organization: Organization | null
  users: {[key: string]: User}
}

const initialState = {
  currentUser: null,
  organization: { id: '', planId: '5e9f439ff6e79f15bfa5583c', title: 'TODO Replace' }, //TODO: Fetch this
  users: {},
};

export default function userReducer(
  state: UserState = initialState,
  action: ActionType
): UserState {
  switch (action.type) {
    case SharedActions.init: {
      return {
        ...state,
        organization: action.payload.organization,
        users: action.payload.users,
      };
    }

    case SharedActions.user.setCurrentUser: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case SharedActions.user.update: {
      const user: User = action.payload;
      return {...state, users: {...state.users, [user.id]: user}};
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}