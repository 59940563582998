import { useDataImport } from './useDataImport'
import SelectGHGICategory from '../SelectGHGICategory'
import AddInformation from '../AddInformation'
import UploadManager from '../UploadManager'
import EmissionsPortfolio from '../EmissionsPortfolio'
import { PageLayout } from '../../components/PageLayout'

export default function DataImport() {
  const {
    currentStep,
    onGHGICategorySelected,
    goBack,
    ghgiCategories,
    loading,
    uploadFilesAndGoToManage,
    goToPortfolio,
  } = useDataImport()
  return (
    <PageLayout fullWidth={true}>
      <div className="w-full h-full">
        {
          currentStep === 'category' &&
          <SelectGHGICategory
            onGHGICategorySelected={onGHGICategorySelected}
            loading={loading}
            ghgiCategories={ghgiCategories}
          />
        }
        {
          currentStep === 'upload' &&
          <AddInformation
            goBack={goBack}
            loading={loading}
            ghgiCategories={ghgiCategories}
            nextStep={uploadFilesAndGoToManage}
          />
        }
        {
          currentStep === 'manage' &&
          <UploadManager
            goBack={goBack}
            ghgiCategories={ghgiCategories}
            loading={loading}
            nextStep={goToPortfolio}
          />
        }
        {
          currentStep === 'portfolio' &&
          <EmissionsPortfolio
            ghgiCategories={ghgiCategories}
            loading={loading}
          />
        }
      </div>
    </PageLayout>
  )
}
