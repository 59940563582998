import { useEffect } from "react"
import { useState, useContext } from "react"
import FileActions from "../../actions/FileActions"
import { useAlertContext } from "../../contexts/AlertContext"
import { UserContext, UserContextType } from '../../contexts/UserContext'
import { Endpoints, GET } from "../../utils/API"

export type CDPHistory = {
  date: Date,
  url: string,
  name?: string,
}

export type CDPReportValue = {
  key?: string,
  value?: string | CDPReportValue[],
  name: string,
}

export type CDPReport = {
  year: number,
  c6: CDPReportValue[],
  c7: CDPReportValue[]
}

const defaults = {
  report: { year: 0, c6: [], c7: []},
  year: new Date().getFullYear()
}

const numFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const reportNames = {
  c61: 'Gross Global Scope 1 Emissions (metric tons CO2e)',
  c62: 'Gross Global Scope 2 Emissions: Location Based (metric tons CO2e)',
  c63: 'Gross Global Scope 2 Emissions: Market Based (metric tons CO2e)',
  c72: 'Country/Region, Scope 1 Emissions (metric tons CO2e)',
  c73: 'By Facility, By Activity',
  c73b: 'Facility, Scope 1 Emissions (metric tons CO2e)',
  c73c: 'Activity, Scope 1 Emissions (metric tons CO2e)',
  c75: 'Country/Region, Scope 2 Emissions (metric tons CO2e)',
  c76: 'By Facility',
  c76b: 'Facility, Scope 2 Emissions (metric tons CO2e)',
}

export const useCDPReport = () => {
  const { organization } = useContext(UserContext) as UserContextType
  const { showAlert } = useAlertContext()

  // DEFINE A TYPE FOR REPORTS AND HISTORY ONCE WE FIGURE THAT OUT
  const [reportList, setReportList] = useState<CDPReport[]>([])
  const [report, setReport] = useState<CDPReport>(defaults.report)
  const [exportHistory, setExportHistory] = useState<CDPHistory[]>([])
  const [selectedYear, setSelectedYear] = useState(defaults.year)
  const [prevYear, setPrevYear] = useState(0)
  const [nextYear, setNextYear] = useState(0)
  const [availableYears, setAvailableYears] = useState<number[]>([])
  const [exporting, setExporting] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadReports()
  }, [organization])

  const loadReports = async () => {
    setLoading(true)
    try {
        if (organization) {
          // Call server for all reports
          const { data }: any = await GET({
            endpoint: Endpoints.auth.reports.cdp.fetch,
            queryParameters: { organizationId: organization._id}
          })

          const res = data.map((r:any) => {
            const report:CDPReport = { year: Number(r.year), c6: [], c7: [] }

            if (r.c61 || r.c61 === 0) report.c6.push({ key: 'c61', name: reportNames.c61, value: numFormatter.format(r.c61) })
            if (r.c62 || r.c62 === 0) report.c6.push({ key: 'c62', name: reportNames.c62, value: numFormatter.format(r.c62) })
            if (r.c63 || r.c63 === 0) report.c6.push({ key: 'c63', name: reportNames.c63, value: numFormatter.format(r.c63) })

            if (r.c72) report.c7.push({
              key: 'c72',
              name: reportNames.c72,
              value: Object.keys(r.c72).map((k) => ({ name: k, value: numFormatter.format(r.c72[k]) }))
            })
            // reportNames.c73 is the value in this case, and the actual question is the name
            if (r.c73) report.c7.push({ key: 'c73', name: reportNames.c73 })
            if (r.c73b) report.c7.push({
              key: 'c73b',
              name: reportNames.c73b,
              value: Object.keys(r.c73b).map((k) => ({ name: k, value: numFormatter.format(r.c73b[k]) }))
            })
            if (r.c73c) report.c7.push({
              key: 'c73c',
              name: reportNames.c73c,
              value: Object.keys(r.c73c).map((k) => ({ name: k, value: numFormatter.format(r.c73c[k]) }))
            })
            if (r.c75) report.c7.push({
              key: 'c75',
              name: reportNames.c75,
              value: Object.keys(r.c75).map((k) => ({ name: k, value: numFormatter.format(r.c75[k]) }))
            })
            // reportNames.c76 is the value in this case, and the actual question is the name
            if (r.c76) report.c7.push({ key: 'c76', name: reportNames.c76 })
            if (r.c76b) report.c7.push({
              key: 'c76b',
              name: reportNames.c76b,
              value: Object.keys(r.c76b).map((k) => ({ name: k, value: numFormatter.format(r.c76b[k]) }))
            })

            return report
          })

          res.sort((a:CDPReport,b:CDPReport) => b.year - a.year)

          // Load report history (parallel)
          await loadHistory(res[0]?.year || [])

          setReportList(res)
          setReport(res[0] || defaults.report)
          setSelectedYear(res[0]?.year || defaults.year)
          setNextYear(0)
          setPrevYear(res[1]?.year || 0)
          setAvailableYears(res.map((r:CDPReport) => r.year))
        }
      } catch (e: any) {
        console.error(e)
        showAlert({
          title: 'There was a problem loading the report',
          message: e.message,
          variation: 'error'
        })
      }
    setLoading(false)
  }

  const loadHistory = async (year: number) => {
    try {
      // Call server for this report's history
      if (organization) {
        const res = await FileActions.getCDPHistory(organization._id, year)
        const history = res.documents.map((file:any) => ({ date: new Date(file.created), url: file.url, name: file.name }))
        setExportHistory(history.sort((a:CDPHistory,b:CDPHistory) => Number(a.date) - Number(b.date)))
      } else {
        throw new Error('No organization detected')
      }
    } catch (e: any) {
      console.error(e)
      showAlert({
        title: 'There was a problem loading report history',
        message: e.message,
        variation: 'error'
      })
    }
  }

  const changeYear = async (newYear: number) => {
    setLoading(true)
    const index = reportList.findIndex((rep) => rep.year === newYear)

    if (index > -1) {
      await loadHistory(newYear)

      setReport(reportList[index])
      setSelectedYear(reportList[index].year)
      setPrevYear(reportList[index+1]?.year || 0)
      setNextYear(reportList[index-1]?.year || 0)
    }

    setLoading(false)
  }

  const exportCSV = async () => {
    setExporting(true)

    try {
      let data = 'item,detail,value,filter\n'
      const csvRow = (row:CDPReportValue) => {
        if (Array.isArray(row.value)) {
          row.value.forEach((subrow:CDPReportValue) => {
            data += `${row.key},"${row.name}","${subrow.value || ''}","${subrow.name}"\n`
          })
        } else {
          data += `${row.key},"${row.name}","${row.value || ''}", \n`
        }
      }
      report.c6?.forEach(csvRow)
      report.c7?.forEach(csvRow)

      const fileName = `CDP_${report.year}_${new Date().toLocaleDateString().replace(/\//g, '-')}.csv`
      const file = new File([data], fileName, { type: 'text/csv' })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement("a")
      link.download = fileName
      link.href = fileURL
      link.click()

      if (organization) {
        // Only upload if latest was more than 24h ago
        const today = Date.now()
        const latest = Number(exportHistory[exportHistory.length - 1]?.date) || 0
        if (today - latest > 24 * 3600 * 1000) {
          // UPLOAD FILE
          await FileActions.uploadFile(
            file,
            Endpoints.auth.documents.create,
            organization._id,
            "CDP_REPORT",
            report.year
          )

          // REFRESH HISTORY
          await loadHistory(report.year)
        }
      } else {
        throw new Error('No organization detected')
      }
    } catch (e: any) {
      console.error(e)
      showAlert({
        title: 'There was a problem exporting the report',
        message: e.message,
        variation: 'error'
      })
    }

    setExporting(false)
  }

  return {
    availableYears,
    selectedYear,
    changeYear,
    prevYear,
    nextYear,
    exportHistory,
    exportCSV,
    report,
    organization,
    loading,
    exporting,
  }
}

/*const dummyReport = {
  year: 2022,
  c6: [
    {
      key: 'c61',
      name: 'Gross Global Scope 1 Emissions (metric tons CO2e)',
      value: 61.87
    },
    {
      key: 'c62',
      name: 'Gross Global Scope 2 Emissions: Location Based (metric tons CO2e)',
      value: 5861
    },
    {
      key: 'c63',
      name: 'Gross Global Scope 2 Emissions: Market Based (metric tons CO2e):',
      value: 4965
    }
  ],
  c7: [
    {
      key: 'c72',
      name: 'Country/Region, Scope 1 Emissions (metric tons CO2e)',
      value: [
        { name: "France", value: 0 },
        { name: "United Kingdom", value: 0 },
        { name: "USA", value: 0 },
        { name: "Belgium", value: 0 },
        { name: "Czech Republic", value: 0 },
        { name: "Germany", value: 0 },
        { name: "Italy", value: 0 },
        { name: "Romania", value: 0 },
        { name: "Brazil", value: 0 },
        { name: "Luxembourg", value: 0 },
        { name: "Morocco", value: 0 },
        { name: "Poland", value: 0 },
        { name: "Spain", value: 0 },
        { name: "Turkey", value: 0 },
        { name: "UK", value: 0 },
        { name: "Uruguay", value: 0 },
      ]
    },
    {
      key: 'c73',
      name: 'By Facility, By Activity',
    },
    {
      key: 'c73b',
      name: 'Facility, Scope 1 Emissions (metric tons CO2e)',
      value: [
        { name: "Beaune, France", value: 0 },
        { name: "Bierne, France", value: 0 },
        { name: "Gresford, Uk", value: 0 },
        { name: "Alsip-69", value: 0 },
        { name: "Atlanta-80", value: 0 },
        { name: "Champaign-64", value: 0 },
        { name: "East Longmeadow-73", value: 0 },
        { name: "Garland-72", value: 0 },
        { name: "Havre De Grace-79", value: 0 },
        { name: "Hebron-81", value: 0 },
      ]
    },
    {
      key: 'c73c',
      name: 'Activity, Scope 1 Emissions (metric tons CO2e)',
      value: [
        { name: "LPG Liters - Carbon Fund", value: 0 },
        { name: "Natural gas_kWh_Carbon Fund", value: 0 },
        { name: "Electricity (KWh)", value: 0 },
      ]
    },
  ]
}*/
