import { useContext } from 'react'
import { UserContext, UserContextType } from '../../contexts/UserContext'

export const useSelectGHGICategory = () => {
  const { organization } = useContext(UserContext) as UserContextType

  return {
    organization,
  }
}
