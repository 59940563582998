import SharedActions, { ActionType } from '../utils/ActionTypes';
import Commitment from '../types/Commitment';

export type CommitmentState = {
  commitments: { [key: string]: Commitment }
}

const initialState = {
  commitments: {},
};

export default function commitmentReducer(
  state: CommitmentState = initialState,
  action: ActionType
): CommitmentState {
  switch (action.type) {

    case SharedActions.commitments.fetch: {
      const { commitments } = action.payload;

      return {
        ...state,
        commitments: {
          ...state.commitments,
          ...commitments,
        }
      };
    }

    case SharedActions.commitments.create:
    case SharedActions.commitments.update: {
      const { commitment } = action.payload;

      return {
        ...state,
        commitments: {
          ...state.commitments,
          [commitment.id]: commitment
        }
      };
    }

    case SharedActions.commitments.delete: {
      const { commitmentId } = action.payload;

      const { [commitmentId]: removedItem, ...rest } = state.commitments;

      return {
        ...state,
        commitments: {
          ...rest,
        }
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}