import moment, { Moment } from "moment-timezone";

type FileData = {
    fileId: string
    fileName: string
    fileSize: number //bytes

    url: string

    created: Moment
    updated: Moment
}

/**
 * Function called to convert a db generic user object to a local generic user object
 *
 * @param result: the db object to be converted
 */
export function dbFileDataToLocalFileData(result: any): FileData {
    return {
        fileId: result.fileId,
        fileName: result.fileName,
        fileSize: result.fileSize,

        url: result.url,

        created: moment(result.created),
        updated: moment(result.updated),
    };
}

export default FileData;