import { useEffect, useState } from 'react';
import loading from "../../../assets/loading.svg";

const Loading = ({ delay = 0 }: {delay?: number}) => {

  const [ showLoader, setShowLoader ] = useState(false);
  useEffect(() => {
    const displayTimeout = setTimeout(() => {
      setShowLoader(true);
    }, delay);

    return () => clearTimeout(displayTimeout);
  }, []);

  return (
    <div className="spinner">
      {showLoader &&
      <img src={loading} alt="Loading"/>
      }
    </div>
  );
};

export default Loading;
