import { AiOutlineCloseCircle, AiOutlineUpload, AiOutlineWarning } from 'react-icons/ai'
import { BsBackspace } from 'react-icons/bs'
import { WorkbookWarning } from '../../types/File'
import classNames from '../../utils/classNames'

interface Props {
  warning: WorkbookWarning,
  containerStyles?: string,
  onIgnoreClicked: (issue: WorkbookWarning) => void,
  onAddWorkbook: (event: any) => void
}

export default function FileWarning({ warning, containerStyles, onIgnoreClicked, onAddWorkbook }: Props) {
  return (
    <div className={classNames("flex-grow flex flex-col px-4 pb-4 border-t border-slate-200 pt-4", containerStyles)}>
      <div className="flex items-center mb-3">
        {
          warning.type === 'error'
            ? <AiOutlineCloseCircle className="text-red-400 mr-2 text-lg" />
            : <AiOutlineWarning className="text-yellow-400 mr-2 text-lg" />
        }

        <h4 className="font-bold">{warning.message}</h4>
      </div>
      <p className="text-slate-600">
        {
          warning.type === 'error'
            ? 'This file will not be imported until this issue is resolved. Please upload a fixed version of this file.'
            : 'This file will be imported, but this field will be ignored until a fixed version on the workbook is uploaded.'
        }
      </p>
      {
        warning.sampleValues && warning.sampleValues.length > 0 && (
          <span className='mt-3'>
            <h5 className="uppercase text-slate-500 text-xs mb-2 tracking-widest">Sample Values</h5>
            <div className="border border-slate-300 rounded-xl shadow-sm overflow-hidden flex flex-col">
              <table className="w-full rounded-md table-auto">
                <tbody>
                  {/* <tr className="divide-x divide-y divide-gray-200">
                    <td className="px-3 py-2 text-center"><p>{warning.row !== undefined && warning.row !== undefined ? warning.row : '--'}</p></td>
                    <td className="px-3 py-2"><p>Facility Asset Information</p></td>
                  </tr> */}
                  {
                    warning.sampleValues.map((value: any, index: number) => (
                      <tr className="divide-x divide-b divide-gray-200">
                        <td className="px-3 py-2 text-center w-12"><p>{warning.row + index + 1}</p></td>
                        <td className="px-3 py-2"><p>{value}</p></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </span>
        )
      }
      <div className="flex items-center justify-end mt-2 w-full">
        {/* <div className="flex items-center">
          <AiOutlineCheck className="text-green-400 mr-2 text-lg" />
          <h4 className="text-green-500 font-bold">All items passed validation</h4>
        </div> */}
        {
          warning.type === 'warning' && (
            <button className="flex items-center justify-end rounded-lg hover:bg-gray-100 p-2" onClick={() => onIgnoreClicked(warning)}>
              <BsBackspace className="text-red-400 mr-2 text-lg" />
              <h4 className="text-red-500 font-bold">Ignore this</h4>
            </button>
          )
        }
        {
          warning.type === 'error' && (
            <div className="flex items-center justify-end rounded-lg hover:bg-gray-100 p-2 relative cursor-pointer border border-slate-200">
              <input className="absolute opacity-0 top-0 bottom-0 left-0 right-0 cursor-pointer" type="file" onChange={onAddWorkbook} />
              <AiOutlineUpload className="text-slate-700 mr-2 text-lg" />
              <h4 className="text-slate-700 font-bold">Upload Workbook</h4>
            </div>
          )
        }
      </div>
    </div>
  )
}
