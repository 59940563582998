/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useState, useRef, useEffect } from 'react'
import { AiOutlineDelete, AiOutlineSearch } from 'react-icons/ai'
import { BiChevronLeft, BiChevronRight, BiDownArrowAlt, BiFirstPage, BiLastPage, BiUpArrowAlt } from 'react-icons/bi'
import { MdDeleteOutline, MdEdit, MdRefresh, MdSaveAlt } from 'react-icons/md'
import {
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
  ColumnDef,
} from '@tanstack/react-table'
import classNames from '../../utils/classNames'
import Select from '../Select'
import Filter from './Filter'
import { TbColumnInsertRight, TbRowInsertBottom } from 'react-icons/tb'
import Button from '../Button'
import { useForm } from 'react-hook-form'
import Modal from '../Modal'

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    thClass?: string
    tdClass?: string
    inputType?: 'number' | 'string' | 'date', // This will govern all the inputs in an editable field
    inputRequired?: boolean
    removableCol?: boolean
  }
}

interface ActionButton {
  icon: ReactNode
  tooltip?: string
  disabled?: Function
  onClick?: Function,
  tableAction?: 'addRow' | 'addColumn'
}

export interface EditableTable {
  onRowAdd?: (newData: any) => Promise<void>
  onRowUpdate?: (newData: any, oldData: any) => Promise<void>
  onRowDelete?: (oldData: any) => Promise<void>
  onRowCreate?: (newData: any) => Promise<void>
  onColumnCreate?: (label: any) => Promise<void>
  onColumnDelete?: (label: any) => Promise<void>
}

interface Props {
  title?: string | ReactNode,
  columns: ColumnDef<unknown, any>[],
  data: any[],
  options?: any,
  searchable?: boolean,
  pageSize?: number,
  actions?: ActionButton[],
  rowActions?: ActionButton[],
  loading?: boolean,
  downloadable?: boolean | string,
  reload?: Function,
  editable?: EditableTable
}

export const exportCSV = async (title: string, columns: any[], rows: any[]) => {
  const safeString = (str: string) => String(str).includes(',') ? `"${str}"` : str
  const headers = columns.map((h) => safeString(h.header))

  let data = `${headers.join(',')}\n`

  rows.forEach((row) => {
    const rowArray = columns.map((h) => safeString(row[h.accessorKey]))
    data += `${rowArray.join(',')}\n`
  })

  const fileName = `${title}_${new Date().toLocaleDateString().replace(/\//g, '-')}.csv`
  const file = new File([data], fileName, { type: 'text/csv' })
  const fileURL = URL.createObjectURL(file)
  const link = document.createElement("a")
  link.download = fileName
  link.href = fileURL
  link.click()
}

export default function Table({
  title,
  columns,
  data,
  options = {},
  pageSize = 20,
  searchable = true,
  actions,
  rowActions,
  loading,
  downloadable,
  reload,
  editable,
}: Props) {
  const [globalFilter, setGlobalFilter] = useState('')
  const [showDraftColumn, setShowDraftColumn] = useState<boolean>(false)
  const [showDraftRow, setShowDraftRow] = useState<boolean>(false)
  const [draftColumnLabel, setDraftColumnLabel] = useState<string>('')
  const [editableRowId, setEditableRowId] = useState<string>()
  const [deleteRowId, setDeleteRowId] = useState<string>()
  const [deleteColumnId, setDeleteColumnId] = useState<string>()
  const tableWrapperRef = useRef<any>(null)

  const { register, handleSubmit, watch, reset, setValue } = useForm()

  const newRowSubmit = handleSubmit((formData) => {
    if (editableRowId && editable?.onRowUpdate) {
      delete formData.rowId
      // Optismistic update
      editable.onRowUpdate(formData, data[Number(editableRowId)])
      setEditableRowId(undefined)
    } else if (editable?.onRowCreate) {
      // Optismistic creation
      editable.onRowCreate(formData)
      setShowDraftRow(false)
    }
    reset()
  })

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    initialState: {
      pagination: { pageSize }
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...options,
  })

  const isFiltered = !!table.getAllColumns().reduce((a, c) => a + Number(!!c.getFilterValue()), 0)

  useEffect(() => {
    if (editableRowId) {
      const row = table.getRow(editableRowId)
      if (row) {
        const cells = row.getAllCells()
        setValue('rowId', row.id)
        for (const cell of cells) {
          setValue(cell.column.id, cell.renderValue())
        }
      }
    }
  }, [editableRowId])

  const openColumCreator = async () => {
    setShowDraftColumn(true)
    await new Promise((r) => setTimeout(r, 100)) // Wait for the new column to become visible
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollLeft = tableWrapperRef.current.scrollWidth
    }
  }

  const openRowCreator = async () => {
    setShowDraftRow(true)
    await new Promise((r) => setTimeout(r, 100)) // Wait for the new column to become visible
    if (tableWrapperRef.current) {
      tableWrapperRef.current.scrollTop = tableWrapperRef.current.scrollHeight
    }
  }

  return (
    <>
      <Modal
        title="Delete Row Confirmation"
        description="Are you sure you want to delete this row? This action cannot be undone"
        open={Boolean(deleteRowId)}
        onClose={() => { }}
        actionButtons={[
          {
            label: 'Cancel',
            onClick: () => setDeleteRowId(undefined),
            variant: 'ghost',
            disabled: loading
          },
          {
            label: 'Confirm',
            onClick: async () => {
              if (editable?.onRowDelete && deleteRowId) {
                // Optismistic deletion
                editable.onRowDelete(data[Number(deleteRowId)])
                setDeleteRowId(undefined)
              }
            },
            variant: 'danger',
            loading: loading,
            disabled: loading,
          }
        ]}
      />
      <Modal
        title="Delete Column Confirmation"
        description="Are you sure you want to delete this column? This action cannot be undone"
        open={Boolean(deleteColumnId)}
        onClose={() => setDeleteColumnId(undefined)}
        actionButtons={[
          {
            label: 'Cancel',
            onClick: () => setDeleteColumnId(undefined),
            variant: 'ghost',
            disabled: loading
          },
          {
            label: 'Confirm',
            onClick: async () => {
              if (editable?.onColumnDelete && deleteColumnId) {
                // Optismistic deletion
                editable.onColumnDelete(deleteColumnId)
                setDeleteColumnId(undefined)
              }
            },
            variant: 'danger',
            loading: loading,
            disabled: loading,
          }
        ]}
      />
      <div className="w-full rounded-lg border-slate-200 bg-white shadow-md overflow-hidden">
        {/* Header */}
        <div className="p-3 flex justify-between items-center">
          <h3 className="font-bold">{title}</h3>
          <div className="flex items-center mr-1">
            {isFiltered && (
              <button
                className="mr-3 text-gray-400 hover:text-gray-600 focus:outline-none"
                onClick={() => table.resetColumnFilters()}
              >
                Clear Filters
              </button>
            )}
            {searchable && <div
              className="w-44 flex justify-between items-center border border-slate-200 rounded-full py-2 px-3"
            >
              <input
                type="text"
                placeholder="Search"
                className="w-full outline-none"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              <AiOutlineSearch className="text-accent text-xl focus:outline-none" />
            </div>}
            {downloadable && (
              <button
                type="button"
                title="Export as CSV"
                onClick={() => exportCSV(
                  (typeof downloadable === 'string' && downloadable)
                  || (typeof title === 'string' && title)
                  || 'Sustainabase Table',
                  columns,
                  data
                )}
                className="text-accent hover:text-accent-dark hover:bg-gray-100 p-2 rounded-full text-xl ml-3 "
              >
                <MdSaveAlt />
              </button>
            )}
            {reload && (
              <button
                type="button"
                title="Refresh Data"
                className={classNames(
                  'text-accent text-xl focus:outline-none rounded-full p-2',
                  loading ? 'animate-spin' : '',
                  !!reload ? 'hover:text-accent-dark hover:bg-gray-100' : '',
                  downloadable ? 'ml-1' : 'ml-3'
                )}
                disabled={!reload}
                onClick={() => reload && reload()}
              >
                <MdRefresh />
              </button>
            )}
            {actions?.map((act, i) => (
              <button
                key={`custom-act-${i}`}
                type="button"
                title={act.tooltip}
                className={classNames(
                  'text-accent focus:outline-none rounded-full p-2 ml-1 text-xl',
                  'hover:enabled:text-accent-dark hover:enabled:bg-gray-100',
                  'disabled:text-gray-300 disabled:cursor-not-allowed',
                )}
                disabled={act.disabled && act.disabled()}
                onClick={() => {
                  if (act.tableAction === 'addColumn') openColumCreator()
                  else if (act.tableAction === 'addRow') openRowCreator()
                  else if (act.onClick) act?.onClick()
                }}
              >
                {act.icon}
              </button>
            ))}
          </div>
        </div>
        {/* Table */}
        <div className="relative">
          {
            editable?.onColumnCreate && !showDraftColumn && !draftColumnLabel && !editableRowId && (
              <button
                className="absolute z-10 top-0 right-0 hover:right-3 hover:top-3 hover:bottom-3 rounded-md bottom-0 opacity-0 hover:opacity-70 flex items-center justify-center bg-gray-300 w-3 hover:w-12 hover:transition-opacity shadow-md cursor-pointer backdrop-blur-lg"
                onClick={openColumCreator}
              >
                <TbColumnInsertRight />
              </button>
            )
          }
          {
            editable?.onRowCreate && !showDraftRow && !editableRowId && !draftColumnLabel && (
              <button
                className="absolute z-10 bottom-2 right-0 left-0 hover:bottom-3 hover:right-3 hover:left-3 rounded-md opacity-0 hover:opacity-70 flex items-center justify-center bg-gray-300 h-3 hover:h-12 hover:transition-opacity shadow-md cursor-pointer backdrop-blur-lg"
                onClick={openRowCreator}
              >
                <TbRowInsertBottom />
              </button>
            )
          }
          <div className="w-full overflow-x-auto" ref={tableWrapperRef}>
            <form onSubmit={newRowSubmit} id='new-row-form'>
              <table className="w-full">
                <thead className="border-t border-slate-200">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {(rowActions || editable?.onRowUpdate || editable?.onRowDelete) && (
                        <th className="border-x border-slate-200 p-3 w-0" >
                          <div className="flex items-center text-center justify-center text-gray-400 font-normal cursor-default w-full">
                            Actions
                          </div>
                        </th>
                      )}
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={classNames(
                            'py-3 pl-3 pr-2 relative',
                            header.column.columnDef.meta?.thClass || '',
                            !header.column.columnDef.meta?.thClass?.includes('border') ?
                              'border-x border-slate-200' : ''
                          )}
                        >
                          {editable?.onColumnDelete && header.column.columnDef.meta?.removableCol && (
                            <button
                              type="button"
                              className="z-30 flex items-center justify-center flex-grow border-2 absolute right-0 left-0  top-0 h-3 hover:h-12 opacity-0 hover:opacity-75 bg-red-500 rounded-md active:outline-none focus:outline-none"
                              onClick={(event) => setDeleteColumnId(header.column.id)}
                            >
                              <AiOutlineDelete className="text-white text-xl" />
                            </button>
                          )}
                          {header.isPlaceholder ? null : (
                            <div className="flex items-center justify-between w-full">
                              <div
                                title={header.column.getCanSort() ? `Sort by ${header.column.columnDef.header}` : ''}
                                className={classNames(
                                  'flex items-center transition-colors text-gray-400 font-normal justify-between',
                                  header.column.getCanSort() ? 'cursor-pointer' : '',
                                  header.column.getCanSort() && !header.column.getIsSorted() ? 'hover:text-gray-600 group' : ''
                                )}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {header.column.getCanSort() && (
                                  <span className="w-5">
                                    {{
                                      asc: <BiUpArrowAlt className="text-sm ml-1" />,
                                      desc: <BiDownArrowAlt className="text-sm ml-1" />,
                                    }[header.column.getIsSorted() as string] ?? null}
                                    {!header.column.getIsSorted() && (
                                      <BiUpArrowAlt className="text-sm ml-1 hidden group-hover:block" />
                                    )}
                                  </span>
                                )}
                              </div>
                              {/* Here goes the filter */}
                              {header.column.getCanFilter() && (
                                <Filter column={header.column} table={table} />
                              )}
                            </div>
                          )}
                        </th>
                      ))}
                      {
                        showDraftColumn && (
                          <th className="relative shadow-md overflow-visible w-36">
                            <input
                              placeholder="Title here" className="border-b px-3 py-1 mx-2 text-sm placeholder:italic placeholder:font-light w-32 backdrop-blur-lg"
                              onChange={(e) => setDraftColumnLabel(e.target.value)}
                            />
                            <div className="absolute z-10 flex flex-col top-14 left-0 right-0 px-2 py-2 rounded-md bg-white">
                              <Button
                                label='Create'
                                variant='primary'
                                type='button'
                                onClick={() => {
                                  if (editable?.onColumnCreate) {
                                    editable.onColumnCreate(draftColumnLabel)
                                  }
                                  setShowDraftColumn(false)
                                  setDraftColumnLabel('')
                                }}
                                className='shadow-sm justify-center'
                              />
                              <div className="h-3" />
                              <Button
                                label='Cancel'
                                variant='ghost'
                                type='button'
                                onClick={() => {
                                  setShowDraftColumn(false)
                                  setDraftColumnLabel('')
                                }}
                                className='shadow-sm justify-center'
                              />
                            </div>
                          </th>
                        )
                      }
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} className="border-b border-slate-200">
                      {rowActions && (
                        <td className="text-center border-x border-slate-200 p-2 w-0">
                          <div className="min-w-max">
                            {rowActions?.map((act, i) => (
                              <button
                                key={`${row.id}-act-${i}`}
                                type="button"
                                title={act.tooltip}
                                className={classNames(
                                  'p-2.5 rounded-full hover:enabled:bg-gray-100',
                                  'disabled:text-gray-300 disabled:cursor-not-allowed',
                                )}
                                disabled={act.disabled && act.disabled(row.original)}
                                onClick={() => act.onClick && act.onClick(row.original)}
                              >
                                {act.icon}
                              </button>
                            ))}
                            {
                              editable?.onRowUpdate && (
                                <button
                                  type="button"
                                  title={'Edit'}
                                  className={classNames(
                                    'p-2.5 rounded-full hover:enabled:bg-gray-100',
                                    'disabled:text-gray-300 disabled:cursor-not-allowed',
                                  )}
                                  disabled={false}
                                  onClick={() => setEditableRowId(row.id)}
                                >
                                  <MdEdit className="text-xl text-slate-500 hover:text-gray-600" />
                                </button>
                              )
                            }
                            {
                              editable?.onRowDelete && (
                                <button
                                  type="button"
                                  title={'Delete Intensity'}
                                  className={classNames(
                                    'p-2.5 rounded-full hover:enabled:bg-gray-100',
                                    'disabled:text-gray-300 disabled:cursor-not-allowed',
                                  )}
                                  disabled={false}
                                  onClick={() => setDeleteRowId(row.id)}
                                >
                                  <MdDeleteOutline className="text-xl text-slate-500 hover:text-gray-600" />
                                </button>
                              )
                            }

                          </div>
                        </td>
                      )}
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className={classNames(
                              'py-3 pl-3 pr-2',
                              cell.column.columnDef.meta?.tdClass || '',
                              !cell.column.columnDef.meta?.tdClass?.includes('border') ?
                                'border-x border-slate-200' : ''
                            )}
                          >
                            {
                              editableRowId !== row.id && flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            }
                            {
                              editableRowId === row.id && (
                                <input
                                  type={cell.column?.columnDef?.meta?.inputType || 'string'}
                                  placeholder={cell.column?.columnDef?.header as string}
                                  {...register(cell.column.id, { required: cell.column?.columnDef?.meta?.inputRequired })}
                                  value={watch(cell.column.id)}
                                  className="focus:outline-none active:outline-none text-blue-500"
                                />
                              )
                            }
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                  {
                    showDraftRow && (
                      <tr className="border-b border-slate-200 z-10">
                        {
                          (rowActions || editable?.onRowUpdate || editable?.onRowDelete) && <td className={classNames('py-3 pl-3 pr-2 border-x')}></td>
                        }
                        {
                          table.getAllColumns().map((col, i) => {
                            return (
                              <td
                                key={`draft-row-${col.id}-${i}`}
                                className={classNames(
                                  'py-3 pl-3 pr-2 border-x',
                                  col.columnDef?.meta?.tdClass,
                                  col?.columnDef.meta?.tdClass?.includes('border') ? 'border-x border-slate-200' : ''
                                )}
                              >
                                <input
                                  type={col?.columnDef?.meta?.inputType || 'string'}
                                  placeholder={col?.columnDef?.header as string}
                                  {...register(col.id, { required: col?.columnDef?.meta?.inputRequired })}
                                  value={watch(col.id)}
                                  className="focus:outline-none active:outline-none"
                                />
                              </td>
                            )
                          }
                          )
                        }
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </form>
          </div>
        </div>
        {/* Footer */}
        <div className="p-3 flex justify-between items-center rounded-b-md bg-accent-light">
          {
            (showDraftRow || editableRowId)
              ? <div className="flex items-center">
                <Button
                  label="Cancel"
                  variant='ghost'
                  className='mr-2'
                  onClick={() => {
                    setShowDraftRow(false)
                    if (editableRowId) {
                      setEditableRowId(undefined)
                    }
                    reset()
                  }}
                />
                <Button
                  label="Save"
                  variant='primary'
                  className='mr-2'
                  type='submit'
                  form="new-row-form"
                />
              </div>
              : <div />
          }
          <div className="flex items-center gap-2">
            <Select
              selected={table.getState().pagination.pageSize.toString()}
              options={[5, 10, 20].map((size) => ({
                id: size.toString(),
                name: `${size.toString()} rows`
              }))}
              onChange={o => o && table.setPageSize(Number(o.id))}
              triggerStyle="bg-transparent border-0"
              iconStyle="text-gray-800"
            />
            <button
              className="p-1 text-accent text-lg"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <BiFirstPage />
            </button>
            <button
              className="p-1 text-accent text-lg"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <BiChevronLeft />
            </button>
            <span className="flex items-center gap-1 text-xs">
              {
                table.getState().pagination.pageIndex
                * table.getState().pagination.pageSize
                + (table.getRowModel().rows.length > 0 ? 1 : 0)
              }
              {'-'}
              {
                table.getState().pagination.pageIndex
                * table.getState().pagination.pageSize
                + table.getRowModel().rows.length
              }
              {' of '}
              {table.getFilteredRowModel().rows.length}
            </span>
            <button
              className="p-1 text-accent text-lg"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <BiChevronRight />
            </button>
            <button
              className="p-1 text-accent text-lg"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <BiLastPage />
            </button>
          </div>
        </div>
      </div >
    </>
  )
}
