import { ReactNode, useEffect, useState } from "react";
import Snackbar, { DisplayingError, SnackbarDisplayType } from "../components/generics/Snackbar";

export type SnackbarType = { message: string, type?: SnackbarDisplayType } | null | string

export type ErrorSnackbarType = {
    error: DisplayingError,
    SnackbarComponent: ReactNode,
    setSnackbarError: (val: SnackbarType) => void,
};

export const useErrorSnackbar = (shouldAutoDismissError: boolean = false): ErrorSnackbarType => {

    const [error, setError] = useState<DisplayingError>(null);
    const [lastErrorType, setLastErrorType] = useState<SnackbarDisplayType>(SnackbarDisplayType.error);

    //Clear the snackbar timeout after removing the component
    useEffect(() => {
        if (error && shouldAutoDismissError) {
            const timer = setTimeout(() => {
                setErrorWrapper(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [error, shouldAutoDismissError]);

    /**
     * Convenience wrapper function to allow for error type to be options, defaults to error
     *
     * @param val: the new value of error
     */
    const setErrorWrapper = (val: {message: string, type?: SnackbarDisplayType} | null | string) => {
        if (typeof val === 'string') {
            setError({ message: val, type: SnackbarDisplayType.error });
        } else if (val === null) {
            setError(null);
        } else {
            const newType = val.type ?? SnackbarDisplayType.error;
            setError({ message: val.message, type: newType });
            setLastErrorType(newType);
        }
    };

    const SnackbarComponent = (
        <Snackbar
            open={error !== null}
            message={error ? error.message : ''}
            type={error ? error.type : lastErrorType}
        />
    );

    return {
      error,
      SnackbarComponent,
      setSnackbarError: setErrorWrapper,
    };
};

export default useErrorSnackbar;