import AddUserRequest from '../types/requests/users/AddUserRequest'
import { ActionType } from '../utils/ActionTypes'
import { authedFetch, DELETE, Endpoints, PATCH, POST } from '../utils/API'
import SharedActions from '../utils/ActionTypes'

export default class UserActions {
  static async addUserToOrganization(request: AddUserRequest, organizationId: string):Promise<ActionType> {
    try {
      const response: any = await POST({
        endpoint: Endpoints.auth.user.create,
        body: {
          ...request,
          organizationId
        },
      })
      return {
        type: SharedActions.user.create,
        payload: response.user
      }
    }
    catch(error: any) {
      throw new Error(error)
    }
  }

  static async fetchUsers(organizationId: string) {
    const res = await authedFetch(Endpoints.auth.user.fetchByOrganization, {
      method: 'GET',
      queryParameters: { organizationId }
    })

    return res.users
  }

  static async updateUser(id: string, organizationId: string, updateData: object) {
    const { user } = await PATCH({
      endpoint: Endpoints.auth.user.update,
      body: {
        accountId: id,
        organizationId: organizationId,
        ...updateData,
      }
    })

    return user
  }

  static async deleteUser(id: string, organizationId: string) {
    const { accountId } = await DELETE({
      endpoint: Endpoints.auth.user.delete,
      queryParameters: { accountId: id, organizationId }
    })

    return accountId
  }
}