import Goal from '../types/Goal';
import SharedActions, { ActionType } from '../utils/ActionTypes';

export type GoalState = {
  goals: { [key: string]: Goal }
}

const initialState = {
  goals: {},
};

export default function goalReducer(
  state: GoalState = initialState,
  action: ActionType
): GoalState {
  switch (action.type) {

    case SharedActions.focusArea.update: {
      const { goals } = action.payload;

      return {
        ...state,
        goals: {
          ...state.goals,
          ...goals
        }
      };
    }

    case SharedActions.treeView.fetchFullTree: {
      const { goals } = action.payload;
      const goalObject = goals.reduce(
        (obj: any, item: Goal) => ((obj[item.id] = item), obj),
        {}
      );

      return {
        ...state,
        goals: {
          ...state.goals,
          ...goalObject,
        },
      };
    }

    case SharedActions.goal.create:
    case SharedActions.goal.update:
    case SharedActions.strategy.create:
    case SharedActions.strategy.delete: {
      const { goal } = action.payload;

      return {
        ...state,
        goals: {
          ...state.goals,
          [goal.id]: goal
        }
      };
    }

    case SharedActions.goal.delete: {
      const { goalId } = action.payload;

      const { [goalId]: removedItem, ...rest } = state.goals;

      return {
        ...state,
        goals: {
          ...rest,
        }
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}