import React from 'react'
import { MdOutlineManageAccounts } from 'react-icons/md'
import { ArcadiaCredential } from '~/types/Arcadia'
import moment from 'moment-timezone'
import Button from '~/components/Button'

interface Props {
  loading: boolean,
  credentials: ArcadiaCredential[],
  goToCredentialDetails: (credential: ArcadiaCredential) => void,
}

export default function UtilityCredentials({ loading, credentials, goToCredentialDetails }: Props) {
  return (
    <div className="w-full border border-slate-100 shadow-sm bg-white flex flex-col rounded-lg">
      <div className="flex items-center p-4">
        <MdOutlineManageAccounts className="text-xl text-gray-400 mr-3" />
        <h4 className="font-bold">Utility Credentials</h4>
      </div>
      <div className="w-full max-h-[200px] overflow-y-auto">
        {
          loading && (
            <div className='px-2'>
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
            </div>
          )
        }
        {
          !loading && credentials.length > 0 && credentials.map((credential, index: number) => (
            <div key={credential.entityId} id={credential.entityId} className="w-full justify-between border-b border-slate-100">
              <div className="flex flex-col align-start justify-start w-full p-3">
                <h5 className="mb-1 font-semibold">{credential.providerName}</h5>
                <p className="text-slate-500">{moment(credential.createdDate).format('l')}</p>
                <p className="text-slate-400 trucate">{credential.username}</p>
                <Button
                  label="See Details"
                  variant='ghost'
                  className='mt-3 h-8 w-fit'
                  onClick={() => goToCredentialDetails(credential)}
                />
              </div>
            </div>
          ))
        }
        {
          !loading && credentials?.length === 0 && (
            <div className="border-2 border-dashed border-gray-200 rounded-sm p-3 text-center flex flex-col my-3 mx-2">
              <h4 className="font-bold">No recent credentials</h4>
            </div>
          )
        }
      </div>
    </div>
  )
}
