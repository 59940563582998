import { useContext, useMemo, createElement, useState } from 'react'
// import { useSignal } from '@preact/signals-react'
import { RiLayoutGridLine } from 'react-icons/ri'
import { RiBuilding4Line } from 'react-icons/ri'
import { CgSpinner } from 'react-icons/cg'
import { startCase } from 'lodash'
import Button from '~/components/Button'
import { PageLayout } from '~/components/PageLayout'
import { ViewReportCard } from './ViewReportCard'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import type SisenseDashboard from '~/types/SisenseDashboard'
import { getDashboardCategoryIcon } from '~/constants/DashboardCategoryIcons'
import { categories as emissionsCategories } from './EmissionsTracking'

const categories = [
  'energy',
  'water',
  'waste',
]

export function MyActivities() {
  const { dashboards } = useContext(UserContext) as UserContextType
  // const currentCategory = useSignal<string>('')
  const [currentCategory, setCurrentCategory] = useState<string>('')


  const displayDashboards = useMemo<SisenseDashboard[]>(() => {
    if (!dashboards) return []

    // Filter out any dashboards already handled by the emissions page.
    const newDashboards = dashboards.filter(dashboard => {
      console.log('Dashboard for filter: ', dashboard, emissionsCategories)
      return !emissionsCategories.some((category) => category.key === dashboard.category || category.label === dashboard.category)
    })

    // Special case, show things NOT in any category
    if (currentCategory === 'other') {
      return newDashboards.filter(dashboard => !categories.includes(dashboard.category))
    }

    return currentCategory
      ? newDashboards.filter(dashboard => dashboard.category === currentCategory)
      : newDashboards
  }, [dashboards, currentCategory])

  const onClickFilter = (name: string) => () => { setCurrentCategory(name) }

  return <PageLayout
    pageTitle="Activity Data"
    pageIcon={RiBuilding4Line}
  // pageSubTitle="Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacina odio."
  >
    <div className="md:w-[53rem] 2xl:w-[78rem] mx-auto mb-8">
      <hr className="my-8" />

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-8">
        <Button
          onClick={onClickFilter('')}
          iconLeft={<RiLayoutGridLine />}
          variant={currentCategory ? 'ghost' : 'primary'}
        >
          All Activity Reports
        </Button>
        {categories.map(option => (
          <Button
            key={option}
            onClick={onClickFilter(option)}
            variant={currentCategory === option ? 'primary' : 'ghost'}
            iconLeft={createElement(getDashboardCategoryIcon(option))}
          >
            {startCase(option)}
          </Button>
        ))}
        <Button
          onClick={onClickFilter('other')}
          iconLeft={createElement(getDashboardCategoryIcon('other'))}
          variant={currentCategory === 'other' ? 'primary' : 'ghost'}
        >
          Other
        </Button>
      </div>

      {/* Dashboards */}
      <div className="flex justify-center">
        {!dashboards ? (
          <CgSpinner className="text-slate-400 animate-spin w-16 h-16" />
        ) : displayDashboards.length === 0 ? (
          <p className="text-2xl">No Activities Available</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-8 sm:gap-12">
            {displayDashboards.map(dashboard => (
              <ViewReportCard
                key={dashboard.oid}
                name={dashboard.title}
                category={dashboard.category}
                // description="Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacina odio."
                reportHref={`/dashboard/${dashboard.oid}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  </PageLayout>
}
