import { useState, useMemo } from 'react'
import { useSetState, useAsync } from 'react-use'
import { BsSliders2 } from 'react-icons/bs'
import { MdCalendarToday, MdExitToApp } from 'react-icons/md'
import Table, { exportCSV } from '~/components/Table'
import Select, { itemsToSelectOptions } from '~/components/Select'
import { SelectOption } from '~/components/Select'
import Button from '~/components/Button'
import { ReportBanner } from '~/components/ReportBanner'
import { getEmissionsReportYears } from '~/actions/ReportsActions'
import { useOrgId } from '~/hooks/useOrgId'
import LoadingIcon from '~/components/generics/LoadingIcon'
import { type FactBillingType } from '~/types/entities/FactBilling'
import { uniq } from '~/utils/collection'

const scopeOptions: SelectOption[] = [1, 2, 3].map(num => ({
  id: String(num),
  name: `Scope ${num}`,
}))

interface Props {
  reportName: string
  tableColumns: { header: string, accessorKey: string }[]
  filterList: string[]
  fetchReport: (orgId: string, year: number) => Promise<any>
  mapReportsToTableRows: (item: FactBillingType) => any
}

export function AuditReport<T>({
  reportName,
  tableColumns,
  filterList,
  fetchReport,
  mapReportsToTableRows,
} : Props) {
  const todaysYear = new Date().getFullYear()
  const orgId = useOrgId()
  const [tableData, setTableData] = useState<T[]>([])
  const defaultFilters: Record<string, string> = useMemo(() => {
    const f: any = {}
    for (const k of filterList) {
      f[k] = ''
    }
    f.scope = ''
    return f
  }, [filterList])
  const [filters, patchFilters] = useSetState(defaultFilters)
  const [availableYears, setAvailableYears] = useState<number[]>([])
  const [year, setYear] = useState<number>(todaysYear)
  const [loading, setLoading] = useState(true)

  const nextYear = availableYears.find(y => y > year)
  const prevYear = availableYears.findLast(y => y < year)

  const onChangeFilter = (key: string) => (option: SelectOption) => {
    patchFilters({ [key]: option.id })
  }

  const resetFilter = () => {
    patchFilters(defaultFilters)
  }

  useAsync(async () => {
    // Get all years that have reports
    if (!orgId) return

    setLoading(true)

    try {
      const years = await getEmissionsReportYears(orgId)

      console.log('got years', years)

      setAvailableYears(years)

      if (!years.includes(year)) {
       setYear(years.at(-1) || year)
      }
    } catch (error: any) {
      console.error(error)
    }

    setLoading(false)
  }, [orgId])

  useAsync(async () => {
    // Get all data
    if (!year || !availableYears.length || !availableYears.includes(year) || !orgId) return

    setLoading(true)

    try {
      const reports: FactBillingType[] = await fetchReport(orgId, year)
      console.log('got reports', reports)
      setTableData(reports.map(mapReportsToTableRows))
    } catch (error: any) {
      console.error(error)
    }

    setLoading(false)
  }, [orgId, year, availableYears])

  const filteredTableData = useMemo(() => {
    console.log('tabledata', tableData)
    let data = [...tableData]
    for (const key in defaultFilters) {
      // @ts-ignore
      if (filters[key]) data = data.filter(item => item[key] === filters[key])
    }
    return data
  }, [tableData, filters])

  const filterSelectOptions = useMemo<Record<string, SelectOption[]>>(() => {
    /*let numSetFilters = 0
    for (const filter in filters) {
      if (filters[filter]) {
        ++numSetFilters
      }
    }*/

    const newOptions: Record<string, SelectOption[]> = {}
    for (const key in defaultFilters) {
      newOptions[key] = itemsToSelectOptions(
        uniq(
          // @ts-ignore
          tableData.map(item => item[key])
          //(numSetFilters === 1 ? filteredTableData : tableData).map(item => item[key])
        )
      )
    }
    return newOptions
  }, [tableData, filteredTableData, filters])

  const yearSelectOptions: SelectOption[] = useMemo(() => availableYears.map(yr => ({
    id: String(yr),
    name: String(yr),
  })), [availableYears])

  const makeFilter = (id: string, name: string, options?: SelectOption[]) =>
    <Select
      key={id}
      disabled={loading}
      triggerStyle="grow"
      placeholder={name}
      options={options ?? filterSelectOptions[id]}
      onChange={onChangeFilter(id)}
      selected={filters[id]}
    />

  return <div>
    <ReportBanner
      title={`${year} ${reportName}`}
      year={year}
      changeYear={setYear}
      prevYear={prevYear}
      nextYear={nextYear}
    />

    <div className="relative p-6 -mt-16 pb-24">
      <div className="flex flex-col gap-8 p-6 bg-white rounded-xl border-slate-100 border shadow-md">
        <div className="flex items-end gap-4">
          <BsSliders2 className="text-accent" />
          <span className="font-bold">Filters</span>
        </div>
        <div className="flex justify-between flex-wrap gap-6">
          {filterList.map(fil => makeFilter(fil, tableColumns.find(item => item.accessorKey === fil)?.header || ''))}
          {makeFilter('scope', 'Scope', scopeOptions)}

          <Button variant="ghost" onClick={resetFilter} disabled={loading}>Reset Filter</Button>
        </div>
      </div>

      <div className="mt-6 p-6 bg-white rounded-xl border-slate-100 border shadow-md">
        <LoadingIcon isLoading={loading} className="mx-auto" size={128}>
          <Table
            columns={tableColumns}
            data={filteredTableData}
            searchable={false}
          />
        </LoadingIcon>
      </div>

      <div className="fixed left-0 bottom-0 w-full flex items-center gap-8 px-6 py-4 bg-white border-slate-100 border-t shadow-md">
        <Select
          loading={loading}
          containerStyle="min-w-16"
          options={yearSelectOptions}
          selected={String(year)}
          onChange={option => setYear(Number(option.id))}
          placeholder={String(todaysYear)}
          disabled={loading || yearSelectOptions.length === 0}
          iconLeft={MdCalendarToday}
        />
        <span className="grow font-bold text-lg">{reportName}</span>
        <Button
          onClick={() => exportCSV(`${year}_${reportName}`, tableColumns, filteredTableData)}
          iconRight={<MdExitToApp />}
          loading={loading}
          disabled={loading}
        >
          Export CSV
        </Button>
      </div>
    </div>
  </div>
}
