import { useCallback } from 'react'
import { useToggle } from 'react-use'
import { MdLink } from 'react-icons/md'
import { GiTrashCan } from 'react-icons/gi'
import { ArcadiaCredential } from '~/types/Arcadia'
import { arcadiaStatuses, arcadiaStatusDetails } from '~/utils/arcadia'
import Button from '~/components/Button'
import { ArcadiaInfoTempWarning } from '~/views/Arcadia/InfoTempWarning'
import { DeleteModal } from './DeleteModal'
import { UpdateModal } from './UpdateModal'

interface IArcadiaInfoDisplayProps {
  info: ArcadiaCredential
  isTemp?: boolean
  /** Optional reference for delete/update functionality */
  setUtilities?: React.Dispatch<React.SetStateAction<ArcadiaCredential[]>>
}

/** Expanded information of items for an arcadia utility item */
export function ArcadiaInfoDisplay({
  info,
  isTemp,
  setUtilities,
}: IArcadiaInfoDisplayProps) {
  const [updateModalOpen, toggleUpdateModal] = useToggle(false)
  const [deleteModalOpen, toggleDeleteModal] = useToggle(false)

  const onCloseDeleteModal = useCallback(() => {
    toggleDeleteModal(false)
  }, [])

  const onCloseUpdateModal = useCallback(() => {
    toggleUpdateModal(false)
  }, [])

  const websiteUrl = info.provider?.website

  return <>
    {isTemp ? <ArcadiaInfoTempWarning /> : <>
      <DeleteModal
        credentialId={info.entityId}
        open={deleteModalOpen}
        onClose={onCloseDeleteModal}
        setUtilities={setUtilities}
      />
      <UpdateModal
        credential={info}
        open={updateModalOpen}
        onClose={onCloseUpdateModal}
        setUtilities={setUtilities}
      />
    </>}
    <div className="w-full grid grid-cols-2 xl:grid-cols-3 p-4 gap-8 h4:font-bold p:text-slate-500">
      <div>
        <h4 className="font-bold">Username/Email</h4>
        <p className="text-slate-500">{info.username}</p>
      </div>
      <div>
        <h4 className="font-bold">Provider Name</h4>
        <p className="text-slate-500">{info.providerName}</p>
      </div>
      <div>
        <h4 className="font-bold">Provider Website</h4>
        <p className="text-slate-500">
          {websiteUrl ? (
            <a href={websiteUrl} target="_blank" rel="noreferrer"><MdLink className="inline-block" /> {websiteUrl}</a>
          ) : (
            <i>None provided</i>
          )}
        </p>
      </div>
      {!isTemp &&
      <>
        <div>
          <h4 className="font-bold">Credential Status</h4>
          <p className="text-slate-500">{arcadiaStatuses[info.status]}, {arcadiaStatusDetails[info.statusDetail]}</p>
        </div>
        <div>
          <Button
            label="Update Credential"
            iconLeft={<MdLink size="1.5em" />}
            onClick={toggleUpdateModal}
          />
        </div>
        <div>
          <Button
            variant="ghost"
            label="Delete Credential"
            iconLeft={<GiTrashCan size="1.5em" />}
            onClick={toggleDeleteModal}
          />
        </div>
      </>}
    </div>
  </>
}
