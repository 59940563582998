import FileConflictsModal from '~/components/FileConflictsModal'
import { GHGICategory } from '~/views/DataImport/useDataImport'
import { EmissionsPortfolioDashboard } from './Dashboard'
import EmissionsPortfolioUploads from './EmissionsPortfolioUploads'
import Header from './Header'
import { useEmissionsPortfolio } from './useEmissionsPortfolio'

interface Props {
  ghgiCategories: GHGICategory[],
  loading: boolean,
}

export default function EmissionsPortfolio({
  ghgiCategories,
  loading,
}: Props) {
  const {
    userGHGICategories,
    onGHGICategorySelected,
    selectedGHGICategory,
    conflictModalOpen,
    setConflitModalOpen,
    fileConflicts,
    currentView,
    setCurrentView,
    organization,
    userInfo,
    getCredentialDetails,
    selectedCredential,
    setSelectedCredential,
  } = useEmissionsPortfolio({ ghgiCategories })

  return (
    <div className="flex flex-col w-full bg-background-100 h-full">
      <FileConflictsModal
        open={conflictModalOpen}
        onClose={() => setConflitModalOpen(false)}
        conflicts={fileConflicts}
      />
      <Header
        userGHGICategories={userGHGICategories}
        ghgiCategories={ghgiCategories}
        onSelectedCategory={onGHGICategorySelected}
        selectedGHGICategory={selectedGHGICategory}
        openConflictModal={() => setConflitModalOpen(true)}
        currentView={currentView}
        setCurrentView={setCurrentView}
        loadingCategories={loading}
        organization={organization}
      />
      {
        currentView === 'dashboard' &&
        <EmissionsPortfolioDashboard
          ghgiCategories={ghgiCategories}
          selectedGHGICategory={selectedGHGICategory}
          loadingCategories={loading}
          organization={organization}
          userInfo={userInfo}
          getCredentialDetails={getCredentialDetails}
        />
      }
      {
        currentView === 'uploads' &&
        <EmissionsPortfolioUploads
          ghgiCategories={ghgiCategories}
          organization={organization}
          selectedCredential={selectedCredential}
          setSelectedCredential={setSelectedCredential}
        />
      }
    </div>
  )
}
