import MaterialUISnackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';

const SlideTransition = (props: TransitionProps) => {
    return <Slide {...props} direction="up" />;
};

export enum SnackbarDisplayType {
    default = 'default',
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error'
}

export type DisplayingError = {message: string, type: SnackbarDisplayType} | null;

const Snackbar = (props: SnackbarProps & {type: SnackbarDisplayType}) => {

    const { type, ...snackbarProps } = props;

    return (
        <MaterialUISnackbar
            className={`snackbar`}
            {...snackbarProps}
            TransitionComponent={SlideTransition}
            ContentProps={{
                'aria-describedby': 'message-id',
                classes: {root: `${type}`}
            }}
            message={<span id="message-id">{snackbarProps.message}</span>}
        />
    );
};

export default Snackbar