import { useState, useMemo, useEffect } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { ImFilesEmpty } from 'react-icons/im'
import { MdPictureAsPdf } from 'react-icons/md'
import { TbFileSpreadsheet } from 'react-icons/tb'
import UnderlineTabs from '~/components/UnderlineTabs'
import LinearProgress from '~/components/LinearProgress'
import { FileItem } from '~/utils/IndexDB'
import classNames from '~/utils/classNames'
import { ArcadiaCredential } from '~/types/Arcadia'
import { ArcadiaUtilityCard } from '~/views/Arcadia/UtilityCard'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'

// TODO: perhaps extend ArcadiaCredential from FileItem or just find a way so it's not in two different spots in the code.
interface Props {
  files: FileItem[],
  utilities?: ArcadiaCredential[],
  handleAddFiles: (files: any) => void,
  selectedFile?: FileItem,
  selectedUtility?: ArcadiaCredential,
  handleSelectFile?: (file: FileItem | undefined) => void,
  handleSelectUtility?: (utility: ArcadiaCredential | undefined) => void,
  loading?: boolean,
  isTemp?: boolean,
}

export default function UploadFileList({
  files,
  utilities,
  handleAddFiles,
  selectedFile,
  selectedUtility,
  handleSelectFile = () => { },
  handleSelectUtility = () => { },
  loading,
  isTemp,
}: Props) {
  const ghgiCategory = useGhgiCategory()
  const [currentTab, setCurrentTab] = useState('ALL')

  const uploadCategories: string[] = useMemo(() => {
    const cats = ['ALL', 'UPLOADS']
    if (ghgiCategory === 'company_facilities') {
      cats.push('UTILITIES')
    }
    cats.push('API')
    return cats
  }, [ghgiCategory])

  useEffect(() => {
    if (ghgiCategory !== 'company_facilities') {
      handleSelectUtility(undefined)
    }
  }, [ghgiCategory])

  return (
    <div className="rounded-md border border-slate-200 bg-gray-100 h-full p-0 flex flex-col overflow-hidden">
      <div className="bg-white border-b rounded-t-md border-slate-300 flex items-center justify-center pt-1 shadow-md relative">
        <UnderlineTabs
          tabs={uploadCategories}
          currentTab={currentTab}
          onTabSelected={(selected: string) => setCurrentTab(selected)}
          containerClass="w-full"
          tabStyles="tracking-widest text-xs"
        />
      </div>
      <span className="flex flex-col flex-grow mb-0 pb-0 justify-between">
        <div className="overflow-y-scroll flex-grow mb-0 max-h-[calc(50vh-180px)]">
          {
            loading && (
              <div className="w-full flex flex-col py-2">
                <span className="bg-gray-300 mx-2 mb-2 h-12 animate-pulse rounded-md" />
                <span className="bg-gray-300 mx-2 mb-2 h-12 animate-pulse rounded-md" />
                <span className="bg-gray-300 mx-2 mb-2 h-12 animate-pulse rounded-md" />
              </div>
            )
          }
          {
            !loading && (currentTab === 'ALL' || currentTab === 'UPLOADS') && files?.length > 0 && files.map((upload: FileItem) => (
              <div
                className={classNames("w-full flex flex-col border-b border-slate-200", selectedFile?.id === upload.id ? "bg-gray-100 shadow-md" : "bg-white")}
                onClick={() => handleSelectFile(upload)}
                key={upload.id}
              >
                <div className="w-full flex items-center justify-between py-3 px-2" key={upload.id}>
                  <div className={classNames("flex items-center justify-start")}>
                    {(upload.type === 'application/pdf' || upload.file?.type === 'application/pdf') ? <MdPictureAsPdf className="text-slate-300 mr-2" /> : <TbFileSpreadsheet className="text-slate-300 mr-2" />}
                    <h4 className="text-sm font-semibold">{upload?.name || upload.file?.name || 'No name found'}</h4>
                  </div>
                  {
                    upload.status !== 'uploading' && (
                      <p className="justify-self-end italic text-gray-400 text-sm capitalize mx-2">{upload.status}</p>
                    )
                  }
                </div>
                {
                  // progress[upload.file.id].status === 'uploading' && (
                  upload.status === 'uploading' && (
                    <LinearProgress progress={upload.progress || 0} containerStyles="mb-4" />
                  )
                }
              </div>
            ))
          }
          {
            !loading && (currentTab === 'ALL' || currentTab === 'UTILITIES') && ghgiCategory === 'company_facilities' && utilities && utilities.length > 0 && utilities.map((utility: ArcadiaCredential) => (
              <ArcadiaUtilityCard
                isTemp={isTemp}
                key={utility.entityId}
                info={utility}
                selected={selectedUtility?.entityId === utility.entityId}
                onClick={() => handleSelectUtility(utility)}
              />
            ))
          }
        </div>
        {
          !loading && !files?.length && !utilities?.length && (
            <div className="w-full flex-grow rounded-md flex flex-col items-center justify-center p-4">
              <ImFilesEmpty className="text-4xl text-slate-300 mb-3" />
              <h4 className="font-bold mb-2 text-md text-slate-600">No files</h4>
              <p className="text-slate-600">Click below to upload files</p>
            </div>
          )
        }
        <div className="w-full rounded-b-md bg-white text-gray-800 cursor-pointer relative flex items-center py-2.5 px-4 border-t border-slate-200 justify-self-end mt-0 group" >
          <AiOutlinePlus className="text-sm mr-2 cursor-pointer group-hover:text-accent" />
          <h5 className="uppercase tracking-widest font-bold text-xs cursor-pointer group-hover:text-accent">Add Another File</h5>
          <input
            type="file"
            accept=".csv, .xlsx, .xls, .pdf"
            className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
            multiple
            onChange={handleAddFiles}
          />
        </div>
      </span>
    </div>
  )
}
