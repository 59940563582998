import { useState } from 'react'
import { MdUploadFile, MdPictureAsPdf } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
import { TbFileSpreadsheet } from 'react-icons/tb'
import { FileItem } from '../../utils/IndexDB'
import classNames from '../../utils/classNames'

interface Props {
  validateFiles: (file: FileList) => File[]
  processFiles: (files: File[]) => void
  onFileRemove: (file: FileItem) => void
  stagedFiles: FileItem[],
  onWorkbookStatusChange: (upload: FileItem, workbook: boolean) => void
}

export default function FileDragAndDrop({ validateFiles, processFiles, onFileRemove, stagedFiles, onWorkbookStatusChange }: Props) {
  const [dragging, setDragging] = useState(false)

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragging(false)
    const compliantFiles = validateFiles(e.dataTransfer.files)
    processFiles(compliantFiles)
  }

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e.target.files) return
    const compliantFiles = validateFiles(e.target.files)
    processFiles(compliantFiles)
  }

  return (
    <>
      <div className="px-6 w-full flex flex-col items-center">
        <div
          className={`relative flex flex-col items-center justify-center w-full mx-6 mt-6 rounded-lg p-4 h-36 border-2 border-dashed ${dragging ? 'border-blue-500 bg-blue-100' : 'border-slate-200 bg-gray-100'}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={(event) => console.log('Drag and drop container clicked: ', event)}
          draggable={true}
        >
          <MdUploadFile className="text-4xl text-slate-300 mb-2" />
          <h4 className="font-bold mb-1 text-slate-400">Drag file to upload</h4>
          <input
            multiple
            accept=".csv, .xlsx, .xls, .pdf"
            type="file"
            placeholder="click here to browse"
            className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
            onChange={handleFileUpload}
          />
        </div>
      </div>
      <div className="w-full flex flex-col px-2 mt-2">
        {
          stagedFiles.filter((upload: FileItem) => upload.status === 'queued' || upload.status === 'staged').map((upload: FileItem) => (
            <div className="flex items-center justify-between w-full px-6 py-2" key={`${upload.file.name}_${upload.id}_wrapper`}>
              <div className="flex flex-3" key={`${upload.file.name}_${upload.id}_icon_container`}>
                {upload.file.type === 'application/pdf' ? <MdPictureAsPdf className="text-slate-300 mr-2" /> : <TbFileSpreadsheet className="text-slate-300 mr-2" />}
                <p className="text-slate-700">{upload.file.name}</p>
              </div>
              <div className="relative flex items-center justify-end flex-1 mr-8" key={`${upload.file.name}_${upload.id}_input_container`}>
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={upload.workbook}
                  onChange={(event) => onWorkbookStatusChange(upload, event.target.checked)}
                />
                <div className="ml-2 text-sm">
                  <label htmlFor="comments" className="text-sm text-gray-500 leading-3 m-0 p-0">
                    Workbook
                  </label>
                </div>
              </div>

              <button className="text-red-600 text-sm" onClick={() => onFileRemove(upload)} key={`${upload.file.name}_${upload.id}_btn`}><HiOutlineTrash className="text-xl" /></button>
            </div>
          ))
        }
      </div>
    </>
  )
}
