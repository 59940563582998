import { ReactNode } from 'react'
import { CircularProgress, CircularProgressProps } from '@material-ui/core'
import cn from '~/utils/classNames'

type LoadingIconProps = CircularProgressProps & {
  children: ReactNode | ReactNode[],
  isLoading: boolean,
  size?: number | string,
}

export default function LoadingIcon({
  children,
  isLoading,
  size,
  className,
}: LoadingIconProps) {
  if (isLoading) {
    const height: string = typeof size === 'number' ? `${size}px` : size ?? '18px'
    return (
      <CircularProgress
        className={cn(`h-[${height}] w-[${height}] p-1 text-black`, className)}
      />
    )
  }

  return <>{children}</>
}
