import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../../reducers/RootReducer';
import Goal from '../../../types/Goal';
import StrategyBranches from './StrategyBranches';
import useAsyncCall from '../../../hooks/UseAsyncCall';
import FocusAreaActions from '../../../actions/FocusAreaActions';
import StyledTreeItem from './StyledTreeItem';

const GoalBranches = (props: {
  dispatch: Dispatch<any>
  expandedItems: Set<string>
  focusAreaId: string
  goalIds: string[]
  planId: string
}) => {

  const { dispatch, expandedItems, focusAreaId, goalIds, planId } = props;
  const [ previousIsExpanded, setPreviousIsExpanded ] = useState(false);

  const goals = useSelector((store: ApplicationState) => {

    let goals: {[key: string]: Goal} = {};
    goalIds.forEach((goalId) => {
      const goal = store.goal.goals[goalId];
      if (goal != null) {
        goals[goal.id] = goal;
      }
    });

    return goals;
  });

  const fetchParentCall = useAsyncCall(true);
  useEffect(() => {
    if (expandedItems.has(planId) && !previousIsExpanded) {
      fetchParentCall.makeAsyncCall(FocusAreaActions.fetchFocusArea({planId, focusAreaId}, undefined))
        .then((res) => {
          dispatch(res);
        })
        .catch(e => {
          console.error(e);
        });
    }
    setPreviousIsExpanded(expandedItems.has(planId));
  }, [expandedItems, planId, focusAreaId]);

  return (
    <>
      {goalIds.map((goalId) => {
        const goal = goals[goalId];
        if (goal) {
          return (
            <StyledTreeItem key={goalId} nodeId={goalId} label={goal.title}>
              {goal.strategyIds.length > 0 ?
                <StrategyBranches
                  dispatch={dispatch}
                  expandedItems={expandedItems}
                  focusAreaId={focusAreaId}
                  goalId={goalId}
                  planId={planId}
                  strategyIds={goal.strategyIds}
                />
                :
                null
              }
            </StyledTreeItem>
          );
        } else {
          return <StyledTreeItem key={goalId} nodeId={goalId} label={'-'} />;
        }
      })}
    </>
  );
};

export default GoalBranches;