export class FormUtils {
  static getObjectChanges(original: any, updated: any) {
    const changes: any = {}
    Object.keys(updated).forEach((key) => {
      if (original[key] !== updated[key]) {
        changes[key] = updated[key]
      }
    })
    return changes
  }
  static getObjectDifference(original: any, updated: any) {
    const allowedTypes = ['string', 'number', 'boolean', 'undefined']
    console.log(original)
    console.log(updated)
    const changes: any = []
    Object.keys(updated).forEach((key) => {
      if (original[key] !== updated[key] && (Boolean(original[key]) || Boolean(updated[key])) && key !== 'id' && allowedTypes.includes(typeof updated[key])) {
        changes.push({
          fieldName: key,
          originalValue: typeof original[key] === 'boolean' ? original[key] ? 'Yes' : 'No' : original[key],
          updatedValue: typeof updated[key] === 'boolean' ? updated[key] ? 'Yes' : 'No' : updated[key]
        })
      }
      else if (original[key] !== updated[key] && (Boolean(original[key]) || Boolean(updated[key])) && key !== '_id' && typeof updated[key] === 'object') {
        for (const subKey in updated[key]) {
          if (original[key][subKey] !== updated[key][subKey] && allowedTypes.includes(typeof updated[key][subKey])) {
            changes.push({
              fieldName: `${key} / ${subKey}`,
              originalValue: typeof original[key][subKey] === 'boolean' ? original[key][subKey] ? 'Yes' : 'No' : original[key][subKey],
              updatedValue: typeof updated[key][subKey] === 'boolean' ? updated[key][subKey] ? 'Yes' : 'No' : updated[key][subKey]
            })
          }
        }
      }
    })
    return changes
  }
}