import { useState, useEffect } from 'react'

import { CellContext } from '@tanstack/table-core'
import { MdAddCircle, MdDeleteOutline } from 'react-icons/md'
import useAsyncCall from '~/hooks/UseAsyncCall'
import UserActions from '~/actions/UserActions'
import { OrganizationType } from '../../contexts/UserContext'
import { useAlertContext } from '../../contexts/AlertContext'
import UserType from '../../utils/UserType'
import NewUserModal from './NewUserModal'
import Table from '../Table'
import Select from '../Select'
import Modal from '../Modal'

interface UserManagementProps {
  organization: OrganizationType
}

export interface UserDBModel {
  _id: any,
  firstName: any,
  lastName: any,
  organizations: any,
  email: any
}

export interface UserModel {
  id: any,
  userName: any,
  writePermission: any,
  userType: any,
  email?: string,
}

const UserManagement = (props: UserManagementProps) => {
  props.organization.userType = UserType.ADMIN
  const { showAlert } = useAlertContext()
  const
    [userData, setUserData] = useState([{} as UserModel]),
    [newUserModal, setNewUserModal] = useState(false),
    [stagedUserDelete, setStagedUserDelete] = useState<UserModel>()

  const fetchUsersCall = useAsyncCall(true)
  const updateUserCall = useAsyncCall(true)
  const deleteUserCall = useAsyncCall(true)

  useEffect(() => {
    loadUsers(props.organization._id)
  }, [props.organization])

  const loadUsers = async (organizationId: string) => {
    try {
      const res = await fetchUsersCall.makeAsyncCall(UserActions.fetchUsers(organizationId))

      const users: UserModel[] = res.map((user: UserDBModel) => {
        let organization = user.organizations.find((org: any) => organizationId === org.organization._id)
        return {
          id: user._id,
          userName: `${user.firstName} ${user.lastName}`,
          userType: organization?.userType,
          writePermission: organization?.writePermission,
          email: user?.email
        } as UserModel
      })
  
      setUserData(users)
    } catch (e) {
      console.error(e)
      showAlert({
        message: 'Error loading users',
        variant: 'error'
      })
    }
  }

  const setWritePermission = async (writePermission: boolean, id: string) => {
    try {
      const newUser = await updateUserCall.makeAsyncCall(UserActions.updateUser(
        id,
        props.organization._id,
        { writePermission: !writePermission }
      ))

      const updatedUsers = userData.map((user) => {
        if (user.id === id) {
          const targetOrganization = newUser.organizations.find(({ organization }: any) => organization === props.organization._id)
          return {
            email: newUser.email,
            id: newUser._id,
            userName: `${newUser.firstName} ${newUser.lastName}`,
            userType: targetOrganization.userType,
            writePermission: targetOrganization.writePermission
          } as UserModel
        }
        return user
      })
      setUserData(updatedUsers)
    } catch (e) {
      console.error(e)
      showAlert({
        message: 'Error updating user',
        variant: 'error'
      })
    }
  }

  const setUserType = async (id: number, e: any) => {
    try {
      const newUser = await updateUserCall.makeAsyncCall(UserActions.updateUser(
        id.toString(), // ??? Why is id a number?
        props.organization._id,
        { userType: e.target.value }
      ))

      const updatedUsers = userData.map((user) => {
        if (user.id === id) {
          const targetOrganization = newUser.organizations.find(({ organization }: any) => organization === props.organization._id)
          return {
            email: newUser.email,
            id: newUser._id,
            userName: `${newUser.firstName} ${newUser.lastName}`,
            userType: targetOrganization.userType,
            writePermission: targetOrganization.writePermission
          } as UserModel
        }
        return user
      })
      setUserData(updatedUsers)
    } catch (e) {
      console.error(e)
      showAlert({
        message: 'Error updating user',
        variant: 'error'
      })
    }
  }

  const addNewUser = (newUser: UserModel) => {
    setUserData([...userData, newUser])
    setNewUserModal(false)
  }

  const deleteUser = async (userId: string) => {
    try {
      const accountId = await deleteUserCall.makeAsyncCall(UserActions.deleteUser(
        userId,
        props.organization._id
      ))
      const newUsers = userData.filter((user) => user.id !== accountId)
      setUserData(newUsers)
      showAlert({
        title: 'User deleted',
        message: 'User deleted successfully',
        variant: 'success'
      })
      setStagedUserDelete(undefined)
    }
    catch (error) {
      console.error(error)
      showAlert({
        message: 'Error deleting user',
        variant: 'error'
      })
    }
  }

  return (
    <div className="mt-10">
      <Table
        title="User management"
        options={{ autoResetPageIndex: false }}
        columns={[
          { header: 'User Name', accessorKey: 'userName' },
          {
            header: 'Write Permission',
            accessorKey: 'writePermission',
            meta: {
              thClass: props.organization.userType === UserType.REGULAR ? 'hidden' : '',
              tdClass: props.organization.userType === UserType.REGULAR ? 'hidden' : '',
            },
            cell: (data: CellContext<any, any>) => (
              <input
                type="checkbox"
                style={{ marginLeft: '2px' }}
                checked={data.getValue()}
                onChange={() => setWritePermission(data.getValue(), data.row.original.id)} />
            )
          },
          { header: 'Email', accessorKey: 'email' },
          { 
            header: 'User Type', 
            accessorKey: 'userType',
            cell: (data: CellContext<any, any>) => data.getValue() ? (
              <Select
                selected={data.getValue()}
                options={[
                  { id: UserType.ADMIN, name: 'Administrator' },
                  { id: UserType.REGULAR, name: 'Regular' },
                ]}
                onChange={o => o && setUserType(data.row.original.id, o.id)}
                triggerStyle="bg-transparent border-0"
                iconStyle="text-gray-800"
              />
            ) : null
          },          
        ]}
        data={userData}
        actions={[
          {
            tooltip: props.organization.userType === UserType.ADMIN ? 'New User' : 'Feature Disabled',
            icon: <MdAddCircle />,
            disabled: () => props.organization.userType !== UserType.ADMIN,
            onClick: () => setNewUserModal(true)
          }
        ]}
        rowActions={[
          {
            tooltip: props.organization.userType === UserType.ADMIN ? 'Delete User' : 'Feature Disabled',
            icon: <MdDeleteOutline className="text-gray-600 hover:text-gray-600" />,
            disabled: () => props.organization.userType === UserType.REGULAR,
            onClick: (row:UserModel) => setStagedUserDelete(row)
          }
        ]}
        reload={() => loadUsers(props.organization._id)}
        loading={fetchUsersCall.isCalling}
      />

      <Modal
        title={<>
          Remove <b>{stagedUserDelete?.userName || ''}</b> from the organization
        </>}
        description={<>
          Are you sure you want to remove <b>{stagedUserDelete?.userName}</b> from the organization? <b>This action cannot be undone</b>
        </>}
        open={!!stagedUserDelete}
        onClose={() => !deleteUserCall.isCalling && setStagedUserDelete(undefined)}
        actionButtons={[
          {
            label: 'Cancel',
            variant: 'ghost',
            type: 'button',
            disabled: deleteUserCall.isCalling,
            onClick: () => setStagedUserDelete(undefined)
          },
          {
            label: 'Delete',
            variant: 'danger',
            type: 'button',
            disabled: deleteUserCall.isCalling,
            loading: deleteUserCall.isCalling,
            onClick: () => deleteUser(stagedUserDelete?.id)
          }
        ]}
      />

      <NewUserModal
        open={newUserModal}
        onClose={() => setNewUserModal(false)}
        onAddUser={addNewUser}
        organizationId={props.organization._id}
      />
    </div>
  )
}

export default UserManagement