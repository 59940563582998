import Project from '../types/Project';
import SharedActions, { ActionType } from '../utils/ActionTypes';

export type ProjectState = {
  doTableIds: string[]
  projects: { [key: string]: Project }
}

const initialState = {
  doTableIds: [],
  projects: {},
};

export default function projectReducer(
  state: ProjectState = initialState,
  action: ActionType
): ProjectState {
  switch (action.type) {

    case SharedActions.initiative.update: {
      const { projects } = action.payload;

      return {
        ...state,
        projects: {
          ...state.projects,
          ...projects
        }
      };
    }
    case SharedActions.treeView.fetchFullTree: {
      const { projects } = action.payload;
      const projectsObject = projects.reduce(
        (obj: any, item: Project) => ((obj[item.id] = item), obj),
        {}
      );

      return {
        ...state,
        projects: {
          ...state.projects,
          ...projectsObject,
        },
      };
    }

    case SharedActions.project.create:
    case SharedActions.project.update: {
      const { project } = action.payload;

      return {
        ...state,
        projects: {
          ...state.projects,
          [project.id]: project
        }
      };
    }

    case SharedActions.project.delete: {
      const { projectId } = action.payload;

      const { [projectId]: removedItem, ...rest } = state.projects;

      return {
        ...state,
        projects: {
          ...rest,
        }
      };
    }

    case SharedActions.project.fetchAll: {
      const { tableIds, projects } = action.payload;

      return {
        ...state,
        doTableIds: tableIds,
        projects: {
          ...state.projects,
          ...projects,
        },
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}