import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import EmpoweringSustainability from '../../../../assets/EmpoweringSustainability.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      display: 'block',
      margin: 'auto',
      marginBottom: 8,
      objectFit: 'contain',
      width: 200,
    },
}));

const Copyright = ({ withLogo } : {withLogo?: boolean}) => {

  const classes = useStyles();

  return (
    <Box mt={8} mb={2}>
      { withLogo && <img alt="SustainaBase Logo" className={classes.logo} src={EmpoweringSustainability}/> }
      <Typography variant="body2" color="textSecondary" align="center">
        {`©${new Date().getFullYear()} `}
        <Link color="inherit" href="https://sustainabase.com/" target="_blank">
          SustainaBase, Inc
        </Link>
        {'. All Rights Reserved.'}
        <br/>
        <Link color="inherit" href="https://sustainabase.com/privacy-policy/" target="_blank">
          Privacy Policy
        </Link>
        {` | `}
        <Link color="inherit" href="https://sustainabase.com/terms-of-use/" target="_blank">
          Terms of Use
        </Link>
      </Typography>
    </Box>
  );
};

export default Copyright;
