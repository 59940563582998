import { useAsyncFn } from 'react-use'
import { deleteArcadiaCredential } from '~/actions/ArcadiaActions'
import Modal from '~/components/Modal'
import { useAlertContext } from '~/contexts/AlertContext'
import { ArcadiaCredential } from '~/types/Arcadia'
import { removeOne } from '~/utils/collection'

interface IProps {
  credentialId: string
  open: boolean
  onClose: () => void
  /** Optional reference for delete/update functionality */
  setUtilities?: React.Dispatch<React.SetStateAction<ArcadiaCredential[]>>
}

export function DeleteModal({
  credentialId,
  open,
  onClose,
  setUtilities,
}: IProps) {
  const { showAlert } = useAlertContext()

  const [{ loading }, deleteCredential] = useAsyncFn(async () => {
    try {
      const result = await deleteArcadiaCredential(credentialId)

      showAlert({
        title: 'Success',
        message: 'Credential deleted.',
        type: 'success',
      })

      setUtilities?.(utilities => removeOne(utilities, item => item.entityId === credentialId))

      onClose()

      return result
    } catch (e: any) {
      showAlert({
        title: 'Error',
        message: 'Could not delete credential.',
        type: 'error',
      })

      throw e
    }
  }, [credentialId])

  return <Modal
    open={open}
    onClose={onClose}
    title="Delete"
    description="Are you sure you want to delete this credential?"
    actionButtons={[
      { label: 'Cancel', onClick: onClose, variant: 'ghost' },
      { label: 'OK', onClick: deleteCredential, variant: 'primary', loading },
    ]}
  />
}
