import Modal from '../Modal'
import Button from '../Button'

interface Props {
  open: boolean
  onClose: () => void
  conflicts: any[]
}

export type FileConflict = {
  fieldName: string,
  currentValue: string,
  conflictValue: string,
}

export default function FileConflictsModal({ open, onClose, conflicts }: Props) {
  return <Modal
    className='md:max-w-[800px]'
    open={open}
    title="Confirm Changes"
    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, quasi doloremque?"
    onClose={onClose}
    actionButtons={[
      {
        label: 'Close',
        onClick: () => onClose(),
        variant: 'ghost',
      }, 
      // {
      //   label: 'Confirm',
      //   onClick: () => onClose(),
      //   variant: 'primary',
      // }

    ]}
  >
    <div className="flex flex-col items-center px-6 py-6 w-[80vw] max-w-[800px] max-h-96 overflow-y-auto">
      {conflicts.map((conflict: FileConflict, index: number) => (
        <div className="border border-slate-200 rounded-xl p-4 flex items-center justify-between w-full mb-3" key={index}>
          <div className="flex flex-col items-start justify-start">
            <h4 className="font-bold text-lg">Current</h4>
            <p className="text-xs text-slate-400 font-semibold">{conflict.fieldName}</p>
            <p className="text-slate-600">{conflict.currentValue}</p>
          </div>
          <div className="flex items-center justify-center">
            <span className="h-full min-h-[60px] w-[1px] bg-slate-200 mr-6" />
            <div className="flex flex-col items-start justify-start">
              <h4 className="font-bold text-lg">Conflict</h4>
              <p className="text-xs text-slate-400 font-semibold">{conflict.fieldName}</p>
              <p className="text-slate-600">{conflict.conflictValue}</p>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button
              label="Ignore"
              variant="ghost"
            />
            <span className="w-2" />
            <Button
              label="Update"
              variant='primary'
            />
          </div>
        </div>
      ))}
    </div>
  </Modal>
}
