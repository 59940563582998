import { useState } from 'react'
import { MdChevronLeft, MdChevronRight, MdExitToApp, MdOutlineAssignment, MdOutlineCalendarToday, MdSaveAlt } from 'react-icons/md'
import { BsArchive, BsCloudArrowDown } from 'react-icons/bs'
import { useCDPReport } from './useCDPReport'
import cdpLogo from '~assets/cdp_logo.png'
import loadingIcon from "~assets/loading.svg"
import Select from '~/components/Select'
import Modal from '~/components/Modal'
import Button from '~/components/Button'

export default function CDPReport() {
  const {
    availableYears,
    selectedYear,
    changeYear,
    prevYear,
    nextYear,
    exportHistory,
    exportCSV,
    report,
    organization,
    loading,
    exporting,
  } = useCDPReport()

  const [openExport, setOpenExport] = useState(false)

  return (
    <>
      <div className={`relative w-full h-screen flex flex-col items-center bg-background-100 ${loading ? 'overflow-hidden' : 'overflow-auto'}`}>
        <div className="w-full h-80 min-h-banner px-12 flex items-center justify-between bg-gradient-to-r from-gradient-light via-gradient-highlight to-gradient-dark">
          <Button
            className="bg-white/10 enabled:hover:bg-white/20 enabled:text-white disabled:text-white/50"
            iconLeft={<MdChevronLeft className="text-xl" />}
            label={`${prevYear || selectedYear - 1}`}
            disabled={!prevYear}
            onClick={() => changeYear(prevYear)}
          />
          <div className="flex justify-center text-white relative">
            <div className="absolute bottom-full">
              <MdOutlineAssignment className="text-3xl" />
            </div>
            <h1 className="text-xl font-bold">
              CDP {selectedYear} Metrics
            </h1>
          </div>
          <Button
            className="bg-white/10 enabled:hover:bg-white/20 enabled:text-white disabled:text-white/50"
            iconRight={<MdChevronRight className="text-xl" />}
            label={`${nextYear || selectedYear + 1}`}
            disabled={!nextYear}
            onClick={() => changeYear(nextYear)}
          />
        </div>

        <div className="flex w-full justify-between px-10 mb-3">
          {/* Left col */}
          <div className="w-56 min-w-fit h-fit p-8 mt-10 bg-white rounded border border-background-300 shadow-sm">
            <img src={cdpLogo} alt="CDP Logo" className="w-32" />
            <h3 className="font-bold mt-8 mb-1">What is a CDP Report?</h3>
            <p className="w-52 text-slate-500">
              The CDP report is a valuable tool that enables businesses to understand and manage their environmental impact. By providing companies with a comprehensive assessment of their sustainability efforts it helps them identify areas where they can improve.
            </p>
          </div>

          {/* Center col */}
          <div className="flex flex-col py-7 -mt-20 w-full bg-white rounded border border-background-300 shadow-sm md:mx-10">
            <div className="px-7 pb-7 border-b border-background-300">
              <h2 className="mt-1 text-xl font-bold">{organization?.name}</h2>
            </div>

            {!report?.year && (
              <div className="px-7 mt-7">
                <div className="text-center w-full p-3 border-2 border-dashed rounded-lg border-slate-200">
                  <h3 className="text-lg font-bold">No report found</h3>
                  <p className="text-base text-slate-500">Upload data for this year to see your {selectedYear} report</p>
                </div>
              </div>
            )}

            {!!report?.year && (<>
              <div className="px-7 mt-7 mb-3">
                <h3 className="text-xs text-neutral-400 uppercase tracking-wider">
                  C6 Emissions
                </h3>
                <table className="w-full mt-3 text-slate-600">
                  <tbody>
                    {report?.c6?.map((emission, i) => (
                      <tr key={`c6-${i}`}>
                        <td className="p-3 leading-tight border border-background-300">
                          {emission.name}
                        </td>
                        <td className="w-32 p-3 text-right leading-tight border border-background-300">
                          {emission.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="my-2 h-[1px] bg-slate-100 w-full" />
              <div className="px-7 mt-3">
                <h3 className="text-xs text-neutral-400 uppercase tracking-wider">
                  C7 Emissions
                </h3>
                <table className="w-full mt-2 text-slate-600">
                  <tbody className="border-b border-background-300">
                    {report?.c7?.map((emission, i) => (<>
                      <tr key={`c7-${i}-key`} className="h-3 border-b border-background-300">
                        <td className="pt-2 text-xs text-neutral-400 uppercase tracking-wider">
                          {emission.key?.replace(/(\d)(\d)/ig, '$1.$2')}
                        </td>
                      </tr>
                      {!Array.isArray(emission.value) && (
                        <tr key={`c7-${i}`} className="border-b border-x border-background-300">
                          <td
                            className="p-3 leading-tight"
                          >
                            {emission.name}
                          </td>
                          <td colSpan={2} className="p-3 leading-tight">
                            {emission.value}
                          </td>
                        </tr>)}
                      {Array.isArray(emission.value) && <>
                        {emission.value.map((sub, j) => (
                          <tr key={`c7-${i}${j}`} className="border-b border-x last:border-b-0 border-background-300">
                            {j === 0 && (
                              <td
                                rowSpan={Array.isArray(emission.value) ? emission.value.length : 1}
                                className="p-3 leading-tight border-r border-background-300"
                              >
                                {emission.name}
                              </td>
                            )}
                            <td className="p-3 leading-tight border-r border-background-300">
                              {sub.name}
                            </td>
                            <td className="w-32 p-3 text-right leading-tight">
                              {sub.value}
                            </td>
                          </tr>
                        ))}
                      </>}
                    </>))}
                  </tbody>
                </table>
              </div>
            </>)}
          </div>

          {/* Right col */}
          <div className="w-56 min-w-fit">
            <div className="mt-10 p-8 bg-white rounded border border-background-300 shadow-sm">
              <h4 className="font-bold mb-1">Ready to download?</h4>
              <p className="w-52 text-slate-500">
                Please review the data to ensure it is accurate and up to date. If you need to update any of the data, please contact your SustainaBase Customer Service Representative.
              </p>
            </div>
            <div className="mt-3 pt-4 px-4 bg-white rounded border border-background-300 shadow-sm">
              <div className="flex items-center mb-4">
                <BsArchive className="text-xl text-accent" />
                <h2 className="font-bold ml-2">Export History</h2>
              </div>
              <ul>
                {exportHistory?.map((file: any) => (
                  <li className="py-3 flex items-center justify-between border-b border-background-300 last:border-none">
                    <div>
                      <p className="text-slate-600">Exported {file.date?.toLocaleDateString()}</p>
                      <a
                        href={file.url}
                        className="text-accent"
                        download={file.name}
                      >
                        Download version
                      </a>
                    </div>
                    <a
                      href={file.url}
                      className="text-accent text-2xl"
                      download={file.name}
                    >
                      <MdSaveAlt />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full h-16 py-6 px-8 flex items-center justify-between sticky bottom-0 bg-white shadow-top">
          <div className="flex items-center">
            <Select
              loading={loading}
              selected={selectedYear.toString()}
              options={availableYears.map((y) => ({ id: y.toString(), name: y.toString() }))}
              onChange={(o) => changeYear(Number(o.id))}
              prefix={<MdOutlineCalendarToday style={{fontSize: '1rem'}} className="mr-2 text-slate-400" />}
            />
            <h1 className="text-lg font-bold ml-3">CDP {selectedYear} Metrics</h1>
          </div>
          <Button
            className="font-light bg-accent-secondary disabled:bg-accent-secondary/50 enabled:hover:bg-accent-secondary/90"
            iconRight={<MdExitToApp className="text-xl" />}
            label={exporting ? "Exporting..." : "Export CSV"}
            disabled={!report.year || exporting}
            onClick={() => setOpenExport(true)}
          />
        </div>

        {loading && (
          <div className="w-full h-full flex items-center justify-center absolute bg-white/90">
            <img className="animate-spin text-xl" src={loadingIcon} alt="Loading" />
          </div>
        )}
      </div>
      <Modal
        title='Export Ready to Download!'
        description="You can download the CDP metrics in CSV format. This file will be stored in the export history after downloading for reference."
        actionButtons={[
          {
            label: 'Cancel',
            variant: 'ghost',
            type: 'button',
            onClick: () => setOpenExport(false)
          }
        ]}
        open={openExport}
        onClose={() => setOpenExport(false)}
        className="pt-1"
      >
        <div className="text-center py-3 mx-7 my-3 border-dashed border-2 border-slate-200  rounded-lg">
          <h3 className="text-base font-semibold mb-2">{selectedYear} CDP Metrics</h3>
          <p className="text-xs mb-1 text-zinc-500">File Details</p>
          <p className="mb-4">{new Date().toLocaleDateString()}, .csv</p>
          <Button
            className="m-auto text-lg hover:bg-accent-dark focus:bg-accent-dark "
            iconLeft={<BsCloudArrowDown />}
            label="Download Report"
            disabled={!report.year || exporting}
            onClick={() => {
              setOpenExport(false)
              if (report.year && !exporting) exportCSV()
            }}
          />
        </div>
      </Modal>
    </>
  )
}
