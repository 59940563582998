import { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserActions from '../../../actions/UserActions';
import { useAlertContext, AlertContextType } from '../../../contexts/AlertContext'
import { UserModel } from '../UserManagement'

interface Props {
  onClose: () => void;
  onAddUser: (user: UserModel) => void;
  organizationId: string;
}

export default function useNewUserModal({ onClose, onAddUser, organizationId }: Props) {
  const [loading, setLoading] = useState(false)
  const { showAlert } = useAlertContext() as AlertContextType

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email:'',
      userType: { id: 'REGULAR', name: 'Regular', value: 'REGULAR' },
      writePermission: false,
    }
  })

  const handleNewUser  = form.handleSubmit(async (data: any) => {
    try {
      setLoading(true)
      const { firstName, lastName, email, userType, writePermission } = data
      
      if(!organizationId) {
        showAlert({
          variation: 'error',
          title: 'There was a problem adding the user',
          message: 'There was as problem, please refresh the page and try again',
          duration: 5000,
        })
        return 
      }
      const { payload } = await UserActions.addUserToOrganization({
        email,
        firstName,
        lastName,
        userType: userType.value,
        writePermission,
        readPermission: true, // Andres: Hardccoded to true for now, don't see a use case for this to be false
      }, organizationId || '')
      const targetOrgInfo = payload.organizations.find((org: any) => org.organization === organizationId)

      const formattedUser: UserModel = {
        email: payload.email,
        userName: `${payload.firstName} ${payload.lastName}`,
        id: payload._id,
        userType: targetOrgInfo.userType,
        writePermission: targetOrgInfo.writePermission,
      }
      onAddUser(formattedUser)
      form.reset()
    }
    catch (error: any) {
      console.log('Error in create new user: ', error)
      showAlert({
        title: 'There was a problem adding the user',
        message: 'Please try again or contact support',
        variation: 'error',
        duration: 5000,
      })
    }
    finally {
      setLoading(false)
    }
  })

  const cleanForm = () => {
    form.reset()
    setLoading(false)
    onClose()
  }

  return {
    form,
    loading,
    handleNewUser,
    cleanForm,
  }
}