import { ReactNode, MouseEventHandler } from 'react'
import { CgSpinnerTwo } from 'react-icons/cg'
import classNames from '~/utils/classNames'

const variants = { 
  primary: 'bg-accent text-white hover:outline-none focus:outline-none',
  danger: 'bg-red-700 text-white hover:outline-none focus:outline-none',
  ghost: 'bg-white text-black border border-black',
  text: 'text-black',
}

interface Props {
  variant?: keyof typeof variants,
  label?: string
  className?: string
  labelStyles?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  type?: 'button' | 'submit' | 'reset'
  iconLeft?: ReactNode
  iconRight?: ReactNode
  icon?: ReactNode
  content?: ReactNode
  form?: string | undefined
  loading?: boolean
  disabled?: boolean
  children?: ReactNode
}

export default function Button({
  variant = 'primary',
  label,
  className = '',
  labelStyles = '',
  onClick,
  type = 'button',
  iconLeft,
  iconRight,
  form,
  loading,
  icon,
  content,
  disabled = false,
  children,
}: Props) {
  return (
    <button
      type={type}
      className={classNames(
        'h-10 px-3 py-2 rounded-md flex items-center disabled:opacity-50 disabled:cursor-not-allowed',
        variants[variant] || variants.text,
        className,
      )}
      onClick={onClick}
      form={form}
      disabled={disabled}
    >
      {loading && <CgSpinnerTwo className="animate-spin flex items-center justify-center h-5 w-12 outline-none" />}
      {!loading && iconLeft && <span className="mr-2">{iconLeft}</span>}
      {!loading && icon}
      {!loading && content}
      {!loading && label && <span className={labelStyles}>{label}</span>}
      {!loading && children && <span className={labelStyles}>{children}</span>}
      {!loading && iconRight && <span className="ml-2">{iconRight}</span>}
    </button>
  )
}
