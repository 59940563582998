import SharedActions, { ActionType } from '../utils/ActionTypes';
import { authedFetch, Endpoints } from '../utils/API';
import CreateInitiativeRequest from '../types/requests/initiative/CreateInitiativeRequest';
import DeleteInitiativeRequest from '../types/requests/initiative/DeleteInitiativeRequest';
import FetchInitiativeRequest from '../types/requests/initiative/FetchInitiativeRequest';
import UpdateInitiativeRequest from '../types/requests/initiative/UpdateInitiativeRequest';

export default class InitiativeActions {

  /**
   * Function called to create a new initiative
   *
   * @param planId: the plan to create the initiative under
   * @param focusAreaId: the focus area the initiative is under
   * @param goalId: the goal the initiative is under
   * @param strategyId: the strategy to create the initiative under
   * @param initiative: the initiative to be created
   */
  static createInitiative({ planId, focusAreaId, goalId, strategyId, initiative }: CreateInitiativeRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.initiative.create, {
      method: 'POST',
      body: {
        planId,
        focusAreaId,
        goalId,
        strategyId,
        initiative,
      }
    })
      .then((res) => {
        return {
          type: SharedActions.initiative.create,
          payload: {
            planId,
            focusAreaId,
            goalId,
            strategy: res.strategy,
            initiative: res.initiative,
          }
        }
      });
  }

  /**
   * Function called to delete an initiative
   *
   * @param planId: the plan the initiative is under
   * @param focusAreaId: the focus area the initiative is under
   * @param goalId: the goal the initiative is under
   * @param strategyId: the strategy the initiative is under
   * @param initiativeId: the initiative to be deleted
   */
  static deleteInitiative({ planId, focusAreaId, goalId, strategyId, initiativeId }: DeleteInitiativeRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.initiative.delete, {
      method: 'DELETE',
      queryParameters: { planId, focusAreaId, goalId, strategyId, initiativeId }
    })
      .then((res) => {
        return {
          type: SharedActions.initiative.delete,
          payload: {
            planId,
            focusAreaId,
            goalId,
            initiativeId,
            strategy: res.strategy,
          }
        }
      });
  }

  /**
   * Function called to fetch an initiative by its id
   *
   * @param planId: the plan the initiative is under
   * @param focusAreaId: the focus area the initiative is under
   * @param goalId: the goal the initiative is under
   * @param strategyId: the strategy the initiative is under
   * @param initiativeId: the initiative to be fetched
   */
  static fetchInitiative({ planId, focusAreaId, goalId, strategyId, initiativeId }: FetchInitiativeRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.initiative.fetch, {
      method: 'GET',
      queryParameters: { planId, focusAreaId, goalId, strategyId, initiativeId }
    })
      .then((res) => {
        return {
          type: SharedActions.initiative.update,
          payload: {
            planId,
            focusAreaId,
            goalId,
            strategyId,
            initiative: res.initiative,
            projects: res.projects,
          }
        }
      });
  }

  /**
   * Function called to update an initiative
   *
   * @param planId: the plan the initiative is under
   * @param focusAreaId: the focus area the initiative is under
   * @param goalId: the goal the initiative is under
   * @param strategyId: the strategy the initiative is under
   * @param update: the updates to the initiative to be pushed
   */
  static updateInitiative({ planId, focusAreaId, goalId, strategyId, update }: UpdateInitiativeRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.initiative.update, {
      method: 'PATCH',
      body: {
        planId,
        focusAreaId,
        goalId,
        strategyId,
        initiativeId: update.id,
        initiative: update
      }
    })
      .then((res) => {
        return {
          type: SharedActions.initiative.update,
          payload: {
            planId,
            focusAreaId,
            goalId,
            strategyId,
            initiative: res.initiative,
            projects: res.projects,
          }
        }
      });
  }
}