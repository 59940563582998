import { useContext, useState, useEffect } from 'react';
import { UserContext, UserContextType } from '../../contexts/UserContext';
import { GET, Endpoints, } from '../../utils/API';
import { useAlertContext } from '../../contexts/AlertContext';
import LoadingIcon from '../generics/LoadingIcon';


const UserImpersonation = () => {
  const { userInfo, loadUserInfo } = useContext(UserContext) as UserContextType;

  const [impersonatedUID, setImpersonatedUID] = useState('')
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { showAlert } = useAlertContext()

  useEffect(() => {
    const uid = sessionStorage.getItem('impersonate-uid')

    setImpersonatedUID(uid || '')
  }, [])

  const impersonate = async (uid: string) => {
    try {
      setLoading(true)
      sessionStorage.setItem('impersonate-uid', uid)

      // Check user to see if uid exists
      // This allows us to handle the error since loadUserInfo can't throw
      const res = await GET({
        endpoint: Endpoints.auth.user.fetch,
      })

      // Refresh user
      await loadUserInfo()

      // If it didn't crash, we're good to go!
      setImpersonatedUID(uid)
      setInputValue('')
      showAlert({
        title: 'Impersonation successful',
        message: `You're now working as ${res.user.email}`,
        variant: 'success'
      })
    } catch (error) {
      sessionStorage.removeItem('impersonate-uid')

      showAlert({
        message: 'Error: Make sure you enter a valid user id',
        variant: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  const goBack = async () => {
    try {
      setLoading(true)
      sessionStorage.removeItem('impersonate-uid')

      // Refresh user
      await loadUserInfo()

      setImpersonatedUID('')
      showAlert({
        title: 'Impersonation finished',
        message: 'Welcome back! You are now yourself again',
        variant: 'success'
      })
    } catch (error) {
      showAlert({
        message: 'Something went wrong! Please open app in a new tab to go back to normal',
        variant: 'error'
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full shadow-md p-6 pt-0 bg-white rounded border mt-4">
      <h6 className="text-xl h-16 flex items-center">User Impersonation</h6>
      {!impersonatedUID && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            impersonate(inputValue)
          }}
          className="flex items-center"
        >
          <p className="text-base">To impersonate a user, enter their user id:</p>
          <input
            type="text"
            className="ml-4 text-base border-b border-black hover:border-b-2 outline-none"
            placeholder="  User ID"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button
            type="submit"
            disabled={loading}
            className="mx-3 px-2 h-10 w-max rounded shadow flex items-center justify-center bg-[#414B50] hover:bg-[#617077] text-white uppercase"
          >
            Impersonate
          </button>
          <LoadingIcon isLoading={loading} size={24}>
            <div />
          </LoadingIcon>
        </form>
      )}
      {impersonatedUID && (
        <div className="flex items-center">
          <p className="text-base">You're already impersonating {userInfo?.email}.</p>
          <button
            type="button"
            disabled={loading}
            onClick={() => goBack()}
            className="mx-3 px-2 h-10 w-max rounded shadow flex items-center justify-center bg-[#414B50] hover:bg-[#617077] text-white uppercase"
          >
            Go Back
          </button>
          <LoadingIcon isLoading={loading} size={24}>
            <div />
          </LoadingIcon>
        </div>
      )}
    </div>
  )
}

export default UserImpersonation