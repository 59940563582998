import defaults from 'lodash/defaults';
import findIndex from 'lodash/findIndex';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import words from 'lodash/words';
import deburr from 'lodash/deburr';
import moment from "moment-timezone";

/**
 * @template {T}
 * @param {T} obj - action constant object
 * @param {Object} [options]
 * @param {string} [options.prepend] - string to prepend to every action constant
 * @param {string} [options.sep] - separator
 * @return {T}
 */
export function populateActions<T>(obj: T, options?: { prepend?: string, sep?: string }): T {
    const { prepend, sep } = defaults(options, { prepend: "", sep: "_" });
    let path = splitActionString(prepend);
    return populateActionsInternal(obj, { path, sep });
}

/**
 * Function called to convert a date/time string pair to an ISO date string
 *
 * @param date: the date string in the form yyyy-MM-dd
 * @param time: the time string in the form HH:mm
 */
export function dateTimeToISO(date: string, time: string): string | null {
    const res = moment(`${date}T${time}`, 'YYYY-MM-DD[T]HH:mm');
    return res.isValid() ? res.toISOString() : null;
}

/**
 * Function called to read a file into a data url
 *
 * @param file: the file to be read as a data url
 * @return a promise to resolve the file as a data url
 */
export function fileToDataUrl(file: File): Promise<string | undefined> {
    return new Promise<string | undefined>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (evt) => {
            if (evt != null && evt.target != null && !(evt.target.result instanceof ArrayBuffer)) {
                resolve(evt.target.result ?? undefined);
            } else {
                reject('Failed to read file as dataURL');
            }
        };

        reader.readAsDataURL(file);
    });
}

/**
 * Function called to check if a form has errors
 *
 * @param errors: the object of possible errors
 * @return {boolean} a boolean stating whether or not the object has errors
 */
export function formHasErrors(errors: {[key: string]: string | null}): boolean {
    return Object.values(errors).reduce((acc: boolean, curr) => acc || (!!curr), false);
}

/**
 * Function called to check if 2 object are equal
 * From: https://github.com/epoberezkin/fast-deep-equal
 *
 * @param a: the first object
 * @param b: the second object
 * @return {boolean} a boolean determining whether or not the objects are equal
 */
export function isObjectEqual(a: any, b: any) {
    if (a === b) return true;

    if (a && b && typeof a == 'object' && typeof b == 'object') {
        if (a.constructor !== b.constructor) return false;

        let length, i, key, keys;
        if (Array.isArray(a)) {
            length = a.length;
            // eslint-disable-next-line eqeqeq
            if (length != b.length) return false;
            for (i = length; i-- !== 0;)
                if (!isObjectEqual(a[i], b[i])) return false;
            return true;
        }

        if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
        if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
        if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

        keys = Object.keys(a);
        length = keys.length;
        if (length !== Object.keys(b).length) return false;

        for (i = length; i-- !== 0;)
            if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

        for (i = length; i-- !== 0;) {
            key = keys[i];
            if (!isObjectEqual(a[key], b[key])) return false;
        }

        return true;
    }

    // true if both NaN, false otherwise
    return Number.isNaN(a) && Number.isNaN(b);
}

/**
 * Function called to check if a string is a valid email
 *
 * @param mail: the string to check if its a email
 */
export function isValidEmail(mail: string): boolean {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
}

/**
 * Function called to check if a string is a valid password
 *
 * @param password: the string to check if its a password
 */
export function isValidPassword(password: string): boolean {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,64}$)/.test(password);
}

/**
 * Function called to check if a string is a valid phone number
 *
 * @param phoneNumber: the string to check if its a phone number
 */
export function isValidPhoneNumber(phoneNumber: string): boolean {
    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);
}

/**
 * Function called to check if a string is a valid routing number
 *
 * @param routingNumber: the string to check if its a routing number
 */
export function isValidRoutingNumber(routingNumber: string): boolean {
    return /^\d{9}$/.test(routingNumber);
}

/**
 * Function called to check if a string is a valid state
 *
 * @param state: the string to check if its a state
 */
export function isValidState(state: string): boolean {
    return /^[A-Z]{2}$/.test(state);
}

/**
 * Function called to check if a string is a valid username
 *
 * @param username: the string to check if its a username
 */
export function isValidUsername(username: string): boolean {
    return /^(?!.*\.\.)(?!.*\.$)[a-z0-9_][a-z0-9_.]{0,29}$/.test(username);
}

/**
 * Function called to check if a string is a valid zip code
 *
 * @param zip: the string to check if its a zip code
 */
export function isValidZipCode(zip: string): boolean {
    return /^\d{5}$/.test(zip);
}

/**
 * Function called to add a new page of ids to an existing array of ids
 *
 * @param previousLastPageToken: the last item of the old ids,or null if the old ids are to be replaced
 * @param oldIds: the array of existing ids in the table
 * @param newIds: the array of ids to be added to the table
 */
export function addNewIdsToEnd(previousLastPageToken: string | null, oldIds: string[], newIds: string[]) {

    //if there is a previous last page token, add all new ids after that id
    if (previousLastPageToken !== null) {
        const indexOfLast = findIndex(oldIds, (o) => o === previousLastPageToken);
        if (indexOfLast !== -1) {
            return oldIds.slice(0, indexOfLast + 1).concat(newIds);
        }
        return oldIds.concat(newIds);
    }

    //if there is not previous last page token, replace the entire array.
    //NOTE: This assumes we do not fetch when we are at the end of the table
    return newIds;
}


//TODO: Fix this

/**
 * @template {T}
 * @param {T} obj
 * @param {string[]} path
 * @param {string} sep
 * @return {T}
 */
export function populateActionsInternal(obj: any, { path, sep } : { path: string[], sep: string }): any {
    if (!isPlainObject(obj)) {
        if (isString(obj)) {
            return path.concat(splitActionString(obj)).map(str => str.toUpperCase()).join(sep);
        }
        else {
            return path.map(str => str.toUpperCase()).join(sep);
        }
    }

    let res: any = {};

    for (let key of keys(obj)) {
        let newPath = path.concat(splitActionString(key));
        res[key] = populateActionsInternal(obj[key], { path: newPath, sep });
    }

    return res;
}

/**
 * @param {string} str
 * @returns {string[]}
 */
export function splitActionString(str: string): string[] {
    return words(deburr(str));
}
