import {
  Fragment,
  ReactElement,
  useContext,
  useState,
  // useMemo,
} from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Dialog, Transition, Listbox, Disclosure } from '@headlessui/react'
import { AiOutlineClose, AiOutlineMenu, AiOutlineDown, AiOutlineCheck, AiOutlineUp, AiOutlineRight, AiOutlineSetting, AiOutlineLogout, AiOutlineCustomerService } from 'react-icons/ai'
import { RiDownloadCloud2Line, RiFileCopy2Line, RiFoldersLine, RiLineChartLine, RiNodeTree, RiOrganizationChart, RiClipboardLine, RiSurveyLine, RiBuilding4Line } from 'react-icons/ri'
import { LuBarChart3 } from 'react-icons/lu'
import { BiBarChartSquare } from 'react-icons/bi'
import classNames from '~/utils/classNames'
import sustainabaseLogo from '~assets/logo.svg'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import useUserAuth from '~/hooks/UseUserAuth'
import SupportModal from './SupportModal'

interface Props {
  hideOrganization?: boolean;
}

const iconStyle = "text-accent"
const menuItemStyle = "font-bold text-md text-slate-800"
const submenuItemStyle = "rounded-md text-md py-2 px-3 ml-2 mb-1 flex items-center justify-between"

type NavItem = {
  name: string
  href: string
  icon?: ReactElement
  children?: NavItem[]
}

const navigation: NavItem[] = [
  {
    name: 'Plan',
    href: '/',
    icon: <RiNodeTree className={iconStyle} />
  },
  {
    name: 'Your Portfolio',
    href: '/import/portfolio/company_facilities',
    icon: <LuBarChart3 className={iconStyle} />,
  },
  {
    name: 'Data Import',
    href: '/import',
    icon: <RiDownloadCloud2Line className={iconStyle} />,
  },
  {
    name: 'My Facilities',
    href: '/facilities',
    icon: <RiBuilding4Line className={iconStyle} />,
  },
  {
    name: 'Emissions Tracking',
    href: '/emissions',
    icon: <RiLineChartLine className={iconStyle} />,
  },
  {
    name: 'Activity Data',
    href: '/activity',
    icon: <BiBarChartSquare className={iconStyle} />,
  },
  {
    name: 'Projects',
    href: '/projects',
    icon: <RiFoldersLine className={iconStyle} />,
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: <RiClipboardLine className={iconStyle} />,
  },
  {
    name: 'Documents',
    href: '/documents',
    icon: <RiFileCopy2Line className={iconStyle} />,
  },
  {
    name: 'Surveys',
    href: '/surveys',
    icon: <RiSurveyLine className={iconStyle} />,
  },
]

export default function SideNav({
  hideOrganization = false,
}: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [supportModalOpen, setSupportModalOpen] = useState(false)
  const { logUserOut } = useUserAuth()
  const {
    userInfo,
    setOrganization,
    organization,
  } = useContext(UserContext) as UserContextType
  const location = useLocation()
  const history = useHistory()

  const bottomNavigation = [
    {
      name: 'Support',
      onClick: () => setSupportModalOpen(true),
      icon: <AiOutlineCustomerService className={iconStyle} />,
    },
    {
      name: 'Settings',
      onClick: () => history.push('/settings'),
      icon: <AiOutlineSetting className={iconStyle} />,
    },
    {
      name: 'Logout',
      onClick: logUserOut,
      icon: <AiOutlineLogout className={iconStyle} />,
    },
  ]

  const currentItem = location.pathname /*useMemo(() => {
    const pathname = location.pathname

    const checkItem = (item: any) => {
      const children = Array.isArray(item) ? item : item.children

      if (item.href && pathname === item.href) return item.name

      if (children) {
        for (const child of children) {
          if (checkItem(child)) return child.name
        }
      }

      return null
    }

    return checkItem(navigation)
  }, [location.pathname])*/

  return (
    <>
      <SupportModal
        visible={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
      />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <AiOutlineClose className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={sustainabaseLogo}
                        alt="Sustainabase"
                      />
                    </div>
                    {
                      !hideOrganization && (
                        <div className="flex items-center rounded-lg border shadow-md">
                          <Listbox value={organization} onChange={setOrganization} disabled={!organization}>
                            <div className="relative flex-grow">
                              <Listbox.Button className=" flex items-center justify-between relative w-full cursor-default rounded-lg bg-white py-2 pr-10 text-left border-none focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm disabled:cursor-not-allowed disabled:opacity-50">
                                <span className="flex items-center w-48">
                                  <RiOrganizationChart className="text-xl text-accent mr-2 ml-3" />
                                  {!organization && <span className="h-6 bg-gray-200 rounded-lg flex-grow animate-pulse" />}
                                  {organization && <span className="block truncate">{organization?.name}</span>}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <AiOutlineDown
                                    className="h-4 w-4 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {userInfo?.organizations && userInfo?.organizations?.length > 0 && userInfo.organizations.map((organizationItem, index) => (
                                    <Listbox.Option
                                      key={organizationItem._id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-accent text-white' : 'text-gray-900'}`
                                      }
                                      value={organizationItem}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span className={`block ${selected ? 'font-medium' : 'font-normal'}`}>
                                            {organizationItem.name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                                              <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                      )
                    }
                    <nav className="flex flex-1 flex-col">
                      <div className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <div key={item.name}>
                            {
                              item.children
                                ? (
                                  <Disclosure>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={classNames(
                                            currentItem === item.href
                                              ? 'bg-gray-50 text-accent'
                                              : 'text-gray-700 hover:bg-gray-50',
                                            'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold justify-between w-full'
                                          )}
                                        >
                                          <div className="flex items-center gap-x-3">
                                            {item.icon}
                                            <h4 className={menuItemStyle}>{item.name}</h4>
                                          </div>
                                          {open ? <AiOutlineUp className="text-lg text-gray-600" /> : <AiOutlineRight className="text-lg text-gray-600" />}
                                        </Disclosure.Button >
                                        <Disclosure.Panel as="ul">
                                          {item.children?.map((child) => (
                                            <div key={child.name}>
                                              {
                                                child.children
                                                  ? (
                                                    <Disclosure as="div">
                                                      {({ open }) => (
                                                        <>
                                                          <Disclosure.Button as="div" className={classNames(submenuItemStyle, "flex items-center justify-between")}>
                                                            <h5 className="text-md">{child.name}</h5>
                                                            {open ? <AiOutlineUp className="text-lg text-gray-600" /> : <AiOutlineRight className="text-lg text-gray-600" />}
                                                          </Disclosure.Button>
                                                          <Disclosure.Panel as="ul" className="space-y-1 ml-3">
                                                            {
                                                              child.children?.map((grandchild, index) => (
                                                                <li key={grandchild.name}>
                                                                  <Link to={grandchild?.href || '#'} className={submenuItemStyle}><h6>{grandchild.name}</h6></Link>
                                                                </li>
                                                              ))}
                                                          </Disclosure.Panel>
                                                        </>
                                                      )}
                                                    </Disclosure>
                                                  )
                                                  : (
                                                    <Link to={child?.href || '#'} className={submenuItemStyle}>
                                                      <h5>{child.name}</h5>
                                                    </Link>
                                                  )
                                              }
                                            </div>
                                          ))}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )
                                : (
                                  <Link
                                    to={item?.href}
                                    className={classNames(
                                      currentItem === item.href
                                        ? 'bg-gray-50 text-accent'
                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                      'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                  >
                                    {item.icon}
                                    <h4 className={menuItemStyle}>{item.name}</h4>
                                  </Link>
                                )
                            }

                          </div>
                        ))}
                      </div>
                    </nav>
                    {
                      bottomNavigation.map((item) => (
                        <button className={classNames('focus:outine-accent flex items-center gap-x-3 rounded-md hover:bg-gray-100 py-1 px-2')} key={item.name} onClick={item.onClick}>
                          {item.icon}
                          <h4 className={menuItemStyle}>{item.name}</h4>
                        </button>
                      ))
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:h-full lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-10 w-auto"
                src={sustainabaseLogo}
                alt="Your Company"
              />
            </div>
            {
              !hideOrganization && (
                <div className="flex items-center rounded-lg border shadow-md">
                  <Listbox value={organization} onChange={setOrganization} disabled={!organization}>
                    <div className="relative">
                      <Listbox.Button className=" flex items-center justify-between relative w-full cursor-default rounded-lg bg-white py-2 pr-10 text-left border-none focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm disabled:cursor-not-allowed disabled:opacity-50">
                        <span className="flex items-center w-48">
                          <RiOrganizationChart className="text-xl text-accent mr-2 ml-3" />
                          {!organization && <span className="h-6 bg-gray-200 rounded-lg flex-grow animate-pulse" />}
                          {organization && <span className="block truncate">{organization?.name}</span>}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <AiOutlineDown
                            className="h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {userInfo?.organizations && userInfo?.organizations?.length > 0 && userInfo.organizations.map((organizationItem, index) => (
                            <Listbox.Option
                              key={organizationItem._id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-accent text-white' : 'text-gray-900'
                                }`
                              }
                              value={organizationItem}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block ${selected ? 'font-medium' : 'font-normal'
                                      }`}
                                  >
                                    {organizationItem.name}
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                                      <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              )
            }

            {/* DESKTOP NAVIGATION */}
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7 justify-between mb-4">
                <li>
                  <ul>
                    {navigation.map((item) => (
                      <li key={item.name}>
                        {
                          item.children
                            ? (
                              <Disclosure as="div" className="w-full">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="justify-between flex-grow w-full group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                                      <span className="flex items-center gap-x-3">
                                        {item.icon}
                                        <h4 className={menuItemStyle}>{item.name}</h4>
                                      </span>
                                      {open ? <AiOutlineUp className="text-lg text-gray-400" /> : <AiOutlineRight className="text-lg text-gray-400" />}
                                    </Disclosure.Button>
                                    <Disclosure.Panel as="ul" className="mt-1 mb-2 rounded-md flex flex-col">
                                      {item.children?.map((child) => (
                                        <div key={child.name} className="flex flex-col">
                                          {
                                            child.children
                                              ? (
                                                <Disclosure as="div">
                                                  {({ open }) => (
                                                    <>
                                                      <Disclosure.Button as="div" className={classNames(submenuItemStyle, currentItem === child.href ? 'bg-gray-100' : '')}>
                                                        <h5>{child.name}</h5>
                                                        {open ? <AiOutlineUp className="text-lg text-gray-400" /> : <AiOutlineRight className="text-lg text-gray-400" />}
                                                      </Disclosure.Button>
                                                      <Disclosure.Panel as="div" className="mt-2 ml-3">
                                                        {
                                                          child.children?.map((grandchild) => (
                                                            <div key={grandchild.name} className={classNames(submenuItemStyle, currentItem === grandchild.href ? 'bg-gray-100' : '')}>
                                                              <Link to={grandchild?.href || '#'}>
                                                                <h6>{grandchild.name}</h6>
                                                              </Link>
                                                            </div>
                                                          ))}
                                                      </Disclosure.Panel>
                                                    </>
                                                  )}
                                                </Disclosure>
                                              )
                                              : (
                                                <Link to={child?.href || '#'} className={classNames(submenuItemStyle, currentItem === child.href ? "bg-gray-100" : "")}>
                                                  <h5>{child.name}</h5>
                                                </Link>
                                              )
                                          }
                                        </div>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )
                            : (
                              <Link
                                to={item.href}
                                className={classNames(
                                  currentItem === item.href
                                    ? 'bg-gray-50 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                  'group flex items-center gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                {item.icon}
                                <h4 className={menuItemStyle}>{item.name}</h4>
                              </Link>
                            )
                        }

                      </li>
                    ))}
                  </ul>
                </li>
              </ul>

              <div className="grid grid-cols-1 gap-y-4 mb-6">
                {
                  bottomNavigation.map((item) => (
                    <button
                      className="focus:outine-accent flex items-center gap-x-3 rounded-md hover:bg-gray-100 py-1 px-2"
                      key={item.name}
                      onClick={item.onClick}
                    >
                      {item.icon}
                      <h4 className={menuItemStyle}>{item.name}</h4>
                    </button>
                  ))
                }
              </div>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center justify-between gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <AiOutlineMenu className="h-6 w-6" aria-hidden="true" />
          </button>
          <Link to="/">
            <img
              className="h-10 w-auto"
              src={sustainabaseLogo}
              alt="Your Company"
            />
          </Link>
        </div>
      </div>
    </>
  )
}
