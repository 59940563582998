import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../../reducers/RootReducer';
import Project from '../../../types/Project';
import useAsyncCall from '../../../hooks/UseAsyncCall';
import InitiativeActions from '../../../actions/InitiativeActions';
import StyledTreeItem from './StyledTreeItem';

const ProjectBranches = (props: {
  dispatch: Dispatch<any>
  expandedItems: Set<string>
  focusAreaId: string
  goalId: string
  initiativeId: string
  planId: string
  projectIds: string[]
  strategyId: string
}) => {

  const { dispatch, expandedItems, focusAreaId, goalId, initiativeId, planId, projectIds, strategyId } = props;
  const [ previousIsExpanded, setPreviousIsExpanded ] = useState(false);

  const projects = useSelector((store: ApplicationState) => {

    let projects: {[key: string]: Project} = {};
    projectIds.forEach((projectId) => {
      const project = store.project.projects[projectId];
      if (project != null) {
        projects[project.id] = project;
      }
    });

    return projects;
  });

  const fetchParentCall = useAsyncCall(true);
  useEffect(() => {
    if (expandedItems.has(planId) && !previousIsExpanded) {
      fetchParentCall.makeAsyncCall(InitiativeActions.fetchInitiative({planId, focusAreaId, goalId, strategyId, initiativeId}))
        .then((res) => {
          dispatch(res);
        })
        .catch(e => {
          console.error(e);
        });
    }
    setPreviousIsExpanded(expandedItems.has(planId));
  }, [expandedItems, planId, focusAreaId, goalId, strategyId, initiativeId]);

  return (
    <>
      {projectIds.map((projectId) => {
        return <StyledTreeItem key={projectId} nodeId={projectId} label={projects[projectId] ? projects[projectId].title : '-'} />;
      })}
    </>
  );
};

export default ProjectBranches;