import SharedActions, { ActionType } from '../utils/ActionTypes';
import { authedFetch, Endpoints } from '../utils/API';
import CreateStrategyRequest from '../types/requests/strategy/CreateStrategyRequest';
import DeleteStrategyRequest from '../types/requests/strategy/DeleteStrategyRequest';
import FetchStrategyRequest from '../types/requests/strategy/FetchStrategyRequest';
import UpdateStrategyRequest from '../types/requests/strategy/UpdateStrategyRequest';

export default class StrategyActions {

  /**
   * Function called to create a new strategy
   *
   * @param planId: the plan to create the strategy under
   * @param focusAreaId: the focus area the strategy is under
   * @param goalId: the goal the strategy is under
   * @param strategy: the strategy to be created
   */
  static createStrategy({ planId, focusAreaId, goalId, strategy }: CreateStrategyRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.strategy.create, {
      method: 'POST',
      body: {
        planId,
        focusAreaId,
        goalId,
        strategy
      }
    })
      .then((res) => {
        return {
          type: SharedActions.strategy.create,
          payload: {
            planId,
            focusAreaId,
            goal: res.goal,
            strategy: res.strategy,
          }
        }
      });
  }

  /**
   * Function called to delete a strategy
   *
   * @param planId: the plan the strategy is under
   * @param focusAreaId: the focus area the strategy is under
   * @param goalId: the goal the strategy is under
   * @param strategyId: the strategy to be deleted
   */
  static deleteStrategy({ planId, focusAreaId, goalId, strategyId }: DeleteStrategyRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.strategy.delete, {
      method: 'DELETE',
      queryParameters: { planId, focusAreaId, goalId, strategyId }
    })
      .then((res) => {
        return {
          type: SharedActions.strategy.delete,
          payload: {
            planId,
            focusAreaId,
            strategyId,
            goal: res.goal,
          }
        }
      });
  }

  /**
   * Function called to fetch a strategy by its id
   *
   * @param planId: the plan the strategy is under
   * @param focusAreaId: the focus area the strategy is under
   * @param goalId: the goal the strategy is under
   * @param strategyId: the strategy to be fetched
   */
  static fetchStrategy({ planId, focusAreaId, goalId, strategyId }: FetchStrategyRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.strategy.fetch, {
      method: 'GET',
      queryParameters: { planId, focusAreaId, goalId, strategyId }
    })
      .then((res) => {
        return {
          type: SharedActions.strategy.update,
          payload: {
            planId,
            focusAreaId,
            goalId,
            strategy: res.strategy,
            initiatives: res.initiatives,
          }
        }
      });
  }

  /**
   * Function called to update a strategy
   *
   * @param planId: the plan the strategy is under
   * @param focusAreaId: the focus area the strategy is under
   * @param goalId: the goal the strategy is under
   * @param strategyId: strategy to be updated
   * @param update: the updates to the strategy to be pushed
   */
  static updateStrategy({ planId, focusAreaId, goalId, strategyId, update }: UpdateStrategyRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.strategy.update, {
      method: 'PATCH',
      body: {
        planId,
        focusAreaId,
        goalId,
        strategyId,
        strategy: update
      }
    })
      .then((res) => {
        return {
          type: SharedActions.strategy.update,
          payload: {
            planId,
            focusAreaId,
            goalId,
            strategy: res.strategy,
            initiatives: res.initiatives,
          }
        }
      });
  }
}