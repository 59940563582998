import { useEffect, useContext, useState } from "react"
import { useGhgiCategory } from "~/hooks/UseGhgiCategory";
import { UserContext, UserContextType } from "~/contexts/UserContext";
import { useAlertContext } from "~/contexts/AlertContext";
import FileActions from "~/actions/FileActions";
import {
  FileItem,
  getFilesFromGHGICategory,
  addFile,
  updateFileById,
  deleteFileById,
  getFilesFromFilter,
  getFileById,
} from "~/utils/IndexDB";
import { allowedFileTypes, maxFileSize } from '~/utils/FileValidation'
import { WorkbookWarning } from "~/types/File";
import { ArcadiaCredential } from "~/types/Arcadia";
import { getCredentials } from "~/utils/CredentialsIndexDB";
//import { getArcadiaCredentialsByOrg } from "~/actions/ArcadiaActions";

export function useUploadManager() {
  const [stagedFiles, setStagedFiles] = useState<FileItem[]>([])
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<FileItem>()
  const [selectedUtility, setSelectedUtility] = useState<ArcadiaCredential>()
  const [files, setFiles] = useState<FileItem[]>([])
  const [utilities, setUtilities] = useState<ArcadiaCredential[]>([])
  const [criticalOnly, setCriticalOnly] = useState<boolean>(false)
  const [workbookWarnings, setWorkbookWarnings] = useState<WorkbookWarning[]>([])
  const { organization } = useContext(UserContext) as UserContextType
  const { showAlert } = useAlertContext()
  const ghgiCategory = useGhgiCategory()

  useEffect(() => {
    if (organization?._id) {
      loadFiles()
      if (ghgiCategory === 'company_facilities') {
        loadCredentials()
      }
    }
    getUploadedWorkbooks()
  }, [organization?._id, ghgiCategory])

  const loadFiles = async () => {
    try {
      setLoadingFiles(true)
      const localFiles = await getFilesFromGHGICategory(ghgiCategory)
      const uploadedLocalFiles = localFiles.filter((file: FileItem) => file.status !== 'queued')
      const stagedFiles = localFiles.filter((file: FileItem) => file.status === 'queued')
      manageFiles(stagedFiles)
      setFiles(stagedFiles)
      for (const file of uploadedLocalFiles) {
        if (file.id) {
          await deleteFileById(file.id)
        }
      }

      /*const uts = await getArcadiaCredentialsByOrg(organization?._id as string)
      setUtilities(uts)*/
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setLoadingFiles(false)
    }
  }

  const loadCredentials = async () => {
    try {
      setLoadingFiles(true)
      const documents = await getCredentials()
      const credentials = documents.map((document) => document.credential)
      console.log('Credentials from indexDB: ', credentials)
      setUtilities(credentials)
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setLoadingFiles(false)
    }
  }

  const manageFiles = async (filesForUpload: FileItem[]) => {
    try {
      const fileIds = filesForUpload.map((file) => file.id)
      const filteredFiledIds: any[] = fileIds.filter((id) => Boolean(id))
      for (const file of filesForUpload) {
        if (file.status === 'queued') {
          console.log('Uploading file: ', file.id)
          await uploadFile(file, filteredFiledIds)
        }
      }
    }
    catch (error: any) {
      console.log('Error uploading files: ', error)
      showAlert({
        title: 'There was a problem uploading your files',
        message: error.message,
        variation: 'error'
      })

    }
  }

  const uploadFile = async (stagedUpload: FileItem, stagedFileIds: number[]) => {
    try {
      if (!organization?._id) {
        throw Error('Organization not found')
      }

      const response = await FileActions.streamFileUpload(
        stagedUpload.file,
        stagedUpload.workbook ? "WORKBOOK" : "DOCUMENT",
        organization._id,
        'DOCUMENT',
        ghgiCategory,
        (streamEvent: any) => {
          const percentCompleted = Math.round(
            (streamEvent.loaded * 100) / streamEvent.total
          )
          updateFileStatus(stagedUpload, percentCompleted, 'uploading', stagedFileIds)
        }
      )
      await updateFileStatus(stagedUpload, 100, 'uploaded', stagedFileIds,)
      let issues: WorkbookWarning[] = []
      if (stagedUpload.workbook) {
        await updateFileStatus(stagedUpload, 100, 'validating', stagedFileIds)
        const validationResponse = await FileActions.validateWorkbook(response.s3Response.fileId, ghgiCategory)
        issues = validationResponse.issues
        console.log('Issues from workbook: ', issues)
        await updateFileStatus(stagedUpload, 100, 'uploaded', stagedFileIds)
      }
      await FileActions.updateFileStatus(response.s3Response.fileId, 'uploaded')
      const newFiles = await getFilesFromFilter((fileItem) => Boolean(fileItem.id) && Boolean(stagedFileIds.includes(fileItem.id || -1)))
      newFiles[0].issues = issues
      setFiles(newFiles)
    }
    catch (error: any) {
      // Set the status to error for the target file
      showAlert({
        title: 'There was a problem uploading your files',
        message: error.message,
        variation: 'error'
      })
      throw new Error(error)
    }
  }

  const onWorkbookStatusChange = async (file: FileItem, workbook: boolean) => {
    try {
      const newFile = { ...file, workbook }
      if (file.id) {
        const updatedFile = await updateFileById(file.id, newFile)
        if (!updatedFile) {
          showAlert({
            title: 'Error updating file',
            message: 'Please try again or contact support',
            variation: 'error',
          })
        }
        const newStagedFiles = stagedFiles.map((fileItem: FileItem) => updatedFile && fileItem.id === updatedFile?.id ? updatedFile : fileItem)
        setStagedFiles(newStagedFiles)
      }
      else {
        showAlert({
          title: 'File wasn\'t indexed',
          message: 'Please upload file again',
          variation: 'error',
        })
      }
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        title: 'There was a problem',
        message: error?.message || 'Please try again or contact support',
        variation: 'error',
      })
    }
  }

  const updateFileStatus = async (file: FileItem, progress: number, status: 'queued' | 'uploading' | 'uploaded' | 'validating', fileIds: number[]) => {
    if (!file.id)
      return
    await updateFileById(file.id, {
      ...file,
      status,
      progress,
    })

    const updatedFiles = []

    for (const fileId of fileIds) {
      const updated = await getFileById(fileId)
      if (!updated)
        continue
      updatedFiles.push(updated)
    }

    setFiles(updatedFiles)
  }

  const handleAddFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault()
      let errors = []
      let compliantFiles = []
      /*if (e.target.files && e.target.files.length > 20) {
        console.error('Too many files dropped!')
      }*/
      const stagedFiles = e.target.files
      if (!stagedFiles) return
      for (const fileItem of Array.from(stagedFiles)) {
        if (!allowedFileTypes.includes(fileItem.type)) {
          showAlert({
            variant: 'error',
            title: 'Error uploading file.',
            message: `Unsupported file type: ${fileItem.type}`
          })
          console.log(fileItem)
          errors.push(`${fileItem.name} doesn't have a valid file type`)
        }
        else if (fileItem.size > maxFileSize) {
          errors.push(`${fileItem.name} is above ${maxFileSize} bytes`)
        }
        else {
          compliantFiles.push(fileItem)
        }
      }
      const newDBFiles = await Promise.all(compliantFiles.map((fileItem: File) => addFile(fileItem, false, 'queued', ghgiCategory)))
      setStagedFiles(newDBFiles)
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem adding your files',
        message: error.message
      })
    }
  }

  const getUploadedWorkbooks = async () => {
    try {
      if (organization?._id) {
        const { workbooks } = await FileActions.getWorkbooksUploads(organization._id, ghgiCategory)
        const warnings: WorkbookWarning[] = []
        for (const workbook of workbooks) {
          if (workbook.issues?.length > 0) {
            for (const issue of workbook.issues) {
              warnings.push({
                ...issue,
                workbookId: workbook._id,
              })
            }
          }
        }
        setWorkbookWarnings(warnings)
      }
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        variant: 'error',
        title: 'There was a problem adding your files',
        message: error.message
      })
    }
  }

  const ignoreWorkbookIssue = async (issue: WorkbookWarning) => {
    try {
      if (!issue?._id)
        return
      const updatedFile = await FileActions.updateIssueStatus(issue._id, 'discarted')
      console.log('Updated file: ', updatedFile)
      updatedFile.issues = updatedFile.issues.filter((issue: WorkbookWarning) => issue.status === 'open')
      const newFiles = files.map((file: FileItem) => file.id === updatedFile.id ? updatedFile : file)
      setFiles(newFiles)
      if (updatedFile.id === selectedFile?.id) {
        setSelectedFile(updatedFile)
      }
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        variant: 'error',
        title: 'There was a problem adding your files',
        message: error.message
      })
    }
  }

  return {
    files,
    utilities,
    criticalOnly,
    setCriticalOnly,
    handleAddFiles,
    workbookWarnings,
    stagedFiles,
    setStagedFiles,
    setFiles,
    selectedUtility,
    setSelectedUtility,
    onWorkbookStatusChange,
    selectedFile,
    setSelectedFile,
    loadingFiles,
    ignoreWorkbookIssue,
    manageFiles,
  }
}
