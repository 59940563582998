import Initiative from '../types/Initiative';
import SharedActions, { ActionType } from '../utils/ActionTypes';

export type InitiativeState = {
  initiatives: { [key: string]: Initiative }
}

const initialState = {
  initiatives: {},
};

export default function initiativeReducer(
  state: InitiativeState = initialState,
  action: ActionType
): InitiativeState {
  switch (action.type) {

    case SharedActions.strategy.update: {
      const { initiatives } = action.payload;

      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          ...initiatives
        }
      };
    }

    case SharedActions.treeView.fetchFullTree: {
      const { initiatives } = action.payload;
      const initiativesObject = initiatives.reduce(
        (obj: any, item: Initiative) => ((obj[item.id] = item), obj),
        {}
      );

      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          ...initiativesObject,
        },
      };
    }

    case SharedActions.initiative.create:
    case SharedActions.initiative.update:
    case SharedActions.project.create:
    case SharedActions.project.delete: {
      const { initiative } = action.payload;

      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          [initiative.id]: initiative
        }
      };
    }

    case SharedActions.initiative.delete: {
      const { initiativeId } = action.payload;

      const { [initiativeId]: removedItem, ...rest } = state.initiatives;

      return {
        ...state,
        initiatives: {
          ...rest,
        }
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}