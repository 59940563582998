import moment, { Moment } from 'moment-timezone';
import Plan from '../types/Plan';
import SharedActions, { ActionType } from '../utils/ActionTypes'

export type PlanState = {
  plan: Plan | null
  planTreeLoadTime: Moment | null
  viewingPlanTree: string | null
}

const initialState = {
  plan: null,
  planTreeLoadTime: null,
  viewingPlanTree: null,
};

export default function planReducer(
  state: PlanState = initialState,
  action: ActionType
): PlanState {
  switch (action.type) {

    case SharedActions.focusArea.create:
    case SharedActions.focusArea.delete:
    case SharedActions.plan.update: {
      const { plan } = action.payload;

      return {
        ...state,
        plan: plan
      };
    }

    case SharedActions.plan.setViewingTree: {
      return {...state, viewingPlanTree: action.payload};
    }

    case SharedActions.treeView.fetchFullTree: {
      return {
        ...state,
        planTreeLoadTime: moment()
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}