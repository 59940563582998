import { useContext } from 'react'
import { useAsync } from 'react-use'
import { Endpoints } from '~/utils/API'
import { GET } from '~/utils/API'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import { GHGICategory } from '~/views/DataImport/useDataImport'

export function useGhgiCategories() {
  const { organization } = useContext(UserContext) as UserContextType

  const state = useAsync(async () => {
    const result = await GET({
      endpoint: Endpoints.auth.ghgiCategories.getAll,
    })

    return result.categories as GHGICategory[]
  }, [organization])

  return {
    categories: state.value || [],
    loading: state.loading,
    error: state.error,
  }
}
