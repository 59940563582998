// import { AiFillEdit } from 'react-icons/ai'
import Data from '~/components/Data'
import type { CustomerLocationAccountType } from '~/types/entities'

interface Props {
  account: CustomerLocationAccountType
}

export function GeneralAccountEditForm({
  account,
}: Props) {
  return (
    <div className="w-full flex flex-col p-4">
      <div className="flex items-center justify-between">
        <h5 className="text-gray-400 uppercase font-semibold text-xs tracking-widest mb-3">ACCOUNT</h5>
        {/*<button
          className="text-slate-400 hover:text-slate-600 focus:outline-none focus:text-slate-600 flex items-center justify-end"
        >
          <AiFillEdit className="text-lg mr-2" />
          <p className="font-semibold">Edit</p>
        </button>*/}
      </div>
      <div className="grid grid-cols-2 w-full gap-4">
        <Data label="Provider Name" value={account.provider?.name ?? ''} />
        <Data label="Provider Type" value={account.provider?.type ?? ''} />
        <Data label="Resource Name" value={account.resource?.resource ?? ''} />
        <Data label="Sector Name" value={account.sector?.name ?? ''} />
        <Data label="Subsector Name" value={account.subSector?.name ?? ''} />
      </div>
    </div>
  )
}
