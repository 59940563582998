import { createContext, useState, useContext, ReactNode, FC, Fragment } from 'react';
import { Transition } from '@headlessui/react'
import { AiOutlineCheckCircle, AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai'
import { BiErrorCircle } from 'react-icons/bi'

export type NotificationConfig = {
  message: string
  title: string
  variation: 'success' | 'error' | 'warning' | 'info' | undefined
  duration: number
  verticalPosition?: 'top' | 'bottom'
  horizontalPosition?: 'left' | 'center' | 'right'
}

export type AlertContextType = {
  showAlert: (notificationConfig: NotificationConfig) => void
}

export const AlertContext = createContext<AlertContextType | null>(null);

export const useAlertContext = () => useContext<any>(AlertContext)

const AlertProvider: FC<ReactNode> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationConfig>()

  const showAlert = async (newNotification: NotificationConfig) => {
    if(!newNotification.duration) newNotification.duration = 5000
    if(!newNotification.variation) newNotification.variation = 'info'
    if(!newNotification.verticalPosition) newNotification.verticalPosition = 'top'
    if(!newNotification.horizontalPosition) newNotification.horizontalPosition = 'right'
    setNotification(newNotification)

    // // Logic to open and automatically close the notification
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        setNotification(undefined)
      }, newNotification.duration);
    });
  }

  return (
    <AlertContext.Provider value={{ showAlert }}>
      <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-4 sm:items-start sm:p-6 z-[10000]"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={!!notification}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mt-14">
              <div className="p-3">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {/* Create the variations for the icon */}
                    {notification?.variation === 'success' && <AiOutlineCheckCircle className="h-6 w-6 text-green-400" aria-hidden="true" />}
                    {notification?.variation === 'error' && <BiErrorCircle className="h-6 w-6 text-red-400" aria-hidden="true" />}
                    {notification?.variation === 'info' && <AiOutlineInfoCircle className="h-6 w-6 text-blue-400" aria-hidden="true" />}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-semibold text-gray-900">{notification?.title}</p>
                    <p className="mt-1 text-sm text-gray-500">{notification?.message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setNotification(undefined)
                      }}
                    >
                        <AiOutlineClose className="" />
                      <span className="sr-only">
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      </>
      { children }
    </AlertContext.Provider>
  )
}

export default AlertProvider;