import { memo, createElement } from 'react'
import { Link } from 'react-router-dom'
import Button from '~/components/Button'
import { getDashboardCategoryIcon } from '~/constants/DashboardCategoryIcons'

type Props = {
  name: string
  category: string
  description?: string
  reportHref: string
}

export const ViewReportCard = memo(({
  name,
  description,
  category,
  reportHref,
}: Props) => (
  <div className="flex w-[25rem] h-[16rem] flex-col items-center text-center p-8 rounded-xl bg-white border border-gray-400">
    <div>{createElement(getDashboardCategoryIcon(category), { className: "text-4xl text-accent" })}</div>
    <h3 className="text-2xl py-2">{name}</h3>
    <div className="grow" />
    <p>{description}</p>
    
    {/**TODO: temporary, button component should handle Links or make a ButtonLink component that extends Button */}
    <Link to={reportHref} className="hover:no-underline">
      <Button
        className="mt-8 !border-accent"
        variant="ghost"
      >
        View Report
      </Button>
    </Link>
  </div>
))
