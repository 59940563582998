import classNames from '../../utils/classNames'

interface Props {
  label: string,
  value: string,
  loading?: boolean,
  containerStyles?: string,
  labelStyles?: string,
  valueStyles?: string,
}

export default function Data({ label, value, loading, containerStyles, labelStyles, valueStyles }: Props) {
  return (
    <div className={classNames("flex flex-col", containerStyles)}>
      <h5 className={classNames("text-xs text-slate-500 mb-2", labelStyles)}>{label}</h5>
      {
        !loading
        ? <p className={classNames(valueStyles)}>{value}</p>
        : <span className="animate-pulse h-6 w-full bg-gray-100 rounded-lg" />
      }
      
    </div>
  )
}
