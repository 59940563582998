import SharedActions, { ActionType } from '../utils/ActionTypes';
import { authedFetch, Endpoints } from '../utils/API';
import CreateFocusAreaRequest from '../types/requests/focusArea/CreateFocusAreaRequest';
import DeleteFocusAreaRequest from '../types/requests/focusArea/DeleteFocusAreaRequest';
import FetchFocusAreaRequest from '../types/requests/focusArea/FetchFocusAreaRequest';
import UpdateFocusAreaRequest from '../types/requests/focusArea/UpdateFocusAreaRequest';

export default class FocusAreaActions {

  /**
   * Function called to create a new focus area
   *
   * @param planId: the plan to create the focus area under
   * @param focusArea: the focus area to be created
   */
  static createFocusArea({ planId, focusArea }: CreateFocusAreaRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.focusArea.create, {
      method: 'POST',
      body: {
        planId,
        focusArea
      }
    })
      .then((res) => {
        return {
          type: SharedActions.focusArea.create,
          payload: {
            plan: res.plan,
            focusArea: res.focusArea
          }
        }
      });
  }

  /**
   * Function called to delete a focus area
   *
   * @param planId: the plan the focus area is under
   * @param focusAreaId: the id of the focus area to delete
   */
  static deleteFocusArea({ planId, focusAreaId }: DeleteFocusAreaRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.focusArea.delete, {
      method: 'DELETE',
      queryParameters: { planId, focusAreaId }
    })
      .then((res) => {
        return {
          type: SharedActions.focusArea.delete,
          payload: {
            focusAreaId,
            plan: res.plan,
          }
        }
      });
  }

  /**
   * Function called to fetch a focus area by its id
   *
   * @param planId: the plan the focus area is under
   * @param focusAreaId: the id of the focus area to fetch
   */
  static fetchFocusArea({ planId, focusAreaId }: FetchFocusAreaRequest, organizationId: string | undefined): Promise<ActionType> {
    return authedFetch(Endpoints.auth.focusArea.fetch, {
      method: 'GET',
      queryParameters: { planId, focusAreaId, organizationId }
    })
      .then((res) => {
        return {
          type: SharedActions.focusArea.update,
          payload: {
            planId,
            focusArea: res.focusArea,
            goals: res.goals,
          }
        }
      });
  }

  /**
   * Function called to update a focus area
   *
   * @param planId: the plan the focus area is under
   * @param focusAreaId: focus area to be updated
   * @param update: the updates to the focus area to be pushed
   */
  static updateFocusArea({ planId, focusAreaId, update }: UpdateFocusAreaRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.focusArea.update, {
      method: 'PATCH',
      body: {
        planId,
        focusAreaId,
        focusArea: update
      }
    })
      .then((res) => {
        return {
          type: SharedActions.focusArea.update,
          payload: {
            planId,
            focusArea: res.focusArea,
            goals: res.goals,
          }
        }
      });
  }
}