import GHGIIcon from '../GHGIIcon'

interface Props {
  label: string
  id: string
  disabled?: boolean,
  onClick: () => void
}

export default function GHGICategoryCard({ label, id, disabled, onClick }: Props) {
  const iconClass = "text-4xl mb-3 fill-accent group-hover:fill-white"
  
  return (
    <div 
      className="bg-white rounded-xl w-36 h-36"
      onClick={disabled ? () => {} : onClick}
    >
      <div 
        className={`group focus:bg-accent w-36 h-36 flex flex-col items-center justify-center border cursor-pointer ${disabled && 'opacity-40 cursor-not-allowed'} border-slate-100 rounded-xl p-2 hover:text-white active:text-white hover:fill-white active:fill-white hover:bg-accent active:bg-accent hover:shadow-lg`}
      >
        <GHGIIcon
          categoryId={id}
          iconClass={iconClass}
        />
        <h4 className="font-bold text-center">{label}</h4>
      </div>
    </div>

  )
}
