import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from '../generics/Loading';
import CDPReport from '../../views/CDPReport';
import { ActivityAuditReport, EmissionsAuditReport } from '~/views/EmissionsAuditReport'
import { PageLayout } from '../PageLayout';

const ReportsList = lazy(() => import('./ReportsList'));
const GPC = lazy(() => import('./gpc/GPC'));
const LargestUsers = lazy(() => import('./largest_users/LargestUsers'));
const CommunityGHGI = lazy(() => import('./community_ghgi/CommunityGHGI'));
const GetDynamicCarbonV1 = lazy(() => import('./dynamic_carbon_v1/GetDynamicCarbonV1'));
const NhscConsolidated = lazy(() => import('./nhsc/NhscConsolidated'));
const SolarTogether = lazy(() => import('./solar_together/SolarTogether'));
const Sasb = lazy(() => import('./sasb/Sasb'));
const Gri = lazy(() => import('./gri/Gri'));
const FYDynamicCarbon = lazy(() => import('./fy_dynamic_carbon/FYDynamicCarbon'));
const GPS = lazy(() => import('./gps/GPS'));
const GPS2 = lazy(() => import('./gps/GPS2'));
const EndCustomerAllocation = lazy(() => import('./endCustomerAllocation/EndCustomerAllocation'));
const RyderCustom1 = lazy(() => import('./ryderCustom1/RyderCustom1'));
const VitafloCustom1 = lazy(() => import('./vitafloCustom1/VitafloCustom1'));

const ReportsRouting = () => {
  return (<PageLayout fullWidth={true}>
    <Switch>
      <Route path="/reports/cdp">
        <CDPReport />
      </Route>
      <Route path="/reports/audit/emissions">
        <EmissionsAuditReport />
      </Route>
      <Route path="/reports/audit/activity">
        <ActivityAuditReport />
      </Route>
      <Route path="/reports/largestUsers" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <LargestUsers />
        </Suspense>
      </Route>
      <Route path="/reports/gpc" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <GPC />
        </Suspense>
      </Route>
      <Route path="/reports/communityghgi" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <CommunityGHGI />
        </Suspense>
      </Route>
      <Route path="/reports/dynamicCarbonV1" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <GetDynamicCarbonV1 />
        </Suspense>
      </Route>
      <Route path="/reports/nhscConsolidated" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <NhscConsolidated />
        </Suspense>
      </Route>
      <Route path="/reports/solarTogether" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <SolarTogether />
        </Suspense>
      </Route>
      <Route path="/reports/sasb" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <Sasb />
        </Suspense>
      </Route>
      <Route path="/reports/gri" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <Gri />
        </Suspense>
      </Route>
      <Route path="/reports/fyDynamicCarbon" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <FYDynamicCarbon />
        </Suspense>
      </Route>
      <Route path="/reports/gps" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <GPS />
        </Suspense>
      </Route>
      <Route path="/reports/gps2" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <GPS2 />
        </Suspense>
      </Route>
      <Route path="/reports/endCustomerAllocation" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <EndCustomerAllocation />
        </Suspense>
      </Route>
      <Route path="/reports/ryderCustom1" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <RyderCustom1 />
        </Suspense>
      </Route>
      <Route path="/reports/vitafloCustom1" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <VitafloCustom1 />
        </Suspense>
      </Route>
      <Route path="/reports" exact>
        <Suspense fallback={<Loading delay={1000} />}>
          <ReportsList />
        </Suspense>
      </Route>
    </Switch>
  </PageLayout>);
};

export default ReportsRouting;
