import { getEmissionsReport } from '~/actions/ReportsActions'
import { type FactBillingType } from '~/types/entities/FactBilling'
import { AuditReport } from './AuditReport'

const tableColumns: {header: string, accessorKey: string}[] = [
  { header: 'Scope', accessorKey: 'scope' },
  { header: 'Category', accessorKey: 'category' },
  { header: 'Asset', accessorKey: 'asset' },
  { header: 'Country', accessorKey: 'country' },
  { header: 'State', accessorKey: 'state' },
  { header: 'Activity', accessorKey: 'activityType' },
  { header: 'Usage Amount', accessorKey: 'usageAmount' },
  { header: 'Emissions Factor', accessorKey: 'emissionsFactorAmount' },
  { header: 'Emissions Factor Unit', accessorKey: 'emissionsFactorUnit' },
  { header: 'Emissions Amount (MTCO2)', accessorKey: 'emissionsAmount' },
  { header: 'e-factor ref', accessorKey: 'efRef' },
  { header: 'Carbon Factor', accessorKey: 'carbonFactor' },
  { header: 'Methane Factor', accessorKey: 'methaneFactor' },
  { header: 'Nitrous Factor', accessorKey: 'nitrousFactor' },
  { header: 'Carbon GWP', accessorKey: 'carbonGWP' },
  { header: 'Methane GWP', accessorKey: 'methaneGWP' },
  { header: 'Nitrous GWP', accessorKey: 'nitrousGWP' },
]

const filterList = [
  'category',
  'asset',
  'activityType',
]

type EmissionsTableItem = {
  scope: string
  category: string
  asset: string
  country: string
  state: string
  activityType: string
  usageAmount: string
  emissionsFactorAmount: string
  emissionsFactorUnit: string
  emissionsAmount: string
  efRef: string
  carbonFactor: string
  methaneFactor: string
  nitrousFactor: string
  carbonGWP: string
  methaneGWP: string
  nitrousGWP: string
}

const mapReportsToTableRows = (item: FactBillingType) => ({
  scope: String(item.scopeId),
  category: item.customerLocationAccount?.ghgActivity?.activity,
  asset: item.customerAsset?.nickName,
  country: item.customerAsset?.country,
  state: item.customerAsset?.state,
  activityType: item.resource?.resource,
  usageAmount: item.sumDailyAvgAmt,
  emissionsFactorAmount: item.emissionsFactorAmount,
  emissionsFactorUnit: item.emissionsFactorUnit,
  emissionsAmount: item.sumDailyAvgEmissions,
  efRef: item.emissionsFactorRef,
  carbonFactor: item.emissionsFactor?.co2,
  methaneFactor: item.emissionsFactor?.ch4,
  nitrousFactor: item.emissionsFactor?.n2o,
  carbonGWP: item.emissionsFactor?.GWP?.co2,
  methaneGWP: item.emissionsFactor?.GWP?.ch4,
  nitrousGWP: item.emissionsFactor?.GWP?.n2o,
})

export function EmissionsAuditReport() {
  return <AuditReport<EmissionsTableItem>
    reportName="Emissions Factor Audit Report"
    filterList={filterList}
    tableColumns={tableColumns}
    fetchReport={getEmissionsReport}
    mapReportsToTableRows={mapReportsToTableRows}
  />
}
