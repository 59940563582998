// const tabs = [
//   { name: 'My Account', href: '#', current: true },
//   { name: 'Company', href: '#', current: false },
//   { name: 'Team Members', href: '#', current: false },
//   { name: 'Billing', href: '#', current: false },
// ]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  tabs: string[],
  currentTab: string,
  onTabSelected: (tab: string) => void,
  containerClass?: string,
  tabStyles?: string
}

export default function UnderlineTabs({ tabs, currentTab, onTabSelected, containerClass = "", tabStyles = "" }: Props) {
  return (
    <div className={containerClass}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full "
          defaultValue={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="isolate flex" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <div
              className='overflow-hidden'
              key={index}
            >
              <div
                key={tab}
                onClick={() => onTabSelected(tab)}
                className={classNames(
                  currentTab === tab ? ' border-accent border-b-2 font-semibold' : 'text-gray-500 hover:bg-gray-50',
                  'group relative min-w-0 flex-1 overflow-hidden py-2 px-4 text-sm font-medium text-center  focus:z-10 cursor-pointer box-border',
                  tabStyles
                )}
              >
                <p>
                  <span>{tab}</span>
                </p>
              </div>
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}