import { GiFactory } from 'react-icons/gi'
import { BiGasPump } from 'react-icons/bi'
import { BsFillLightningChargeFill, BsWater } from 'react-icons/bs'
import { RiOilFill } from 'react-icons/ri'
import { FaDumpster } from 'react-icons/fa'

interface Props {
  activityId?: string,
  iconClass: string
}

export default function GHGIActivityIcon({ activityId, iconClass }: Props) {

  const activityIcons: any = {
    stationary_combustion: <GiFactory className={iconClass} />,
    purchased_electricity: <BsFillLightningChargeFill className={iconClass} />,
    waste: <FaDumpster className={iconClass} />,
    water: <BsWater className={iconClass} />,
    refrigerants: <RiOilFill className={iconClass} />,
    mobile_combustion: <BiGasPump className={iconClass} />,
    default: <GiFactory className={iconClass} />,
  }

  return (
    <>
      {activityId && activityIcons[activityId] ? activityIcons[activityId] : activityIcons['default']}
    </>
  )
}
