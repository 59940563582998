import { OrganizationType, UserInfo } from '../../../contexts/UserContext'
import { BsGear } from 'react-icons/bs'
import { AiOutlineFolderOpen } from 'react-icons/ai'

interface Props {
  organization?: OrganizationType
  userInfo?: UserInfo
}

export default function Organization({ organization, userInfo }: Props) {
  return (
    <div className="p-4 flex flex-col rounded-lg shadow-md bg-white border border-slate-1oo">
      <span className="w-full flex justify-between">
        <span className="h-24 w-24 rounded-full bg-gray-200 flex items-center justify-center">
          <h3 className="text-3xl font-bold text-gray-400">{organization?.name && organization.name[0]}</h3>
        </span>
        <button className="disabled:cursor-not-allowed h-fit disabled:opacity-50" disabled={true}>
          <BsGear className="text-gray-400 text-xl" />
        </button>
      </span>
      <span className="mt-3 grid grid-cols-[40px_1fr] gap-x-2 gap-y-5">
        <span className="flex justify-center">
          <AiOutlineFolderOpen className="text-gray-400" />
        </span>
        <h3 className="font-bold text-slate-900">{organization?.name}</h3>
        <span className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
          <h3 className="font-bold text-gray-400">{organization?.name && organization.name[0]}</h3>
        </span>
        <span>
          <h3 className="font-bold">{`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}</h3>
          <p className="text-slate-500 mt-1 text-xs">{userInfo?.email}</p>
          {/* <p className="text-slate-500 mt-1">{userInfo?.}</p> */}
        </span>
      </span>
    </div>
  )
}
