import { authedFetch, Endpoints } from '../utils/API';
import SharedActions, { ActionType } from '../utils/ActionTypes';
import Plan from '../types/Plan';

export default class PlanActions {

  /**
   * Function called to fetch the users plan
   */
  static fetchPlan(organizationId: string | undefined): Promise<ActionType> {
    return authedFetch(Endpoints.auth.plan.fetch, {
      method: 'GET',
      queryParameters: { organizationId }
    })
      .then((res) => {
        return {
          type: SharedActions.plan.update,
          payload: {
            plan: res.plan,
            focusAreas: res.focusAreas,
          }
        }
      });
  }

  static fetchFullPlanTree(organizationId: string | undefined):  Promise<ActionType> {
    return authedFetch(Endpoints.auth.plan.fetchFullTree, {
      method: 'GET',
      queryParameters: { organizationId}
    })
      .then((res) => {
        return {
          type: SharedActions.treeView.fetchFullTree,
          payload: {
            focusAreas: res.focusAreas,
            goals: res.goals,
            strategies: res.strategies,
            initiatives: res.initiatives,
            projects: res.projects,
          }
        }
      });
  }

  /**
   * Function called to set what plan tree is being viewed
   *
   * @param planId: the id of the plan being viewed, or null to dismiss tree
   */
  static setViewingTreePlan(planId: string | null) {
    return {
      type: SharedActions.plan.setViewingTree,
      payload: planId,
    };
  }

  /**
   * Function called to update a plan
   *
   * @param update: the updates to the plan to be pushed
   */
  static updatePlan(update: Plan): Promise<ActionType> {
    return authedFetch(Endpoints.auth.plan.update, {
      method: 'PATCH',
      body: {
        plan: update
      }
    })
      .then((res) => {
        return {
          type: SharedActions.plan.update,
          payload: {
            plan: res.plan,
            focusAreas: res.focusAreas,
          }
        }
      });
  }
}