import { IconType } from 'react-icons'
import { MdChevronLeft, MdChevronRight, MdOutlineAssignment } from 'react-icons/md'
import Button from '~/components/Button'

interface Props {
  year: number,
  titleIcon?: IconType,
  title?: string,
  prevYear?: number,
  nextYear?: number,
  changeYear: (year: number) => any,
}

export function ReportBanner({
  titleIcon,
  title,
  year,
  prevYear,
  nextYear,
  changeYear,
} : Props) {
  return <div className="w-full h-80 min-h-banner px-12 flex items-center justify-between bg-gradient-to-r from-gradient-light via-gradient-highlight to-gradient-dark">
    <Button
      className="bg-white/10 enabled:hover:bg-white/20 enabled:text-white disabled:text-white/50"
      iconLeft={<MdChevronLeft className="text-xl" />}
      label={`${prevYear || year - 1}`}
      disabled={!prevYear}
      onClick={() => changeYear(prevYear || year)}
    />
    <div className="flex justify-center text-white relative">
      <div className="absolute bottom-full">
        {titleIcon || <MdOutlineAssignment className="text-3xl" />}
      </div>
      <h1 className="text-xl font-bold">
        {title}
      </h1>
    </div>
    <Button
      className="bg-white/10 enabled:hover:bg-white/20 enabled:text-white disabled:text-white/50"
      iconRight={<MdChevronRight className="text-xl" />}
      label={`${nextYear || year + 1}`}
      disabled={!nextYear}
      onClick={() => changeYear(nextYear || year)}
    />
  </div>
}
