import { useEffect, useRef, Suspense, lazy } from 'react';
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ReactIconProvider } from '~/utils/ReactIconProvider';
// import useUserAuth from '~/hooks/UseUserAuth';
import Loading from "~/components/generics/Loading";
import useErrorSnackbar from '~/hooks/UseErrorSnackbar';
import { ApplicationState } from '~/reducers/RootReducer';
import NetworkActions from '~/actions/NetworkActions';
import ActionPlanTreeDialog from '~/components/action_plan/browsable_tree/ActionPlanTreeDialog';
import ReportsRouting from '~/components/reports/ReportsRouting';
// import LoginAuth0 from '~/components/LoginAuth0';
// import EverAfterDisplay from '~/components/generics/EverAfterDisplay';
import DataImport from '~/views/DataImport'
import PlatformHealth from '~/views/PlatformHealth'
import UserProvider from '~/contexts/UserContext';
import Settings from '~/components/settings/NavSettings';
import Surveys from '~/components/supply_chain/Surveys';
// import SingleSignOn from '~/components/SingleSignOn'
import AlertProvider from '~/contexts/AlertContext';
import { EmissionsTracking } from '~/components/track/EmissionsTracking';
import { MyActivities } from '~/components/track/MyActivities';
import "~styles/App.css";

const Plan = lazy(() => import('~/views/Plan'));
const MyFacilitiesPage = lazy(() => import('~/views/Facilities/MyFacilitiesPage'));
const CarbonFootprint = lazy(() => import('~/components/carbon_footprint/CarbonFootprint'));
const Track = lazy(() => import('~/components/Track'));
const FocusAreaPage = lazy(() => import('~/components/action_plan/focus_area/FocusAreaPage'));
const ActionPlan = lazy(() => import('~/components/action_plan/ActionPlan'));
const Inventory = lazy(() => import('~/components/inventory/Inventory'));
const Commitments = lazy(() => import('~/components/commitments/Commitments'));
const CommitmentPage = lazy(() => import('~/components/commitments/CommitmentPage'));
const Do = lazy(() => import('~/components/do/Do'));
const Documents = lazy(() => import('~/components/documents/Documents'));
const Forecasts = lazy(() => import('~/components/forecasts/Forecasts'));
const ErrorPage = lazy(() => import("~/components/ErrorPage"));

function App() {
  const { SnackbarComponent, setSnackbarError } = useErrorSnackbar();
  const removeErrorTimeout = useRef<NodeJS.Timeout | null>();

  const dispatch = useDispatch();
  const globalSnackbarMessage = useSelector((store: ApplicationState) => store.network.globalSnackbarMessage)
  const viewingPlanTree = useSelector((store: ApplicationState) => store.plan.viewingPlanTree)

  /**
   * Updates the global snackbar component whenever the global error updates
   */
  useEffect(() => {
    setSnackbarError(globalSnackbarMessage);
    if (globalSnackbarMessage != null) {
      if (removeErrorTimeout.current)
        clearTimeout(removeErrorTimeout.current);

      removeErrorTimeout.current = setTimeout(() => {
        dispatch(NetworkActions.setGlobalError(null));
      }, 5000);
    }

    return () => {
      if (removeErrorTimeout.current)
        clearTimeout(removeErrorTimeout.current);
    }
  }, [globalSnackbarMessage]);

  return (
    <AlertProvider>
      <UserProvider>
        <ReactIconProvider>
          <CssBaseline />
            <Switch>
              <Route path="/health">
                <Suspense fallback={<Loading delay={1000} />}>
                  <PlatformHealth />
                </Suspense>
              </Route>
              <Route path="/error">
                <Suspense fallback={<Loading delay={1000} />}>
                  <ErrorPage />
                </Suspense>
              </Route>
              <Route path="/">
                <Switch>
                  <Route path="/" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Plan />
                    </Suspense>
                  </Route>
                  <Route path="/settings" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Settings />
                    </Suspense>
                  </Route>
                  <Route path="/dynamiccarbon" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <CarbonFootprint />
                    </Suspense>
                  </Route>
                  <Route path="/track/dynamiccarbon" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <CarbonFootprint />
                    </Suspense>
                  </Route>
                  <Route path="/plan" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <ActionPlan />
                    </Suspense>
                  </Route>
                  <Route path="/plan/:focusAreaId" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <FocusAreaPage />
                    </Suspense>
                  </Route>
                  <Route path="/intensities" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Inventory />
                    </Suspense>
                  </Route>
                  <Route path="/commitments" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Commitments />
                    </Suspense>
                  </Route>
                  <Route path="/reports">
                    <ReportsRouting />
                  </Route>
                  <Route path="/commitments/:commitmentId" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <CommitmentPage />
                    </Suspense>
                  </Route>
                  <Route path="/projects" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Do />
                    </Suspense>
                  </Route>
                  <Route path="/documents" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Documents />
                    </Suspense>
                  </Route>
                  <Route path="/surveys" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Surveys />
                    </Suspense>
                  </Route>
                  <Route path="/targets" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Forecasts />
                    </Suspense>
                  </Route>
                  <Route path="/facilities" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <MyFacilitiesPage />
                    </Suspense>
                  </Route>
                  <Route path="/activity" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <MyActivities/>
                    </Suspense>
                  </Route>
                  <Route path="/emissions" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <EmissionsTracking />
                    </Suspense>
                  </Route>
                  <Route path="/import/:stepId?/:ghgiCategory?/:view?" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <DataImport />
                    </Suspense>
                  </Route>
                  <Route path="/dashboard/:dashboardId" exact>
                    <Suspense fallback={<Loading delay={1000} />}>
                      <Track />
                    </Suspense>
                  </Route>
                </Switch>
                {SnackbarComponent}
                {viewingPlanTree &&
                  <ActionPlanTreeDialog dispatch={dispatch} planId={viewingPlanTree} />
                }
              </Route>
            </Switch>
        </ReactIconProvider>
      </UserProvider>
    </AlertProvider>
  );
}

export default App;
