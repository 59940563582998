import { Endpoints, GET, PATCH } from "../utils/API"
import { PartialActivity } from "../types/Activity"

export class ActivityActions {
  public static async searchLatestByAccount(accountNumber: string) {
    const { activities } = await GET({
      endpoint: Endpoints.auth.activities.search,
      queryParameters: {
        accountNumber,
      }
    })
    return activities
  }

  public static async updateActivity(update: PartialActivity, activityType: string) {
    console.log('Selected activity type: ', activityType)
    const response = await PATCH({
      endpoint: Endpoints.auth.activities.update,
      body: { ...update, activityType },
    })
    return response
  }
}