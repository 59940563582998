import { IconType } from 'react-icons'
import { RiArrowDownCircleLine, RiArrowUpCircleLine } from 'react-icons/ri'
import { BsLightningCharge, BsBuildings, BsTrash, BsDropletHalf } from 'react-icons/bs'
import { MdOutlineEnergySavingsLeaf } from 'react-icons/md'

export const DashboardCategoryIcons: Record<string, IconType> = {
  upstream: RiArrowUpCircleLine,
  downstream: RiArrowDownCircleLine,
  'your organization': BsBuildings,
  waste: BsTrash,
  water: BsDropletHalf,
  energy: MdOutlineEnergySavingsLeaf,
  default: BsLightningCharge,
}

export function getDashboardCategoryIcon(category: string) : IconType {
  return DashboardCategoryIcons[category] ?? DashboardCategoryIcons.default
}
