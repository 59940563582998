import SharedActions, { ActionType } from '../utils/ActionTypes';
import { authedFetch, Endpoints } from '../utils/API';
import FileData, { dbFileDataToLocalFileData } from '../types/FileData';

export default class DocumentsActions {

  /**
   * Function called to delete a document
   *
   * @param documentId: the document to be deleted
   */
  static deleteDocument(documentId: string): Promise<ActionType> {
    return authedFetch(Endpoints.auth.documents.delete, {
      method: 'DELETE',
      queryParameters: { documentId }
    })
      .then((res) => {
        return {
          type: SharedActions.documents.delete,
          payload: {
            documentId,
          }
        }
      });
  }

  /**
   * Function called to fetch all of a users documents
   */
  static fetchAllDocuments(organizationId: string, fileType: string): Promise<ActionType> {
    return authedFetch(Endpoints.auth.documents.fetchAll, {
      method: 'GET',
      queryParameters: { organizationId, fileType }
    })
      .then((res) => {

        const tableIds: string[] = [];
        const documents: {[key: string]: FileData} = {};
        for (let i = 0; i < res.documents.length; i++) {
          const document: FileData = dbFileDataToLocalFileData(res.documents[i]);
          tableIds.push(document.fileId);
          documents[document.fileId] = document;
        }


        return {
          type: SharedActions.documents.fetchAll,
          payload: {
            tableIds,
            documents,
          }
        }
      });
  }
}
