import {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import UploadFileList from '../../../components/UploadFileList'
import GHGIIcon from '../../../components/GHGIIcon'
import { useLocation } from 'react-router-dom'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'
import { GHGICategory } from '../../DataImport/useDataImport'
import { AiOutlineCheck, AiOutlineWarning } from 'react-icons/ai'
import { useAlertContext } from '../../../contexts/AlertContext'
import { FileItem, addFile, updateFileById } from '../../../utils/IndexDB'
import FileActions from '../../../actions/FileActions'
import { OrganizationType } from '../../../contexts/UserContext'
import moment from 'moment-timezone'
import WorkbookConfirmModal from '../../../components/WorkbookConfirmModal'
import { allowedFileTypes, maxFileSize } from '../../../utils/FileValidation'
import Select, { SelectOption } from '../../../components/Select'
import { ArcadiaCredential } from '~/types/Arcadia'
import { getArcadiaCredentialsByOrg } from '~/actions/ArcadiaActions'
import { ArcadiaInfoDisplay } from '~/views/Arcadia/InfoDisplay'
import { sortCredentials, sortCredentialsByDate } from '~/actions/ArcadiaActions'

interface Props {
  ghgiCategories: GHGICategory[],
  organization?: OrganizationType,
  selectedCredential: ArcadiaCredential | undefined,
  setSelectedCredential: (credential: ArcadiaCredential | undefined) => void,
}

const periodOptions: SelectOption[] = [
  { name: 'Month', id: 'month' },
  { name: 'Quarter', id: 'quarter' },
  { name: '6 Months', id: 'sixMonths' },
  { name: 'Year', id: 'year' },
]

const getPeriodDates = (id: string) => {
  let unixStart = moment().unix()
  let unixEnd = moment().unix()
  switch (id) {
    case 'month':
      unixStart = moment().startOf('month').unix()
      unixEnd = moment().endOf('month').unix()
      break
    case 'quarter':
      unixStart = moment().startOf('quarter').unix()
      unixEnd = moment().endOf('quarter').unix()
      break
    case 'sixMonths':
      unixStart = moment().startOf('quarter').subtract(3, 'months').unix()
      unixEnd = moment().endOf('quarter').unix()
      break
    case 'year':
      unixStart = moment().startOf('year').unix()
      unixEnd = moment().endOf('year').unix()
      break
  }

  return [unixStart, unixEnd]
}

const sortOptions = [
  { name: 'By Name', id: 'name' },
  { name: 'By Date', id: 'date' },
]

export default function Uploads({
  ghgiCategories,
  organization,
  selectedCredential,
  setSelectedCredential,
}: Props) {
  const ghgiCategory = useGhgiCategory()
  const [loadingFileInfo, setLoadingFileInfo] = useState(false)
  const [files, setFiles] = useState<FileItem[] | any[]>([])
  const [utilities, setUtilities] = useState<ArcadiaCredential[]>([])
  const [stagedFiles, setStagedFiles] = useState<FileItem[]>([])
  const [selectedFile, setSelectedFile] = useState<FileItem>()

  const [period, setPeriod] = useState<SelectOption>(periodOptions[0])
  const [sort, setSort] = useState<SelectOption>(sortOptions[0])
  const { showAlert } = useAlertContext()

  const { search } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(search) as any
    const credential = query.get('credential')
    console.log('credential: ', credential)
    if (credential && utilities?.length > 0) {
      const targetCredential = utilities.find((utility: ArcadiaCredential) => utility.entityId === credential)
      console.log('targetCredential: ', targetCredential)
      if (targetCredential) {
        setSelectedCredential(targetCredential)
      }
    }
  }, [search, utilities])

  useEffect(() => {
    getFilesInPeriod()
  }, [period, ghgiCategory, organization])

  useEffect(() => {
    if (organization && files?.length > 0) {
      manageFiles()
    }
  }, [organization, files])

  const fetchUtilities = useCallback(async () => {
    if (!organization?._id) return

    try {
      const uts = await getArcadiaCredentialsByOrg(organization._id)
      setUtilities(uts)
    } catch (error: any) {
      console.error(error)
    }
  }, [organization?._id])

  const getFilesInPeriod = async () => {
    try {
      setLoadingFileInfo(true)
      const [unixStart, unixEnd] = getPeriodDates(period.id)
      if (!organization?._id)
        return
      const { documents } = await FileActions.getFilesInPeriod(organization._id, ghgiCategory, unixStart, unixEnd)
      setFiles(documents)
      await fetchUtilities()
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        message: error.message,
        type: 'error',
        title: 'Error',
      })
    }
    finally {
      setLoadingFileInfo(false)
    }
  }

  const handleAddFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault()
      let errors = []
      let compliantFiles = []
      if (e.target.files && e.target.files.length > 20) {
        console.error('Too many files dropped!')
      }
      const stagedFiles = e.target.files
      if (!stagedFiles) return
      for (const fileItem of Array.from(stagedFiles)) {
        if (!allowedFileTypes.includes(fileItem.type)) {
          console.error('File type not allowed!', fileItem.type)
          errors.push(`${fileItem.name} doesn't have a valid file type`)
        }
        else if (fileItem.size > maxFileSize) {
          errors.push(`${fileItem.name} is above ${maxFileSize} bytes`)
        }
        else {
          compliantFiles.push(fileItem)
        }
      }
      const newDBFiles = await Promise.all(compliantFiles.map((fileItem: File) => addFile(fileItem, false, 'queued', ghgiCategory)))
      setStagedFiles(newDBFiles)
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem adding your files',
        message: error.message
      })
    }
  }

  const uploadFile = async (stagedUpload: FileItem) => {
    try {
      // const form
      if (!organization?._id) {
        throw Error('Organization not found')
      }


      const response = await FileActions.streamFileUpload(
        stagedUpload.file,
        stagedUpload.workbook ? "WORKBOOK" : "DOCUMENT",
        organization._id,
        'DOCUMENT',
        ghgiCategory,
        (streamEvent: any) => {
          const percentCompleted = Math.round(
            (streamEvent.loaded * 100) / streamEvent.total
          )
          // Find the right array element
          updateFileStatus(stagedUpload, percentCompleted, 'uploading')
          // Update status to uploading and progress to the percent completed

        }
      )
      await FileActions.updateFileStatus(response.s3Response.fileId, 'uploaded')
      await updateFileStatus(stagedUpload, 100, 'uploaded')
      if (stagedUpload.workbook) {
        await FileActions.validateWorkbook(response.s3Response.fileId, ghgiCategory)
      }
    }
    catch (error: any) {
      // Set the status to error for the target file
      showAlert({
        title: 'There was a problem uploading your files',
        message: error.message,
        variation: 'error'
      })
      throw new Error(error)
    }
  }

  const manageFiles = async () => {
    try {
      for (const file of files) {
        if (file.status === 'queued') {
          await uploadFile(file)
        }
      }
    }
    catch (error: any) {
      console.log('Error uploading files: ', error)
      showAlert({
        title: 'There was a problem uploading your files',
        message: error.message,
        variation: 'error'
      })

    }
  }

  const updateFileStatus = async (file: FileItem, progress: number, status: 'queued' | 'uploading' | 'uploaded') => {
    if (!file.id)
      return
    // Update the mongoDB record before updating indexDB and state
    // const response = await FileActions.updateFileStatus()
    const target = await updateFileById(file.id, {
      ...file,
      status,
      progress,
    })
    const newFiles = files.map((fileItem: FileItem) => {
      if (fileItem.id === file.id) {
        return target
      }
      return fileItem
    })
    setFiles(newFiles)
  }

  const onWorkbookStatusChange = async (file: FileItem, workbook: boolean) => {
    try {
      const newFile = { ...file, workbook }
      if (file.id) {
        const updatedFile = await updateFileById(file.id, newFile)
        const newStagedFiles = stagedFiles.map((fileItem: FileItem) => updatedFile && fileItem.id === updatedFile.id ? updatedFile : fileItem)
        setStagedFiles(newStagedFiles)
      }
      else {
        showAlert({
          title: 'File wasn\'t indexed',
          message: 'Please upload file again',
          variation: 'error',
        })
      }
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        title: 'There was a problem',
        message: error?.message || 'Please try again or contact support',
        variation: 'error',
      })
    }
  }

  const handleSelectFile = (file: FileItem | undefined) => {
    setSelectedFile(file)
    setSelectedCredential(undefined)
  }

  const handleSelectUtility = (utility: ArcadiaCredential | undefined) => {
    setSelectedFile(undefined)
    setSelectedCredential(utility)
  }

  // If a cred is deleted or modified, set selectedCredential to reflect it.
  useEffect(() => {
    if (selectedCredential) {
      const current = utilities.find(item => item.entityId === selectedCredential.entityId)
      if (current) {
        if (current !== selectedCredential) {
          setSelectedCredential(current)
        }
      } else {
        setSelectedCredential(undefined)
      }
    }
  }, [utilities])

  const sortedUtilities: ArcadiaCredential[] = useMemo(() => {
    const sorted = [...utilities]
    if (sort.id === 'date') {
      sorted.sort(sortCredentialsByDate)
    } else {
      sorted.sort(sortCredentials)
    }
    return sorted
  }, [utilities, sort])

  return (
    <div className="w-full sm:px-6 lg:px-4">
      <div className="flex flex-col w-full pb-8">
        <div className="flex items-center gap-4 mt-4">
          {/* <h3 className="font-bold">Time Range</h3> */}
          <Select
            options={periodOptions}
            onChange={setPeriod}
            selected={period.id}
            containerStyle='w-36'
          />
          <Select
            options={sortOptions}
            onChange={setSort}
            selected={sort.id}
            containerStyle='w-36'
          />
        </div>
        <div className="bg-white rounded-lg shadow-md grid md:grid-cols-[400px_1fr] sm:grid-cols-1 gap-6 p-6 border border-slate-200 mt-4">
          <WorkbookConfirmModal
            open={stagedFiles?.length > 0}
            files={stagedFiles}
            onClose={() => setStagedFiles([])}
            onWorkbookStatusChange={onWorkbookStatusChange}
            onConfirm={() => {
              setFiles([...files, ...stagedFiles])
              setStagedFiles([])
            }}
          />
          <UploadFileList
            files={files}
            utilities={sortedUtilities}
            handleAddFiles={handleAddFiles}
            handleSelectFile={handleSelectFile}
            handleSelectUtility={handleSelectUtility}
            selectedFile={selectedFile}
            selectedUtility={selectedCredential}
          />
          <div className="rounded-lg border border-slate-200 flex flex-col items-center justify-center p-6">
            <GHGIIcon
              categoryId={ghgiCategory}
              iconClass="text-accent text-4xl"
            />
            <h4 className="font-bold text-lg mt-3">{`${ghgiCategories.find((category: GHGICategory) => category.id === ghgiCategory)?.name}`}</h4>
            <div className="w-full min-w-48 bg-slate-200 h-[1px] min-h-[1px] my-6" />
            <div className="flex items-start justify-around w-full">
              {
                loadingFileInfo && (
                  <>
                    <div className="h-24 w-24 bg-gray-100 rounded-xl animate-pulse" />
                    <div className="h-24 w-24 bg-gray-100 rounded-xl animate-pulse" />
                    <div className="h-24 w-24 bg-gray-100 rounded-xl animate-pulse" />
                  </>
                )
              }
              {
                !loadingFileInfo && selectedFile && (
                  <>
                    <div className="flex flex-col items-center">
                      <p className="text-xs text-slate-500">Problems</p>
                      <span className="w-12 h-12 flex items-center justify-center">
                        {
                          selectedFile?.issues && selectedFile?.issues && selectedFile.issues.length > 0 ? (
                            <AiOutlineWarning className="text-warning text-3xl" />
                          )
                            : (
                              <AiOutlineCheck className="text-accent text-3xl" />
                            )
                        }
                      </span>
                      <h5>Verified</h5>
                      <p className="text-slate-400">{selectedFile?.issues?.length || '0'} warnings</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-xs text-slate-500">Data Owner</p>
                      <span className="w-12 h-12 flex items-center justify-center">
                        <span className="h-10 w-10 bg-gray-300 rounded-full flex items-center justify-center">
                          <span className="text-white font-bold">{selectedFile?.owner && selectedFile.owner?.firstName[0]}</span>
                        </span>
                      </span>
                      <h5>{selectedFile?.owner && `${selectedFile?.owner?.firstName} ${selectedFile?.owner?.lastName}`}</h5>
                      <p className="text-slate-400">{selectedFile?.owner && selectedFile.owner?.email}</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p className="text-xs text-slate-500">Last Update</p>
                      <span className="w-12 h-12 flex items-center justify-center">
                        <h4 className="text-2xl text-slate-400 font-bold">{selectedFile?.updated ? moment().diff(selectedFile.updated, 'days') : '--'}</h4>
                      </span>
                      <h5>Days ago</h5>
                      {
                        selectedFile?.updated && moment().diff(selectedFile.updated, 'days') > 30
                          ? <p className="text-red-400">Update needed</p>
                          : <p className="text-slate-400">Up to Date</p>
                      }
                    </div>
                  </>
                )
              }
              {
                !loadingFileInfo && selectedCredential && <ArcadiaInfoDisplay info={selectedCredential} setUtilities={setUtilities} />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
