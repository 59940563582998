import { useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { useForm } from 'react-hook-form'
import Input from '~/components/Input'
import Modal from '~/components/Modal'
import { ArcadiaCredential } from '~/types/Arcadia'
import { updateArcadiaCredential } from '~/actions/ArcadiaActions'
import { useAlertContext } from '~/contexts/AlertContext'
import { findAndUpdateOne } from '~/utils/collection'

interface IProps {
  credential: ArcadiaCredential
  open: boolean
  onClose: () => void
  /** Optional reference for delete/update functionality */
  setUtilities?: React.Dispatch<React.SetStateAction<ArcadiaCredential[]>>
}

export function UpdateModal({
  credential,
  open,
  onClose,
  setUtilities,
}: IProps) {
  const form = useForm({
    defaultValues: {
      username: credential.username,
      password: '',
    },
  })
  const { showAlert } = useAlertContext()

  useEffect(() => {
    form.reset({ username: credential.username, password: '' })
  }, [credential.username, credential.entityId])

  const [{ loading }, updateCredential] = useAsyncFn(async (userName, password) => {
    try {
      const result = await updateArcadiaCredential(credential.entityId, userName, password)

      showAlert({
        title: 'Success',
        message: 'Credential details changed.',
        type: 'success',
      })

      setUtilities?.(utilities => findAndUpdateOne(utilities, item => item.entityId === credential.entityId, result.credential))

      onClose()

      return result
    } catch (e: any) {
      showAlert({
        title: 'Error',
        message: 'Could not update credential.',
        type: 'error',
      })

      throw e
    }
  }, [credential.entityId])

  const handleSubmit = form.handleSubmit(async (data) => {
    console.log('Form Submitted', data)
    updateCredential(data.username, data.password)
  })

  return <Modal
    open={open}
    onClose={onClose}
    title="Update credential"
    description="Enter new credential information:"
    actionButtons={[
      { label: 'Cancel', onClick: onClose, variant: 'ghost' },
      { label: 'OK', onClick: handleSubmit, variant: 'primary', loading, type: 'submit' },
    ]}
  >
    <form
      id="update-credential-form"
      className="w-full px-4 pb-8 pt-2 flex items-start flex-col space-y-4"
      onSubmit={handleSubmit}
    >
      <Input
        label="Username"
        name="username"
        value={form.watch('username')}
        options={{ required: true }}
        register={form.register}
      />
      <span className="w-10" />
      <Input
        label="Password"
        name="password"
        type="password"
        value={form.watch('password')}
        options={{ required: true }}
        register={form.register}
      />
    </form>
  </Modal>
}
