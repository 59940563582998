import {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TreeView from '@material-ui/lab/TreeView'

import { ApplicationState } from '../../../reducers/RootReducer'
import FocusAreaBranches from './FocusAreaBranches'
import StyledTreeItem, { MinusSquare, PlusSquare } from './StyledTreeItem'
import useAsyncCall from '../../../hooks/UseAsyncCall'
import PlanActions from '../../../actions/PlanActions'
import LoadingButton from '../../generics/LoadingButton'
import { UserContext, UserContextType } from '../../../contexts/UserContext'

const ActionPlanTree = () => {
  const { organization } = useContext(UserContext) as UserContextType;
  const dispatch = useDispatch()
  const planTreeLoadTime = useSelector((store: ApplicationState) => store.plan.planTreeLoadTime)
  const plan = useSelector((store: ApplicationState) => store.plan.plan)
  const expandedTreeView = useSelector((store: ApplicationState) => getExpandedTreeView(store))

  const [expanded, setExpanded] = useState<string[]>([])
  const [expandedSet, setExpandedSet] = useState<Set<string>>(new Set())
  const [selected, setSelected] = useState<string[]>([])
  const [shouldExpandFullTree, setShouldExpandFullTree] = useState(false)

  const fetchPlanCall = useAsyncCall(true)
  const fetchFullTreeCall = useAsyncCall(true)
  useEffect(() => {
    if (!plan) {
      fetchPlanCall
        .makeAsyncCall(PlanActions.fetchPlan(organization?._id))
        .then((res) => {
          dispatch(res)
        })
        .catch((e) => {
          console.error(e)
        });
    };
  }, [organization])

  useEffect(() => {
    if (planTreeLoadTime != null && shouldExpandFullTree) {
      setExpanded((oldExpanded) =>
        oldExpanded.length === 0 ? expandedTreeView : []
      );
      setShouldExpandFullTree(false)
    };
  }, [planTreeLoadTime, shouldExpandFullTree])

  /**
   * Function called when a tree branch is toggled
   *
   * @param event
   * @param nodeIds
   */
  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
    setExpandedSet(new Set(nodeIds))
  }

  /**
   * Function called when a tree branch/leaf is selected
   *
   * @param event
   * @param nodeIds
   */

  const handleSelect = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setSelected(nodeIds)
  }

  const handleExpandClick = () => {
    if (planTreeLoadTime == null) {
      fetchFullTreeCall
        .makeAsyncCall(PlanActions.fetchFullPlanTree(organization?._id))
        .then((res) => {
          dispatch(res)
          setShouldExpandFullTree(true)
        })
        .catch((e) => {
          console.error(e)
        });
    }else{
      setExpanded((oldExpanded) =>
        oldExpanded.length === 0 ? expandedTreeView : []
      )
    };
    console.log(expandedSet)
  }

  return (
    <>
      <LoadingButton
        isLoaderShowing={fetchFullTreeCall.isCalling}
        variant="outlined"
        onClick={handleExpandClick}
      >
        {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
      </LoadingButton>
      <TreeView
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {plan ? (
          <StyledTreeItem key={plan.id} nodeId={plan.id} label={plan.title}>
            {plan.focusAreaIds.length > 0 ? (
              <FocusAreaBranches
                dispatch={dispatch}
                expandedItems={expandedSet}
                focusAreaIds={plan.focusAreaIds}
                organizationId={organization?._id}
                planId={plan.id}
              />
            ) : null}
          </StyledTreeItem>
        ) : (
          <StyledTreeItem key={'-'} nodeId={'-'} label={'-'} />
        )}
      </TreeView>
      {fetchFullTreeCall.SnackbarComponent}
    </>
  )
}

const getExpandedTreeView = (store: ApplicationState) => {
  if (store.plan.plan != null) {
    let arr = [store.plan.plan?.id];

    //   // arr.push(...Object.keys(store.focusArea.focusAreas))
    //   // arr.push(...Object.keys(store.goal.goals))
    //   // arr.push(...Object.keys(store.strategy.strategies))
    //   // arr.push(...Object.keys(store.initiative.initiatives))
    //   // arr.push(...Object.keys(store.project.projects))

    store.plan.plan.focusAreaIds.forEach((focusAreaId) => {
      const focusArea = store.focusArea.focusAreas[focusAreaId];

      if (focusArea.goalIds.length > 0) {
        arr.push(focusAreaId)
      };

      focusArea.goalIds.forEach((goalId) => {
        const goal = store.goal.goals[goalId];

        if (goal.strategyIds.length > 0) {
          arr.push(goalId)
        };

        goal.strategyIds.forEach((strategyId) => {
          const strategy = store.strategy.strategies[strategyId];

          if (strategy.initiativeIds.length > 0) {
            arr.push(strategyId)
          };

          strategy.initiativeIds.forEach((initiativeId) => {
            const initiative = store.initiative.initiatives[initiativeId];

            if (initiative.projectIds.length > 0) {
              arr.push(initiativeId)
            };
          });
        });
      });
    });

    return arr
  };
  return []
}

export default ActionPlanTree
