import { MdOutlineFileDownload } from 'react-icons/md'
import { BsChevronCompactRight, BsChevronCompactLeft } from 'react-icons/bs'
import Layout from '~/components/Layout'
import GHGIIcon from '~/components/GHGIIcon'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'
import { GHGICategory } from '~/views/DataImport/useDataImport'
import ButtonTabs from '~/components/ButtonTabs'
import { useAddInformation } from './useAddInformation'
import Modal from '~/components/Modal'
import Textarea from '~/components/Textarea'
import Input from '~/components/Input'
import Button from '~/components/Button'
import { FileItem, deleteFileById } from '~/utils/IndexDB'
import FileDragAndDrop from '~/components/FileDragAndDrop'
import FormCombobox from '~/components/FormCombobox'
import fplLogo from '~assets/fpl_icon.png'
import dukeEnergyLogo from '~assets/duke_energy_logo.png'
import oucLogo from '~assets/ouc_logo.png'

const downloadableWorkbooks = new Set<string>([
  'business_travel',
  'fuel_and_energy_related_activities',
  'transportation_and_distribution_upstream',
  'transportation_and_distribution_downstream',
  'waste_generated_in_operations',
  'capital_goods',
  'purchased_goods_and_services',
  'leased_assets_upstream',
  'leased_assets_downstream',
  'company_facilities',
  'company_vehicles',
])

interface Props {
  goBack: () => void
  loading: boolean
  ghgiCategories: GHGICategory[]
  nextStep: (files: FileItem[]) => void,
}

export default function AddInformation({ goBack, loading, ghgiCategories, nextStep }: Props) {
  const ghgiCategory = useGhgiCategory()
  const {
    currentTab,
    onTabSelected,
    tabs,
    validateFiles,
    addNewStagedFiles,
    stagedFiles,
    removeFile,
    onWorkbookStatusChange,
    apis,
    handleFormSubmit,
    addConnectionForm,
    submittingRequest,
    utilityForm,
    handleUtilityFormSubmit,
    queueFiles,
    // handleManualEntry,
    downloadWorkbook,
    downloadingWorkbook,
    loadingProviders,
    utilityProviders,
    getUtilityProviders,
    loadingCredentials,
    stagedFilesModalOpen,
    setStagedFilesModalOpen,
  } = useAddInformation()

  return (
    <Layout>
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex flex-col items-center mt-8">
          <GHGIIcon
            categoryId={ghgiCategory}
            iconClass="text-4xl text-accent"
          />
          <h2 className="text-xl font-bold text-slate-900 mt-4">
            {ghgiCategories?.length > 0 && (ghgiCategories.find((category: GHGICategory) => category?.id === ghgiCategory)?.name || 'Unknown Category')}
          </h2>
          <p className="w-[400px] md:w-[500px] text-center mt-2 text-slate-700">
            All data uploaded will be added to this category. Ready to upload data to a different GHGI category?
          </p>
          <p
            onClick={goBack}
            className="text-accent text-md mt-2 underline underline-offset-2 font-semibold cursor-pointer"
          >
            Change Category
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <div
            className="cursor-pointer mt-36"
            onClick={goBack}
          >
            <BsChevronCompactLeft className="text-gray-300 hover:text-accent text-8xl" />
          </div>
          <div className="flex flex-col mb-24">
            <div className="mt-10 bg-white rounded-md drop-shadow-lg border border-slate-200 py-4 flex flex-col items-center w-[700px]">
              <h3 className="text-lg font-bold text-slate-900 mb-4">Add Your Information</h3>
              <ButtonTabs
                tabs={tabs}
                currentTab={currentTab}
                onTabSelected={onTabSelected}
                containerClass={"border border-slate-200 rounded-xl mx-4"}
              />
              {
                currentTab === 'Upload file' && (
                  <>
                    <p className="text-slate-700 text-center w-[400px] mt-4">Drop your files or click into the container to select manually. Mark the files that are workbooks and click Upload.</p>
                    <FileDragAndDrop
                      validateFiles={validateFiles}
                      processFiles={addNewStagedFiles}
                      onFileRemove={removeFile}
                      stagedFiles={stagedFiles}
                      onWorkbookStatusChange={onWorkbookStatusChange}
                    />
                    <Button
                      type="button"
                      variant="primary"
                      label="Upload"
                      className="bg-accent-secondary mt-3"
                      loading={loadingCredentials}
                      disabled={stagedFiles?.length === 0}
                      onClick={() => {
                        nextStep(stagedFiles)}
                      }
                    />
                  </>
                )
              }
              {
                currentTab === 'Connect API' && (
                  <>
                    <p className="text-slate-700 text-center w-[400px] mt-4">
                      Whether to an internal ERP system or a third-party service, please provide a description of the APIs you need below and click “Request Connection.” A team member will reach out with next steps.
                      <br />
                      <br />
                      The icons below are just a sample of numerous API connections available.
                    </p>
                    <div className="px-6 w-full flex flex-col items-center">
                      <div className="w-full grid grid-cols-3 px-2 mt-4 gap-4">
                        {
                          apis.map((api) => (
                            <div
                              key={api.id}
                              className="flex flex-col items-center justify-center rounded-lg border-slate-200 py-1 px-2"
                            >
                              <span className="h-8 w-28 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${api.imageSrc})` }} />
                            </div>
                          ))
                        }
                      </div>
                      <form
                        id="add-connection-form"
                        className="w-full px-4 pb-8 flex items-start flex-col  mt-4"
                        onSubmit={handleFormSubmit}
                      >
                        <Textarea
                          label="Tell us here which API connections you need."
                          name="description"
                          register={addConnectionForm.register}
                          value={addConnectionForm.watch('description')}
                        />
                      </form>
                      <Button
                        type="submit"
                        variant="primary"
                        form="add-connection-form"
                        label="Request Connection"
                        className="bg-accent-secondary mb-4"
                      />
                    </div>
                  </>
                )
              }
              {
                currentTab === 'Add Utility' && (
                  <>
                    <p className="text-slate-700 text-center w-[400px] mt-4">
                      Save time and prevent data gaps by linking directly to your utility providers. Follow the steps below to connect with a supported provider.
                      <br />
                      <br />
                      By providing your user name and password, you authorize SustainaBase to access your account and pull activity data as described in our Privacy Policy.
                    </p>
                    <div className="px-6 w-full mt-2 flex flex-col items-center">
                      <div className="w-[450px] flex items-center justify-between px-2 my-4">
                        <div
                          className="flex flex-col items-center justify-center rounded-lg border-slate-200 py-1 px-2"
                        >
                          <span className="h-8 w-28 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${fplLogo})` }} />
                        </div>
                        <div
                          className="flex flex-col items-center justify-center rounded-lg border-slate-200 py-1 px-2"
                        >
                          <span className="h-8 w-28 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${oucLogo})` }} />
                        </div>
                        <div
                          className="flex flex-col items-center justify-center rounded-lg border-slate-200 py-1 px-2"
                        >
                          <span className="h-8 w-28 bg-contain bg-no-repeat bg-center" style={{ backgroundImage: `url(${dukeEnergyLogo})` }} />
                        </div>
                      </div>
                      <form className="mx-20 flex flex-col mt-2" id="utility-form" onSubmit={handleUtilityFormSubmit}>
                        <FormCombobox
                          label="Select Provider"
                          name="provider"
                          register={utilityForm.register}
                          options={{ required: true }}
                          defaultValue={utilityForm.watch('provider')}
                          autocompleteOptions={utilityProviders}
                          control={utilityForm.control}
                          loading={loadingProviders}
                          onQueryChange={getUtilityProviders}
                          mode="fetch"
                        />
                        <div className="w-full mt-4 flex items-center justify-between">
                          <Input
                            label="Username"
                            name="username"
                            register={utilityForm.register}
                            options={{ required: true }}
                            value={utilityForm.watch('username')}
                          />
                          <span className="w-10" />
                          <Input
                            label="Password"
                            name="password"
                            type="password"
                            value={utilityForm.watch('password')}
                            register={utilityForm.register}
                            options={{ required: true }}
                          />
                        </div>
                        <div className="w-full my-8 flex items-center justify-center">
                          <Button
                            type="submit"
                            form="utility-form"
                            variant="primary"
                            label="Verify Login"
                            className="bg-accent-secondary"
                            loading={loadingCredentials}
                          />
                        </div>
                      </form>
                    </div>
                  </>
                )
              }
              {/* {
                currentTab === 'Manual Entry' && (
                  <>
                    <p className="text-slate-700 text-center w-[400px] mt-4">Do you prefer to simply key in data yourself? Select "Continue" to manually enter data.</p>
                    <div className="w-full flex items-center justify-center my-8">
                      <Button
                        label="Continue"
                        className="bg-accent-secondary px-12"
                        onClick={handleManualEntry}
                      />
                    </div>
                  </>
                )
              } */}
            </div>
            {
              currentTab === 'Upload file' && downloadableWorkbooks.has(ghgiCategory) && (
                <div className="flex flex-col items-center mt-8">
                  <p className="">Looking for a workbook template?</p>
                  <span className="mt-2">
                    <Button
                      label="Download workbook"
                      iconLeft={<MdOutlineFileDownload className="text-lg" />}
                      onClick={downloadWorkbook}
                      loading={downloadingWorkbook}
                    />
                  </span>
                </div>
              )
            }
          </div>
          <div className="cursor-pointer mt-36">
            <BsChevronCompactRight
              className="text-gray-300 hover:text-accent text-8xl"
              onClick={() => {
                if (stagedFiles.filter((file) => file.status === 'staged').length > 0) {
                  setStagedFilesModalOpen(true)
                  return
                }
                nextStep(stagedFiles)
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        open={stagedFilesModalOpen}
        onClose={() => setStagedFilesModalOpen(false)}
        title="Check your uploads before continuing"
        description='There are some files that have not been queued for upload. Click "Upload" to queue them for upload or "Continue" to proceed.'
        actionButtons={[
          {
            label: 'Continue',
            onClick: async () => {
              const staged = stagedFiles.filter((fileItem: FileItem) => fileItem.status === 'staged')
              const promises = staged.map(async (fileItem: FileItem) => {
                if (fileItem.id)
                  await deleteFileById(fileItem.id)
              })
              await Promise.all(promises)
              const queuedFiles = stagedFiles.filter((fileItem: FileItem) => fileItem.status === 'queued')
              nextStep(queuedFiles)
            },
            variant: 'ghost',
          },
          {
            label: 'Review Files',
            onClick: () => setStagedFilesModalOpen(false),
            variant: 'primary',
            type: 'submit',
            loading: submittingRequest,
          }
        ]}
      />
    </Layout>
  )
}
