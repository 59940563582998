import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../../reducers/RootReducer';
import Initiative from '../../../types/Initiative';
import ProjectBranches from './ProjectBranches';
import useAsyncCall from '../../../hooks/UseAsyncCall';
import StrategyActions from '../../../actions/StrategyActions';
import StyledTreeItem from './StyledTreeItem';

const InitiativeBranches = (props: {
  dispatch: Dispatch<any>
  expandedItems: Set<string>
  focusAreaId: string
  goalId: string
  initiativeIds: string[]
  planId: string
  strategyId: string
}) => {

  const { dispatch, expandedItems, focusAreaId, goalId, initiativeIds, planId, strategyId } = props;
  const [ previousIsExpanded, setPreviousIsExpanded ] = useState(false);

  const initiatives = useSelector((store: ApplicationState) => {

    let initiatives: {[key: string]: Initiative} = {};
    initiativeIds.forEach((initiativeIds) => {
      const initiative = store.initiative.initiatives[initiativeIds];
      if (initiative != null) {
        initiatives[initiative.id] = initiative;
      }
    });

    return initiatives;
  });

  const fetchParentCall = useAsyncCall(true);
  useEffect(() => {
    if (expandedItems.has(planId) && !previousIsExpanded) {
      fetchParentCall.makeAsyncCall(StrategyActions.fetchStrategy({planId, focusAreaId, goalId, strategyId}))
        .then((res) => {
          dispatch(res);
        })
        .catch(e => {
          console.error(e);
        });
    }
    setPreviousIsExpanded(expandedItems.has(planId));
  }, [expandedItems, planId, focusAreaId, goalId, strategyId]);

  return (
    <>
      {initiativeIds.map((initiativeId) => {
        const initiative = initiatives[initiativeId];
        if (initiative) {
          return (
            <StyledTreeItem key={initiativeId} nodeId={initiativeId} label={initiative.title}>
              {initiative.projectIds.length > 0 ?
                <ProjectBranches
                  dispatch={dispatch}
                  expandedItems={expandedItems}
                  focusAreaId={focusAreaId}
                  goalId={goalId}
                  initiativeId={initiativeId}
                  planId={planId}
                  projectIds={initiative.projectIds}
                  strategyId={strategyId}
                />
                :
                null
              }
            </StyledTreeItem>
          );
        } else {
          return <StyledTreeItem key={initiativeId} nodeId={initiativeId} label={'-'} />;
        }
      })}
    </>
  );
};

export default InitiativeBranches;