import { memo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  name: string
  href: string
  icon?: any
}

export const EmissionsHeaderCard = memo(({
  name,
  icon: Icon,
  href,
}: Props) => (
  <Link to={href} className="hover:no-underline">
    <div className="flex flex-col items-center justify-center text-center
                    w-[25rem] h-[10rem] p-8 rounded-xl
                    bg-gradient-to-r from-accent-dark via-accent to-accent-dark">
      <div><Icon className="text-4xl text-white drop-shadow-md" /></div>
      <h3 className="text-2xl py-2 text-white font-bold drop-shadow-md">{name}</h3>
    </div>
  </Link>
))
