import { UserContext, UserContextType } from '../../contexts/UserContext';
import {
    useState,
    useEffect,
    useContext,
} from 'react'
import UserManagement from './UserManagement';
import UserImpersonation from './UserImpersonation';
import { PageLayout } from '../PageLayout';
import Tabs, { TabItem } from '../Tabs'

const Settings = () => {
    const [selectedTab, setSelectedTab] = useState<TabItem>()

    const { organization, userInfo } = useContext(UserContext) as UserContextType;
    const isImpersonating = sessionStorage.getItem('impersonate-uid')

    const settingTabs: TabItem[] = [
        { id: 'management', name: 'User Management', disabled: !organization?.userType },
        { id: 'impersonation', name: 'Impersonate Users', disabled: !(userInfo?.flags?.canImpersonate || isImpersonating) },
    ]

    useEffect(() => {
        console.log('Selected tab changed: ', selectedTab)
    }, [selectedTab])

    return (
        <PageLayout>
            <div className=" w-[calc(100vw-400px)] flex flex-col h-full relative py-10">
                <Tabs
                    tabs={settingTabs}
                    onChange={setSelectedTab}
                />
                {
                    selectedTab?.id === 'management' && organization && (
                        <UserManagement organization={organization} />
                    )
                }
                {
                    selectedTab?.id === 'impersonation' && (userInfo?.flags?.canImpersonate || isImpersonating) && (
                        <UserImpersonation />
                    )
                }
            </div>
        </PageLayout>
    )
}

export default Settings