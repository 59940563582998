import { BsChevronRight } from 'react-icons/bs'
import GHGIActivityIcon from '~/components/GHGIActivityIcon'
import classNames from '~/utils/classNames'
import { GHGIActivityData } from '~/views/DataImport/useDataImport'

interface Props {
  selected?: GHGIActivityData,
  activityTypes: GHGIActivityData[],
  onSelect: (activity: any) => void,
  loadingCategories: boolean,
  editing: boolean,
}

export function ActivityTypeSelection({
  selected,
  activityTypes,
  onSelect,
  loadingCategories,
  editing,
}: Props) {
  return (
    <div className="flex flex-col">
      <h5 className="text-gray-400 uppercase font-semibold text-xs tracking-widest mb-3">Activity Type</h5>

      {loadingCategories ? <>
        <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
        <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
        <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
      </> : activityTypes.map(activity => (
        <button
          className={classNames(
            "py-2.5 px-3 flex items-center justify-between w-full rounded-lg hover:bg-gray-100 mb-2 outline-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
            activity.id === selected?.id ? "bg-gray-200" : ""
          )}
          onClick={() => onSelect(selected?.id === activity.id ? undefined : activity)}
          key={activity.id}
          disabled={editing}
        >
          <div className="flex items-center">
            <GHGIActivityIcon
              activityId={activity.id}
              iconClass="text-xl mr-2 fill-gray-400"
            />
            <h5 className="text-grey-600 font-bold text-left">{activity.label}</h5>
          </div>
          <BsChevronRight className="text-grey-300 ml-3" />
        </button>
      ))}
    </div>
  )
}
