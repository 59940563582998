import type {
  CustomerType,
  CustomerAssetType,
  CustomerLocationAccountType,
} from '~/types/entities'
import {
  Endpoints,
  GET,
  PATCH,
  POST,
} from '~/utils/API'

export async function getCustomer(customerId: string): Promise<CustomerType> {
  const result = await GET({
    endpoint: Endpoints.customer.get,
    queryParameters: {
      customerId,
    }
  })
  return result.customer as CustomerType
}

export async function getCustomers(mongoId: string): Promise<CustomerType[]> {
  const result = await GET({
    endpoint: Endpoints.customers.get,
    queryParameters: {
      mongoId,
    }
  })
  return result.customers as CustomerType[]
}

export async function getCustomerAsset(assetId: number): Promise<CustomerAssetType> {
  const result = await GET({
    endpoint: Endpoints.customerasset.get,
    queryParameters: {
      assetId: assetId.toString(),
    }
  })
  return result.asset as CustomerAssetType
}

interface getCustomerAssetsOptions {
  organizationId: string
  ghgiCategory?: string
  activityType?: string
  assetType?: string
  name?: string
  limit?: number
  skip?: number
}

export async function getCustomerAssets(options: getCustomerAssetsOptions): Promise<CustomerAssetType[]> {
  const queryParameters: any = {
    ...options,
    customerMongoId: options.organizationId,
  }

  const result = await GET({
    endpoint: Endpoints.customerassets.get,
    queryParameters,
  })
  return result.assets as CustomerAssetType[]
}

export async function updateCustomerAsset(organizationId: string, ghgiCategory: string, assetId: string, updates: Partial<CustomerAssetType>): Promise<CustomerAssetType> {
  const queryParameters: any = {
    customerMongoId: organizationId,
    ghgiCategory,
  }

  const result = await PATCH({
    endpoint: Endpoints.customerasset.patch,
    body: { assetId: Number(assetId), data: updates },
    queryParameters,
  })
  return result.asset as CustomerAssetType
}

export async function createCustomerAsset(organizationId: string, ghgiCategory: string, asset: Partial<CustomerAssetType>): Promise<CustomerAssetType> {
  const queryParameters: any = {
    customerMongoId: organizationId,
    ghgiCategory,
  }

  const result = await POST({
    endpoint: Endpoints.customerasset.post,
    body: { data: asset, customerMongoId: organizationId },
    queryParameters,
  })
  return result.asset as CustomerAssetType
}

export async function getCustomerLocationAccount(customerLocationAccountId: string): Promise<CustomerLocationAccountType> {
  const result = await GET({
    endpoint: Endpoints.customerlocationaccount.get,
    queryParameters: {
      customerLocationAccountId,
    }
  })
  return result.locationAccount as CustomerLocationAccountType
}

export async function getCustomerLocationAccounts(ghgiCategory: string, customerMongoId: string, customerAssetId?: number): Promise<CustomerLocationAccountType[]> {
  const queryParameters: Record<string, string> = {
    ghgiCategory,
    customerMongoId,
  }

  if (customerAssetId) queryParameters.customerAssetId = String(customerAssetId)

  const result = await GET({
    endpoint: Endpoints.customerlocationaccounts.get,
    queryParameters,
  })
  return result.customerLocationAccounts as CustomerLocationAccountType[]
}

/*export async function patchCustomerLocation(assetId: string, data: CustomerAssetType) : Promise<CustomerAssetType> {
  const result = await PATCH({
    endpoint: Endpoints.customerasset.patch,
    body: {
      assetId,
      data,
    }
  })
  return result as CustomerAssetType
}*/
