import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../../reducers/RootReducer';
import Strategy from '../../../types/Strategy';
import InitiativeBranches from './InitiativeBranches';
import useAsyncCall from '../../../hooks/UseAsyncCall';
import GoalActions from '../../../actions/GoalActions';
import StyledTreeItem from './StyledTreeItem';

const StrategyBranches = (props: {
  dispatch: Dispatch<any>
  expandedItems: Set<string>
  focusAreaId: string
  goalId: string
  planId: string
  strategyIds: string[]
}) => {

  const { dispatch, expandedItems, focusAreaId, goalId, planId, strategyIds } = props;
  const [ previousIsExpanded, setPreviousIsExpanded ] = useState(false);

  const strategies = useSelector((store: ApplicationState) => {

    let strategies: {[key: string]: Strategy} = {};
    strategyIds.forEach((strategyId) => {
      const strategy = store.strategy.strategies[strategyId];
      if (strategy != null) {
        strategies[strategy.id] = strategy;
      }
    });

    return strategies;
  });

  const fetchParentCall = useAsyncCall(true);
  useEffect(() => {
    if (expandedItems.has(planId) && !previousIsExpanded) {
      fetchParentCall.makeAsyncCall(GoalActions.fetchGoal({planId, focusAreaId, goalId}))
        .then((res) => {
          dispatch(res);
        })
        .catch(e => {
          console.error(e);
        });
    }
    setPreviousIsExpanded(expandedItems.has(planId));
  }, [expandedItems, planId, focusAreaId, goalId]);

  return (
    <>
      {strategyIds.map((strategyId) => {
        const strategy = strategies[strategyId];
        if (strategy) {
          return (
            <StyledTreeItem key={strategyId} nodeId={strategyId} label={strategy.title}>
              {strategy.initiativeIds.length > 0 ?
                <InitiativeBranches
                  dispatch={dispatch}
                  expandedItems={expandedItems}
                  focusAreaId={focusAreaId}
                  goalId={goalId}
                  initiativeIds={strategy.initiativeIds}
                  planId={planId}
                  strategyId={strategyId}
                />
                :
                null
              }
            </StyledTreeItem>
          );
        } else {
          return <StyledTreeItem key={strategyId} nodeId={strategyId} label={'-'} />;
        }
      })}
    </>
  );
};

export default StrategyBranches;