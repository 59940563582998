import {
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react'
import enphaseLogo from '../../../assets/enphase.png'
import oracleLogo from '../../../assets/oracle.svg'
import shipstationLogo from '../../../assets/shipstation.svg'
import samsaraLogo from '../../../assets/samsara.svg'
import sageLogo from '../../../assets/sage.svg'
import fuelmasterLogo from '../../../assets/fuelmaster.png'
import { useForm } from 'react-hook-form'
import { POST, Endpoints } from '../../utils/API'
import { UserContext, UserContextType } from '../../contexts/UserContext'
import { useAlertContext } from '../../contexts/AlertContext'
import { useHistory } from 'react-router-dom'
import { getFilesFromFilter, addFile, FileItem, deleteFileById, updateFileById } from '../../utils/IndexDB'
import { allowedFileTypes, maxFileSize } from '../../utils/FileValidation'
import { WorkbookActions } from '../../actions/WorkbookActions'
import { UtilityActions } from '../../actions/UtilityActions'
import { ArcadiaCredential } from '~/types/Arcadia'
import { addCredential } from '~/utils/CredentialsIndexDB'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'

const apis: Array<{ id: ConnectedAPIIds, imageSrc: any }> = [
  {
    id: 'enphase',
    imageSrc: enphaseLogo,
  },
  {
    id: 'oracle',
    imageSrc: oracleLogo,
  },
  {
    id: 'shipstation',
    imageSrc: shipstationLogo,
  },
  {
    id: 'samsara',
    imageSrc: samsaraLogo,
  },
  {
    id: 'sage',
    imageSrc: sageLogo,
  },
  {
    id: 'fuelmaster',
    imageSrc: fuelmasterLogo,
  },
]

export type ConnectedAPIIds = 'enphase' | 'oracle' | 'shipstation' | 'samsara' | 'sage' | 'fuelmaster'

export const useAddInformation = () => {
  const ghgiCategory = useGhgiCategory()
  const tabs = useMemo(() => {
    const newTabs: string[] = [
      'Upload file',
      'Connect API',
    ]
    if (ghgiCategory === 'company_facilities') {
      newTabs.push('Add Utility')
    }
    //newTabs.push('Manual Entry')
    return newTabs
  }, [ghgiCategory])
  const [currentTab, setCurrentTab] = useState<string>(tabs[0])
  const [stagedFiles, setStagedFiles] = useState<FileItem[]>([])
  const [connectAPIRequest, setConnectAPIRequest] = useState<ConnectedAPIIds>()
  const [submittingRequest, setSubmittingRequest] = useState<boolean>(false)
  const [downloadingWorkbook, setDownloadingWorkbook] = useState<boolean>(false)
  const [loadingProviders, setLoadingProviders] = useState<boolean>(false)
  const [utilityProviders, setUtilityProviders] = useState<any[]>([])
  const [loadingCredentials, setLoadingCredentials] = useState<boolean>(false)
  const [stagedFilesModalOpen, setStagedFilesModalOpen] = useState<boolean>(false)
  const { organization } = useContext(UserContext) as UserContextType
  const { showAlert } = useAlertContext()
  const history = useHistory()

  const addConnectionForm = useForm()
  const utilityForm = useForm()

  useEffect(() => {
    if (ghgiCategory) {
      loadLocalFiles()
    }
  }, [])

  const loadLocalFiles = async () => {
    try {
      const localFiles = await getFilesFromFilter((file: FileItem) => file.ghgi_category === ghgiCategory && file.status === 'staged')
      setStagedFiles(localFiles)
    }
    catch (error) {
      console.error(error)
    }
  }

  const onTabSelected = (tab: string) => {
    setCurrentTab(tab)
  }

  const addNewStagedFiles = async (newFiles: File[]) => {
    const newDBFiles = await Promise.all(newFiles.map((file: File) => addFile(file, false, 'queued', ghgiCategory)))
    setStagedFiles([...stagedFiles, ...newDBFiles])
  }

  const queueFiles = async () => {
    console.log('Files before queue: ', stagedFiles)
    const promises = stagedFiles.map(async (file: FileItem) => {
      if (!file.id) {
        return file
      }
      const udpatedFile = await updateFileById(file.id, { ...file, status: 'queued' })
      return udpatedFile
    })
    const updatedFiles = await Promise.all(promises)
    const updated: any[] = updatedFiles.filter((file: FileItem | undefined) => Boolean(file))
    console.log('Files after queue: ', updatedFiles)
    setStagedFiles(updated)
  }

  const validateFiles = (files: FileList) => {
    let errors = []
    let compliantFiles = []
    /*if (files.length > 20) {
      console.error('Too many files dropped!')
    }*/
    console.log('Files dropped: ', files)
    for (const fileItem of Array.from(files)) {
      if (!allowedFileTypes.includes(fileItem.type)) {

        let extension = /(?:\.([^.]+))?$/.exec(fileItem.name)![1]
        showAlert({
          variant: 'error',
          title: 'Error uploading file.',
          message: `Unsupported file type: ${extension}`
        })
        errors.push(`${fileItem.name} doesn't have a valid file type`)
      }
      else if (fileItem.size > maxFileSize) {
        errors.push(`${fileItem.name} is above ${maxFileSize} bytes`)
      }
      else {
        compliantFiles.push(fileItem)
      }
    }
    console.log('Compliant files: ', compliantFiles)
    return compliantFiles
  }

  const removeFile = async (file: FileItem) => {
    if (file.id) {
      await deleteFileById(file.id)
      const newStagedFiles = stagedFiles.filter(f => f !== file)
      setStagedFiles(newStagedFiles)
    }
  }

  const onWorkbookStatusChange = async (file: FileItem, workbook: boolean) => {
    try {
      const newFile = { ...file, workbook }
      if (file.id) {
        const updatedFile = await updateFileById(file.id, newFile)
        const newStagedFiles = stagedFiles.map((fileItem: FileItem) => updatedFile && fileItem.id === updatedFile.id ? updatedFile : fileItem)
        setStagedFiles(newStagedFiles)
      }
      else {
        showAlert({
          title: 'File wasn\'t indexed',
          message: 'Please upload file again',
          variation: 'error',
        })
      }
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        title: 'There was a problem',
        message: error?.message || 'Please try again or contact support',
        variation: 'error',
      })
    }
  }

  const handleFormSubmit = addConnectionForm.handleSubmit(async (data: any) => {
    try {
      setSubmittingRequest(true)
      await POST({
        endpoint: Endpoints.auth.connections.create,
        body: {
          apiId: connectAPIRequest,
          description: data.description,
          organizationId: organization?._id,
          organizationName: organization?.name
        }
      })
      showAlert({
        title: 'Connection request submitted',
        message: 'Your request has been submitted and will be reviewed',
        variation: 'success',
      })
      setConnectAPIRequest(undefined)
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        title: 'There was a problem',
        message: error?.message || 'Please try again or contact support',
        variation: 'error',
      })
    }
    finally {
      setSubmittingRequest(false)
    }
  })

  const handleUtilityFormSubmit = utilityForm.handleSubmit(async (data: any) => {
    try {
      console.log('data: ', data, organization)
      // We would place the logic to the API right here
      setLoadingCredentials(true)
      if (!organization?._id)
        return
      const results = await UtilityActions.submitCredentials(data.username, data.password, data.provider.id, organization?._id || '')
      const cred = results?.credential as ArcadiaCredential
      if (!cred?.entityId) throw new Error('No entityId in results')

      showAlert({
        title: 'Credentials received',
        message: 'We will notify you when the utility has been added',
        variation: 'info',
      })
      await addCredential(cred)
    }
    catch (error: any) {
      showAlert({
        variation: 'error',
        title: 'There was a problem adding the utility',
        message: error?.message || 'Please try again or contact support',
      })
    }
    finally {
      setLoadingCredentials(false)
    }
  })

  const handleManualEntry = () => {
    if (ghgiCategory) {
      history.push(`/import/portfolio/${ghgiCategory}`)
    }
  }

  const downloadWorkbook = async () => {
    try {
      setDownloadingWorkbook(true)
      await WorkbookActions.downloadWorkbook(ghgiCategory)
    }
    catch (error: any) {
      showAlert({
        variation: 'error',
        title: 'There was a problem downloading the workbook',
        message: error?.message || 'Please try again or contact support',
      })
    }
    finally {
      setDownloadingWorkbook(false)
    }
  }

  const getUtilityProviders = async (query: string) => {
    try {
      setLoadingProviders(true)
      const { providers } = await UtilityActions.getUtilityProviders(query)
      console.log('Providers: ', providers)
      const formattedOptions = providers.map((provider: any) => {
        return {
          id: provider.providerId,
          name: provider.providerName,
          value: provider
        }
      })
      setUtilityProviders(formattedOptions)
    }
    catch (error: any) {
      showAlert({
        variation: 'error',
        title: 'There was a problem getting the utility providers',
        message: error?.message || 'Please try again or contact support',
      })
    }
    finally {
      setLoadingProviders(false)
    }
  }

  return {
    currentTab,
    onTabSelected,
    tabs,
    addNewStagedFiles,
    validateFiles,
    stagedFiles,
    removeFile,
    apis,
    connectAPIRequest,
    setConnectAPIRequest,
    handleFormSubmit,
    addConnectionForm,
    submittingRequest,
    utilityForm,
    handleUtilityFormSubmit,
    handleManualEntry,
    downloadWorkbook,
    downloadingWorkbook,
    onWorkbookStatusChange,
    loadingProviders,
    utilityProviders,
    getUtilityProviders,
    loadingCredentials,
    queueFiles,
    stagedFilesModalOpen,
    setStagedFilesModalOpen,
  }
}
