import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../../reducers/RootReducer';
import FocusArea from '../../../types/FocusArea';
import GoalBranches from './GoalBranches';
import PlanActions from '../../../actions/PlanActions';
import useAsyncCall from '../../../hooks/UseAsyncCall';
import StyledTreeItem from './StyledTreeItem';

const FocusAreaBranches = (props: {
  organizationId: string | undefined
  dispatch: Dispatch<any>
  expandedItems: Set<string>
  focusAreaIds: string[]
  planId: string
}) => {

  const {dispatch, expandedItems, focusAreaIds, organizationId, planId } = props;
  const [ previousIsExpanded, setPreviousIsExpanded ] = useState(false);

  const focusAreas = useSelector((store: ApplicationState) => {

    let focusAreas: {[key: string]: FocusArea} = {};
    focusAreaIds.forEach((focusAreaId) => {
      const focusArea = store.focusArea.focusAreas[focusAreaId];
      if (focusArea != null) {
        focusAreas[focusArea.id] = focusArea;
      }
    });

    return focusAreas;
  });

  const fetchParentCall = useAsyncCall(true);
  useEffect(() => {
    if (expandedItems.has(planId) && !previousIsExpanded) {
      fetchParentCall.makeAsyncCall(PlanActions.fetchPlan(organizationId))
        .then((res) => {
          dispatch(res);
        })
        .catch(e => {
          console.error(e);
        });
    }
    setPreviousIsExpanded(expandedItems.has(planId));
  }, [expandedItems, planId]);

  return (
    <>
      {focusAreaIds.map((focusAreaId) => {
        const focusArea = focusAreas[focusAreaId];
        if (focusArea) {
          return (
            <StyledTreeItem key={focusAreaId} nodeId={focusAreaId} label={focusArea.title}>
              {focusArea.goalIds.length > 0 ?
                <GoalBranches
                  dispatch={dispatch}
                  expandedItems={expandedItems}
                  focusAreaId={focusAreaId}
                  goalIds={focusArea.goalIds}
                  planId={planId}
                />
                :
                null
              }
            </StyledTreeItem>
          );
        } else {
          return <StyledTreeItem key={focusAreaId} nodeId={focusAreaId} label={'-'} />;
        }
      })}
    </>
  );
};

export default FocusAreaBranches;