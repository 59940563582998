import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs'
import { AiOutlinePlus, AiOutlineCheckCircle, AiOutlineInfoCircle } from 'react-icons/ai'
// import { BsBackspace } from 'react-icons/bs'
import { Switch } from '@headlessui/react'
import classNames from '~/utils/classNames'
import Layout from '~/components/Layout'
import GHGIIcon from '~/components/GHGIIcon'
import { GHGICategory } from '~/views/DataImport/useDataImport'
import { useUploadManager } from './useUploadManager'
import { WorkbookWarning } from '~/types/File'
import UploadFileList from '~/components/UploadFileList'
import FileWarning from '~/components/FileWarning'
import WorkbookConfirmModal from '~/components/WorkbookConfirmModal'
import { ArcadiaInfoDisplay } from '~/views/Arcadia/InfoDisplay'
//import { ArcadiaInfoTempWarning } from '~/views/Arcadia/InfoTempWarning'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'

interface Props {
  goBack: () => void,
  ghgiCategories: GHGICategory[],
  loading: boolean,
  nextStep: () => void,
}

export default function UploadManager({
  goBack,
  ghgiCategories,
  loading,
  nextStep,
}: Props) {
  const ghgiCategory = useGhgiCategory()
  const {
    files,
    utilities,
    criticalOnly,
    setCriticalOnly,
    handleAddFiles,
    stagedFiles,
    setStagedFiles,
    setFiles,
    onWorkbookStatusChange,
    selectedFile,
    setSelectedFile,
    selectedUtility,
    setSelectedUtility,
    loadingFiles,
    ignoreWorkbookIssue,
    manageFiles,
  } = useUploadManager()
  const history = useHistory()

  const handleSelectFile = useCallback((file: any) => {
    setSelectedFile(file)
    setSelectedUtility(undefined)
  }, [])

  const handleSelectUtility = useCallback((utility: any) => {
    setSelectedFile(undefined)
    setSelectedUtility(utility)
  }, [])

  return (
    <Layout>
      <WorkbookConfirmModal
        open={stagedFiles?.length > 0}
        files={stagedFiles}
        onClose={() => setStagedFiles([])}
        onWorkbookStatusChange={onWorkbookStatusChange}
        onConfirm={() => {
          setFiles([...files, ...stagedFiles])
          manageFiles(stagedFiles)
          setStagedFiles([])
        }}
      />
      <div className="flex flex-col items-center w-full h-full">
        <div className="flex flex-col items-center mt-8">
          <GHGIIcon
            categoryId={ghgiCategory}
            iconClass="text-4xl text-accent"
          />
          <h2 className="text-xl font-bold text-slate-900 mt-4">
            {ghgiCategories?.length > 0 && (ghgiCategories.find((category: GHGICategory) => category?.id === ghgiCategory)?.name || 'Unknown Category')}
          </h2>
          <p className="w-[400px] md:w-[500px] text-center mt-2 text-slate-700">
            Data you submit will be uploaded to this greenhouse gas reporting category after the system validates it for accuracy. Please review the files uploaded so far and use the gray plus button or select “Add Another” file to upload additional files. <br /><br />Ready to upload data to a different greenhouse gas reporting category?
          </p>
          <p
            onClick={() => history.push('/import/category')}
            className="text-accent text-md mt-2 underline underline-offset-2 font-semibold cursor-pointer"
          >
            Change Category
          </p>
        </div>
        <div className="flex items-start justify-between w-full">
          <div
            className="cursor-pointer mt-36"
            onClick={goBack}
          >
            <BsChevronCompactLeft className="text-gray-300 hover:text-accent text-8xl" />
          </div>
          <div className="mt-10 bg-white rounded-md drop-shadow-lg border border-slate-200 py-4 items-center w-full mb-24 grid gap-4 p-4 grid-cols-1 align-top xl:grid-cols-[400px_1fr] max-h-[50vh] md:overflow-hidden overflow-auto">
            <div className="col-span-1 flex flex-col h-full xl:max-h-[calc(50vh-50px)]">
              <div className="flex items-center justify-between mb-4 h-8">
                <h4 className="font-bold text-md">Your Information</h4>
              </div>
              <UploadFileList
                files={files}
                utilities={utilities}
                handleAddFiles={handleAddFiles}
                handleSelectFile={handleSelectFile}
                handleSelectUtility={handleSelectUtility}
                selectedFile={selectedFile}
                selectedUtility={selectedUtility}
                loading={loadingFiles}
                isTemp={true}
              />
            </div>
            <div className="col-span-1 flex flex-col xl:h-full xl:max-h-[calc(50vh-50px)] h-fit">
              <div className="flex items-center justify-between mb-4 h-8">
                <Switch.Group as="div" className="flex-row-reverse flex items-center">
                  <Switch.Label as="span" className="text-sm ml-2">
                    <p className="text-slate-800 mr-2 font-light text-sm">Critical issues only</p>
                  </Switch.Label>
                  <Switch
                    checked={criticalOnly}
                    onChange={setCriticalOnly}
                    className={classNames(
                      criticalOnly ? 'bg-accent' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        criticalOnly ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
                <div className="bg-accent-secondary flex items-center justify-center rounded-md relative cursor-pointer group hover:opacity-80">
                  <AiOutlinePlus className="text-lg text-white mx-3 my-2 cursor-pointer" />
                  <input
                    type="file"
                    accept=".csv, .xlsx, .xls, .pdf"
                    className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
                    multiple
                    onChange={handleAddFiles}
                  />
                </div>
              </div>
              <div className="border border-slate-200 rounded-md flex flex-col overflow-auto xl:h-full ">
                {selectedFile?.issues && selectedFile?.issues.length > 0 ? (
                  <>
                    <div className="p-4">
                      <h4 className="font-bold mb-2">Please review the file issues</h4>
                      <p className="text-slate-600">Below is a summary of file validation issues detected. If these are critical for your business to update, please update the original file and upload again.</p>
                    </div>
                    <div className="xl:overflow-auto h-[calc(50vh-220px)]">
                      {
                        selectedFile.issues.map((warning: WorkbookWarning) => (
                          <FileWarning
                            warning={warning}
                            containerStyles={(criticalOnly && warning.type !== 'error') || warning.status === 'discarted' ? 'hidden' : ''}
                            onIgnoreClicked={ignoreWorkbookIssue}
                            onAddWorkbook={handleAddFiles}
                          />
                        ))
                      }
                    </div>
                  </>
                ) : selectedFile ? (
                  <div className="w-full flex flex-col items-center justify-center p-4 mt-6">
                    <AiOutlineCheckCircle className="text-5xl mb-3 text-green-400" />
                    <h4 className="font-bold mb-2 text-md text-center">No issues</h4>
                    <p className="text-center text-slate-500">This file has no warnings associated with it</p>
                  </div>
                ) : selectedUtility ? (
                  <ArcadiaInfoDisplay info={selectedUtility} isTemp={true} />
                ) : (
                  <div className="w-full flex flex-col items-center justify-center p-4 mt-6">
                    <AiOutlineInfoCircle className="text-5xl mb-3 text-blue-400" />
                    <h4 className="font-bold mb-2 text-md text-center">Select a file or utility</h4>
                    <p className="text-center text-slate-500">Select a file to view the issues associated with if it any</p>
                    {/*<ArcadiaInfoTempWarning />*/}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="cursor-pointer mt-36"
            onClick={nextStep}
          >
            <BsChevronCompactRight className="text-gray-300 hover:text-accent text-8xl" />
          </div>
        </div>
      </div>
    </Layout>
  )
}
