import Dexie, { Table } from 'dexie'
import { ArcadiaCredential } from '~/types/Arcadia'


interface CredentialTable {
  id?: number,
  entityId: string,
  credential: ArcadiaCredential
}

export class UtilityDB extends Dexie {
  utilities!: Table<CredentialTable>

  constructor() {
    super('UtilityDB')
    this.version(1).stores({
      utilities: '++id, entityId, credential',
    })
  }
}

export const utilityDB = new UtilityDB()

export const addCredential = async (credential: ArcadiaCredential) => {
  const document = {
    entityId: credential.entityId,
    credential
  }
  const id = await utilityDB.utilities.add(document)
  return { ...document, id: Number(id) }
}

export const getCredentials = async () => {
  const documents = await utilityDB.utilities.toArray()
  return documents
}

export const getCredential = async (id: number) => {
  const document = await utilityDB.utilities.get(id)
  return document
}

export const updateCredential = async (id: number, changes: Partial<ArcadiaCredential>) => {
  const updates: any = {}
  if (changes.entityId) {
    updates.entityId = changes.entityId
  }
  updates.credential = changes
  await utilityDB.utilities.update(id, updates)
  const updatedCredential = await utilityDB.utilities.get(id)
  return updatedCredential
}

export const deleteCredential = async (id: number) => {
  await utilityDB.utilities.delete(id)
}

export const deleteCredentials = async () => {
  await utilityDB.utilities.clear()
}