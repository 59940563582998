import SharedActions, { ActionType } from '../utils/ActionTypes';
import { authedFetch, Endpoints } from '../utils/API';
import CreateGoalRequest from '../types/requests/goal/CreateGoalRequest';
import DeleteGoalRequest from '../types/requests/goal/DeleteGoalRequest';
import FetchGoalRequest from '../types/requests/goal/FetchGoalRequest';
import UpdateGoalRequest from '../types/requests/goal/UpdateGoalRequest';

export default class GoalActions {

  /**
   * Function called to create a new goal
   *
   * @param planId: the plan to create the goal under
   * @param focusAreaId: the focus area the goal is under
   * @param goal: the goal to be created
   */
  static createGoal({ planId, focusAreaId, goal }: CreateGoalRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.goal.create, {
      method: 'POST',
      body: {
        planId,
        focusAreaId,
        goal,
      }
    })
      .then((res) => {
        return {
          type: SharedActions.goal.create,
          payload: {
            planId,
            focusArea: res.focusArea,
            goal: res.goal,
          }
        }
      });
  }

  /**
   * Function called to delete a goal
   *
   * @param planId: the plan the goal is under
   * @param focusAreaId: the focus area the goal is under
   * @param goalId: the goal to be deleted
   */
  static deleteGoal({ planId, focusAreaId, goalId }: DeleteGoalRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.goal.delete, {
      method: 'DELETE',
      queryParameters: { planId, focusAreaId, goalId }
    })
      .then((res) => {
        return {
          type: SharedActions.goal.delete,
          payload: {
            planId,
            goalId,
            focusArea: res.focusArea,
          }
        }
      });
  }

  /**
   * Function called to fetch a goal by its id
   *
   * @param planId: the plan the goal is under
   * @param focusAreaId: the focus area the goal is under
   * @param goalId: the goal to be fetched
   */
  static fetchGoal({ planId, focusAreaId, goalId }: FetchGoalRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.goal.fetch, {
      method: 'GET',
      queryParameters: { planId, focusAreaId, goalId }
    })
      .then((res) => {
        return {
          type: SharedActions.goal.update,
          payload: {
            planId,
            focusAreaId,
            goal: res.goal,
            strategies: res.strategies,
          }
        }
      });
  }

  /**
   * Function called to update a goal
   *
   * @param planId: the plan the goal is under
   * @param focusAreaId: the focus area the goal is under
   * @param goalId: the id of the goal being updated
   * @param update: the updates to the goal to be pushed
   */
  static updateGoal({ planId, focusAreaId, goalId, update }: UpdateGoalRequest): Promise<ActionType> {
    return authedFetch(Endpoints.auth.goal.update, {
      method: 'PATCH',
      body: {
        planId,
        focusAreaId,
        goalId,
        goal: update
      }
    })
      .then((res) => {
        return {
          type: SharedActions.goal.update,
          payload: {
            planId,
            focusAreaId,
            goal: res.goal,
            strategies: res.strategies,
          }
        }
      });
  }
}