import GHGICategoryCard from '../../components/GHGICategoryCard'
import { useSelectGHGICategory } from './useSelectGHGICategory'
import Layout from '../../components/Layout'
import { GHGICategory } from '../DataImport/useDataImport'

interface Props {
  onGHGICategorySelected: (categoryId: string) => void
  loading: boolean
  ghgiCategories: GHGICategory[]
}

export default function SelectGHGICategory({ onGHGICategorySelected, loading, ghgiCategories }: Props) {
  const {
    organization,
  } = useSelectGHGICategory()

  return (
    <Layout loading={loading}>
      <div className="w-full h-full flex flex-col items-center pt-12 px-12 bg-background-100">
        <h2 className="text-xl  font-bold text-slate-900">
          Select Your First GHGI Category
        </h2>
        <p className="w-[400px] md:w-[500px] text-center mt-6 text-slate-700">
          Ready to add some data to your company's profile? Select the Greenhouse Gas Reporting Category that you would like to add data to.
        </p>
        {/* Category layout */}
        <div className="flex flex-col lg:flex-row items-space-between mt-12 ">
          {/* Container with heading and grid */}
          <div className="flex flex-col items-center">
            <h4 className="text-slate-600 tracking-widest text-xs mb-4 font-light">UPSTREAM</h4>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 grid-flow-row gap-4">
              {
                ghgiCategories.filter(({category}: GHGICategory) => category === 'upstream').map((category, index) => (
                  <GHGICategoryCard 
                    key={category.id}
                    label={category.name}
                    id={category.id}
                    disabled={!organization || !organization.ghgiCategories || !organization.ghgiCategories.includes(category.id)}
                    onClick={() => onGHGICategorySelected(category.id)}
                  />
                ))
              }
            </div>
          </div>
          <div className="w-full h-[1px] min-h-[1px] bg-slate-200 my-8 min-w-[50vw] lg:h-full lg:w-[1px] lg:min-w-[1px] lg:mx-8 lg:my-0 lg:min-h-[50vh]"></div>
          <div className="flex flex-col items-center">
            <h4 className="text-slate-600 tracking-widest text-xs mb-4 font-light lg:text-center">REPORTING COMPANY</h4>
            <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-1 grid-flow-row gap-4">
            {
                ghgiCategories.filter(({category}: GHGICategory) => category === 'reporting_company').map((category, index) => (
                  <GHGICategoryCard 
                    key={category.id}
                    label={category.name}
                    id={category.id}
                    disabled={!organization || !organization.ghgiCategories || !organization.ghgiCategories.includes(category.id)}
                    onClick={() => onGHGICategorySelected(category.id)}
                  />
                ))
              }
            </div>
          </div>
          <div className="w-full h-[1px] min-h-[1px] bg-slate-200 my-8 min-w-[50vw] lg:h-full lg:w-[1px] lg:min-w-[1px] lg:mx-8 lg:my-0 lg:min-h-[50vh]"></div>
          <div className="flex flex-col items-center">
            <h4 className="text-slate-600 tracking-widest text-xs mb-4 font-light">DOWNSTREAM</h4>
            <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 grid-flow-row gap-4">
              {
                ghgiCategories.filter(({category}: GHGICategory) => category === 'downstream').map((category, index) => (
                  <GHGICategoryCard 
                    key={category.id}
                    label={category.name}
                    id={category.id}
                    disabled={!organization || !organization.ghgiCategories || !organization.ghgiCategories.includes(category.id)}
                    onClick={() => onGHGICategorySelected(category.id)}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
