import { useContext, Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CellContext } from '@tanstack/table-core';

import { MdAddCircle, MdDeleteOutline, MdVisibility } from 'react-icons/md';

import Table from '../Table'
import useAsyncCall from '../../hooks/UseAsyncCall';
import Copyright from '../generics/labels/Copyright';
import Loading from '../generics/Loading';
import FileData from '../../types/FileData';
import DocumentsActions from '../../actions/DocumentsActions';
import StringFormatters from '../../utils/StringFormatters';
import UploadDocumentsDialog from '../documents/UploadDocumentsDialog';
import DeleteDocumentDialog from '../documents/DeleteDocumentsDialog';
import { UserContext, UserContextType } from '../../contexts/UserContext';
import { PageLayout } from '../PageLayout';

const Surveys = () => { // Ex SupplyChain
  const { organization } = useContext(UserContext) as UserContextType;
  const [addDocumentsDialogOpen, setAddDocumentsDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileData | null>(null)
  const [documents, setDocuments] = useState<FileData[]>([])

  const dispatch = useDispatch();

  const fetchDocumentsCall = useAsyncCall(true);
  const fileType = 'SUPPLIER';

  //fetch all documents on load of the page
  useEffect(() => {
    fetchDocuments();
  }, [organization]);

  /**
   * Function called to fetch all the document data objects in this table
   */
  const fetchDocuments = () => {
    if (organization) {
      fetchDocumentsCall.makeAsyncCall(DocumentsActions.fetchAllDocuments(organization._id, fileType))
        .then((res) => {
          const { tableIds, documents } = res.payload
          const docList: FileData[] = tableIds.map((docId: string) => documents[docId]).filter((doc: FileData) => doc != null)
          setDocuments(docList)
        })
        .catch(e => {
          console.error(e);
        });
    }
  };

  return (
    <PageLayout>
      <div className="overflow-auto">
        <div className="px-6 py-16">
          <Suspense fallback={<Loading delay={1000} />}>
            <Table
              title="Surveys"
              columns={[
                { header: 'File Name', accessorKey: 'fileName', meta: { tdClass: 'max-w-xs truncate' } },
                { header: 'File Size', accessorKey: 'fileSize', cell: (data: CellContext<any, any>) => StringFormatters.formatBytes(data.getValue()) },
                { header: 'Added', accessorKey: 'created', cell: (data: CellContext<any, any>) => StringFormatters.formatDateTime(data.getValue()) },
              ]}
              data={documents}
              actions={[
                {
                  tooltip: organization?.writePermission ? 'Add File' : 'Feature Disabled',
                  icon: <MdAddCircle />,
                  disabled: () => !organization?.writePermission,
                  onClick: () => setAddDocumentsDialogOpen(true)
                }
              ]}
              rowActions={[
                {
                  tooltip: 'Open File',
                  icon: <MdVisibility className="text-gray-600 hover:text-gray-600" />,
                  onClick: (row: FileData) => window.open(row.url)
                },
                {
                  tooltip: organization?.writePermission ? 'Delete File' : 'Feature Disabled',
                  icon: <MdDeleteOutline className="text-gray-600 hover:text-gray-600" />,
                  disabled: () => !organization?.writePermission,
                  onClick: (row: FileData) => setFileToDelete(row)
                }
              ]}
              loading={fetchDocumentsCall.isCalling}
              reload={fetchDocuments}
            />
          </Suspense>
        </div>
        <Copyright />
      </div>
      {addDocumentsDialogOpen &&
        <UploadDocumentsDialog
          dispatch={dispatch}
          open={addDocumentsDialogOpen}
          onClose={(reload) => {
            setAddDocumentsDialogOpen(false);
            if (reload) {
              fetchDocuments();
            }
          }}
          fileType={fileType}
        />
      }
      {!!fileToDelete && (
        <DeleteDocumentDialog
          dispatch={dispatch}
          fileToDelete={fileToDelete}
          open={!!fileToDelete}
          onClose={(deleted) => {
            if (deleted) {
              setDocuments(
                documents.filter((doc) => doc.fileId !== fileToDelete.fileId)
              )
            }
            setFileToDelete(null)
          }}
        />
      )}
      {fetchDocumentsCall.SnackbarComponent}
    </PageLayout>
  );
}

export default Surveys
