interface Props {
  progress: number,
  containerStyles?: string,
}

export default function LinearProgress({ progress, containerStyles }: Props) {
  return (
    <div className={`my-2 h-4 rounded-full mx-4 bg-gray-200 flex ${containerStyles}`}>
      <div
        className={`h-full bg-accent rounded-full border-2 border-blue`}
        style={{ width: `${progress < 5 ? 5 : progress > 100 ? 100 : progress}%` }}
      />
    </div>
  )
}
