import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import YesNoDialog from "../components/generics/dialog/YesNoDialog";
import SharedActions from "../utils/ActionTypes";
import { clearFileCache } from '../utils/FileCache';

const useUserAuth = () => {

    const [ logoutDialogOpen, setLogoutDialogOpen ] = useState(false);

    const dispatch = useDispatch();
    const auth0 = useAuth0();
    const { logout, ...rest } = auth0;

    /**
     * Function called to log out the user
     */
    const logUserOut = () => {
        //Cookies are cleared once logout finishes so no need to clear them here
        dispatch({ type: SharedActions.reset });
        clearFileCache();
        logout();
    };

    /**
     * Function called when the logout button is clicked
     *
     * @param isLoggingOut: a boolean stating whether or not the user opted to log out
     */
    const btnLogoutClicked = (isLoggingOut: boolean) => {

        setLogoutDialogOpen(false);

        if (isLoggingOut) {
            logUserOut();
        }
    };

    const LogoutDialog = (
        <YesNoDialog
            open={logoutDialogOpen}
            title={`Logging out?`}
            description={`Are you sure you want to log out?`}
            handleClose={btnLogoutClicked}
        />
    );

    return {
        ...rest,
        logUserOut,
        LogoutDialog,
        setLogoutDialogOpen,
    };
};

export default useUserAuth;