import { useState, useEffect } from 'react'
import {
  AiOutlinePlus,
  // AiOutlineWarning,
  AiOutlineArrowRight
} from 'react-icons/ai'
import { IoReturnDownBackSharp } from 'react-icons/io5'
import Button from '~/components/Button'
import Layout from '~/components/Layout'
import { GHGICategory } from '../../DataImport/useDataImport'
import GHGIIcon from '~/components/GHGIIcon'
import classNames from '~/utils/classNames'
import Modal from '~/components/Modal'
import Textarea from '~/components/Textarea'
import { useForm } from 'react-hook-form'
import FormSelect, { SelectOption } from '~/components/FormSelect'
import { Endpoints, POST } from '~/utils/API'
import { OrganizationType } from '~/contexts/UserContext'
import { useAlertContext } from '~/contexts/AlertContext'

interface Props {
  ghgiCategories: GHGICategory[],
  userGHGICategories: GHGICategory[]
  onSelectedCategory: (category: GHGICategory) => void,
  selectedGHGICategory: GHGICategory | undefined,
  openConflictModal: () => void,
  currentView: 'dashboard' | 'uploads',
  setCurrentView: (view: 'dashboard' | 'uploads') => void,
  loadingCategories: boolean,
  organization?: OrganizationType,
}

export default function Header({
  ghgiCategories,
  userGHGICategories,
  onSelectedCategory,
  selectedGHGICategory,
  openConflictModal,
  currentView,
  setCurrentView,
  loadingCategories,
  organization,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [loadingSubmission, setLoadingSubmission] = useState(false)
  const [showScroll, setShowScroll] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { showAlert } = useAlertContext()
  const newCategoryForm = useForm()

  useEffect(() => {
    window.addEventListener('resize', handleWidthSizeChange)
    return () => window.removeEventListener('resize', handleWidthSizeChange)
  }, [])

  useEffect(() => {
    if ((userGHGICategories.length + 2) > window.innerWidth / 160)
      setShowScroll(true)
    else
      setShowScroll(false)
  }, [windowWidth, userGHGICategories])

  const handleWidthSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleFormSubmit = newCategoryForm.handleSubmit(async (data) => {
    try {
      setLoadingSubmission(true)
      await POST({
        endpoint: Endpoints.auth.ghgiCategories.request,
        body: {
          ghgi_category_name: data.category.name,
          ghgi_category_id: data.category.id,
          description: data.description,
          organizationId: organization?._id,
          organizationName: organization?.name,
        }
      })
      showAlert({
        title: 'Success',
        message: 'Your request has been submitted. You will be notified when your request has been approved.',
        type: 'success',
      })
      newCategoryForm.reset()
      setModalOpen(false)
    }
    catch (error) {
      console.error(error)
    }
    finally {
      setLoadingSubmission(false)
    }
  })

  const selectGHGICategories: SelectOption[] = ghgiCategories.map((category: GHGICategory) => {
    return {
      id: category.id,
      name: category.name,
      value: category.id,
      disabled: userGHGICategories.some((userCategory: GHGICategory) => userCategory.id === category.id)
    }
  })

  return (
    <>
      <div className="relative">
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b to-[#1E736E] from-[#7AACAF]" />
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-b from-[#1E736E] via-[rgba(0,0,0,0)] to-[#1E736E] opacity-50" />
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-r from-[#1E736E] via-[rgba(0,0,0,0)] to-[#1E736E] opacity-50" />
        <Layout>
          <div className="w-full flex flex-col text-white z-10 pt-4">
            <div className="flex items-center justify-between w-full xl:flex-row flex-col">
              <div className="z-10 max-w-xl flex flex-col items-center xl:items-start">
                <h3 className="text-lg mb-2 font-bold">Your Portfolio</h3>
                <p className="text-sm">This page displays your portfolio of assets, accounts, and activity data types. Click on a category to view and edit asset and account details</p>
              </div>
              <span className='h-6' />
              <div className="flex items-center justify-end z-10">
                {/* <Button
                      variant='text'
                      iconLeft={<AiOutlineWarning className="text-white" />}
                      label={`2 File Issues`}
                      labelStyles="text-white"
                      onClick={openConflictModal}
                    />
                    <span className="w-2" /> */}
                {
                  currentView === 'dashboard' && (
                    <>
                      <Button
                        variant='ghost'
                        label="View Data Sources"
                        onClick={() => setCurrentView('uploads')}
                      />
                      <span className="w-2" />
                      {/* <Button
                        variant='primary'
                        iconLeft={<AiOutlinePlus />}
                        label="Add More Files"
                        onClick={() => setCurrentView('uploads')}
                      /> */}
                    </>
                  )
                }
                {
                  currentView === 'uploads' && (
                    <Button
                      variant='primary'
                      iconLeft={<IoReturnDownBackSharp />}
                      label="Return to Dashboard"
                      onClick={() => setCurrentView('dashboard')}
                    />
                  )
                }
              </div>
            </div>
            <span className="mt-4 h-[1px] min-h-[1px] max-h-[1px] w-full bg-white z-10 opacity-10" />
            <div className="w-full relative">
              {
                showScroll && (
                  <div className="w-36 absolute h-full bottom-0 -right-4 z-20 bg-gradient-to-r from-transparent to-[#1E736E] flex items-center justify-end pr-3">
                    <AiOutlineArrowRight className="text-white text-4xl" />
                  </div>
                )
              }
              <div className="w-full flex items-center overflow-x-auto bg-gradient-to-r from-opacity-100 to-opacity-0">
                <div className="flex z-10 pr-24 my-4">
                  {
                    loadingCategories && (
                      <>
                        <div className="h-36 w-36 bg-gray-400 animate-pulse rounded-xl mr-2 opacity-20" />
                        <div className="h-36 w-36 bg-gray-400 animate-pulse rounded-xl mr-2 opacity-20" />
                        <div className="h-36 w-36 bg-gray-400 animate-pulse rounded-xl mr-2 opacity-20" />
                      </>
                    )
                  }
                  {
                    !loadingCategories && userGHGICategories.map((category: GHGICategory) => (
                      <div className="h-36 w-36 overflow-hidden mr-2" onClick={() => onSelectedCategory(category)} key={category.id}>
                        <div className={classNames(
                          "group focus:bg-accent w-36 h-36 flex flex-col items-center justify-center border cursor-pointer border-slate-100 rounded-xl p-2 hover:text-white active:text-white hover:fill-white active:fill-white hover:bg-accent active:bg-accent hover:shadow-lg",
                          selectedGHGICategory?.id === category.id ? "bg-white" : ""
                        )}>
                          <GHGIIcon
                            categoryId={category.id}
                            iconClass={classNames(
                              "text-4xl mb-3",
                              selectedGHGICategory?.id === category.id ? "fill-accent" : "fill-white"
                            )}
                          />
                          <h4
                            className={classNames(
                              "font-semibold text-center",
                              selectedGHGICategory?.id === category.id ? "text-slate-800 font-bold" : "text-white"
                            )}
                          >
                            {category.name}
                          </h4>
                        </div>
                      </div>
                    ))
                  }
                  {
                    !loadingCategories && (
                      <div
                        className={`group focus:bg-accent w-36 h-36 flex flex-col items-center justify-center border cursor-pointer border-slate-100 rounded-xl p-2 hover:text-white active:text-white hover:fill-white active:fill-white hover:bg-accent active:bg-accent hover:shadow-lg`}
                        onClick={() => setModalOpen(true)}
                      >
                        <AiOutlinePlus className="text-4xl mb-3 fill-white" />
                        <h4 className="text-white font-semibold text-center">Add Another Category</h4>
                      </div>
                    )
                  }
                </div>
                <div className="h-36 w-36 overflow-hidden mr-2">
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
      <Modal
        title="Request new category"
        description="Interested in adding new data to your Profile? Select the GHGI category you are interested in and your Customer Service Manager will follow up to start the process."
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
          newCategoryForm.reset()
        }}
        actionButtons={[
          {
            label: 'Cancel',
            onClick: () => {
              setModalOpen(false)
              newCategoryForm.reset()
            },
            variant: 'ghost',
          },
          {
            label: 'Connect',
            onClick: handleFormSubmit,
            variant: 'primary',
            type: 'submit',
            loading: loadingSubmission,
          }
        ]}
      >
        <form
          id="add-connection-form"
          className="w-full px-4 pb-8 pt-2 flex items-start flex-col space-y-4"
          onSubmit={(data: any) => console.log('Form Submitted', data)}
        >
          <FormSelect
            label="Category"
            name="category"
            register={newCategoryForm.register}
            options={{ required: true }}
            selectOptions={selectGHGICategories}
            control={newCategoryForm.control}
          />
          <Textarea
            label="Additional Information"
            name="description"
            register={newCategoryForm.register}
            value={newCategoryForm.watch('description')}
          />
        </form>
      </Modal>
    </>
  )
}
