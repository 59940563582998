type Env = {
    STAGE: string
    API_DOMAIN: string
    API_PATH: string
    AUTH0_DOMAIN: string
    AUTH0_CLIENT_ID: string
    AUTH0_AUDIENCE: string

    [key: string]: string
}

const env: any = {};
Object.keys(process.env)
    .filter(key => key.startsWith('REACT_APP_'))
    .forEach(key => env[key.substr('REACT_APP_'.length)] = process.env[key]);

export default env as Env;
