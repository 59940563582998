import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { deleteCredentials } from '~/utils/CredentialsIndexDB'
import { useGhgiCategories } from '~/hooks/UseGhgiCategories'

type DataImportParams = {
  ghgiCategory?: string
  stepId?: string
}

export type GHGICategory = {
  id: string
  name: string
  icon: JSX.Element,
  description: string,
  category: 'upstream' | 'reporting_company' | 'downstream',
  activities: GHGIActivityData[],
}

export type GHGIActivityData = {
  label: string,
  id: string
}

type CategoryType = 'category' | 'upload' | 'manage' | 'portfolio'

export const useDataImport = () => {
  const [currentStep, setCurrentStep] = useState<CategoryType>('category')
  const history = useHistory()
  const { ghgiCategory, stepId } = useParams<DataImportParams>()
  const { loading, categories: ghgiCategories } = useGhgiCategories()

  useEffect(() => {
    if (!ghgiCategory) {
      setCurrentStep('category')
      history.push('/import/category')
    }
    else if (stepId && ['upload', 'manage', 'portfolio'].includes(stepId)) {
      setCurrentStep(stepId as CategoryType)
    }
  }, [ghgiCategory, stepId])

  const onGHGICategorySelected = (categoryId: string) => {
    if (!categoryId) return
    if (!ghgiCategories.some((category: GHGICategory) => category.id === categoryId)) return
    history.push(`/import/upload/${categoryId}`)
  }

  const goBack = () => {
    history.goBack()
  }

  const uploadFilesAndGoToManage = () => {
    history.push(`/import/manage/${ghgiCategory}`)
  }

  const goToPortfolio = () => {
    deleteCredentials()
    history.push(`/import/portfolio/${ghgiCategory}`)
  }

  // Prevent going to categories you don't have access to.
  useEffect(() => {
    if (ghgiCategory && ghgiCategory !== 'category' && ghgiCategories?.length && !ghgiCategories.find(item => item.id === ghgiCategory)) {
      history.replace('/import/category')
    }
  }, [ghgiCategories, ghgiCategory])

  return {
    currentStep,
    onGHGICategorySelected,
    goBack,
    ghgiCategories,
    loading,
    uploadFilesAndGoToManage,
    goToPortfolio,
  }
}