import SharedActions, { ActionType } from '../utils/ActionTypes';
import SisenseDashboard from '../types/SisenseDashboard';

export type DashboardState = {
  dashboards: {[key in string]: SisenseDashboard}
  dashboardIds: string[]
}

const initialState = {
  dashboards: {},
  dashboardIds: [],
};

export default function dashboardReducer(
  state: DashboardState = initialState,
  action: ActionType
): DashboardState {
  switch (action.type) {

    case SharedActions.dashboards.fetchAll: {
      const { dashboards, tableIds } = action.payload;

      return {
        ...state,
        dashboards: dashboards,
        dashboardIds: tableIds,
      };
    }

    case SharedActions.dashboards.update: {
      const dashboard: SisenseDashboard = action.payload;

      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [dashboard.oid]: dashboard,
        },
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}