import FocusArea from '../types/FocusArea'
import SharedActions, { ActionType } from '../utils/ActionTypes'

export type FocusAreaState = {
  focusAreas: { [key: string]: FocusArea }
}

const initialState = {
  focusAreas: {},
}

export default function focusAreaReducer(
  state: FocusAreaState = initialState,
  action: ActionType
): FocusAreaState {
  switch (action.type) {
    case SharedActions.focusArea.create:
    case SharedActions.focusArea.update:
    case SharedActions.goal.create:
    case SharedActions.goal.delete: {
      const { focusArea } = action.payload

      return {
        ...state,
        focusAreas: {
          ...state.focusAreas,
          [focusArea.id]: focusArea,
        },
      }
    }

    case SharedActions.treeView.fetchFullTree: {
      const { focusAreas } = action.payload;
      const focusAreasObject = focusAreas.reduce(
        (obj: any, item: FocusArea) => ((obj[item.id] = item), obj),
        {}
      );

      return {
        ...state,
        focusAreas: {
          ...state.focusAreas,
          ...focusAreasObject,
        },
      };
    }

    case SharedActions.focusArea.delete: {
      const { focusAreaId } = action.payload

      const { [focusAreaId]: removedItem, ...rest } = state.focusAreas

      return {
        ...state,
        focusAreas: {
          ...rest,
        },
      }
    }

    case SharedActions.plan.update: {
      const { focusAreas } = action.payload

      return {
        ...state,
        focusAreas: {
          ...state.focusAreas,
          ...focusAreas,
        },
      }
    }

    case SharedActions.reset: {
      return initialState
    }

    default:
      return state
  }
}
