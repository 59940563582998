import React, { useContext, useState, useEffect } from 'react'
import Modal from '../Modal'
import Textarea from '../Textarea'
import Input from '../Input'
import { useForm } from 'react-hook-form'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import { AlertContext, AlertContextType, NotificationConfig } from '~/contexts/AlertContext'
import { useLocation } from 'react-router-dom'
import JiraActions from '~/actions/JiraActions'
import Toggle from '../Toggle'

interface Props {
  visible: boolean,
  onClose: () => void
}

export default function SupportModal({ visible, onClose }: Props) {
  const [loading, setLoading] = useState(false)
  const { userInfo, organization } = useContext(UserContext) as UserContextType
  const { showAlert } = useContext(AlertContext) as AlertContextType
  const location = useLocation()

  const { register, handleSubmit, formState: { errors }, setValue, watch, reset } = useForm()

  useEffect(() => {
    if (userInfo?.email && visible) {
      setValue('email', userInfo?.email)
      setValue('issueType', 'bug')
    }
  }, [userInfo, visible])

  const submitSupportTicket = handleSubmit(async (data: any) => {
    try {
      setLoading(true)
      console.log('Message for support: ', data)
      await JiraActions.createIssue(
        `[${data.issueType}] ${data.subject}`,
        `Message: ${data.message} | Location: ${location.pathname} | Organization: ${organization?.name} | OrganzationId: ${organization?._id?.toString()}`,
        data.email,
      )
      handleClose()
      // Make the API call to JIRA
      showAlert({
        variation: 'success',
        title: 'Support Ticket Submitted',
        message: 'One of our team members will reach out as soon as possible',
      } as NotificationConfig)

    }
    catch (error) {
      console.error(error)

    }
    finally {
      // setLoading(false)
    }
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal
      title="Create Support Ticket"
      description="Please provide a detailed description of your issue. One of our team members will reach out as soon as possible."
      open={visible}
      onClose={handleClose}
      className='w-fit'
      actionButtons={[
        {
          label: 'Cancel',
          onClick: handleClose,
          variant: 'ghost'
        },
        {
          label: 'Confirm',
          type: 'submit',
          form: 'support-ticket-form',
          variant: 'primary',
          loading: loading,
          onClick: () => { }
        }
      ]}
    >
      <form
        id="support-ticket-form"
        onSubmit={submitSupportTicket}
        className="p-4 w-[70vw] max-w-[500px]"
      >
        <Toggle
          options={[
            { value: 'bug', label: 'Bug' },
            { value: 'feature', label: 'Feature Request' },
          ]}
          selected={watch('issueType')}
          onChange={(option) => setValue('issueType', option.value)}
          containerStyle='mx-auto my-3'
        />
        <Input
          label="Email"
          name="email"
          register={register}
          value={watch('email')}
          options={{ required: 'Email is required' }}
          error={errors?.email?.message}
        />
        <div className="h-4" />
        <Input
          label="Subject"
          name="subject"
          register={register}
          value={watch('subject')}
          options={{ required: 'Subject is required' }}
          error={errors?.subject?.message}
        />
        <div className="h-4" />
        <Textarea
          label="Message"
          name="message"
          minRows={3}
          register={register}
          value={watch('message')}
          options={{ required: 'Message is required' }}
          error={errors?.message?.message}
        />
      </form>
    </Modal >
  )
}
