import { Endpoints, GET } from "../utils/API"

export class WorkbookActions {
  public static async downloadWorkbook(ghgiCategory: string) {
    const { url } = await GET({
      endpoint: Endpoints.auth.workbooks.template.fetch,
      queryParameters: {
        categoryId: ghgiCategory,
      }
    })
    window.location.replace(url)
  }
}