import { useState, useEffect, useCallback } from 'react'
import { Combobox } from '@headlessui/react'
import { AiOutlineCheck, AiOutlineDown } from 'react-icons/ai'
import { CgSpinnerTwo } from 'react-icons/cg'
import { useController } from 'react-hook-form'
import classNames from '../../utils/classNames'
import { debounce } from 'lodash'

export type AutocompleteOption = {
  id: string,
  name: string,
  value: any,
  disabled?: boolean,
}

interface Props  {
  autocompleteOptions: AutocompleteOption[],
  defaultValue?: AutocompleteOption | undefined,
  label: string,
  name: string,
  register?: any
  value?: any
  options?: any,
  control: any,
  disabled?: boolean,
  loading?: boolean,
  onQueryChange: (query: string) => void,
  mode: 'fetch' | 'filter'
}

export default function FormCombobox({ options, defaultValue = undefined, label, autocompleteOptions, register, value, name, control, disabled, loading, onQueryChange, mode='filter' }: Props) {
  const [query, setQuery] = useState<string>('')
  const [active, setActive] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption | undefined>(defaultValue)

  const { field } = useController({
    name,
    control,
  })

  useEffect(() => {
    if (control) {
      field.onChange(selectedOption)
    }
    if(selectedOption && !active) {
      setActive(true)
    }
  }, [selectedOption])

  const filteredOptions = 
    query === ''
      ? autocompleteOptions
      : autocompleteOptions.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })
  
  const debouncedQueryChange = useCallback(debounce((query: string) => onQueryChange(query), 500),[])

  return (
    <Combobox as="div" value={selectedOption} onChange={setSelectedOption} className="w-full">
      <div className="relative w-full pt-3">
        <div className="w-full flex items-center justify-between">
          <Combobox.Input
            className="w-full bg-white py-1.5 pl-2 pr-10 text-gray-900 outline-none sm:text-sm sm:leading-6flex-grow border-b"
            onChange={(event) => {
              setQuery(event.target.value)
              debouncedQueryChange(event.target.value)
            }}
            displayValue={(person) => selectedOption?.name || ''}
            onFocus={() => setActive(true)}
            onBlur={() => {
              if(!query) {
                setActive(false)
              }
              if(!selectedOption) {
                setQuery('')
                setActive(false)
              }
            }}
          />
          <Combobox.Button className="peer h-10 absolute right-0 top-0 bottom-0 w-full min-w-full cursor-default py-3 pl-3 pr-3 text-left focus:border-accent focus:outline-none sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed flex justify-end">
            { loading ? <CgSpinnerTwo className="h-5 w-5 text-gray-400 animate-spin" aria-hidden="true" /> : <AiOutlineDown className="h-5 w-5 text-gray-400" aria-hidden="true" /> }
          </Combobox.Button>
          <Combobox.Label 
            className={
              classNames(
                'text-gray-500 absolute font-semibold transition-top duration-100 peer-focus:ease-linear left-2',
                !active && !selectedOption ? 'text-md top-4' : 'top-0 text-xs'
              )}
          >
            { label }
          </Combobox.Label>
        </div>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {mode === 'filter' && filteredOptions.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>{option.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
            {mode === 'fetch' && autocompleteOptions.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected ? 'font-semibold' : '')}>{option.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}