import Modal from '../Modal'
import { AiOutlineArrowRight } from 'react-icons/ai'

export type FieldChange = {
  fieldName: string,
  originalValue: string | number | boolean,
  updatedValue: string | number | boolean
}

interface Props {
  visible: boolean,
  label?: string,
  changes?: FieldChange[],
  onClose: () => void,
  onConfirm: () => any,
  loading: boolean
}

export type ConfirmChange = {
  label: string,
  changes: Array<{ fieldName: string, originalValue: string | number | boolean, updatedValue: string | number | boolean }>
  onConfirm: () => Promise<void> | void,
  onCancel: () => Promise<void> | void,
}


export default function DashboardChangeConfirmation({ visible, onClose, onConfirm, label, changes, loading }: Props) {
  return (
    <Modal
      open={Boolean(visible)}
      title={`Confirm changes on ${label}`}
      actionButtons={[
        {
          label: 'Cancel',
          onClick: onClose,
          variant: 'ghost'
        },
        {
          label: 'Confirm',
          onClick: onConfirm,
          variant: 'primary',
          loading: loading
        }
      ]}
      onClose={onClose}
    >
      <div className="flex flex-col px-4 py-3">
        {
          changes && changes?.length > 0 && changes.map((change) => (
            <div className="flex flex-col mb-2 w-full rounded-md items-start" key={change.fieldName}>
              <h5 className="font-semibold capitalize mb-2 ml-1">{change.fieldName}</h5>
              <span className="grid grid-cols-7 items-center w-full border border-slate-200 rounded-md py-2 px-3">
                <p className="col-span-3">{change.originalValue ? change.originalValue : <span className="italic text-gray-500">Empty field</span>}</p>
                <AiOutlineArrowRight className="" />
                <p className="col-span-3">{change.updatedValue ? change.updatedValue : <span className="italic text-gray-500">Empty field</span>}</p>
              </span>
            </div>
          ))
        }
        {
          changes?.length === 0 && (
            <div className="flex flex-col items-center justify-center w-full p-4 rounded-md border-dashed border-2">
              <h4 className="font-semibold mb-2 text-lg">No changes registered</h4>
              <p className="text-gray-600">Please check the form and click save again</p>
            </div>
          )
        }
      </div>
    </Modal>
  )
}
