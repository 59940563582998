import SharedActions, { ActionType } from '../utils/ActionTypes';
import Forecast from '../types/forecasts/Forecast';

export type ForecastState = {
  forecasts: {[key in string]: Forecast}
  forecastIds: string[]
}

const initialState = {
  forecasts: {},
  forecastIds: [],
};

export default function forecastReducer(
  state: ForecastState = initialState,
  action: ActionType
): ForecastState {
  switch (action.type) {

    case SharedActions.forecasts.delete: {
      let newForecasts = {...state.forecasts};
      delete newForecasts[action.payload];

      return {
        ...state,
        forecastIds: state.forecastIds.filter((id) => id !== action.payload),
        forecasts: newForecasts,
      };
    }

    case SharedActions.forecasts.fetchAll: {
      const { forecasts, tableIds } = action.payload;

      return {
        ...state,
        forecasts: forecasts,
        forecastIds: tableIds,
      };
    }

    case SharedActions.forecasts.update: {
      const forecast: Forecast = action.payload;

      return {
        ...state,
        forecasts: {
          ...state.forecasts,
          [forecast.id]: forecast,
        },
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}