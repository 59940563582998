import SharedActions, { ActionType } from "../utils/ActionTypes";
import { SnackbarDisplayType } from "../components/generics/Snackbar";

export type DisplayingError = {message: string, type: SnackbarDisplayType} | null;

export type NetworkState = {
    globalSnackbarMessage: DisplayingError
    initDone: boolean
}

const initialState = {
    globalSnackbarMessage: null,
    initDone: false,
};

export default function networkReducer(
    state: NetworkState = initialState,
    action: ActionType
): NetworkState {
    switch (action.type) {
        case SharedActions.network.setGlobalError: {
            return { ...state, globalSnackbarMessage: action.payload };
        }

        case SharedActions.init: {
            return {
                ...state,
                initDone: true,
            };
        }

        case SharedActions.reset: {
            return initialState;
        }

        default:
            return state
    }
}