import { populateActions } from '~/utils/Utils';
import { Middleware } from "redux";
import { createLogger } from "redux-logger";
import Constants from "./Constants";

const SharedActions = populateActions({
    commitments: {
        create: '',
        fetch: '',
        delete: '',
        update: '',
    },
    dashboards: {
        fetchAll: '',
        update: '',
    },
    documents: {
        delete: '',
        fetchAll: '',
    },
    focusArea: {
        create: '',
        delete: '',
        update: '',
    },
    forecasts: {
        delete: '',
        fetchAll: '',
        update: '',
    },
    glidePaths: {
        delete: '',
        fetchAll: '',
        update: '',
    },
    goal: {
        create: '',
        delete: '',
        update: '',
    },
    initiative: {
        create: '',
        delete: '',
        update: '',
    },
    metrics: {
        fetch: '',
    },
    network: {
        setGlobalError: '',
    },
    plan: {
        setViewingTree: '',
        update: '',
    },
    project: {
        create: '',
        delete: '',
        fetchAll: '',
        update: '',
    },
    strategy: {
        create: '',
        delete: '',
        update: '',
    },
    treeView:{
        fetchFullTree: '',
    },
    user: {
        setCurrentUser: '',
        update: '',
        create: '',
    },
    init: '',
    reset: '',
});

export type ActionType = {
    type: string
    payload: any
};

export const middleware: Middleware[] = [];
if (!Constants.isProd) {
    middleware.push(createLogger({ collapsed: true, diff: false }));
}

export default SharedActions;