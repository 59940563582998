import { useEffect, useReducer, useState, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';

import useAsyncCall, { UseAsyncCallType } from "../UseAsyncCall";
import FileActions from "../../actions/FileActions";
import { ActionType } from "../../utils/ActionTypes";
import existingFileReducer, { ExistingFileActions, ExistingFileState } from "./ExistingFileReducer";
import newFileReducer, { NewFileActions, NewFileState } from "./NewFileReducer";
import { fileToDataUrl } from '~/utils/Utils';
import { UserContext, UserContextType } from '../../contexts/UserContext';

export type UseMultiFileFetchType = UseAsyncCallType & {
    addFiles: (files: File | File[]) => void
    changeFile: (fileId: string, file: File) => void
    deleteFile: (fileId: string) => void
    orderedFiles: {fileId: string, fileUrl: string | null}[]
    orderedFileIds: string[]
    setOrderedFileIds: (fileIds: string[]) => void
    uploadFiles: (fileType: string) => Promise<string[] | null | undefined>
}

//declare as constant to prevent looped state updates
const emptyObject = {};
const emptyFileIdArray: string[] = [];

const useMultiFileFetch = (fileIds: string[] = emptyFileIdArray, disabled: boolean, endpoint?: string): UseMultiFileFetchType => {

    const fetchFileUrl = useAsyncCall(true);

    const [ orderedFileIds, setOrderedFileIds ] = useState(fileIds);
    const [ existingFiles, dispatchExistingFiles ] = useReducer<(state: ExistingFileState, action: ActionType) => ExistingFileState>(existingFileReducer, {
        files: {}
    });
    const [ newFiles, dispatchNewFiles ] = useReducer<(state: NewFileState, action: ActionType) => NewFileState>(newFileReducer, {
        files: {}
    });
    const { organization } = useContext(UserContext) as UserContextType;


    //Fetch the existing files of any file ids passed in
    useEffect(() => {
        if (fileIds.length > 0) {
            fetchFileUrl.makeAsyncCall(Promise.all(fileIds.map((fileId) => FileActions.getFileUrl(fileId))))
              .then((res) => {

                  let existing: {[fileId: string]: string} = {};
                  for (let i = 0; i < fileIds.length; i++) {
                      const fileId = fileIds[i];
                      existing[fileId] = res[i];
                  }

                  dispatchExistingFiles({ type: ExistingFileActions.setFiles, payload: existing });
              })
              .catch((err) => {
                  console.error(err);
              });
        } else {
            dispatchExistingFiles({ type: ExistingFileActions.setFiles, payload: emptyObject });
        }
    }, [fileIds]);

    //if the user stops editing the file, reset any selected files
    useEffect(() => {
        if (disabled) {
            setOrderedFileIds(fileIds);
        }
    }, [fileIds, disabled]);

    /**
     * Function called to add multiple files
     *
     * @param files: the new files to be added
     */
    const addFiles = (files: File | File[]) => {
        const fileArr = Array.isArray(files) ? files : [files];
        Promise.all(fileArr.map((file) => fileToDataUrl(file)))
          .then((fileUrls) => {
              let fileIds = [];
              for (let i = 0; i < fileUrls.length; i++) {
                  const fileUrl = fileUrls[i];
                  if (fileUrl != null) {
                      const newFileId = uuidv4();
                      fileIds.push(newFileId);
                      dispatchNewFiles({ type: NewFileActions.setFile, payload: { fileId: newFileId, file: fileArr[i], fileUrl } });
                  }
              }
              setOrderedFileIds([...orderedFileIds, ...fileIds]);
          })
          .catch((e) => {
              console.error(e);
          });
    }

    /**
     * Function called to change a file
     *
     * @param fileId: the file id to be updated
     * @param file: the new file to replace the file id with
     */
    const changeFile = (fileId: string, file: File) => {
        fileToDataUrl(file)
          .then((fileUrl) => {
              const newFileId = uuidv4();
              dispatchNewFiles({ type: NewFileActions.setFile, payload: { fileId: newFileId, file, fileUrl } });
              const fileIdx = orderedFileIds.indexOf(fileId);

              //if the old file id is in the ordered array, replace it. Otherwise, add it to the end of the array
              if (fileIdx !== -1) {
                  setOrderedFileIds([...orderedFileIds.slice(0, fileIdx), newFileId, ...orderedFileIds.slice(fileIdx + 1)]);
              } else {
                  setOrderedFileIds([...orderedFileIds, newFileId]);
              }

              //delete the old local new file if it exists
              if (newFiles.files.hasOwnProperty(fileId)) {
                  dispatchNewFiles({ type: NewFileActions.deleteFile, payload: fileId });
              }
          })
          .catch((e) => {
              console.error(e);
          })
    }

    /**
     * Function called to delete a file
     *
     * @param fileId: the file to be deleted
     */
    const deleteFile = (fileId: string) => {

        //remove the file from the ordered list
        setOrderedFileIds(orderedFileIds.filter((orderedFile) => orderedFile !== fileId));

        //delete the local new file if it exists
        if (newFiles.files.hasOwnProperty(fileId)) {
            dispatchNewFiles({ type: NewFileActions.deleteFile, payload: fileId });
        }
    };

    /**
     * Function called to upload the newly selected files to the server
     *
     * @return a promise to upload the files
     */
    const uploadFiles = (fileType: string): Promise<string[] | null | undefined> => {

        if (endpoint == null) {
            return Promise.reject(Error('No endpoint specified for uploading these files.'));
        }

        if (organization == null) {
          return Promise.reject(Error('Missing organization id.'));
        }

        if (isEqual(fileIds, orderedFileIds)) { //if the lists are equal, do nothing
            return Promise.resolve(undefined);
        } else if (orderedFileIds.length === 0) { //if the list is empty, "delete" the old files
            return Promise.resolve(null);
        } else { //upload the new files if there are ones
            const filesToUpload: {idx: number, file: File}[] = [];
            for (let i = 0; i < orderedFileIds.length; i++) {
                const fileId = orderedFileIds[i];
                if (newFiles.files.hasOwnProperty(fileId)) {
                    const newFile = newFiles.files[fileId].file;
                    filesToUpload.push({idx: i, file: newFile});
                }
            }

            return Promise.all(filesToUpload.map((fileObj) => FileActions.uploadFile(fileObj.file, endpoint, organization._id, fileType)))
              .then((res) => {

                  //replace the temporary local file ids with the new file ids from the server
                  const newFileIds = [...orderedFileIds];
                  for (let i = 0; i < filesToUpload.length; i++) {
                      const fileToOverwrite = filesToUpload[i].idx;
                      newFileIds[fileToOverwrite] = res[i];
                  }
                  return newFileIds;
              });
        }

    };

    const orderedFiles: {fileId: string, fileUrl: string | null}[] = orderedFileIds.map((fileId) => {
        let fileUrl: string | null = null;
        if (existingFiles.files.hasOwnProperty(fileId)) {
            fileUrl = existingFiles.files[fileId];
        } else if (newFiles.files.hasOwnProperty(fileId)) {
            fileUrl = newFiles.files[fileId].fileUrl;
        }

        return { fileId, fileUrl };
    })

    return {
        ...fetchFileUrl,
        addFiles,
        changeFile,
        deleteFile,
        orderedFiles,
        orderedFileIds,
        setOrderedFileIds,
        uploadFiles,
    };
};

export default useMultiFileFetch;
