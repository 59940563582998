export const arcadiaStatuses: Record<string, string> = {
  OK: 'OK',
  PENDING: 'Pending',
  ACTION_REQUIRED: 'Action Required',
}

export const arcadiaStatusDetails: Record<string, string> = {
  ACCOUNT_LOCKED: 'Account Locked',
  E_BILL_REQUIRED: 'E-Bill Required',
  LOGIN_FAILURE: 'Login Failure',
  MULTI_FACTOR_AUTH_FAILURE: 'Multi-factor Auth Failure',
  NAVIGATION_FAILURE: 'Navigation Failure',
  NO_ACCOUNTS_FOUND: 'No Accounts Found',
  NO_ACTION_REQUIRED: 'No Action Required',
  SECURITY_QUESTIONS: 'Security Questions',
  STATEMENT_UNAVAILABLE: 'Statement Unavailable',
  UNDER_REVIEW: 'Under Review',
  UNSUPPORTED_CAPTCHA: 'Unsupported Captcha',
  USER_ACTION_REQUIRED: 'User Action Required',
  WEBSITE_DOWN: 'Provider Website Down',
  ACCOUNT_SETUP_REQUIRED: 'Account Setup Required',
}
