import GHGIActivityIcon from '~/components/GHGIActivityIcon'
import Select, { SelectOption } from '~/components/Select'
import { GHGIActivityData } from '~/views/DataImport/useDataImport'
import classNames from '~/utils/classNames'

interface Props {
  accountOptions: SelectOption[]
  activityType?: GHGIActivityData
  loading: boolean
  disabled: boolean
  selectedAccountOption?: SelectOption
  onChangeAccount: (option: SelectOption) => void
  onLoadMore: () => void
}

export function AccountSelector({
  accountOptions,
  activityType,
  loading = false,
  disabled = false,
  selectedAccountOption,
  onChangeAccount,
  onLoadMore,
}: Props) {
  return (
    <div
      className={classNames(
        "p-4 w-full flex justify-between bg-white border-b first:rounded-t-lg last:rounded-b-md last:border-b-none",
        !accountOptions.length ? 'hidden' : ''
      )}
    >
      <div className="flex flex-col flex-grow max-w-[75%] mr-2">
        {activityType && (
          <div className="flex items-center mb-2">
            <GHGIActivityIcon activityId={activityType.id} iconClass="text-xl mr-2 fill-gray-400" />
            <h3 className="font-bold text-lg">{activityType.label}</h3>
          </div>
        )}
        <p className="text-slate-500">Use the selector on the right to choose which account you want to view. Review and edit the asset and account details below.</p>
      </div>
      <Select
        loading={loading}
        disabled={disabled}
        options={accountOptions}
        selected={selectedAccountOption?.id}
        onChange={onChangeAccount}
        containerStyle="w-[120px]"
        triggerStyle="max-w-[200px] truncate"
        dropdownBottomElement={(
          <button
            type="button"
            className="w-full flex items-center justify-center underline text-accent focus:outline-none py-1 mt-1"
            onClick={onLoadMore}
          >
            <p>Load More</p>
          </button>
        )}
      />
    </div>
  )
}
