import { createElement } from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import { AiOutlineCheck, AiOutlineDown, AiOutlineUp } from 'react-icons/ai'
import classNames from '~/utils/classNames'
import { CgSpinnerTwo } from 'react-icons/cg'
import { IconType } from 'react-icons'

export interface SelectOption {
  id: string,
  name: string
  category?: string
}

interface Props {
  onChange: (option: SelectOption) => void,
  options: SelectOption[],
  selected?: string,
  placeholder?: string,
  disabled?: boolean,
  loading?: boolean,
  triggerStyle?: string,
  iconStyle?: string,
  containerStyle?: string,
  dropdownBottomElement?: JSX.Element,
  prefix?: JSX.Element,
  top?: boolean,
  iconLeft?: IconType,
}

/** Convert an array of items (usually strings) in to an array of SelectOption, sorted alphabetically. */
export function itemsToSelectOptions(arr: any[]) : SelectOption[] {
  const items: SelectOption[] = arr.map(item => ({ id: String(item), name: String(item) }))
  items.sort((a, b) => a.name.localeCompare(b.name))
  return items
}

export default function Select({
  onChange,
  options,
  selected,
  placeholder,
  disabled,
  loading,
  triggerStyle,
  iconStyle,
  containerStyle,
  dropdownBottomElement,
  prefix,
  top,
  iconLeft,
}: Props) {

  return (
    <RadixSelect.Root
      value={selected}
      onValueChange={(val) => {
        const option = options?.find((o) => o.id === val)
        if (option) onChange(option)
      }}
      disabled={disabled || loading}
    >
      <RadixSelect.Trigger
        className={classNames(
          triggerStyle,
          'h-fit flex justify-between items-center w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 focus:outline-none text-sm leading-6 border border-slate-200 disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        {iconLeft && (
          <RadixSelect.Icon
            className={classNames(
              'flex items-center mr-2 text-gray-300',
              iconStyle
            )}
          >
            {createElement(iconLeft, { className: 'text-md', 'aria-hidden': true })}
          </RadixSelect.Icon>
        )}
        {prefix}
        <RadixSelect.Value
          placeholder={placeholder || 'Select'}
        />
        <RadixSelect.Icon
          className={classNames(
            'flex items-center ml-2 text-gray-300',
            iconStyle
          )}
        >
          {loading && (
            <span className="animate-spin">
              <CgSpinnerTwo className="text-lg" />
            </span>
          )}
          {!loading && (
            <AiOutlineDown className="text-xs" aria-hidden="true" />
          )}
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content
          className={classNames(
            'z-50 max-h-60 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-fit',
            containerStyle
          )}
        >
          <RadixSelect.ScrollUpButton className="bg-white flex items-center justify-center">
            <AiOutlineUp className="text-slate-400" />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport>
            {options.map((option) => (
              <RadixSelect.Item
                key={option.id}
                value={option.id}
                className={classNames(
                  'text-gray-900 hover:bg-accent-dark hover:text-white group',
                  'flex items-center relative cursor-default select-none py-2 pl-3 pr-2'
                )}
              >
                <RadixSelect.ItemText
                  className={classNames(
                    option.id === selected ? 'font-semibold' : 'font-normal'
                  )}
                >
                  {option.name}
                </RadixSelect.ItemText>
                <RadixSelect.ItemIndicator className="ml-2">
                  <AiOutlineCheck className="group-hover:text-white text-accent-dark" />
                </RadixSelect.ItemIndicator>
              </RadixSelect.Item>
            ))}
            {dropdownBottomElement}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className="bg-white flex items-center justify-center">
            <AiOutlineDown className="text-slate-400" />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  )
}