import {
  MdOutlineAirplanemodeActive,
  MdOutlineDirectionsCar,
  MdOutlineLocalFireDepartment,
  MdDeleteOutline,
  MdCategory,
  MdCreditCard,
  MdOutlineEmojiTransportation,
  MdDomain,
  MdOutlineCarRental,
  MdOutlineStorefront,
  MdLightbulbOutline,
  MdOutlineRecycling,
  MdOutlineLocalShipping,
} from 'react-icons/md'
import {
  GiCargoCrane,
} from 'react-icons/gi'
import {
  BiDollar,
} from 'react-icons/bi'
import { CiBoxes } from 'react-icons/ci'

interface Props {
  categoryId: string,
  iconClass: string
}

export default function GHGIIcon({ categoryId, iconClass }: Props) {

  const categoryIcons: {[key: string]: JSX.Element} = {
    'business_travel': <MdOutlineAirplanemodeActive className={iconClass} />,
    'fuel_and_energy_related_activities': <MdOutlineLocalFireDepartment className={iconClass} />,
    'transportation_and_distribution_upstream': <MdOutlineLocalShipping className={iconClass} />,
    'employee_communting': <MdOutlineDirectionsCar className={iconClass} />,
    'waste_generated_in_operations': <MdDeleteOutline className={iconClass} />,
    'capital_goods': <GiCargoCrane className={iconClass} />,
    'purchased_goods_and_services': <MdCreditCard className={iconClass} />,
    'leased_assets_upstream': <MdOutlineEmojiTransportation className={iconClass} />,
    'company_facilities': <MdDomain className={iconClass} />,
    'company_vehicles': <MdOutlineCarRental className={iconClass} />,
    'investments': <BiDollar className={iconClass} />,
    'processing_of_sold_products': <CiBoxes className={iconClass} />,
    'franchises': <MdOutlineStorefront className={iconClass} />,
    'transportation_and_distribution_downstream': <MdOutlineLocalShipping className={iconClass} />,
    'use_of_sold_products': <MdLightbulbOutline className={iconClass} />,
    'end_of_life_treatment': <MdOutlineRecycling className={iconClass} />,
    'leased_assets_downstream': <MdOutlineEmojiTransportation className={iconClass} />,
    'default': <MdCategory className={iconClass} />,
  }
  return (
    <>
      {categoryIcons[categoryId] || categoryIcons['default']}
    </>
  )
}
