import { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'
// import AssetActions from '~/actions/AssetActions'
import { useAlertContext } from '~/contexts/AlertContext'
import { OrganizationType } from '~/contexts/UserContext'
// import { Account } from '~/types/Account'
import { Activity, PartialActivity } from '~/types/Activity'
// import { Asset, PartialAsset } from '~/types/Asset'
import { GHGIActivityData, GHGICategory } from '~/views/DataImport/useDataImport'
// import { AccountActions } from '~/actions/AccountActions'
import { ActivityActions } from '~/actions/ActivityActions'
import FileActions from '~/actions/FileActions'
import { File } from '~/types/File'
import { getArcadiaCredentialsByOrg } from '~/actions/ArcadiaActions'
import { ArcadiaCredential } from '~/types/Arcadia'
import { getCustomerAsset, getCustomerAssets, getCustomerLocationAccounts, updateCustomerAsset } from '~/actions/CustomerActions'
import type { CustomerAssetType, CustomerLocationAccountType } from '~/types/entities'
// import AssetActions from '~/actions/AssetActions'

interface Props {
  ghgiCategories: GHGICategory[]
  organization?: OrganizationType
}

export function useEmissionsPortfolioDashboard({
  ghgiCategories,
  organization,
}: Props) {
  const [activityTypes, setActivityTypes] = useState<GHGIActivityData[]>([])
  const [customerAssets, setCustomerAssets] = useState<CustomerAssetType[]>([])
  const [loadingAssets, setLoadingAssets] = useState<boolean>(true)
  const [selectedAsset, setSelectedAsset] = useState<CustomerAssetType>()
  const [selectedActivity, setSelectedActivity] = useState<any>(null)
  const ghgiCategory = useGhgiCategory()
  const [latestActivity, setLatestActivity] = useState<Activity>()
  const [accounts, setAccounts] = useState<CustomerLocationAccountType[]>([])
  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(false)
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([])
  const [selectedAccount, setSelectedAccount] = useState<CustomerLocationAccountType>()
  const [editing, setEditing] = useState<boolean>(false)
  const [loadingCredentials, setLoadingCredentials] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<ArcadiaCredential[]>([])
  const { showAlert } = useAlertContext()

  useEffect(() => {
    if (selectedAccount?.customerAsset) {
      fetchAsset(selectedAccount.customerAsset as number)
    }
  }, [selectedAccount])

  useEffect(() => {
    const selectedCategory = ghgiCategories.find((category: GHGICategory) => category.id === ghgiCategory)
    setActivityTypes(selectedCategory?.activities || [])
    getFilesInPeriod()
    getCredentialsInPeriod()
  }, [ghgiCategory, ghgiCategories, organization])

  useEffect(() => {
    if (selectedAccount && selectedAccount?.ghgActivity !== selectedActivity?.id) {
      setSelectedAccount(undefined)
    }
  }, [organization, selectedActivity])

  useEffect(() => {
    if (organization?._id) {
      searchAssets('', organization._id, undefined)
    }
  }, [organization, ghgiCategory])

  useEffect(() => {
    searchAccounts()
  }, [selectedAsset, selectedActivity, organization, ghgiCategory])

  useEffect(() => {
    if (!selectedAsset && selectedActivity) {
      setSelectedActivity(undefined)
    }
  }, [selectedAsset])

  useEffect(() => {
    // TODO: Why is this erroring? Do we even need this anymore when moving from mongodb to mysql?
    /*if (selectedAccount?.accountNumber) {
      searchLatestActivity()
    }*/
    if (selectedAccount && selectedAccount?.id && typeof selectedAccount?.id === 'object') {
      /*const assetExistsInList = assets.find((asset: Asset) => asset._id === selectedAccount.assetId?._id)
      if (!assetExistsInList) {
        setAssets([...assets, selectedAccount.assetId])
      }*/
      setSelectedAsset(selectedAccount.id)
    }
    if (!selectedActivity) {
      const targetActivity = activityTypes.find((activity: GHGIActivityData) => activity.id === String(selectedAccount?.ghgActivity))
      if (targetActivity) {
        setSelectedActivity(targetActivity)
      }
    }
  }, [selectedAccount])

  const onActivitySelect = (activity: any) => {
    setSelectedActivity(activity)
  }

  const searchAssets = async (name: string, organizationId: string, activityTypeId?: string, loadMore: boolean = false) => {
    try {
      setLoadingAssets(true)

      const returnedAssets = await getCustomerAssets({
        organizationId: organization!._id,
        ghgiCategory,
        activityType: activityTypeId,
        name,
      })

      setCustomerAssets(returnedAssets)

      /*const limit = 10
      let skip = 0
      if (loadMore) {
        skip = assets.length
      }
      const { assets: returnedAssets } = await AssetActions.search(name, organizationId, ghgiCategory, activityTypeId, skip, limit)
      if (loadMore) {
        const uniqueAssets = returnedAssets.filter((asset: Asset) => !assets.find((existingAsset: Asset) => existingAsset._id === asset._id))
        setAssets([...assets, ...uniqueAssets])
      }
      else {
        setAssets(returnedAssets)
      }*/
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem searching for assets',
        message: error.message,
      })
    }
    finally {
      setLoadingAssets(false)
    }
  }

  const searchAccounts = async (loadMore: boolean = false, accountTypeId?: string) => {
    if (!ghgiCategory || !organization?._id) return

    try {
      setLoadingAccounts(true)
      /*const limit = 30
      let skip = 0
      if (loadMore) {
        skip = accounts.length
      }
      let activities = activityTypes.map((activity: GHGIActivityData) => activity.id)
      if (selectedActivity) {
        activities = [selectedActivity.id]
      }
      if (accountTypeId) {
        activities = [accountTypeId]
      }*/
      const accountsResponse = await getCustomerLocationAccounts(
        //organization._id,
        ghgiCategory,
        organization._id,
        selectedAsset?.id,
        //activities,
        //skip,
        //limit
      )
      let newAccounts = accountsResponse
      /*if (loadMore) {
        const uniqueAccounts = accountsResponse.filter((newAccount: Account) => !accounts.find((existingAccount: Account) => newAccount._id === existingAccount._id))
        newAccounts = [...accounts, ...uniqueAccounts]
      }*/
      setAccounts(newAccounts)
      const includesCurrentSelected = newAccounts.find((account: CustomerLocationAccountType) => account.id === selectedAccount?.id)
      if (!Boolean(includesCurrentSelected)) {
        setSelectedAccount(undefined)
      }
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem searching for accounts',
        message: error.message,
      })
    }
    finally {
      setLoadingAccounts(false)
    }
  }

  /*const searchLatestActivity = async () => {
    if (!selectedAccount?.id) return

    try {
      const activities = await ActivityActions.searchLatestByAccount(String(selectedAccount?.id))
      setLatestActivity(activities[0])
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem searching for the latest activity',
        message: error.message,
      })
    }
  }*/

  const fetchAsset = async (assetId: number) => {
    try {
      const asset = await getCustomerAsset(assetId)
      setSelectedAsset(asset)
    }
    catch (error: any) {
      showAlert({
        message: error.message,
        type: 'error',
        title: 'Error getting asset',
      })
    }
  }

  const getFilesInPeriod = async () => {
    try {
      setLoadingFiles(true)
      const unixStart = moment().subtract(1, 'months').unix()
      const unixEnd = moment().unix()
      if (!organization?._id)
        return
      const { documents } = await FileActions.getFilesInPeriod(organization._id, ghgiCategory, unixStart, unixEnd)
      setFiles(documents)
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        message: error.message,
        type: 'error',
        title: 'Error',
      })
    }
    finally {
      setLoadingFiles(false)
    }
  }

  const getCredentialsInPeriod = async () => {
    if (!organization?._id) return

    try {
      setLoadingCredentials(true)
      const lastMonth = moment().subtract(1, 'months').unix()
      const response = await getArcadiaCredentialsByOrg(organization._id)
      const recentCredentials = response.filter((cred: ArcadiaCredential) => moment(cred.createdDate).unix() > lastMonth)
      setCredentials(recentCredentials)
    }
    catch (error: any) {
      console.error(error)
      showAlert({
        message: error.message,
        type: 'error',
        title: 'Error',
      })
    }
    finally {
      setLoadingCredentials(false)
    }
  }

  const updateAsset = async (assetId: string, update: Partial<CustomerAssetType>) => {
    // TODO: v2 edit assets
    try {
      const newAsset = await updateCustomerAsset(organization!._id, ghgiCategory, assetId, update)
      const updatedAssets = customerAssets.map((asset: CustomerAssetType) => asset.id === newAsset.id ? newAsset : asset)
      setCustomerAssets(updatedAssets)
      if (selectedAsset?.id === newAsset.id) {
        setSelectedAsset(newAsset)
      }
      showAlert({
        variant: 'success',
        title: 'Asset updated',
        message: 'The asset was updated successfully',
      })
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem updating the asset',
        message: error.message,
      })
    }
  }

  const updateAccount = async (update: any) => {
    // TODO: v2 edit assets
    /*try {
      const newAccount = await AccountActions.updateAccount(update)
      const updatedAccounts = accounts.map((account: Account) => account._id === newAccount._id ? newAccount : account)
      setAccounts(updatedAccounts)
      if (selectedAccount?._id === newAccount._id) {
        setSelectedAccount(newAccount)
      }
      showAlert({
        variant: 'success',
        title: 'Account updated',
        message: 'The account was updated successfully',
      })
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem updating the account',
        message: error.message,
      })
    }*/
  }

  const updateActivity = async (update: PartialActivity) => {
    try {
      const newActivity = await ActivityActions.updateActivity(update, selectedActivity.id)
      setLatestActivity(newActivity)
      showAlert({
        variant: 'success',
        title: 'Activity updated',
        message: 'The activity was updated successfully',
      })
    }
    catch (error: any) {
      showAlert({
        variant: 'error',
        title: 'There was a problem updating the activity',
        message: error.message,
      })
    }
  }

  return {
    activityTypes,
    selectedActivity,
    onActivitySelect,
    searchAssets,
    customerAssets,
    loadingAssets,
    selectedAsset,
    setSelectedAsset,
    latestActivity,
    accounts,
    loadingAccounts,
    selectedAccount,
    setSelectedAccount,
    updateAsset,
    updateAccount,
    updateActivity,
    loadingFiles,
    files,
    searchAccounts,
    editing,
    setEditing,
    loadingCredentials,
    credentials,
  }
}
