import { Action, applyMiddleware, createStore } from 'redux';
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter, useHistory } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import App from "~/App";
import * as serviceWorker from "./serviceWorker";
import Env from '~/utils/Env';
import { middleware } from '~/utils/ActionTypes';
import RootReducer, { ApplicationState } from '~/reducers/RootReducer';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Constants from '~/utils/Constants';

const theme = createTheme({
  palette: {
    primary: {
      main: Constants.primaryColor,
      dark: Constants.primaryColorDark,
      light: Constants.primaryColorLight,
    },
    secondary: {
      main: Constants.secondaryColor,
      dark: Constants.secondaryColorDark,
      light: Constants.secondaryColorLight,
    },
    error: {
      main: Constants.errorColor,
    },
  },
});

export const store = createStore<ApplicationState, Action, {}, {}>(RootReducer, applyMiddleware(...middleware));

const Auth0Wrapper = () => {

  const history = useHistory();

  return (
    <Auth0Provider
      domain={Env.AUTH0_DOMAIN}
      clientId={Env.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: Env.AUTH0_AUDIENCE,
      }}
      onRedirectCallback={(appState) => {
        history.push(
          appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname
        );
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Auth0Wrapper />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
