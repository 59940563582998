import { ArcadiaCredential } from '~/types/Arcadia'
import { 
  Endpoints,
  GET,
  PATCH,
  DELETE,
} from '~/utils/API'
import moment from 'moment-timezone'

export const statusPriority: Record<string, number> = {
  ACTION_REQUIRED: 0,
  PENDING: 1,
  OK: 2,
}

export function sortCredentials(a: ArcadiaCredential, b: ArcadiaCredential) {
  if (a.status !== b.status) return statusPriority[a.status] - statusPriority[b.status]

  return a.providerName.localeCompare(b.providerName)
}

export function sortCredentialsByDate(a: ArcadiaCredential, b: ArcadiaCredential) {
  if (a.status !== b.status) return statusPriority[a.status] - statusPriority[b.status]

  return moment(b.lastModifiedDate).diff(moment(a.lastModifiedDate))
}

export async function getArcadiaCredential(entityId: string) {
  const credential = await GET({
    endpoint: Endpoints.arcadia.credential.get,
    queryParameters: {
      entityId,
    }
  })
  return credential
}

export async function getArcadiaCredentialsByOrg(organizationId: string) {
  const result = await GET({
    endpoint: Endpoints.arcadia.credentials.get,
    queryParameters: {
      organizationId,
    }
  })

  const credentials = result.credentials as ArcadiaCredential[]

  credentials.sort(sortCredentials)

  return credentials
}

export async function deleteArcadiaCredential(credentialId: string) {
  const result = await DELETE({
    endpoint: Endpoints.arcadia.credential.delete,
    queryParameters: { credentialId },
  })
  return result
}

export async function updateArcadiaCredential(credentialId: string, userName: string, password: string) {
  const result = await PATCH({
    endpoint: Endpoints.arcadia.credential.patch,
    body: {
      credentialId,
      userName,
      password,
    },
  })
  return result
}
