import moment, { Moment } from "moment-timezone";
import isString from 'lodash/isString';

import Name from '../types/Name';

export default class StringFormatters {

    /**
     * Function called to format a size of memory
     *
     * @param bytes: the bytes to be formatted
     * @param decimals: the number of decimal places to show
     */
    static formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Function called to format an ISO date string YYYY-MM-DD to MM/DD/YYYY
     *
     * @param isoDateString: the string to be formatted
     */
    static formatDateString(isoDateString: string): string {
        const [year, month, day] = isoDateString.split('-');
        return `${month}/${day}/${year}`;
    }

    /**
     * Function call to format general date-times
     *
     * @param val
     * @param showDate
     * @param showTime
     * @param tz
     */
    static formatDateTime(val?: Moment, showDate: boolean = true, showTime: boolean = true, tz: string = moment.tz.guess(true)): string {

        if (val == null) {
            return '';
        }

        let formatter = [];
        if (showDate) {
            formatter.push('MM/DD/YYYY');
        }

        if (showTime) {
            formatter.push('hh:mm A (z)');
        }

        return val.tz(tz).format(formatter.join(' '));
    }

    /**
     * Function called to format a number with a minimum and maximum number of decimal places
     *
     * @param value: the value to be formatted
     * @param minPrecision: the minimum number of decimal places the number can have
     * @param maxPrecision: the maximum number of decimal places the number can have
     * @returns {*}: the newly formatted number
     */
    static formatDecimal(value: number, minPrecision: number, maxPrecision: number) {
        const out = parseFloat((value).toFixed(maxPrecision));
        if (isNaN(out) || out === null)
            return "?";

        let decimalCount = Number.isInteger(out) ? 0 : out.toString().split(".")[1].length || 0;
        if (decimalCount < minPrecision)
            return out.toFixed(minPrecision);
        else
            return out;

    }

    /**
     * Function called to format a duration of time
     *
     * @param millis: the duration of time to format in milliseconds
     */
    static formatDuration(millis: number) {
        const hours = Math.floor(millis / (1000 * 60 * 60));
        const minMillis = (millis - (hours * (1000 * 60 * 60)));
        const mmnt = moment.utc(minMillis);

        let hoursText = '';
        if (hours > 0) {
            hoursText = `${hours} hr `;
        }

        return `${hoursText}${mmnt.format("mm [min]")}`;
    }

    /**
     * Function called to format a duration of time
     *
     * @param millis: the duration of time to format in milliseconds
     */
    static formatShortDuration(millis: number) {
        const hours = Math.floor(millis / (1000 * 60 * 60));
        const minMillis = (millis - (hours * (1000 * 60 * 60)));
        const mmnt = moment.utc(minMillis);

        if (hours >= 24) {
            return '> 1 day'
        }

        let hoursText = '';
        if (hours > 0) {
            hoursText = `${hours}h `;
        }

        return `${hoursText}${mmnt.format("mm[m]")}`;
    }

    /**
     * Function called to format a dollars value
     *
     * @param val: the value to be formatted (in cents)
     * @param numDecimals: the number of decimals to display in the format
     * @param naText: a the string to be shown if the value could not be formatted
     * @param isAbs: a boolean determining whether or not an absolute value should be applied
     * @param withDollarSign: a boolean stating whether or not to add a dollar sign to the format
     * @returns {string}: a string that represents the position in proper formatting
     */
    static formatDollars(val: string | number | null | undefined, numDecimals = 2, naText: string = 'N/A', isAbs = false, withDollarSign = true): string {

        let value: number | null = isString(val) ? parseFloat(val) : val ?? null;
        if (value != null) value = value / 100;

        if (value == null || isNaN(value)) {
            return naText;
        }

        if (isAbs) {
            return StringFormatters.numberWithCommas(`${withDollarSign ? '$' : ''}${Math.abs(value).toFixed(numDecimals).toString()}`);
        } else {
            return StringFormatters.numberWithCommas(`${withDollarSign ? '$' : ''}${value.toFixed(numDecimals).toString()}`);
        }
    }

    /**
     * Function called to format a number in dollars in thousands or millions
     *
     * @param value: the number to be formatted (in cents)
     * @param withDollarSign: a boolean stating whether or not to add a dollar sign to the format
     * @param precision: the number of decimals needed at a minimum
     * @param maxPrecision: the number of decimals at a maximum
     * @param naText: a the string to be shown if the value could not be formatted
     */
    static formatDollarsInMillionsOrThousands(value: number | null | undefined, withDollarSign: boolean = true, precision = 0, maxPrecision: number | null = null, naText: string = '') {

        let out = Number(value);

        if (maxPrecision == null) {
            maxPrecision = precision;
        }

        if (value == null || isNaN(out)) {
            return naText;
        } else {
            out = out / 100;
            if(out > 999999) {
                return `${withDollarSign ? '$' : ''}${StringFormatters.formatDecimal(out / 1000000, precision, maxPrecision)}M`;
            } else if (out > 999) {
                return `${withDollarSign ? '$' : ''}${StringFormatters.formatDecimal(out / 1000, precision, maxPrecision)}K`;
            }
        }

        return StringFormatters.formatDollars(out, 0, undefined, undefined, withDollarSign);
    }

    /**
     * Function called to format a full name
     *
     * @param name: the name to be formatted
     */
    static formatLongName(name?: Name): string {
        return `${name?.firstName ?? ""}${name?.middleName ? ' ' + name?.middleName : ''} ${name?.lastName ?? ""}${name?.suffix ? ' ' + name?.suffix : ''}`.trim();
    }

    /**
     * Function called to format a phone number
     *
     * @param phoneNumber: the phone number to be formatted
     * @param withCountryCode: whether or not to show the country code of the number
     */
    static formatPhoneNumber(phoneNumber: string, withCountryCode: boolean = false): string {
        const countryCode = (phoneNumber.length > 10) ? phoneNumber.slice(0, -10) : '';
        const cleanPhoneNumber = (phoneNumber.length > 10) ? phoneNumber.slice(-10) : phoneNumber;
        const mask = `${withCountryCode ? countryCode : ''} (XXX) XXX-XXXX`;

        if (cleanPhoneNumber.length < 10) {
            return cleanPhoneNumber;
        }

        let result = "";
        let index = 0;
        for (let i = 0; i < mask.length; i++) {
            const ch = mask[i];
            if (ch === 'X') {
                result += cleanPhoneNumber[index];
                index++;
            } else {
                result += ch;
            }
        }
        return result.trim();
    }

    /**
     * Function called to get a clean formatted phone number +1XXXXXXXXXX
     *
     * @param phoneNumber: the phone number to be clean formatted
     * @param withCountryCode: a boolean stating whether or not the country code should be included
     */
    static formatPhoneNumberPlain(phoneNumber: string, withCountryCode: boolean = true): string {
        const digitsOnly = phoneNumber.replace(/\D/g,'');
        let countryCode = (digitsOnly.length > 10) ? digitsOnly.slice(0, -10) : '';
        const cleanPhoneNumber = (digitsOnly.length > 10) ? digitsOnly.slice(-10) : digitsOnly;

        if (countryCode === '') {
            countryCode = '1';
        }

        return `${withCountryCode ? `+${countryCode}` : ''}${cleanPhoneNumber}`;
    }

    /**
     * Function called to format a range of numbers
     *
     * @param minVal: the min number in the range
     * @param maxVal: the max number in the range
     */
    static formatRange(minVal: string | number | null, maxVal: string | number | null, formatNum: (val: number) => string = (val) => `${val}`) {

        let min: number | null = isString(minVal) ? parseFloat(minVal) : minVal;
        if (min != null && isNaN(min)) {
            min = null;
        }

        let max: number | null = isString(maxVal) ? parseFloat(maxVal) : maxVal;
        if (max != null && isNaN(max)) {
            max = null;
        }

        if (min != null && max != null) {
            if (min > max) {
                return ``;
            } else {
                return `${formatNum(min)}-${formatNum(max)}`;
            }
        } else if (min != null) {
            return `> ${formatNum(min)}`
        } else if (max != null) {
            return `< ${formatNum(max)}`;
        } else {
            return ``;
        }
    }

    /**
     * Function called to format a name just by first name last name
     *
     * @param name: the name to be formatted
     */
    static formatShortName(name?: Name): string {
        return `${name?.firstName ?? ""} ${name?.lastName ?? ""}`.trim();
    }

    /**
     * Function called to format a string by upper casing the first letter of each word
     *
     * @param str: the string to be formatted
     */
    static formatUpperCaseWords(str: string): string {
        return str.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    /**
     * Function called to format a url
     *
     * @param url: the url to be formatted
     */
    static formatUrl(url: string): string {
        return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").replace(/[/]?$/i, "");
    }

    /**
     * Helper Function to take a number string and add commas to it
     *
     * @param val: the number string to add commas to
     * @return {string}: the string representing the same number with commas in it
     */
    static numberWithCommas(val: string | number): string {
        let parts = `${val}`.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    };

    /**
     * Function called to format an optional value
     *
     * @param value: the value to be formatted
     * @param defaultString: the string to be shown when the value does not exist, defaults to N/A
     */
    static optional(value?: any, defaultString: string = ''): any {
        if (value != null) {
            return value;
        }

        return defaultString;
    }
}

export function formatDateSid(dateSid: string) {
    return `${dateSid.substring(0, 4)}/${dateSid.substring(4, 6)}/${dateSid.substring(6)}`
}
