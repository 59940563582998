import { getActivityReport } from '~/actions/ReportsActions'
import { type FactBillingType } from '~/types/entities/FactBilling'
import { AuditReport } from './AuditReport'
import { formatDateSid } from '~/utils/StringFormatters'

const tableColumns: {header: string, accessorKey: string}[] = [
  { header: 'Scope', accessorKey: 'scope' },
  { header: 'Category', accessorKey: 'category' },
  { header: 'Asset', accessorKey: 'asset' },
  { header: 'Account Number', accessorKey: 'accountNumber' },
  { header: 'Activity', accessorKey: 'activityType' },
  { header: 'Begin Date', accessorKey: 'beginDate' },
  { header: 'End Date', accessorKey: 'endDate' },
  { header: 'Usage Amount', accessorKey: 'usageAmount' },
  { header: 'Emissions Factor', accessorKey: 'emissionsFactor' },
  { header: 'Emissions Factor Unit', accessorKey: 'emissionsFactorUnit' },
  { header: 'Emissions Amount (MTCO2)', accessorKey: 'emissionsAmount' },
  { header: 'e-factor ref', accessorKey: 'efRef' },
  { header: 'Carbon Factor', accessorKey: 'carbonFactor' },
  { header: 'Methane Factor', accessorKey: 'methaneFactor' },
  { header: 'Nitrous Factor', accessorKey: 'nitrousFactor' },
  { header: 'Carbon GWP', accessorKey: 'carbonGWP' },
  { header: 'Methane GWP', accessorKey: 'methaneGWP' },
  { header: 'Nitrous GWP', accessorKey: 'nitrousGWP' },
]

const filterList = [
  'category',
  'asset',
  'activityType',
]

type ActivityTableItem = {
  scope: string
  category: string
  asset: string
  accountNumber: string
  activityType: string
  beginDate: string
  endDate: string
  usageAmount: string
  emissionsFactor: string
  emissionsFactorUnit: string
  emissionsAmount: string
  efRef: string
  carbonFactor: string
  methaneFactor: string
  nitrousFactor: string
  carbonGWP: string
  methaneGWP: string
  nitrousGWP: string
}

const mapReportsToTableRows = (item: FactBillingType) => ({
  scope: String(item.scopeId),
  category: item.customerLocationAccount?.ghgActivity?.activity,
  asset: item.customerAsset?.nickName,
  accountNumber: item.customerLocationAccount?.accountNumber,
  activityType: item.resource?.resource,
  beginDate: formatDateSid(String(item.beginDate)),
  endDate: formatDateSid(String(item.endDate)),
  usageAmount: item.sumDailyAvgAmt,
  emissionsFactor: item.emissionsFactorAmount,
  emissionsFactorUnit: item.emissionsFactorUnit,
  emissionsAmount: item.sumDailyAvgEmissions,
  efRef: item.emissionsFactorRef,
  carbonFactor: item.emissionsFactor?.co2,
  methaneFactor: item.emissionsFactor?.ch4,
  nitrousFactor: item.emissionsFactor?.n2o,
  carbonGWP: item.emissionsFactor?.GWP?.co2,
  methaneGWP: item.emissionsFactor?.GWP?.ch4,
  nitrousGWP: item.emissionsFactor?.GWP?.n2o,
})

export function ActivityAuditReport() {
  return <AuditReport<ActivityTableItem>
    reportName="Activity Audit Report"
    filterList={filterList}
    tableColumns={tableColumns}
    fetchReport={getActivityReport}
    mapReportsToTableRows={mapReportsToTableRows}
  />
}
