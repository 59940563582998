import { SnackbarDisplayType } from "../components/generics/Snackbar";
import SharedActions from "../utils/ActionTypes";

export default class NetworkActions {

    /**
     * Function to allow for error type to be options, defaults to error
     *
     * @param val: the new value of error
     */
    static setGlobalError(val: {message: string, type?: SnackbarDisplayType} | null) {
        if (val === null) {
            return {type: SharedActions.network.setGlobalError, payload: null};
        } else {
            const newType = val.type ? val.type : SnackbarDisplayType.error;
            return {type: SharedActions.network.setGlobalError, payload: { message: val.message, type: newType }};
        }
    }

    /**
     * Function called to get the initial state
     */
    static fetchInit() {

    }

}