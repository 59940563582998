import React from 'react'
import classNames from '~/utils/classNames'

interface Option {
  label: string
  value: string
}

interface Props {
  containerStyle?: string
  options: Option[]
  selected: string
  onChange: (option: Option) => void
}

export default function Toggle({ containerStyle, options, selected, onChange }: Props) {
  return (
    <div className={classNames(`border rounded-md w-fit flex shadow-sm`, containerStyle)}>
      {
        options.map((option, index) => (
          <div
            key={option.value}
            onClick={() => onChange(option)}
            className={classNames(
              'px-4 py-2 text-sm rounded-md cursor-pointer',
              `w-[${100 / options.length}%]`,
              index === 0 ? 'rounded-l-md' : '',
              index === options.length - 1 ? 'rounded-r-md' : '',
              selected === option.value ? 'bg-accent text-white' : 'text-slate-500 hover:bg-slate-100'
            )}
          >
            {option.label}
          </div>
        ))
      }
    </div>
  )
}
