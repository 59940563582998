import { GET, Endpoints, POST } from "../utils/API";

export class UtilityActions {
  public static async getUtilityProviders(search: string="") {
    const response = await GET({
      endpoint: Endpoints.auth.utlities.getProviders,
      queryParameters: {
        search
      }
    })
    return response
  }

  public static async submitCredentials(username: string,  password: string,  providerId: string,  organizationId: string) {
    const response = await POST({
      endpoint: Endpoints.auth.utlities.submitCredentials,
      body: {
        username,
        password,
        providerId,
        organizationId
      },
    })

    return response
  }
}