import { ButtonProps } from "@material-ui/core/Button";
import { ValidatedTextfieldProps } from "../components/generics/inputs/ValidatedTextfield";

export default class Constants {

    static isProd = process.env.NODE_ENV === "production";

    static primaryColor = '#414B50';
    static primaryColorDark = '#2d3438';
    static primaryColorLight = '#676f73';

    static secondaryColor = '#71BE44';
    static secondaryColorDark = '#4f852f';
    static secondaryColorLight = '#8dcb69';

    static errorColor = '#e54949';
    static infoColor = '#1976d2';
    static successColor = '#42c860';
    static warningColor = '#ffa000';

    static editButtonProps: ButtonProps = {
        type: "submit",
        fullWidth: true,
        variant: "contained",
    };

    static formTextfieldProps: ValidatedTextfieldProps = {
        variant: 'filled',
        margin: 'dense',
        fullWidth: true,
    };

}
