import React from 'react'
import VisitorLayout from '../../components/VisitorLayout'
import { AiOutlineCheck, AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai'
import { usePlatformHealth } from './usePlatformHealth'
import { CgSpinnerTwo } from 'react-icons/cg'

export default function PlatfomHealth() {
  const {
    loading,
    status,
    authenticationStatus,
    databaseStatus,
    webAppStatus,
    webServerStatus,
    dashboardsStatus,
  } = usePlatformHealth()
  return (
    <VisitorLayout>
      <div className="items-center flex flex-col min-w-2xl py-10">
        <h2 className="text-xl  font-bold text-slate-900">
          Platform Health
        </h2>
        <p className="w-[400px] md:w-[500px] text-center mt-6 text-slate-700">
          Keep track of the platform's health and status.
        </p>
        {loading && <div className="mt-4 rounded-md bg-gray-200 h-10 w-96 animate-pulse" />}
        {!loading && status && (
          <div className=" mt-4 border-2 border-green-400 rounded-md py-2 px-4 flex items-center justify-center font-semibold text-green-500">
            <AiOutlineCheck className="text-green-500 text-xl mr-2" />
            {status === 'ok' && 'Platform is healthy and operational'}
            {status === 'degraded' && 'Platform is degraded and may be experiencing issues'}
            {status === 'down' && 'Platform is down and experiencing issues'}
          </div>
        )}
        <div className="flex flex-col mt-5 w-full">
          <div className="bg-white border shadow-sm p-4 rounded-md mb-3 w-full">
            <div className="flex items-center">
              {loading && <CgSpinnerTwo className="text-slate-400 animate-spin text-xl" />}
              {!loading && authenticationStatus?.healthy && <AiOutlineCheckCircle className="text-green-500 text-xl" />}
              {!loading && !authenticationStatus?.healthy && <AiOutlineWarning className="text-yellow-500 text-xl" />}
              <h3 className="font-bold ml-2">Authentication</h3>
            </div>
            <p className="text-slate-400 mt-2">The authentication service handles authentication and authorization for all users to gain access to the web application</p>
          </div>
          <div className="bg-white border shadow-sm p-4 rounded-md mb-3 w-full">
            <div className="flex items-center">
              {loading && <CgSpinnerTwo className="text-slate-400 animate-spin text-xl" />}
              {!loading && databaseStatus?.healthy && <AiOutlineCheckCircle className="text-green-500 text-xl" />}
              {!loading && !databaseStatus?.healthy && <AiOutlineWarning className="text-yellow-500 text-xl" />}
              <h3 className="font-bold ml-2">Database</h3>
            </div>
            <p className="text-slate-400 mt-2">The database stores and serves all the information associated to accounts, activities, as well as some precalculated emission data</p>
          </div>
          <div className="bg-white border shadow-sm p-4 rounded-md mb-3 w-full">
            <div className="flex items-center">
              {loading && <CgSpinnerTwo className="text-slate-400 animate-spin text-xl" />}
              {!loading && webAppStatus?.healthy && <AiOutlineCheckCircle className="text-green-500 text-xl" />}
              {!loading && !webAppStatus?.healthy && <AiOutlineWarning className="text-yellow-500 text-xl" />}
              <h3 className="font-bold ml-2">Web Application</h3>
            </div>
            <p className="text-slate-400 mt-2">The web applicaiton is the app users access, to upload information and get reporting on their emissions and activity</p>
          </div>
          <div className="bg-white border shadow-sm p-4 rounded-md mb-3 w-full">
            <div className="flex items-center">
              {loading && <CgSpinnerTwo className="text-slate-400 animate-spin text-xl" />}
              {!loading && webServerStatus?.healthy && <AiOutlineCheckCircle className="text-green-500 text-xl" />}
              {!loading && !webServerStatus?.healthy && <AiOutlineWarning className="text-yellow-500 text-xl" />}
              <h3 className="font-bold ml-2">Web Server</h3>
            </div>
            <p className="text-slate-400 mt-2">The web server handles the logic to communicate the web application to the databases and creates the calculations to determine emissions based on the activity</p>
          </div>
          <div className="bg-white border shadow-sm p-4 rounded-md mb-3 w-full">
            <div className="flex items-center">
              {loading && <CgSpinnerTwo className="text-slate-400 animate-spin text-xl" />}
              {!loading && dashboardsStatus?.healthy && <AiOutlineCheckCircle className="text-green-500 text-xl" />}
              {!loading && !dashboardsStatus?.healthy && <AiOutlineWarning className="text-yellow-500 text-xl" />}
              <h3 className="font-bold ml-2">Dashboards</h3>
            </div>
            <p className="text-slate-400 mt-2">The dashboards are the visualizations of the data that is uploaded to the platform</p>
          </div>
        </div>
      </div>
    </VisitorLayout>
  )
}
