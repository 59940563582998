import { combineReducers } from "redux";
import { ActionType } from "../utils/ActionTypes";
import commitmentsReducer, { CommitmentState } from './CommitmentsReducer';
import dashboardReducer, { DashboardState } from './DashboardReducer';
import documentsReducer, { DocumentsState } from './DocumentsReducer';
import focusAreaReducer, { FocusAreaState } from './FocusAreaReducer';
import forecastReducer, { ForecastState } from './ForecastReducer';
import goalReducer, { GoalState } from './GoalReducer';
import initiativeReducer, { InitiativeState } from './InitiativeReducer';
import networkReducer, { NetworkState } from './NetworkReducer';
import planReducer, { PlanState } from './PlanReducer';
import projectReducer, { ProjectState } from './ProjectReducer';
import strategyReducer, { StrategyState } from './StrategyReducer';
import userReducer, { UserState } from './UserReducer';


export interface ApplicationState {
  commitments: CommitmentState
  dashboards: DashboardState
  documents: DocumentsState
  focusArea: FocusAreaState
  forecasts: ForecastState
  goal: GoalState
  initiative: InitiativeState
  network: NetworkState
  plan: PlanState
  project: ProjectState
  strategy: StrategyState
  user: UserState
}

const combinedReducer = combineReducers<ApplicationState>({
  commitments: commitmentsReducer,
  dashboards: dashboardReducer,
  documents: documentsReducer,
  focusArea: focusAreaReducer,
  forecasts: forecastReducer,
  goal: goalReducer,
  initiative: initiativeReducer,
  network: networkReducer,
  plan: planReducer,
  project: projectReducer,
  strategy: strategyReducer,
  user: userReducer,
});

export default function rootReducer(
  state: ApplicationState | undefined,
  action: any
): ApplicationState {
  let newState = combinedReducer(state, action);

  //Handle actions that require data from more than 1 reducer
  const typedAction = (action as ActionType);
  switch (typedAction.type) {
    default: {
      return newState;
    }
  }
}