import FileData from '../types/FileData';
import SharedActions, { ActionType } from '../utils/ActionTypes';

export type DocumentsState = {
  documentsTableIds: string[]
  documents: { [key: string]: FileData }
}

const initialState = {
  documentsTableIds: [],
  documents: {},
};

export default function documentsReducer(
  state: DocumentsState = initialState,
  action: ActionType
): DocumentsState {
  switch (action.type) {

    case SharedActions.documents.delete: {
      const { documentId } = action.payload;

      const { [documentId]: removedItem, ...rest } = state.documents;

      return {
        ...state,
        documents: {
          ...rest,
        }
      };
    }

    case SharedActions.documents.fetchAll: {
      const { tableIds, documents } = action.payload;

      return {
        ...state,
        documentsTableIds: tableIds,
        documents: {
          ...state.documents,
          ...documents,
        },
      };
    }

    case SharedActions.reset: {
      return initialState;
    }

    default:
      return state
  }
}