import { Auth0Client } from "@auth0/auth0-spa-js";
import get from 'lodash/get';
import Env from './Env';
import axios from 'axios';

type PostParameters = {
  body: Object | FormData;
  queryParameters?: Record<string, string>;
  customHeaders?: object;
  endpoint: string;
}
type GetParameters = {
  queryParameters?: Record<string, string>;
  customHeaders?: object;
  endpoint: string;
}

//Map of endpoints
export const Endpoints = {
  auth: {
    commitment: {
      backgroundImageUpload: '/commitment/backgroundImagePost',
      create: '/commitment',
      delete: '/commitment',
      fetch: '/commitment',
      fetchAll: '/commitments',
      update: '/commitment',
    },
    dashboards: {
      fetch: '/dashboard',
      fetchCarbonFootprint: '/dynamicCarbon',
      fetchAll: '/dashboards',
    },
    documents: {
      create: '/document',
      delete: '/document',
      getPresigned: '/document',
      fetchAll: '/documents',
      update: '/ghgi/documents',
      insights: '/documents/insights',
      get: '/document',
      updateIssue: '/document/issue',
      cdpHistory: '/cdp/documents',
    },
    files: {
      getUrl: '/files',
      search: '/ghgi/documents',
    },
    assets: {
      search: '/assets',
      update: '/assets',
    },
    focusArea: {
      backgroundImageUpload: '/focusAreas/backgroundImagePost',
      create: '/focusAreas',
      delete: '/focusAreas',
      fetch: '/focusAreas',
      update: '/focusAreas',
    },
    forecasts: {
      emissions: {
        fetchData: '/forecasts/emissions/data',
        list: '/forecasts/emissions/list',
      },
      resources: {
        fetchData: '/forecasts/resource/data',
        list: '/forecasts/resource/list',
      },
      create: '/forecast',
      delete: '/forecast',
      fetch: '/forecasts',
      update: '/forecast',
    },
    goal: {
      create: '/goals',
      delete: '/goals',
      fetch: '/goals',
      update: '/goals',
    },
    initiative: {
      create: '/initiatives',
      delete: '/initiatives',
      fetch: '/initiatives',
      update: '/initiatives',
    },
    intensity: {
      inventory: {
        fetch: '/intensity/inventory',
      },
      create: '/intensity/row',
      delete: '/intensity/row',
      fetch: '/intensity',
      setColumns: '/intensity/columns',
      update: '/intensity/row',
    },
    plan: {
      fetch: '/plans',
      fetchFullTree: '/plans/tree',
      update: '/plans',
    },
    project: {
      create: '/projects',
      delete: '/projects',
      fetch: '/projects',
      fetchAll: '/projects/all',
      update: '/projects',
    },
    reports: {
      cdp: {
        fetch: '/reports/cdp'
      },
      activity: {
        fetch: '/reports/activity',
      },
      emissions: {
        fetch: '/reports/emissions',
        years: {
          fetch: '/reports/emissions/years',
        },
      },
      largestUsers: {
        fetch: '/reports/largestUsers',
      },
      gpc: {
        fetch: '/reports/gpc',
      },
      communityGHGI: {
        fetch: '/reports/communityghgi',
      },
      dynamicCarbonV1: {
        fetch: '/reports/dynamicCarbonV1',
      },
      nhscConsolidated: {
        fetch: '/reports/nhscConsolidated',
      },
      solarTogether: {
        fetch: '/reports/solarTogether',
      },
      sasb: {
        fetch: '/reports/sasb',
      },
      gri: {
        fetch: '/reports/gri',
      },
      fyDynamicCarbon: {
        fetch: '/reports/fyDynamicCarbon',
      },
      gps: {
        fetch: '/reports/gps',
      },
      gps2: {
        fetch: '/reports/gps2',
      },
      endCustomerAllocation: {
        fetch: '/reports/endCustomerAllocation',
      },
      ryderCustom1: {
        fetch: '/reports/ryder_custom_1',
      },
      vitafloCustom1: {
        fetch: '/reports/vitaflo_custom_1',
      },
      fetchAll: '/reports',
    },
    strategy: {
      backgroundImageUpload: '/strategies/backgroundImagePost',
      create: '/strategies',
      delete: '/strategies',
      fetch: '/strategies',
      update: '/strategies',
    },
    user: {
      fetch: '/user',
      fetchByOrganization: '/user/organization',
      update: '/user',
      create: '/user',
      delete: '/user',
    },
    glidePath: {
      create: '/glidePath',
      delete: '/glidePath',
      fetch: '/glidePath',
      update: '/glidePath',
    },
    ghgiCategories: {
      getAll: '/ghgi/categories',
      request: '/ghgi/categories',
    },
    connections: {
      create: '/connections'
    },
    workbooks: {
      template: {
        fetch: '/workbooks/template'
      },
      fetch: '/workbooks',
      validate: '/workbooks/validate',
    },
    accounts: {
      fetch: '/ghgi/account',
      search: '/ghgi/accounts',
      update: '/ghgi/accounts',
    },
    activities: {
      search: '/ghgi/activities',
      update: '/ghgi/activities',
    },
    utlities: {
      getProviders: '/utility/providers',
      submitCredentials: '/utility/credentials',
    }
  },
  health: {
    check: '/health',
  },
  arcadia: {
    credential: {
      get: '/arcadia/credential',
      delete: '/arcadia/credential',
      patch: '/arcadia/credential',
    },
    credentials: {
      get: '/arcadia/credentials',
    },
    secret: {
      get: '/arcadia/secret',
    },
    secrets: {
      get: '/arcadia/secrets',
    },
  },
  customer: {
    get: '/customer',
  },
  customers: {
    get: '/customers',
  },
  customerasset: {
    get: '/customerasset',
    patch: '/customerasset',
    post: '/customerasset',
  },
  customerassets: {
    get: '/customerassets',
  },
  customerlocationaccount: {
    get: '/customerlocationaccount',
  },
  customerlocationaccounts: {
    get: '/customerlocationaccounts',
  },
};

const auth0 = new Auth0Client({
  domain: Env.AUTH0_DOMAIN,
  clientId: Env.AUTH0_CLIENT_ID,
});

/**
 * Function called to perform an authorized fetch
 *
 * @param endpoint: the endpoint to be hit
 * @param params: the params to add to the fetch call
 */
export async function authedFetch(endpoint: string, params?: { method: string, queryParameters?: object, body?: object | FormData }) {
  const baseUrl = `${Env.API_PATH}`;

  await auth0.checkSession()

  const token = await auth0.getTokenSilently({
    authorizationParams: {
      audience: Env.AUTH0_AUDIENCE,
    },
  })

  //TODO: Uncomment this
  // if (claims == null) {
  //   //TODO: Sign out user
  //   auth0.logout();
  //   window.location.href = '/';
  // }

  let customHeaders: { [key: string]: string } = {};
  if (!(params?.body instanceof FormData)) {
    customHeaders['Content-Type'] = 'application/json';
  }

  const url = new URL(baseUrl + endpoint);
  const method = params ? params.method : "GET";
  const options: RequestInit = {
    method: method,
    credentials: 'include',
    mode: 'cors',
    headers: {
      ...getAuthHeaders(token),
      ...customHeaders
    }
  };

  if (params && params.queryParameters) {

    //convert query parameters object to record where all values are strings
    let record: Record<string, string> = {};
    Object.entries(params.queryParameters).forEach(([key, val]) => {
      if (val != undefined) { // eslint-disable-line eqeqeq
        record[key] = val !== 'object' ? String(val) : val;
      }
    });

    url.search = new URLSearchParams(record).toString();
  }

  if (params && params.body) {
    if (params.body instanceof FormData) {
      options.body = params.body
    } else if (params.body) {
      options.body = JSON.stringify(params.body);
    }
  }

  const res = await fetch(url.toString(), options)
  const { ok, status } = res;
  const text = await res.text(); //TODO: see if theres a better way to check for empty responses

  let obj: any = {};
  try {
    if (text.length) obj = JSON.parse(text);
  } catch (e) {
    throw Error(`Something went wrong on the server, please try again. (Status: ${status})`);
  }

  if (res.status === 403 && obj?.error?.type === 'sustainabase_error') {
    //TODO: Sign out user
    auth0.logout();
    window.location.href = '/';
  }

  if (res.status >= 400) {
    throw new Error(String(res.body) || 'Something went wrong on the server, please try again.')
  }

  if (!ok) {
    const errorMessage = get(obj, 'error.message', null);
    if (obj.requestId) throw Error(errorMessage || `Something went wrong on the server, please try again. (Status: ${status}) (Id: ${obj.requestId})`);
    else console.error('Something went wrong, object.requestId was not found')
  }

  return obj;
}

const getAccessToken = async () => {
  try {
    await auth0.checkSession();
    const token = await auth0.getTokenSilently({
      authorizationParams: {
        audience: Env.AUTH0_AUDIENCE,
      },
    });
    return token;
  }
  catch (error) {
    console.error('Error getting the access token: ', error)
    return ''
  }
}

const getAuthHeaders = (token: string) => {
  const headers: Record<string, string> = {
    'Authorization': `Bearer ${token}`
  }

  const impersonatedUID = sessionStorage.getItem('impersonate-uid')
  if (impersonatedUID) {
    headers['impersonate-uid'] = impersonatedUID
  }

  return headers
}

export const POST = async ({ endpoint, body, queryParameters, customHeaders }: PostParameters) => {
  const token = await getAccessToken();
  let url = new URL(Env.API_PATH + endpoint);

  // Simplified the query parameter method, I don't know if it's missing anything
  if (queryParameters) {
    url.search = new URLSearchParams(queryParameters).toString();
  }

  const response = await axios.post(url.toString(), body, {
    headers: {
      'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
      ...getAuthHeaders(token),
      ...customHeaders
    },
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data
  } else {
    throw new Error(response.statusText);
  }
}

export const GET = async ({ endpoint, queryParameters, customHeaders }: GetParameters) => {
  const token = await getAccessToken();
  const url = new URL(`${Env.API_PATH}${endpoint}`);
  // Simplified the query parameter method, I don't know if it's missing anything
  if (queryParameters) {
    url.search = new URLSearchParams(queryParameters).toString();
  }
  const response = await axios.get(url.toString(), {
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeaders(token),
      ...customHeaders
    },
  });
  return response.data;
}

export const PUT = async ({ endpoint, queryParameters, customHeaders = {}, body }: PostParameters) => {
  const token = await getAccessToken();
  const url = new URL(`${Env.API_PATH}${endpoint}`);

  // Simplified the query parameter method, I don't know if it's missing anything
  if (queryParameters) {
    url.search = new URLSearchParams(queryParameters).toString();
  }

  const response = await axios.put(
    url.toString(),
    body instanceof FormData ? body : JSON.stringify(body) as BodyInit,
    {
      headers: {
        'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
        ...getAuthHeaders(token),
        ...customHeaders
      },
    }
  );
  return response;
}

export const DELETE = async ({ endpoint, queryParameters, customHeaders }: GetParameters) => {
  const token = await getAccessToken();
  const url = new URL(`${Env.API_PATH}${endpoint}`);
  // Simplified the query parameter method, I don't know if it's missing anything
  if (queryParameters) {
    url.search = new URLSearchParams(queryParameters).toString();
  }
  const response = await axios.delete(url.toString(), {
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeaders(token),
      ...customHeaders
    },
  });
  return response.data;
}

export const PATCH = async ({ endpoint, body, customHeaders, queryParameters }: PostParameters) => {
  const token = await getAccessToken();
  const url = new URL(`${Env.API_PATH}${endpoint}`);

  // Simplified the query parameter method, I don't know if it's missing anything
  if (queryParameters) {
    url.search = new URLSearchParams(queryParameters).toString();
  }

  const response = await axios.patch(
    url.toString(),
    body instanceof FormData ? body : JSON.stringify(body) as BodyInit,
    {
      headers: {
        'Content-Type': body instanceof FormData ? 'multipart/form-data' : 'application/json',
        ...getAuthHeaders(token),
        ...customHeaders
      },
    }
  );
  return response.data;
}
