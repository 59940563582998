import React, { useRef } from 'react'
import { Dispatch } from 'redux'

import useMultiFileFetch from '../../hooks/MultiFileFetch/UseMultiFileFetch'
import useAsyncCall from '../../hooks/UseAsyncCall'
import NetworkActions from '../../actions/NetworkActions'
import { SnackbarDisplayType } from '../generics/Snackbar'
import { Endpoints } from '../../utils/API'
import Modal from '../Modal'
import Button from '../Button'

interface Props {
  dispatch: Dispatch<any>
  onClose: (didAddFiles: boolean) => void
  open: boolean
  fileType: string
}

const UploadDocumentsDialog = ({ dispatch, onClose, open, fileType }: Props) => {
  const uploadRef = useRef<any>(null)

  const uploadFilesCall = useAsyncCall(true)
  const documentsToUpload = useMultiFileFetch(undefined, false, Endpoints.auth.documents.create)

  const handleClose = (didAddFiles: boolean) => {
    onClose(didAddFiles)
    if (uploadRef.current) uploadRef.current.value = null
  }

  /**
   * Function called to upload the currently selected files
   */
  const uploadFiles = () => {
    uploadFilesCall.makeAsyncCall(documentsToUpload.uploadFiles(fileType))
      .then((res) => {
        dispatch(NetworkActions.setGlobalError({
          message: `${res.length} file(s) have successfully been added.`,
          type: SnackbarDisplayType.success
        }))
        handleClose(true)
      })
      .catch(e => {
        console.error(e)
        handleClose(false)
      })
  }

  return (<>
    <Modal
      title="Add Files"
      description="Select the files you wish to upload"
      open={open}
      onClose={() => !uploadFilesCall.isCalling && handleClose(false)}
      actionButtons={[
        {
          label: 'Cancel',
          variant: 'ghost',
          type: 'button',
          disabled: uploadFilesCall.isCalling,
          onClick: () => handleClose(false)
        },
        {
          label: 'Submit',
          variant: 'primary',
          type: 'button',
          disabled: uploadFilesCall.isCalling,
          onClick: () => uploadFiles()
        }
      ]}
    >
      <div className="text-center py-3">
        <Button
          variant="primary"
          label="Select Files"
          className="relative mx-auto mb-1"
          loading={uploadFilesCall.isCalling}
          content={
            <input
              multiple
              type="file"
              className="absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
              disabled={uploadFilesCall.isCalling}
              onChange={(e) => {
                if (e.target.files) {
                  documentsToUpload.addFiles(Array.from(e.target.files))
                } else {
                    const file = e.target.files ? e.target.files[0] : null
                    if (file != null) {
                      documentsToUpload.addFiles(file)
                    }
                }
              }}
            />
          }
        />
        <p>{documentsToUpload.orderedFiles.length} files selected</p>
      </div>
    </Modal>
    {uploadFilesCall.SnackbarComponent}
  </>)
}

export default UploadDocumentsDialog
