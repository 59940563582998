import moment from 'moment-timezone'
import { BsArchive } from 'react-icons/bs'
import { AiOutlineDownload } from 'react-icons/ai'

interface Props {
  loading: boolean
  files: any[]
}

export default function FileArchive({ loading, files }: Props) {
  return (
    <div className="w-full border border-slate-100 shadow-sm bg-white flex flex-col rounded-lg">
      <div className="flex items-center p-4">
        <BsArchive className="text-xl text-gray-400 mr-3" />
        <h4 className="font-bold">File Archive</h4>
      </div>
      <div className="w-full max-h-[200px] overflow-y-auto px-2">
        {
          loading && (
            <>
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
              <div className="w-full bg-gray-100 h-10 rounded-lg animate-pulse mb-2" />
            </>
          )
        }
        {
          !loading && files.length > 0 && files.map((file) => (
            <div key={file.id} className="w-full flex justify-between border-b border-slate-100 py-3 px-3">
              <div className="">
                <h5 className="mb-1 font-semibold">{file.owner ? `${file.owner?.firstName} ${file.owner?.lastName}` : 'Unknown owner'}</h5>
                <p className="text-slate-500">{moment(file.uploaded).format('l')}</p>
                <p className="text-slate-400 max-w-[150px] truncate">{file.name}</p>
              </div>
              <div className="">
                <a href={file.url} target='_blank' rel="noreferrer" download >
                  <AiOutlineDownload className="text-gray-400 hover:text-gray-200 rounded-md" />
                </a>
              </div>
            </div>
          ))
        }
        {
          !loading && files?.length === 0 && (
            <div className="border-2 border-dashed border-gray-200 rounded-sm p-3 text-center flex flex-col mb-3 mx-2">
              <h4 className="font-bold">No recent files</h4>
              {/* <p className="text-slate-500">Change your category or upload a workbook</p> */}
            </div>
          )
        }
      </div>
    </div>
  )
}
