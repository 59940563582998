import makeStyles from '@material-ui/core/styles/makeStyles';
import Constants from '~/utils/Constants';

/** @deprecated Please migrate from mui to tailwind instead */
const useCommonStyles = makeStyles((theme) =>
  ({
    backgroundImage: {
      position: 'relative',
      width: '100%',
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '56.25%',
      }
    },
    backgroundImageContent: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    buttonGreen: {
      borderColor: Constants.successColor,
      color: Constants.successColor,
    },
    buttonRed: {
      borderColor: Constants.errorColor,
      color: Constants.errorColor,
    },
    secondaryToolbar: {
      background: '#EEEEEE',
      boxShadow: '0 4px 5px rgba(0,0,0,0.2)',
      boxSizing: 'border-box',
      zIndex: 1,
    },
    secondaryToolbarToggleButton: {
      padding: '5px 7px',
    },
    grow: {
      flexGrow: 1,
    },
    dialogPaper: {
      background: '#fafafa',
    },
    scrollableContent: {
      overflow: 'auto',
    },
    containerGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    textArea: {
      boxSizing: 'border-box',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      marginTop: 8,
      marginBottom: 8,
      padding: 4,
      resize: 'none',
      width: '100%',
    }
}));

export default useCommonStyles;