import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import ActionPlanTree from './ActionPlanTree';
import PlanActions from '../../../actions/PlanActions';
import Copyright from '../../generics/labels/Copyright';
import SlideUpTransition from '../../generics/SlideUpTransition';
import useCommonStyles from '../../../hooks/UseCommonStyles';

import logo from '../../../../assets/logo.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: 32,
      objectFit: 'contain',
    },
}));

const ActionPlanTreeDialog = (props: {
  dispatch: Dispatch<any>
  planId: string
}) => {

  const { dispatch, planId } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  /**
   * Function called to close the tree dialog
   */
  const handleClose = () => {
    dispatch(PlanActions.setViewingTreePlan(null));
  }

  return (
    <Dialog
      fullScreen
      TransitionComponent={SlideUpTransition}
      classes={{paperFullScreen: commonClasses.dialogPaper}}
      open={planId != null}
      onClose={() => handleClose()}
      aria-labelledby={`View Plan Tree`}
      aria-describedby={`Dialog to view the Plan Tree`}
    >
      <AppBar position="static" color="transparent">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color='primary' noWrap component={Link} to='/'>
            <img className={classes.logo} src={logo} alt='SustainaBase' />
          </Typography>
          <div className={commonClasses.grow} />
        </Toolbar>
      </AppBar>
      <div className={commonClasses.scrollableContent}>
        <Container className={commonClasses.containerGrid} maxWidth="md">
          <ActionPlanTree />
        </Container>
        <Copyright/>
      </div>
    </Dialog>
  );
};

export default ActionPlanTreeDialog;