import { ReactNode } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import Button from '~/components/Button'
import classNames from '~/utils/classNames'

interface ActionButton {
  label: string
  onClick: () => void
  variant?: 'primary' | 'danger' | 'ghost',
  type?: 'button' | 'submit' | 'reset'
  form?: string,
  loading?: boolean
  disabled?: boolean
}

interface Props {
  children?: ReactNode,
  actionButtons?: ActionButton[]
  title?: string | ReactNode
  description?: string | ReactNode
  open: boolean
  onClose: () => void
  className?: string
}

export default function Modal({
  title,
  description,
  children,
  actionButtons,
  open,
  onClose,
  className = "",
}: Props) {
  return (
    <Dialog.Root open={open} onOpenChange={(isOpen: boolean) => !isOpen && onClose()} modal={true}>
      <Dialog.Portal>
        <Dialog.Overlay className={classNames("z-[10] bg-gray-500 bg-opacity-50 backdrop-blur-sm transition-opacity border-2 fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center")} >
          <Dialog.Content className={classNames("rounded-lg bg-white flex flex-col items-center", className)}>
            <div className="p-4 flex flex-col items-center">
              {title && <Dialog.Title className="text-lg font-semibold mb-2">{title}</Dialog.Title>}
              {description && <Dialog.Description className="text-slate-500">
                {description}
              </Dialog.Description>}
              {children}
            </div>
            {!!actionButtons?.length && <div className="border-t border-slate-100 py-3 px-3 flex items-center justify-end w-full">
              {actionButtons.map((actionButton: ActionButton) => (
                <Button
                  key={actionButton.label}
                  label={actionButton.label}
                  onClick={actionButton.onClick}
                  variant={actionButton.variant}
                  className="ml-2"
                  type={actionButton.type || 'button'}
                  form={actionButton.type === 'submit' ? actionButton.form : undefined}
                  loading={actionButton.loading}
                  disabled={actionButton.disabled}
                />
              ))}
            </div>}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
