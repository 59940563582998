import { BsBuilding, BsBox, BsTruck } from 'react-icons/bs'

interface Props {
  ghgiCategory?: string,
  iconStyle: string
}

export default function AssetIcon({ ghgiCategory, iconStyle }: Props) {
  const assetIcons: any = {
    company_facilities: <BsBuilding className={iconStyle} />,
    company_vehicles: <BsTruck className={iconStyle} />,
    default: <BsBox className={iconStyle} />,
  }

  return (
    <>
      {ghgiCategory && assetIcons[ghgiCategory] ? assetIcons[ghgiCategory] : assetIcons['default']}
    </>
  )
}
