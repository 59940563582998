import pMap from "p-map";
import axios from 'axios'

import { authedFetch, Endpoints, GET, PATCH, POST } from "../utils/API";
import FileUploadResponse from "../types/requests/generics/FileUploadResponse";

export default class FileActions {

    static pMapConcurrencyMax = 10;

    /**
     * Function called to get a file url from a specific file id
     *
     * @param fileId: the id of the file whose url is needed
     */
    static getFileUrl(fileId: string): Promise<string> {
        return authedFetch(Endpoints.auth.files.getUrl, {
            method: 'GET',
            queryParameters: {
                fileId: fileId,
            }
        })
            .then((res) => {
                return res.url
            });
    }

    /**
     * Function called to upload a file to s3
     *
     * @param file: the file to upload
     * @param endpoint: the endpoint for the file to be uploaded to
     */
    static uploadFile(file: File, endpoint: string, organizationId: string, fileType: string, reportYear?: number): Promise<string> {

        //create a place in S3 to upload the image to
        return authedFetch(endpoint, {
            method: 'GET',
            queryParameters: {
                fileName: file.name,
                size: file.size,
                organizationId,
                fileType,
                reportYear
            },
        }).then((s3Response: FileUploadResponse) => {

            //build the form data to upload the image
            const formData = new FormData();
            formData.append('key', s3Response.key);
            if (s3Response.contentType != null) {
                formData.append('Content-Type', s3Response.contentType);
            }
            Object.entries(s3Response.post.fields).forEach(([key, val]) => {
                formData.append(key, val);
            })
            formData.append('file', file);

            //upload the image to s3
            return fetch(s3Response.post.url, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
                .then((res) => {

                    const { ok, status } = res;

                    if (!ok) {
                        throw Error(`Something went wrong on the server, please try again. (Status: ${status})`);
                    }

                    return s3Response.fileId
                });
        })
    }

    /**
     * Function called to upload multiple files to s3
     *
     * @param files: the files to be uploaded
     * @param endpoint: the endpoint for the file to be uploaded to
     */
    static uploadMultipleFiles(files: File[], endpoint: string, organizationId: string, fileType: string): Promise<string[]> {
        return pMap(files, (file) => FileActions.uploadFile(file, endpoint, organizationId, fileType), { concurrency: FileActions.pMapConcurrencyMax })
    }

    static async streamFileUpload(file: File, documentType: "DOCUMENT" | "WORKBOOK", organizationId: string, fileType: string, ghgiCategory: string, streamHandler: (progressEvent: any) => void): Promise<any> {
        const s3Response = await GET({
            endpoint: Endpoints.auth.documents.getPresigned,
            queryParameters: {
                fileName: file.name,
                fileType: documentType, // Andres: Will there be a type for the workbook?
                size: file.size.toString(),
                organizationId: organizationId,
                ghgiCategory,
            }
        })
        const form = new FormData();
        if (s3Response.contentType != null) {
            form.append('Content-Type', s3Response.contentType);
        }
        Object.entries(s3Response.post.fields).forEach(([key, val]: any) => {
            form.append(key, val);
        })
        form.append("file", file)
        const streamResponse = await axios.post(s3Response.post.url, form, {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress: streamHandler,
        })
        return { s3Response, streamResponse }
    }

    static async validateWorkbook(fileId: string, ghgiCategory: string) {
        return POST({
            endpoint: Endpoints.auth.workbooks.validate,
            body: {
                workbookId: fileId,
                ghgiCategory,

            },
        })
    }

    static async getCDPHistory(organizationId: string, reportYear: number) {
        return GET({
            endpoint: Endpoints.auth.documents.cdpHistory,
            queryParameters: {
                organizationId,
                reportYear: reportYear.toString(),
            },
        })
    }

    static async getWorkbooksUploads(organizationId: string, ghgiCategory: string) {
        return GET({
            endpoint: Endpoints.auth.workbooks.fetch,
            queryParameters: {
                organizationId,
                ghgiCategory,
            },
        })
    }

    static async getFileInsights(organizationId: string, ghgiCategory: string, unixStart: number, unixEnd: number) {
        return GET({
            endpoint: Endpoints.auth.documents.insights,
            queryParameters: {
                organizationId,
                ghgiCategory,
                unixStart: unixStart.toString(),
                unixEnd: unixEnd.toString(),
            },
        })
    }

    static async getFilesInPeriod(organizationId: string, ghgiCategory: string, unixStart: number, unixEnd: number) {
        return GET({
            endpoint: Endpoints.auth.files.search,
            queryParameters: {
                organizationId,
                ghgiCategory,
                unixStart: unixStart.toString(),
                unixEnd: unixEnd.toString(),
            },
        })
    }

    static async updateFileStatus(documentId: string, status: 'uploaded' | 'validated' | 'rejected') {
        return PATCH({
            endpoint: Endpoints.auth.documents.update,
            body: {
                documentId,
                status,
            },
        })
    }

    static async getFileById(documentId: string) {
        return GET({
            endpoint: Endpoints.auth.documents.get,
            queryParameters: {
                documentId,
            },
        })
    }

    static async updateIssueStatus(issueId: string, newStatus: "discarted" | "resolved") {
        return PATCH({
            endpoint: Endpoints.auth.documents.updateIssue,
            body: {
                issueId,
                status: newStatus,
            },
        })
    }
}
