import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonProps } from "@material-ui/core/Button";

export type LoadingButtonProps = ButtonProps & {isLoaderShowing: boolean, spinnerColor?: string};

const LoadingButton = (props: LoadingButtonProps) => {

    const { isLoaderShowing, spinnerColor, ...buttonProps } = props;

    return (
      <Button
        {...buttonProps}
        disabled={buttonProps.disabled || isLoaderShowing}
      >
          {isLoaderShowing &&
          <CircularProgress style={{ height: 14, width: 14, marginRight: 5, color: spinnerColor || 'inherit' }}/>
          }
          {props.children}
      </Button>
    );
};

export default LoadingButton;