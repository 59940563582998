import { useState, useCallback } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { CgSpinner } from 'react-icons/cg'
import classNames from '../../utils/classNames'
import { debounce } from 'lodash'

interface Props {
  containerClassname?: string,
  inputClassname?: string,
  loading: boolean,
  onChange: (term: string) => void,
  onChangeDependencies?: any[],
  debounceInMs?: number
}

export default function Searchbar({ 
  containerClassname,
  inputClassname,
  loading = false,
  debounceInMs = 500,
  onChange,
  onChangeDependencies = []  
}: Props) {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedOnSearch = useCallback(debounce(onChange, debounceInMs), onChangeDependencies)

  return (
    <div 
      className={classNames('rounded-full bg-white shadow-md px-3 py-2 flex items-center border justify-between border-slate-200', containerClassname)}
    >
      <input
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value)
          debouncedOnSearch(e.target.value)
        }}
        type="text" 
        className={classNames('outline-none', inputClassname)} 
        placeholder="Search"
      />
      {
        loading && (
          <div className="ml-2 flex items-center justify-center">
            <CgSpinner className="text-lg text-slate-300 animate-spin" />
          </div>
        )
      }
      {
        !loading && (
          <AiOutlineSearch className="text-lg text-slate-300 ml-2" />
        )
      }
    </div>
  )
}
