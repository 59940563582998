import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { AiOutlineCheck } from 'react-icons/ai'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { CgSpinnerTwo } from 'react-icons/cg'
import { useController } from 'react-hook-form'
import classNames from '../../utils/classNames'

export type SelectOption = {
  id: string,
  name: string,
  value: any,
  disabled?: boolean,
}

interface Props  {
  selectOptions: SelectOption[],
  defaultValue?: SelectOption | undefined,
  label: string,
  name: string,
  register?: any
  value?: any
  options?: any,
  control: any,
  disabled?: boolean,
  loading?: boolean,
}

export default function Select({ options, defaultValue = undefined, label, selectOptions, register, value, name, control, disabled, loading }: Props) {
  const [selected, setSelected] = useState<SelectOption | undefined>(defaultValue)

  const { field } = useController({
    name,
    control,
  })

  useEffect(() => {
    if (control) {
      field.onChange(selected)
    }
  }, [selected])

  return (
    <Listbox
    disabled={disabled}
      name={name}
      {...register(name, options)}
      onChange={(option: SelectOption) => {
        if(!option?.disabled)
          setSelected(option)
        }
      }
      value={selected}
    >
      {({ open }) => (
        <>
          <div className="relative pt-3 w-full">
            <Listbox.Button className="peer h-10 relative w-full min-w-full cursor-default border-b border-slate-200 py-2 pl-3 pr-10 text-left focus:border-accent focus:outline-none sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed">
              <span className="block">{selected?.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                { loading && <span className="flex items-center justify-center animate-spin text-xl text-gray-400"><CgSpinnerTwo /></span>}
                { !loading && <HiOutlineChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              </span>
            </Listbox.Button>
            <Listbox.Label
            className={
              classNames(
                'text-gray-500 absolute font-semibold transition-top duration-100 peer-focus:ease-linear left-2',
                !selected ? 'text-md top-6' : 'top-0 text-xs'
              )}
            >
              { label }
            </Listbox.Label>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {selectOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-accent text-white' : '',
                        option?.disabled ? 'text-gray-400 cursor-not-allowed' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span 
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal', 'block truncate',
                            option?.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                            'items-start justify-start flex'
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <AiOutlineCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}