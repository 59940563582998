import axios from 'axios'

export default class JiraActions {

  static async createIssue(summary: string, message: string, email: string) {
    const response = await axios({
      method: 'POST',
      url: `https://jsd-widget.atlassian.com/api/embeddable/dce36378-e12f-4f59-a092-5321d911c25c/request?requestTypeId=1`,
      data: {
        fields: [
          {
            id: 'summary',
            value: summary
          },
          {
            id: 'description',
            value: message
          },
          {
            id: 'email',
            value: email
          },
        ]
      }
    })
    return response
  }
}