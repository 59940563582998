import { memo, ReactNode } from 'react'

type Props = {
  title?: ReactNode | string
  icon?: any
  children?: ReactNode
}

/** Common page header component. Shows a title, icon, and optional description. Pass description as children. */
export const LayoutHeader = memo(({
  title,
  icon: Icon,
  children,
}: Props) => (
  <div className="text-center flex flex-col items-center mt-4">
    {Icon && <Icon className="text-4xl text-accent" />}
    <h1 className="text-xl font-bold py-2">{title}</h1>
    <p>{children}</p>
  </div>
))
