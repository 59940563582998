//import { useMemo } from 'react'
import { BsChevronRight } from 'react-icons/bs'
import AssetIcon from '~/components/AssetIcon'
import Searchbar from '~/components/Searchbar'
import classNames from '~/utils/classNames'
import {
  CustomerAssetType,
  //CustomerLocationAccountType,
} from '~/types/entities'

interface Props {
  searchAssets: (term: string, organizationId: string, acitivityTypeId?: string, loadMore?: boolean) => void
  customerAssets: CustomerAssetType[],
  //accounts: CustomerLocationAccountType[],
  onAssetSelect: (asset: CustomerAssetType | undefined) => void,
  loading: boolean,
  organizationId: string | undefined,
  selectedAsset?: CustomerAssetType,
  selectedActivityTypeId?: string,
  editing: boolean,
  ghgiCategory?: string
}


export default function Assets({
  searchAssets,
  customerAssets,
  //accounts,
  organizationId,
  loading,
  onAssetSelect,
  selectedAsset,
  selectedActivityTypeId,
  editing,
  ghgiCategory,
}: Props) {
  const toggleAsset = (asset: CustomerAssetType) => {
    console.log('toggleAsset', asset)
    onAssetSelect(selectedAsset?.id === asset.id ? undefined : asset)
  }

  /*const nonEmptyAssets = useMemo(() => {
    const exists = new Set<number>()
    for (const account of accounts) {
      // @ts-ignore
      exists.add(Number(account.customerAsset.id || account.customerAsset))
    }
    return customerAssets.filter(asset => exists.has(asset.id))
  }, [customerAssets, accounts])*/

  return (
    <div className="flex flex-col w-full mt-6">
      <h5 className="text-gray-400 uppercase font-semibold text-xs tracking-widest mb-3">ASSETS</h5>
      <Searchbar
        loading={loading}
        onChange={(search: string) => {
          if (!organizationId) return
          searchAssets(search, organizationId, selectedActivityTypeId)
        }}
        onChangeDependencies={[organizationId, selectedActivityTypeId]}
        containerClassname={'mb-2'}
      />
      <div className="flex flex-col w-full pt-2">
        {
          loading && (
            <span>
              <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
              <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
              <div className="w-full rounded-lg bg-gray-200 mb-2 animate-pulse h-10" />
            </span>
          )
        }
        {
          !loading && customerAssets?.length > 0 && (
            <>
              {
                customerAssets.map((asset) => (
                  <button
                    key={asset.id}
                    className={classNames(
                      "py-2.5 px-3 flex items-center justify-between w-full rounded-lg hover:bg-gray-100 mb-1 outline-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed",
                      selectedAsset?.id === asset.id ? "bg-gray-200" : ""
                    )}
                    onClick={() => toggleAsset(asset)}
                    disabled={editing}
                  >
                    <span className="flex items-center">
                      <AssetIcon
                        iconStyle="text-xl mr-2 fill-gray-400"
                        ghgiCategory={ghgiCategory}
                      />
                      <h4 className="font-bold text-left">{asset.nickName}</h4>
                    </span>
                    <BsChevronRight className="text-grey-300 ml-3" />
                  </button>
                ))
              }
              <button
                disabled={customerAssets?.length === 0 || loading || editing || customerAssets.length % 10 !== 0}
                type='button'
                className="mt-1 w-full flex items-center justify-center outline-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => {
                  console.log('Activity type ID: ', selectedActivityTypeId)
                  organizationId && searchAssets('', organizationId, selectedActivityTypeId, true)
                }}
              >
                <p className="text-accent underline underline-offset-1 font-semibold">Load More</p>
              </button>
            </>
          )
        }
        {
          !loading && customerAssets?.length === 0 && (
            <div className="border-2 border-dashed border-gray-200 rounded-xl p-4 text-center flex flex-col">
              <h4 className="font-bold mb-2">No assets found</h4>
              <p className="text-slate-500">Change your search term or upload a workbook</p>
            </div>
          )
        }
      </div>
    </div>
  )
}
