import { ActionType } from "~/utils/ActionTypes";
import { populateActions } from "~/utils/Utils";

export const ExistingFileActions = populateActions({
    setFiles: ''
});

export type ExistingFileState = {
    files: {[fileId: string]: string}
}

/**
 * Function called to update an existing file state
 *
 * @param state: the current state
 * @param action: the action to update the state with
 */
export default function existingFileReducer(state: ExistingFileState, action: ActionType): ExistingFileState {
    switch (action.type) {

        case ExistingFileActions.setFiles: {
            return {...state, files: action.payload};
        }

        default: {
            return state;
        }
    }
}