import { ReactElement, useEffect, useRef } from 'react'
import {
  MdCheckCircle,
  MdPending,
  MdReportProblem,
  MdMoreHoriz,
} from 'react-icons/md'
import { ArcadiaCredential } from '~/types/Arcadia'
import classNames from '~/utils/classNames'

interface IArcadiaUtilityCardProps {
  info: ArcadiaCredential
  isTemp?: boolean
  selected?: boolean
  onClick?: () => void
}

const icons: Record<string, ReactElement> = {
  OK: <MdCheckCircle size="1.5em" className="text-green-600" title="OK" />,
  PENDING: <MdPending size="1.5em" className="text-yellow-600" title="Pending" />,
  default: <MdReportProblem size="1.5em" className="text-red-600" title="Requires Attention" />,
}

/*const tempIcon = <div className="flex">
  <MdMoreHoriz size="1.5em" className="text-green-200" /><i className="whitespace-nowrap">Gathering Data...</i>
</div>*/

const tempIcon = <MdMoreHoriz size="2em" className="text-accent animate-pulse" />

//"bg-gray-100 shadow-md"
/** List item for brief arcadia utility info */
export function ArcadiaUtilityCard({
  info,
  isTemp,
  selected = false,
  onClick = () => { },
}: IArcadiaUtilityCardProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [selected])

  return <div
    ref={ref}
    onClick={onClick}
    className={classNames(
      "w-full flex items-center justify-between px-4 py-2 border-b cursor-pointer",
      selected ? "bg-gray-100 shadow-sm relative z-10" : "bg-white"
    )}
  >
    <div className="flex flex-col" id={info.entityId}>
      <h5 className="font-bold">{info.providerName}</h5>
      <p className="text-slate-500">{info.username}</p>
    </div>
    <div>
      {isTemp
        ? tempIcon
        : icons[info.status] || icons.default
      }
    </div>
  </div>
}
