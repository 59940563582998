import { useLayoutEffect } from "react";

import useUserAuth from '../hooks/UseUserAuth';

const LoginAuth0 = () => {

    const { loginWithRedirect } = useUserAuth();

    useLayoutEffect(() => {
        loginWithRedirect();
    }, []);

    return (
      <div />
    );
};

export default LoginAuth0;