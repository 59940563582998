import { useMemo } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Column, Table } from '@tanstack/react-table'
import { IoFilterSharp } from 'react-icons/io5'
import classNames from '../../utils/classNames'

interface Props {
  column: Column<any, unknown>,
  table: Table<any>
}

export default function Filter({ column, table }: Props) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  const columnFilterValue = column.getFilterValue()
  const isMultichoice = column.getFilterFn()?.name.includes('arr')

  const sortedUniqueValues = useMemo(() => {
    if (typeof firstValue === 'number') {
      return []
    } else {
      return Array.from(column.getFacetedUniqueValues().keys()).sort()
    }
  }, [column.getFacetedUniqueValues()])

  // TBD, probably a min max filter
  // if (typeof firstValue === 'number') return null

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          title={`Filter by ${column.columnDef.header}`}
          className="focus:outline-none"
        >
          <IoFilterSharp
            className={classNames(
              'text-sm',
              columnFilterValue ? 'text-accent-dark' : 'text-accent/60'
            )}
          />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content align="start" className="bg-white rounded shadow transition-all px-3 pt-3 w-40">
          <div>
            {!isMultichoice && (
              <input
                type="text"
                placeholder="Filter"
                className="w-full outline-none pb-3"
                value={(columnFilterValue ?? '') as string}
                onChange={(e) => column.setFilterValue(e.target.value)}
              />
            )}
            {isMultichoice && (
              <ul>
                {sortedUniqueValues.map((val, i) => (
                  <li
                    key={`${column.id}-option-filter-${i}`}
                    className="flex items-center cursor-pointer w-fit pb-3"
                    onClick={() => {
                      column.setFilterValue((prev: string[] | undefined) => {
                        const prevFilter = prev || []
                        if (prevFilter.includes(val)) {
                          return prevFilter.filter((filterVal: string) => filterVal !== val)
                        } else {
                          return [...prevFilter, val]
                        }
                      })
                    }}
                  >
                    <input
                      type="checkbox"
                      className="cursor-pointer accent-accent-dark mr-2"
                      name={`${column.id}-${val}`}
                      checked={((columnFilterValue ?? []) as string)?.includes(val)}
                    />
                    <label htmlFor={`${column.id}-${val}`} className="cursor-pointer mb-0">
                      {val}
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}