import { useState, useEffect, useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import { GHGICategory } from '~/views/DataImport/useDataImport'
import { ArcadiaCredential } from '~/types/Arcadia'

interface Props {
  ghgiCategories: GHGICategory[]
}

export function useEmissionsPortfolio({ ghgiCategories }: Props) {
  const { organization, userInfo } = useContext(UserContext) as UserContextType
  const fileConflicts: any = [
    {
      id: '12345',
      conflictValue: moment().subtract(3, 'months').format('l'),
      currentValue: moment().subtract(2, 'months').format('l'),
      fieldName: 'End Date',
    },
    {
      id: '12345',
      conflictValue: moment().subtract(3, 'months').format('l'),
      currentValue: moment().subtract(2, 'months').format('l'),
      fieldName: 'End Date',
    },
    {
      id: '12345',
      conflictValue: moment().subtract(3, 'months').format('l'),
      currentValue: moment().subtract(2, 'months').format('l'),
      fieldName: 'End Date',
    },
    {
      id: '12345',
      conflictValue: moment().subtract(3, 'months').format('l'),
      currentValue: moment().subtract(2, 'months').format('l'),
      fieldName: 'End Date',
    }
  ]
  const [selectedGHGICategory, setSelectedGHGICategory] = useState<GHGICategory | undefined>(undefined)
  const [conflictModalOpen, setConflitModalOpen] = useState(false)
  const [currentView, setCurrentView] = useState<'dashboard' | 'uploads'>('dashboard')
  const [selectedCredential, setSelectedCredential] = useState<ArcadiaCredential>()
  const history = useHistory()
  const { ghgiCategory, view } = useParams<{ ghgiCategory: string, view: string }>()

  useEffect(() => {
    if (view === 'dasboard') {
      setCurrentView('dashboard')
    }
    else if (view === 'uploads') {
      setCurrentView('uploads')
    }
  }, [view])

  const userGHGICategories = useMemo<GHGICategory[]>(() => {
    if (!organization) return []

    return ghgiCategories.filter(category => organization.ghgiCategories?.includes(category.id))
  }, [organization, ghgiCategories])

  useEffect(() => {
    if (ghgiCategories?.length > 0) {
      const targetCategory = ghgiCategories.find(category => category.id === ghgiCategory)
      if (targetCategory) {
        setSelectedGHGICategory(targetCategory)
      }
    }
  }, [ghgiCategory, ghgiCategories])

  const onGHGICategorySelected = (category: GHGICategory) => {
    history.push(`/import/portfolio/${category.id}`)
  }

  const getCredentialDetails = (credential: ArcadiaCredential) => {
    setCurrentView('uploads')
    setSelectedCredential(credential)
  }

  return {
    organization,
    userGHGICategories,
    onGHGICategorySelected,
    selectedGHGICategory,
    conflictModalOpen,
    setConflitModalOpen,
    fileConflicts,
    currentView,
    setCurrentView,
    userInfo,
    getCredentialDetails,
    selectedCredential,
    setSelectedCredential,
  }
}