import { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
// import { AiOutlineCheck } from 'react-icons/ai'
import { FileItem } from '../../utils/IndexDB'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onWorkbookStatusChange: (file: FileItem, workbook: boolean) => void
  files: FileItem[]
}

export default function WorkbookConfirmModal({ open, onClose, onConfirm, onWorkbookStatusChange, files }: Props) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Confirm Workbooks
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Check the files below and check the workbooks you want to import.
                      </p>
                    </div>
                    <div className="border border-slate-200 mt-3 rounded-lg overflow-hidden">
                      <div className="w-full max-h-48 overflow-auto">
                        {
                          files.map((file: FileItem) => (
                            <div className="flex items-center justify-between w-full border-b last:border-none border-slate-200 py-2 px-4">
                              <h4 className="max-w-[70%] text-left">{file?.name || file?.file?.name || 'No name found'}</h4>
                              <input type="checkbox" checked={file.workbook} onChange={() => onWorkbookStatusChange(file, !file.workbook)} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={onConfirm}
                  >
                    Confirm workbooks
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
