import { Link } from 'react-router-dom'
import { useGhgiCategory } from '~/hooks/UseGhgiCategory'
import { FiAlertOctagon } from 'react-icons/fi'

export function ArcadiaInfoTempWarning() {
  const ghgiCategory = useGhgiCategory()

  return (
    <div className="bg-orange-100 flex px-6 py-3 gap-4">
      <FiAlertOctagon className="text-orange-600 w-[20px] min-w-[20px] max-w-[20px]" />
      <div className="">
        <p className="text-centeritalic text-orange-600 mb-4">
          The system is validating your username and profile and the accounts and data associated with it. Click below to get an update on whether your username and password have been verified.
        </p>
        <Link className="underline font-bold text-orange-600 hover:text-orange-800" to={`/import/portfolio/${ghgiCategory}/uploads`}>Check Verification Status</Link>
      </div>
    </div>
  )
}

/*You will see a Green Checkmark next to the Provider name and the Credential Status will say "OK, No Action Required"
If your username and password require updating, you will see a Red warning icon and the Credential Status will say "Action Required, Login Failure"*/
