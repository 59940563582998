import { ActivityTypeSelection } from '~/views/EmissionsPortfolio/ActivityTypeSelection'
import Assets from '~/views/EmissionsPortfolio/Assets'
import Organization from '~/views/EmissionsPortfolio/Organization'
import { AccountsList } from '~/views/EmissionsPortfolio/AccountsList'
import Layout from '~/components/Layout'
import FileArchive from '~/views/EmissionsPortfolio/FileArchive'
import { GHGICategory } from '~/views/DataImport/useDataImport'
import { useEmissionsPortfolioDashboard } from './useEmissionsPortfolioDashboard'
import { OrganizationType, UserInfo } from '~/contexts/UserContext'
import classNames from '~/utils/classNames'
import UtilityCredentials from '~/views/EmissionsPortfolio/UtilityCredentials'
import type { ArcadiaCredential } from '~/types/Arcadia'

interface Props {
  ghgiCategories: GHGICategory[],
  selectedGHGICategory?: GHGICategory,
  loadingCategories: boolean,
  organization?: OrganizationType
  userInfo?: UserInfo,
  getCredentialDetails: (credential: ArcadiaCredential) => void
}

export function EmissionsPortfolioDashboard({
  ghgiCategories,
  loadingCategories,
  selectedGHGICategory,
  organization,
  userInfo,
  getCredentialDetails,
}: Props) {
  const {
    activityTypes,
    selectedActivity,
    onActivitySelect,
    searchAssets,
    customerAssets,
    loadingAssets,
    selectedAsset,
    setSelectedAsset,
    latestActivity,
    accounts,
    loadingAccounts,
    selectedAccount,
    setSelectedAccount,
    updateAsset,
    updateAccount,
    updateActivity,
    loadingFiles,
    files,
    searchAccounts,
    editing,
    setEditing,
    credentials,
    loadingCredentials,
  } = useEmissionsPortfolioDashboard({ ghgiCategories, organization })

  console.log('selectedGHGICategory', selectedGHGICategory)
  console.log('activityTypes', activityTypes)

  return (
    <Layout>
      <div className="grid grid-cols-3 xl:grid-cols-4 w-full mt-6 gap-8 pb-3 max-h-[calc(100vh-10px)] overflow-auto">
        <div className={classNames("overflow-auto max-h-[calc(100vh-60px)]")}>
          {(loadingCategories || activityTypes.length > 0) && (
            <ActivityTypeSelection
              activityTypes={activityTypes}
              selected={selectedActivity}
              onSelect={onActivitySelect}
              loadingCategories={loadingCategories}
              editing={editing}
            />
          )}
          <Assets
            customerAssets={customerAssets}
            //accounts={accounts}
            searchAssets={searchAssets}
            organizationId={organization?._id}
            loading={loadingAssets}
            onAssetSelect={setSelectedAsset}
            selectedAsset={selectedAsset}
            selectedActivityTypeId={selectedActivity?.id}
            editing={editing}
            ghgiCategory={selectedGHGICategory?.id}
          />
        </div>
        <div className={classNames("col-span-2 overflow-auto max-h-[calc(100vh-60px)]")}>
          {
            selectedGHGICategory && (
              <AccountsList
                activityTypes={activityTypes}
                selectedActivity={selectedActivity}
                setSelectedActivity={onActivitySelect}
                selectedAsset={selectedAsset}
                accounts={accounts}
                activity={latestActivity}
                loadingAccounts={loadingAccounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                updateAsset={updateAsset}
                updateAccount={updateAccount}
                updateActivity={updateActivity}
                onLoadMore={(activityTypeId?: string) => searchAccounts(true, activityTypeId)}
                setEditing={setEditing}
                editing={editing}
              />
            )
          }
          {/*
            selectedGHGICategory && selectedGHGICategory.id === 'company_vehicles' && (
              <CompanyVehiclesDashboard
                selectedAsset={selectedAsset}
                accounts={accounts}
                activity={latestActivity}
                loadingAccounts={loadingAccounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                updateAsset={updateAsset}
                updateAccount={updateAccount}
                updateActivity={updateActivity}
                onLoadMore={(activityTypeId?: string) => searchAccounts(true, activityTypeId)}
                setEditing={setEditing}
                editing={editing}
                setSelectedActivity={onActivitySelect}
                selectedActivity={selectedActivity}
                activityTypes={activityTypes}
              />
            )*/
          }
        </div>
        <div className="overflow-auto max-h-[calc(100vh-60px)] pb-10">
          <Organization
            organization={organization}
            userInfo={userInfo}
          />
          <div className="h-6" />
          <FileArchive
            files={files}
            loading={loadingFiles}
          />
          <div className="h-6" />
          <UtilityCredentials
            credentials={credentials}
            loading={loadingCredentials}
            goToCredentialDetails={getCredentialDetails}
          />
        </div>
      </div>
    </Layout >
  )
}
