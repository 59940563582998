import React from 'react'
import sustainabaseLogo from '../../../assets/logo.svg'
import Button from '../Button'
import { AiOutlineRight } from 'react-icons/ai'
import classNames from '../../utils/classNames'
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode
}

export default function VisitorLayout({ children }: Props) {
  return (
    <div className="min-h-screen w-screen flex flex-col">
      <div className="relative w-full h-20 border-b shadow-md flex items-center justify-between px-4">
        <img
          className="h-10 w-auto"
          src={sustainabaseLogo}
          alt="Sustainabase"
        />
        <Link to="/">
          <Button
            variant="ghost"
            label="Go to App"
            iconRight={<AiOutlineRight className="" />}
          />
        </Link>
      </div>
      <div className={classNames("bg-background-100 overflow-auto mx-auto max-w-7xl sm:px-6 lg:px-8")}>
        {children}
      </div>
    </div>
  )
}
