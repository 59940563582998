import { useState, useEffect } from 'react'
import classNames from '~/utils/classNames'


interface Props {
  label: string,
  name: string,
  register?: any
  value?: string | undefined
  options?: any,
  type?: 'text' | 'password' | 'email' | 'date'
  error?: any,
  disabled?: boolean,
}

export default function Input({ label, name, register, options, type = 'text', value, error, disabled }: Props) {
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    onBlurHandler()
  }, [])

  const onBlurHandler = () => {
    if (value !== undefined && value !== '' && value !== null) {
      setEmpty(false)
    } else {
      setEmpty(true)
    }
  }

  return (
    <div className="flex flex-col-reverse w-full items-start relative pt-4 h-auto">
      {/* <button onClick={()=> console.log('Rows:', ((textareaRef?.current?.scrollHeight || 0) / 40) + 1)}>Get scroll height</button> */}
      {error && <p className="text-sm text-red-600 mt-1">{error}</p>}
      <input
        type={type}
        name={name}
        id={name}
        disabled={disabled}
        className="peer w-full border-b border-slate-200 outline-none pt-0 px-2 pb-2 resize-none disabled:opacity-50 disabled:cursor-not-allowed"
        {...register(name, options)}
        onBlur={onBlurHandler}
      />
      <label
        htmlFor={name}
        className={
          classNames(
            'text-gray-500 absolute font-semibold peer-focus:text-xs peer-focus:top-0 transition-top duration-100 peer-focus:ease-linear left-2',
            empty ? 'text-md top-6' : 'top-0 text-xs'
          )
        }
      >
        {label}
      </label>
    </div>
  )
}
