// const tabs = [
//   { name: 'My Account', href: '#', current: true },
//   { name: 'Company', href: '#', current: false },
//   { name: 'Team Members', href: '#', current: false },
//   { name: 'Billing', href: '#', current: false },
// ]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  tabs: string[],
  currentTab: string,
  onTabSelected: (tab: string) => void,
  containerClass?: string
}

export default function ButtonTabs({ tabs, currentTab, onTabSelected, containerClass = "" }: Props) {
  return (
    <div className={containerClass}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-lg border border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>  
      <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <div key={tab}
              className={classNames(
                tabIdx === 0 ? 'rounded-l-xl' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-xl' : '',
                'bg-white overflow-hidden'
              )}
            >
            <div
              onClick={() => onTabSelected(tab)}
              className={classNames(
                currentTab === tab ? 'text-white bg-accent' : 'text-gray-500 hover:bg-gray-50',
                'group relative min-w-0 flex-1 overflow-hidden py-2 px-4 text-sm font-medium text-center  focus:z-10 cursor-pointer'
              )}
            >
              <p>
                <span>{tab}</span>
              </p>
            </div>
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}