import { useState, useContext, useMemo, createElement } from 'react'
import { useSignal } from '@preact/signals-react'
import { RiNodeTree, RiLayoutGridLine } from 'react-icons/ri'
import { AiOutlineLineChart } from 'react-icons/ai'
import { GiSnowflake2 } from 'react-icons/gi'
import { CgSpinner } from 'react-icons/cg'
import { startCase } from 'lodash'
import Button from '~/components/Button'
import { PageLayout } from '~/components/PageLayout'
import { ViewReportCard } from './ViewReportCard'
import { UserContext, UserContextType } from '~/contexts/UserContext'
import type SisenseDashboard from '~/types/SisenseDashboard'
import { EmissionsHeaderCard } from './EmissionsHeaderCard'
import { getDashboardCategoryIcon } from '~/constants/DashboardCategoryIcons'
import { SelectOption } from '~/components/Select'


interface Category {
  label: string
  key: string

}

export const categories = [
  { label: 'Upstream', key: 'upstream' },
  { label: 'Downstream', key: 'downstream' },
  { label: 'Your Organization', key: 'your organization' },
]

const scopeOptions: SelectOption[] = [
  { id: 'any', name: 'Any Scope' },
  { id: '1', name: 'Scope 1' },
  { id: '2', name: 'Scope 2' },
  { id: '3', name: 'Scope 3' },
]

export function EmissionsTracking() {
  const { dashboards } = useContext(UserContext) as UserContextType
  // Testing the use of a normal state for reactivity
  const [currentCategory, setCurrentCategory] = useState<{ label: string, key: string }>()
  // const currentCategory = useSignal<string>('')
  const currentScope = useSignal<SelectOption>(scopeOptions[0])

  const displayDashboards = useMemo<SisenseDashboard[]>(() => {
    console.log('Changing the display dashboard function',)
    if (!dashboards) return []

    // There are some dashboards with categories that don't fit in to the preset filter options. We don't display those on this page at all.
    let newDashboards = currentCategory?.key
      ? dashboards.filter(dashboard => dashboard.category === currentCategory?.key)
      : dashboards.filter(dashboard => categories.some((category) => category.key === dashboard.category))

    if (currentScope.value.id !== 'any') {
      //newDashboards = newDashboards.filter(dashboard => String(dashboard.scope) === currentScope.value.id)
    }

    return newDashboards
  }, [dashboards, currentCategory, currentScope.value])

  const onClickFilter = (category: Category | undefined) => () => { setCurrentCategory(category) }

  return <PageLayout
    pageTitle="Emissions Tracking"
    pageIcon={RiNodeTree}
  // pageSubTitle="Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacina odio."
  >
    <div className="md:w-[53rem] 2xl:w-[78rem] mx-auto mb-8">
      {/* Stick to top of page buttons */}
      <div className="flex justify-center flex-wrap gap-8 sm:gap-12 mt-8">
        <EmissionsHeaderCard
          name="Dynamic Carbon Footprint"
          href="/dynamiccarbon"
          icon={AiOutlineLineChart}
        />
      </div>

      <hr className="my-8" />

      {/* Filters */}
      <div className="flex flex-wrap gap-4 mb-8">
        <Button
          onClick={() => onClickFilter(undefined)}
          iconLeft={<RiLayoutGridLine />}
          variant={currentCategory ? 'ghost' : 'primary'}
        >
          All Emissions Reports
        </Button>
        <div className="flex flex-col items-end gap-4">
          <div className="flex gap-4">
            {categories.map(option => (
              <Button
                key={option.key}
                onClick={onClickFilter(option)}
                variant={currentCategory?.key === option.key ? 'primary' : 'ghost'}
                iconLeft={createElement(getDashboardCategoryIcon(option.key))}
              >
                {option.label}
              </Button>
            ))}
          </div>
          <div className="grow" />
          {/*<div>
            <Select
              options={scopeOptions}
              selected={currentScope.value}
              onChange={value => currentScope.value = value}
            />
          </div>*/}
        </div>
      </div>

      {/* Dashboards */}
      <div className="flex justify-center">
        {!dashboards ? (
          <CgSpinner className="text-slate-400 animate-spin w-16 h-16" />
        ) : displayDashboards.length === 0 ? (
          <p className="text-2xl">No Emissions Available</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-8 sm:gap-12">
            {displayDashboards.map(dashboard => (
              <ViewReportCard
                key={dashboard.oid}
                name={dashboard.title}
                category={dashboard.category}
                // description="Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacina odio."
                reportHref={`/dashboard/${dashboard.oid}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  </PageLayout>
}
