import Dexie, { Table } from 'dexie'
import moment from 'moment-timezone'

export type FileItem = {
  id?: number,
  _id?: string,
  name: string,
  file: File,
  ghgi_category: string,
  workbook: boolean,
  uploaded?: number,
  updated?: number,
  progress?: number,
  type?: string,
  owner?: any,
  issues?: any[],
  status?: 'queued' | 'uploading' | 'uploaded' | 'failed' | 'validating' | 'completed' | 'staged'
}

export class FileDB extends Dexie {
  files!: Table<FileItem>

  constructor() {
    super('FileDB')
    this.version(1).stores({
      files: '++id, name, type, size, data',
    })
  }
}

export const fileDB = new FileDB()


export const addFile = async (file: File, workbook = false, status: 'queued' | 'uploading' | 'uploaded' | 'failed' | 'staged' = 'staged', ghgi_category: string) => {
  const newFile: FileItem = {
    name: file.name,
    file,
    status,
    workbook,
    ghgi_category,
    uploaded: moment().unix(),
    updated: moment().unix(),
  }
  const id = await fileDB.files.add(newFile)
  newFile.id = Number(id)
  return newFile
}

export const getFiles = async () => {
  return await fileDB.files.toArray()
}

export const getFilesFromGHGICategory = async (ghgi_category: string) => {
  return await fileDB.files.filter((file: FileItem) => file.ghgi_category === ghgi_category).toArray()
}

export const getFilesFromFilter = async (filter: (file: FileItem) => boolean) => {
  return await fileDB.files.filter(filter).toArray()
}

export const updateFileById = async (id: number, changes: FileItem) => {
  changes.updated = moment().unix()
  await fileDB.files.update(id, changes)
  const updatedFile = await fileDB.files.get(id)
  console.log('Updated file in updateFileById', updatedFile)
  return updatedFile
}

export const getFileById = async (id: number) => {
  return await fileDB.files.get(id)
}

export const getFileByName = async (name: string) => {
  return await fileDB.files.where('name').equals(name).first()
}

export const deleteFileByName = async (name: string) => {
  return await fileDB.files.where('name').equals(name).delete()
}

export const deleteFileById = async (id: number) => {
  return await fileDB.files.delete(id)
}

