/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { GET, Endpoints } from '../../utils/API'

type Status = {
  healthy: boolean,
  timestamp: number,
}

export function usePlatformHealth() {
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState<'ok' | 'degraded' | 'down'>()
  const [authenticationStatus, setAuthenticationStatus] = useState<Status>()
  const [databaseStatus, setDatabaseStatus] = useState<Status>()
  const [webAppStatus, setWebAppStatus] = useState<Status>()
  const [webServerStatus, setWebServerStatus] = useState<Status>()
  const [dashboardsStatus, setDashboardsStatus] = useState<Status>()
  const { showAlert } = useAlertContext()

  useEffect(() => {
    getPlatformHealth()
  }, [])

  const getPlatformHealth = async () => {
    try {
      setLoading(true)
      const {
        status,
        authentication,
        database,
        dashboards,
        webApp,
        webServer,
      } = await GET({
        endpoint: Endpoints.health.check,
      })
      setStatus(status)
      setDatabaseStatus(database)
      setWebAppStatus(webApp)
      setWebServerStatus(webServer)
      setDashboardsStatus(dashboards)
      setAuthenticationStatus(authentication)

    }
    catch (error) {
      showAlert({
        message: 'There was an error getting the platform health',
        title: 'Error',
        variation: 'error',
      })
    }
    finally {
      setLoading(false)
    }
  }
  return {
    loading,
    status,
    databaseStatus,
    webAppStatus,
    webServerStatus,
    dashboardsStatus,
    authenticationStatus,
  }
}