import { ReactNode } from 'react'

interface Props {
  children: ReactNode,
  loading?: boolean
}

export default function Layout({ children, loading = false }: Props) {
  return (
    <div className="mx-auto max-w-8xl sm:px-6 lg:px-4" >
      { 
        loading ?
        <div className="w-full h-full flex items-center justify-center min-h-[80vh]">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
        : children 
      }
    </div>
  )
}
