import { createContext, useState, FC, ReactNode, useEffect, useMemo } from 'react'
import Loading from '~/components/generics/Loading';
import LoginAuth0 from '~/components/LoginAuth0';
import useUserAuth from '~/hooks/UseUserAuth';
import Forecast from '~/types/forecasts/Forecast';
import { Endpoints, GET } from '~/utils/API';
import SisenseDashboard from '~/types/SisenseDashboard';
import { useAlertContext } from '~/contexts/AlertContext';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

export type UserContextType = {
  userInfo: UserInfo | undefined
  setUserInfo: (user: UserInfo) => void
  loadUserInfo: () => Promise<void>
  organization: OrganizationType | undefined
  setOrganization: (organization: OrganizationType) => void
  glidePathTargets: Array<Forecast> | undefined
  setGlidePathTargets: (glidePathTargets: Array<Forecast> | undefined) => void
  dashboards: SisenseDashboard[] | undefined
  setDashboards: (dashboards: SisenseDashboard[]) => void
};

export type OrganizationType = {
  _id: string
  name: string
  city: string
  street: string
  state: string
  country: string
  type: string
  readPermission: boolean
  writePermission: boolean
  userType: string
  ghgiCategories?: string[]
  color1: string
  color2: string
  logoUrl: string
}

export type UserInfo = {
  _id: string
  firstName: string
  lastName: string
  email: string
  sisenseToken: string
  flags: Record<string, boolean>
  organizations: OrganizationType[]
};

export const UserContext = createContext<UserContextType | null>(null);

const publicRoutes = [
  '/health',
]

const UserProvider: FC<ReactNode> = ({ children }) => {

  const { isAuthenticated, isLoading, error, user } = useUserAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>();

  const [organization, setOrganization] = useState<OrganizationType>();

  const [glidePathTargets, setGlidePathTargets] = useState<Array<Forecast> | undefined>([]);
  const [dashboards, setDashboards] = useState<SisenseDashboard[]>();

  const { showAlert } = useAlertContext();
  const location = useLocation();

  useEffect(() => {
    if (!user) return
    const load = async () => {
      await Promise.all([
        loadUserInfo(),
        getDashboardInfo(),
        startAmplitudeAgent(),
      ])
    }
    load()
  }, [user])

  // useEffect(() => {
  //   console.log('Organization changed in context: ', organization)
  // }, [organization])

  const startAmplitudeAgent = async () => {
    if (user?.email) {
      amplitude.init('713bb7db1d53c5edfcba0e98d325bb99', user.email);
    } else {
      amplitude.init('713bb7db1d53c5edfcba0e98d325bb99');
    }
  }

  const loadUserInfo = async () => {
    try {
      const res = await GET({
        endpoint: Endpoints.auth.user.fetch,
      })
      console.log('Load user info response: ', res)
      let organizations = res.user.organizations.map((item: any) => {
        let organization = { ...item, ...item.organization }
        delete organization.organization;
        return organization;
      })

      let user = res.user
      delete user.organizations
      // let allOrganizations = organizations.length > 1
      //   ?[{_id: '0', name:'Master Glide Path', city:'', country:'', state: '', street:'',type:'',userType: '', readPermission: true, writePermission: true}].concat(organizations)
      //   : organizations
      user = { ...res.user, flags: res.flags, organizations };
      setUserInfo(user);
      setOrganization(organizations[0] ? { ...organizations[0] } : undefined);
    }
    catch (error: any) {
      console.log('Error in getting user information: ', error)
      showAlert({
        variation: 'error',
        message: 'Error in getting user information',
        title: 'Error'
      })
    }
  }

  const getDashboardInfo = async () => {
    try {
      const res = await GET({
        endpoint: Endpoints.auth.dashboards.fetchAll
      })
      //Dummy code for adding category
      let dashboards = [];
      if (res.dashboards)
        dashboards = res.dashboards.map((item: SisenseDashboard) => {
          const arrayOfSrings = item.title.split('-')
          const category = arrayOfSrings.pop()
          const title = arrayOfSrings.join('-')
          return { ...item, title: title?.trim(), category: category?.trim() }
        });
      else dashboards.push({ category: '', oid: '', title: '', url: '' })
      //We should also add sub-category
      setDashboards(dashboards)
    }
    catch (error: any) {
      console.log('Error in getting dashboard information: ', error)
    }
  }

  const value = useMemo(() => ({
    userInfo,
    setUserInfo,
    loadUserInfo,
    organization,
    setOrganization,
    glidePathTargets,
    setGlidePathTargets,
    dashboards,
    setDashboards
  }), [
    userInfo,
    setUserInfo,
    loadUserInfo,
    organization,
    setOrganization,
    glidePathTargets,
    setGlidePathTargets,
    dashboards,
    setDashboards
  ])

  console.log('Public path: ', location.pathname)

  return <UserContext.Provider value={value}>
    {error ? (
      <div>Oops... {(error as Error).message}</div>
    ) : isLoading ? (
      <Loading />
    ) : !isAuthenticated && !publicRoutes.includes(location.pathname) ? (
      <LoginAuth0 />
    ) : children}
  </UserContext.Provider>;
};

export default UserProvider;
