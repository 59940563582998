import useNewUserModal from './useNewUserModal';
import { UserModel } from '../UserManagement'
import Input from '~/components/Input';
import Select from '~/components/FormSelect';
import Modal from '~/components/Modal';

interface Props {
  open: boolean,
  onClose: () => void,
  onAddUser: (user: UserModel) => void,
  organizationId: string,
}

const NewUserModal = ({ open, onClose, onAddUser, organizationId }: Props) => {
  const {
    loading,
    form,
    handleNewUser,
    cleanForm,
  } = useNewUserModal({ onClose, onAddUser, organizationId })

  return <Modal
    title="Add new user"
    open={open}
    onClose={cleanForm}
    actionButtons={[
      {
        label: 'Cancel',
        variant: 'ghost',
        type: 'button',
        disabled: loading,
        onClick: () => cleanForm()
      },
      {
        label: 'Add User',
        variant: 'primary',
        type: 'button',
        loading: loading,
        disabled: loading || !form.formState.isDirty || !form.formState.isValid,
        onClick: () => handleNewUser()
      }
    ]}
  >
    <div className="w-full pb-4 px-6">
      <div className="flex justify-between items-start w-full">
        <div className="flex flex-col w-5/12 mb-2">
          <Input
            label="First Name"
            name="firstName"
            value={form.watch('firstName')}
            options={{ required: 'User name is required' }}
            register={form.register}
          />
        </div>
        <div className="flex flex-col w-5/12 mb-2">
          <Input
            label="Last Name"
            name="lastName"
            value={form.watch('lastName')}
            options={{ required: 'User last name is required' }}
            register={form.register}
          />
        </div>
      </div>
      <Input
        label="Email"
        name="email"
        value={form.watch('email')}
        options={{ required: 'Email address is required' }}
        register={form.register}
      />
      <div className="flex justify-between items-end w-full mt-4">
        <Select
          label="User Type"
          name="userType"
          options={{ required: 'User type is required' }}
          register={form.register}
          control={form.control}
          value={form.watch('userType')}
          defaultValue={{ name: 'Regular', value: 'REGULAR', id: 'REGULAR' }}
          selectOptions={[
            { name: 'Administrator', value: 'ADMIN', id: 'ADMIN' },
            { name: 'Regular', value: 'REGULAR', id: 'REGULAR' },
          ]}
        />
        <div className="flex mb-2 ml-8">
          <input
            id="writePermission"
            aria-describedby="writePermission"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 cursor-pointer"
            {...form.register('writePermission')}
          />
          <div className="ml-2 text-sm w-max">
            <label htmlFor="writePermission" className="text-sm text-gray-500 leading-3 m-0 p-0 cursor-pointer">
              Write Permission
            </label>
          </div>
        </div>
      </div>
    </div>
  </Modal>
}

export default NewUserModal
