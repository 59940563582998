import { ActionType } from "../../utils/ActionTypes";
import { populateActions } from '~/utils/Utils';

export const NewFileActions = populateActions({
    deleteFile: '',
    setFile: '',
});

export type NewFileState = {
    files: {[fileId: string]: { file: File, fileUrl: string }}
}

/**
 * Function called to update a new file state
 *
 * @param state: the current state
 * @param action: the action to update the state with
 */
export default function newFileReducer(state: NewFileState, action: ActionType): NewFileState {
    switch (action.type) {

        case NewFileActions.deleteFile: {
            const newFiles = {...state.files};
            delete newFiles[action.payload];
            return {
                ...state,
                files: newFiles
            };
        }

        case NewFileActions.setFile: {
            const { fileId, file, fileUrl } = action.payload;
            return {
                ...state,
                files: {
                    ...state.files,
                    [fileId]: {
                        file,
                        fileUrl,
                    }
                }
            };
        }

        default: {
            return state;
        }
    }
}