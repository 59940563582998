import { ReactNode } from 'react'
import SideNav from "~/components/SideNav"
import { LayoutHeader } from '~/components/LayoutHeader'
import classNames from '~/utils/classNames'

interface Props {
  hideOrganization?: boolean
  children?: ReactNode
  pageTitle?: ReactNode | string
  pageSubTitle?: ReactNode | string
  pageIcon?: ReactNode
  fullWidth?: boolean
}

export function PageLayout({
  children,
  hideOrganization = false,
  fullWidth = false,
  pageTitle,
  pageSubTitle,
  pageIcon,
}: Props) {
  return (
    <div className="h-[100vh] w-[100vw] overflow-hidden flex flex-col lg:flex-row">
      <SideNav
        hideOrganization={hideOrganization}
      />
      <main className="flex-grow overflow-auto">
        <LayoutHeader title={pageTitle} icon={pageIcon}>
          {pageSubTitle}
        </LayoutHeader>
        <div className={classNames("overflow-auto", fullWidth ? "" : "mx-auto max-w-7xl px-1 sm:px-6 lg:px-8 mb-4 sm:mb-8 lg:mb-16")}>
          {children}
        </div>
      </main>
    </div>
  )
}