import { ReactNode, useState } from "react";
import useErrorSnackbar from "./UseErrorSnackbar";
import { SnackbarDisplayType } from "../components/generics/Snackbar";

export type UseAsyncCallType = {
    isCalling: boolean
    makeAsyncCall: (promise: Promise<any>) => Promise<any>
    setIsCalling: (val: boolean) => void
    setSnackbarError: (val: {message: string, type?: SnackbarDisplayType} | null) => void
    SnackbarComponent: ReactNode
}

const useAsyncCall = (shouldAutoDismissError: boolean = false): UseAsyncCallType => {

    const [ isCalling, setIsCalling ] = useState(false);
    const { SnackbarComponent, setSnackbarError } = useErrorSnackbar(shouldAutoDismissError);

    /**
     * Function called to make an async call while maintaining state of isCalling, error showing, etc
     *
     * @param promise: the promise of the function called
     */
    const makeAsyncCall = (promise: Promise<any>) => {
        setIsCalling(true);
        return promise
            .then((result: any) => {
                setSnackbarError(null);
                setIsCalling(false);
                return result;
            }, (err) => {
                setSnackbarError({ message: err.message });
                setIsCalling(false);
                throw err
            });
    };

    return {
        isCalling,
        makeAsyncCall,
        setIsCalling,
        setSnackbarError,
        SnackbarComponent,
    }
};

export default useAsyncCall;