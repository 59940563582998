import { ReactNode } from 'react'
import { IconContext } from 'react-icons'

interface Props {
  children: ReactNode
}

const value = {
  className: 'text-2xl',
}

/** The main purpose of this is to be able to place icons without having to manually specifiy each one use the all-so-common text-2xl class.
 * Without that class the default icon size is about half as small as it should be. */
export function ReactIconProvider({ children } : Props) {
  return <IconContext.Provider value={value}>
    {children}
  </IconContext.Provider>
}
