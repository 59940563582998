import React from 'react'
import { Dispatch } from 'redux'

import useAsyncCall from '../../hooks/UseAsyncCall'
import Modal from '../Modal'
import FileData from '../../types/FileData'
import DocumentsActions from '../../actions/DocumentsActions'

interface Props {
  dispatch: Dispatch<any>
  onClose: (didDeleteFile: boolean) => void
  open: boolean
  fileToDelete: FileData
}

const DeleteDocumentDialog = ({ dispatch, onClose, open, fileToDelete } : Props) => {

  const deleteDocumentCall = useAsyncCall(true)

  /**
   * Function called to upload the currently selected files
   */
   const deleteFile = () => {
    deleteDocumentCall.makeAsyncCall(DocumentsActions.deleteDocument(fileToDelete.fileId))
      .then((res) => {
        dispatch(res)
        onClose(true)
      })
      .catch((err) => {
        console.error(err)
        onClose(false)
      })
  }

  return (<>
    <Modal
      title="Delete File"
      description={`Are you sure you want to delete the file: ${fileToDelete.fileName}?`}
      actionButtons={[
        {
          label: 'Cancel',
          variant: 'ghost',
          type: 'button',
          disabled: deleteDocumentCall.isCalling,
          onClick: () => onClose(false)
        },
        {
          label: 'Delete',
          variant: 'danger',
          type: 'button',
          loading: deleteDocumentCall.isCalling,
          onClick: () => deleteFile()
        }
      ]}
      open={open}
      onClose={() => !deleteDocumentCall.isCalling && onClose(false)}
    />
    {deleteDocumentCall.SnackbarComponent}
  </>)
}

export default DeleteDocumentDialog
